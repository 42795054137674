import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  Retailer,
  ListQueries,
  ListResponse,
  RetailerBody,
} from 'resources/types';
import services from 'services';

const GET_RETAILERS = 'admin/retailers/getRetailers';
const CREATE_RETAILER = 'admin/retailers/createRetailer';
const DELETE_RETAILER = 'admin/retailers/deleteRetailer';
const CHANGE_RETAILER_COMPANY_STATUS = 'admin/retailers/changeRetailerCompanyStatus';

const getRetailers = createAsyncThunk(
  GET_RETAILERS,
  async (query?: ListQueries): Promise<ListResponse<Retailer>> => {
    const response = await services.getRetailers(query);
    return response.data;
  },
);

const createRetailer = createAsyncThunk(
  CREATE_RETAILER,
  async (data: RetailerBody): Promise<Retailer> => {
    const response = await services.createRetailer(data);
    return response.data;
  },
);

const deleteRetailer = createAsyncThunk(
  DELETE_RETAILER,
  async (retailerId: number): Promise<{ value: boolean }> => {
    const response = await services.deleteRetailer(retailerId);
    return response.data;
  },
);

const changeRetailerCompanyStatus = createAsyncThunk(
  CHANGE_RETAILER_COMPANY_STATUS,
  async (retailer: Retailer): Promise<{ value: boolean }> => {
    const response = await (retailer.company.status === 'SUSPENDED' ? (
      services.reactivateRetailerCompany(retailer.id)
    ) : (
      services.suspendRetailerCompany(retailer.id)
    ));
    return response.data;
  },
);

export {
  getRetailers,
  createRetailer,
  deleteRetailer,
  changeRetailerCompanyStatus,
};
