import { AxiosResponse } from 'axios';

import { trackEvent } from 'mixpanel';
import { downloadAdWeek } from 'utils';
import instance from 'services/instance';
import { endpoints } from 'resources/constants';
import { ListResponse, RetailerAd, RetailerWeeklyMargins } from 'resources/types';
import { adManagerEvent } from 'resources/constants/mixpanel';

const getRetailerAds = (companyId: number): Promise<AxiosResponse<ListResponse<RetailerAd>>> => (
  instance.get(
    `${endpoints.RETAILER_ADS(companyId)}?sort=year,ASC&sort=startDate,ASC&size=2000`,
    {
      failureMessage: 'Could not get retailer as weeks',
    },
  )
);

const exportRetailerAd = (companyId: number, adWeek: RetailerAd): Promise<unknown> => (
  instance.get(
    endpoints.RETAILER_AD_EXPORT(companyId, adWeek.id),
    {
      responseType: 'blob',
      failureMessage: 'Could download ad week file',
      mixpanelEvent: {
        EventName: adManagerEvent.exportAd,
        Value: adWeek.adName,
      },
    },
  )
    .then((response: AxiosResponse) => {
      const { data, headers } = response;
      const contentDisposition: string = headers['content-disposition'];
      return downloadAdWeek(data, adWeek, contentDisposition);
    })
);

const exportMultipleRetailerAds = (
  companyId: number,
  adWeeks: Array<RetailerAd>,
): Promise<unknown> => {
  const promises = adWeeks.map((adWeek) => (
    instance.get(
      endpoints.RETAILER_AD_EXPORT(companyId, adWeek.id),
      { responseType: 'blob', failureMessage: 'Could download ad week file' },
    )
      .then((response: AxiosResponse) => {
        const { data, headers } = response;
        const contentDisposition: string = headers['content-disposition'];
        return downloadAdWeek(data, adWeek, contentDisposition);
      })));

  return Promise.allSettled(promises)
    .then((results) => {
      results.forEach((result) => {
        if (result.status === 'fulfilled') {
          trackEvent({
            Status: 'success',
            EventName: adManagerEvent.exportMultipleAds,
          });
        } else {
          trackEvent({
            Status: 'fail',
            EventName: adManagerEvent.exportMultipleAds,
          });
        }
      });
    });
};

const getRetailerAdWeeklyMargins = (
  companyId: number,
  adId: number,
): Promise<AxiosResponse<RetailerWeeklyMargins>> => (
  instance.get(
    endpoints.RETAILER_AD(companyId, adId),
    {
      failureMessage: 'Something went, could not get weekly margins.',
    },
  )
);

export default {
  getRetailerAds,
  exportRetailerAd,
  exportMultipleRetailerAds,
  getRetailerAdWeeklyMargins,
};
