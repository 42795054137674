/* eslint-disable no-param-reassign */

import { Theme } from '@mui/material';
import { localStorageKeys } from 'resources/constants';
import {
  RetailerImpItem,
  RetailerImpItemExcelResultRow,
  RetailerSandbox,
  RetailerSandboxDeleteItemsRequestBody,
  RetailerSandboxDepartment,
  RetailerSandboxDepartmentCheckboxState,
  RetailerSandboxErrorData,
  RetailerSandboxItem,
  RetailerSandboxItemCheckboxState,
  RetailerSandboxItemErrorData,
  RetailerSandboxItemsDeletionOptions,
  RetailerSandboxOfferCheckboxState,
  RetailerSandboxOfferErrorData,
  RetailerSandboxOfferPlacement,
  RetailerSandboxOfferPriceRequestOffer,
  RetailerSandboxOfferPricesRequestBody,
  RetailerSandboxOffersUploadingBody,
  RetailerSandboxOffersUploadingResponse,
  RetailerSandboxStatus,
  RetailerSandboxStorageKey,
  RetailerSandboxVendor,
  RetailerWeeklyMargins,
  SandboxFilterByType,
  TableCol,
  TableDataSource,
} from 'resources/types';
import { showMessage } from 'store';
import {
  decimalToPercent,
  decimalToPercentWithCommas,
  getStoredValueForKey,
  removeStoredValueForKey,
  setStoredValueForKey,
  trimUpc,
  validateItemLinkCode,
  withCommas,
} from 'utils';

const insertInArrayAtIndex = <T>(array: Array<T>, index: number, item: T) => (
  [...array.slice(0, index), item, ...array.slice(index)]
);

export const getRetailerSandboxStoredValueForKey = (
  adId: number | null,
  key: RetailerSandboxStorageKey,
) => {
  if (adId == null) {
    return null;
  }

  const valueForKey = getStoredValueForKey(localStorageKeys.AD_SANDBOX, key);
  if (valueForKey == null) {
    return null;
  }

  try {
    const valueByAds = JSON.parse(valueForKey);
    if (typeof valueByAds !== 'object') {
      return null;
    }
    return valueByAds[adId] ?? null;
  } catch (e) {
    return null;
  }
};

export const setRetailerSandboxStoredValueForKey = (
  adId: number | null,
  key: RetailerSandboxStorageKey,
  value: string,
) => {
  if (adId == null) {
    return;
  }

  const valueForKey = getStoredValueForKey(localStorageKeys.AD_SANDBOX, key);
  if (valueForKey == null) {
    setStoredValueForKey(localStorageKeys.AD_SANDBOX, key, JSON.stringify({ [adId]: value }));
    return;
  }

  try {
    const valueByAds = JSON.parse(valueForKey);
    valueByAds[adId] = value;
    setStoredValueForKey(localStorageKeys.AD_SANDBOX, key, JSON.stringify(valueByAds));
  } catch (e) {
    setStoredValueForKey(localStorageKeys.AD_SANDBOX, key, JSON.stringify({ [adId]: value }));
  }
};

export const removeRetailerSandboxStoredValueForKey = (
  adId: number | null,
  key: RetailerSandboxStorageKey,
) => {
  if (adId == null) {
    return;
  }

  const valueForKey = getStoredValueForKey(localStorageKeys.AD_SANDBOX, key);

  if (valueForKey == null) {
    return;
  }

  try {
    const valueByAds = JSON.parse(valueForKey);
    delete valueByAds[adId];
    if (Object.keys(valueByAds).length) {
      setStoredValueForKey(localStorageKeys.AD_SANDBOX, key, JSON.stringify(valueByAds));
      return;
    }

    removeStoredValueForKey(localStorageKeys.AD_SANDBOX, key);
  } catch (e) {
    removeStoredValueForKey(localStorageKeys.AD_SANDBOX, key);
  }
};

const getSandboxDuplicatedItems = (
  sandboxDepartments: Array<RetailerSandboxDepartment> = [],
): { duplicateItems: number[], duplicateOffers: number[] } => {
  const duplicateItems: number[] = [];
  const duplicateOffers: number[] = [];

  const sandboxItems: Record<string, {
    hasDuplicate: boolean;
    itemIds: number[];
    offerIds: number[];
  }> = {};

  sandboxDepartments.forEach((department) => (
    department.sandboxOffers.forEach((offer) => {
      if (!offer.selected) {
        return;
      }
      offer.sandboxItems.forEach((item) => {
        if (!item.selected) {
          return;
        }
        if (!sandboxItems[item.upc]) {
          sandboxItems[item.upc] = {
            hasDuplicate: false,
            itemIds: [],
            offerIds: [],
          };
        } else {
          sandboxItems[item.upc].hasDuplicate = true;
        }
        sandboxItems[item.upc].itemIds.push(item.id);
        sandboxItems[item.upc].offerIds.push(offer.id);
      });
    })
  ));

  Object.values(sandboxItems).forEach((item) => {
    const { hasDuplicate, itemIds, offerIds } = item;
    if (hasDuplicate) {
      duplicateItems.push(...itemIds);
      duplicateOffers.push(...offerIds);
    }
  });

  return { duplicateItems, duplicateOffers };
};

export const generateSandboxDepartmentsErrorsData = (
  sandboxDepartments: Array<RetailerSandboxDepartment> = [],
): RetailerSandboxErrorData => {
  const { duplicateItems, duplicateOffers } = getSandboxDuplicatedItems(sandboxDepartments);

  const departmentsErrorData: RetailerSandboxErrorData = {};

  sandboxDepartments.forEach((department) => {
    let offerErrorCount = 0;
    let offerWarningCount = 0;
    const offersErrorData: Record<number, RetailerSandboxOfferErrorData> = {};

    department.sandboxOffers.forEach((offer) => {
      const itemsErrorData: Record<number, RetailerSandboxItemErrorData> = {};
      let itemErrorCount = 0;

      offer.sandboxItems.forEach((item) => {
        const adUnitCost = item.adUnitCost == null && item.selected;
        const duplicating = duplicateItems.includes(item.id) && item.selected;

        const itemProjectedAdVolume = item.projectedAdVolumeManual == null ? (
          item.projectedAdVolume
        ) : (
          item.projectedAdVolumeManual
        );

        const projectedAdVolume = (
          item.selected
          && (itemProjectedAdVolume == null || itemProjectedAdVolume < 0.5)
        );

        const error = duplicating || adUnitCost || projectedAdVolume;
        if (error) {
          itemErrorCount += 1;
        }

        itemsErrorData[item.id] = {
          id: item.id,
          error,
          adUnitCost,
          duplicating,
          projectedAdVolume,
        };
      });

      const placement = !offer.placement && offer.selected;
      const duplicating = duplicateOffers.includes(offer.id) && offer.selected;

      const offerProjectedAdVolume = offer.projectedAdVolumeManual == null ? (
        offer.projectedAdVolume
      ) : (
        offer.projectedAdVolumeManual
      );

      const offerProjAdVolError = (
        offer.selected
        && (offerProjectedAdVolume == null || offerProjectedAdVolume < 0.5)
      );

      const itemProjAdVolError = Object.values(itemsErrorData).some(
        (itemErrorData) => itemErrorData.projectedAdVolume,
      );

      const prjAdVolErrorCondition = offerProjAdVolError || itemProjAdVolError;

      const error = (itemErrorCount > 0 || prjAdVolErrorCondition || duplicating);

      const offerPrice = offer.offerPrice == null;

      if (error) {
        offerErrorCount += 1;
      }
      if (placement) {
        offerWarningCount += 1;
      }

      offersErrorData[offer.id] = {
        id: offer.id,
        error,
        warning: placement,
        placement,
        offerPrice,
        duplicating,
        projectedAdVolume: prjAdVolErrorCondition,
        items: itemsErrorData,
      };
    });

    departmentsErrorData[department.id] = {
      id: department.id,
      offers: offersErrorData,
      errorCount: offerErrorCount,
      warningCount: offerWarningCount,
    };
  });

  return departmentsErrorData;
};

export const validateSandboxForFinalize = (
  departmentsErrorsData: RetailerSandboxErrorData,
  sandboxStatus: RetailerSandboxStatus,
): {
  isValid: boolean;
  needsConfirmation: boolean;
} => {
  if (sandboxStatus !== 'SANDBOX_CALCULATED') {
    showMessage({
      messages: ['Sandbox must be calculated'],
      options: { variant: 'error' },
    });
    return { isValid: false, needsConfirmation: false };
  }

  const missingPlacement = Object.values(departmentsErrorsData).some(
    (departmentErrorData) => Object.values(departmentErrorData.offers).some(
      (offerErrorData) => offerErrorData.placement,
    ),
  );
  if (missingPlacement) {
    return { isValid: true, needsConfirmation: true };
  }

  return { isValid: true, needsConfirmation: false };
};

export const getAdSandboxMarginsTableData = (
  sandbox: RetailerSandbox | null,
  weeklyMargins: RetailerWeeklyMargins | null,
): Array<{ title: string, value: string }> => (
  [
    {
      title: 'Date From',
      value: weeklyMargins?.startDate || '-',
    },
    {
      title: 'Date To',
      value: weeklyMargins?.endDate || '-',
    },
    {
      title: 'Name',
      value: weeklyMargins?.adName || '-',
    },
    {
      title: 'Total Proj. Margin',
      value: sandbox?.totalProjectedMargin ? `${decimalToPercentWithCommas(sandbox.totalProjectedMargin)}%` : '-',
    },
    {
      title: 'Projected Ad Margin',
      value: sandbox?.projectedAdMargin ? `${decimalToPercentWithCommas(sandbox.projectedAdMargin)}%` : '-',
    },
    {
      title: 'Acceptable Ad Loss',
      value: sandbox?.acceptableAdLoss ? `$${withCommas(sandbox.acceptableAdLoss)}` : '-',
    },
    {
      title: 'Total Adjusted Proj. Margin',
      value: sandbox?.adjustedProjectedMargin ? `${decimalToPercentWithCommas(sandbox.adjustedProjectedMargin)}%` : '-',
    },
    {
      title: 'Projected Ad Margin',
      value: sandbox?.projectedAdMarginAmount != null ? `$${withCommas(sandbox.projectedAdMarginAmount)}` : '-',
    },
  ]
);

export const getSandboxItemsDeletionOptions = ({
  departments = [],
  selectedPlacements,
  filterBy,
  sandboxErrorData,
  deletionOptions,
}: {
  departments?: RetailerSandboxDepartment[],
  selectedPlacements: RetailerSandboxOfferPlacement[],
  filterBy: SandboxFilterByType,
  sandboxErrorData: RetailerSandboxErrorData,
  deletionOptions?: RetailerSandboxItemsDeletionOptions,
}): RetailerSandboxItemsDeletionOptions => {
  const checkboxStates: RetailerSandboxDepartmentCheckboxState[] = [];
  const isPlacementSelected = selectedPlacements.length > 0;
  let allSelected = true;
  let disableRemoveBtn = true;

  const checkboxPrevState: Record<'department' | 'offer' | 'item', Record<number, boolean>> = {
    department: {},
    offer: {},
    item: {},
  };
  if (deletionOptions) {
    deletionOptions.checkboxStates.forEach((departmentState) => {
      checkboxPrevState.department[departmentState.id] = departmentState.checked;
      departmentState.offers.forEach((offerState) => {
        checkboxPrevState.offer[offerState.id] = offerState.checked;
        offerState.items.forEach((itemState) => {
          checkboxPrevState.item[itemState.id] = itemState.checked;
        });
      });
    });
  }

  departments.forEach((department) => {
    const departmentChecked = checkboxPrevState.department[department.id] ?? false;

    const departmentCheckboxState: RetailerSandboxDepartmentCheckboxState = {
      id: department.id,
      checked: departmentChecked,
      offers: [],
    };
    const departmentErrorData = sandboxErrorData[department.id];

    department.sandboxOffers.forEach((offer) => {
      const excludedByPlacement = isPlacementSelected && (
        !offer?.placement || !selectedPlacements.includes(offer.placement)
      );
      const offerErrorData = departmentErrorData?.offers[offer.id];
      const excludedOfferByFilter = filterBy !== null && !offerErrorData?.error;

      if (excludedByPlacement || excludedOfferByFilter) {
        return;
      }

      const offerChecked = checkboxPrevState.offer[offer.id] ?? false;

      const offerCheckboxState: RetailerSandboxOfferCheckboxState = {
        id: offer.id,
        checked: offerChecked,
        items: [],
      };

      offer.sandboxItems.forEach((item) => {
        const itemErrorData = offerErrorData?.items[item.id];
        const excludedItemByFilter = filterBy !== null && !itemErrorData?.error;

        if (excludedItemByFilter) {
          return;
        }

        const itemChecked = checkboxPrevState.item[item.id] ?? false;

        const itemCheckboxState: RetailerSandboxItemCheckboxState = {
          id: item.id,
          checked: itemChecked,
        };

        if (itemCheckboxState.checked) {
          disableRemoveBtn = false;
        } else {
          offerCheckboxState.checked = false;
        }
        offerCheckboxState.items.push(itemCheckboxState);
      });

      if (!offerCheckboxState.checked) {
        departmentCheckboxState.checked = false;
      }
      departmentCheckboxState.offers.push(offerCheckboxState);
    });

    if (!departmentCheckboxState.checked) {
      allSelected = false;
    }
    checkboxStates.push(departmentCheckboxState);
  });

  return {
    allSelected,
    checkboxStates,
    disableRemoveBtn,
  };
};

const updateSandboxItemsDeletionOptions = (
  checkboxStates: RetailerSandboxDepartmentCheckboxState[],
  checked: boolean,
  departmentId: number,
  offerId: number,
  itemId: number,
): RetailerSandboxDepartmentCheckboxState[] => checkboxStates.map(
  (departmentState) => {
    const updatedOffers = departmentState.offers.map(
      (offerState) => {
        const updatedItems = offerState.items.map(
          (itemState) => {
            if (itemState.id === itemId) {
              return { ...itemState, checked };
            }
            return itemState;
          },
        );

        return {
          ...offerState,
          items: updatedItems,
          checked: offerState.id !== offerId
            ? offerState.checked
            : !updatedItems.some(
              (itemState) => !itemState.checked,
            ),
        };
      },
    );

    return {
      ...departmentState,
      offers: updatedOffers,
      checked: departmentState.id !== departmentId
        ? departmentState.checked
        : !updatedOffers.some((offerState) => !offerState.checked),
    };
  },
);

const updateSandboxOffersDeletionOptions = (
  checkboxStates: RetailerSandboxDepartmentCheckboxState[],
  checked: boolean,
  departmentId: number,
  offerId: number,
): RetailerSandboxDepartmentCheckboxState[] => checkboxStates.map(
  (departmentState) => {
    const updatedOffers = departmentState.offers.map(
      (offerState) => {
        if (offerState.id === offerId) {
          return {
            ...offerState,
            checked,
            items: offerState.items.map(
              (itemState) => ({ ...itemState, checked }),
            ),
          };
        }
        return offerState;
      },
    );

    return {
      ...departmentState,
      offers: updatedOffers,
      checked: departmentState.id !== departmentId
        ? departmentState.checked
        : !updatedOffers.some(
          (offerState) => !offerState.checked,
        ),
    };
  },
);

const updateSandboxDepartmentsDeletionOptions = (
  checkboxStates: RetailerSandboxDepartmentCheckboxState[],
  checked: boolean,
  departmentId: number,
): RetailerSandboxDepartmentCheckboxState[] => checkboxStates.map(
  (departmentState) => {
    if (departmentState.id === departmentId) {
      return {
        ...departmentState,
        checked,
        offers: departmentState.offers.map(
          (offerState) => ({
            ...offerState,
            checked,
            items: offerState.items.map(
              (itemState) => ({
                ...itemState,
                checked,
              }),
            ),
          }),
        ),
      };
    }
    return departmentState;
  },
);

export const updateSandboxDeletionOptions = ({
  checkboxStates,
  checked,
  departmentId,
  offerId,
  itemId,
}: {
  checkboxStates: RetailerSandboxDepartmentCheckboxState[];
  checked: boolean;
  departmentId?: number;
  offerId?: number;
  itemId?: number;
}): RetailerSandboxItemsDeletionOptions => {
  let newCheckboxStates = checkboxStates;

  if (departmentId && offerId && itemId) {
    newCheckboxStates = updateSandboxItemsDeletionOptions(
      checkboxStates,
      checked,
      departmentId,
      offerId,
      itemId,
    );
  } else if (departmentId && offerId) {
    newCheckboxStates = updateSandboxOffersDeletionOptions(
      checkboxStates,
      checked,
      departmentId,
      offerId,
    );
  } else if (departmentId) {
    newCheckboxStates = updateSandboxDepartmentsDeletionOptions(
      checkboxStates,
      checked,
      departmentId,
    );
  }

  const allSelected = !newCheckboxStates.some((departmentState) => !departmentState.checked);
  const disableRemoveBtn = !newCheckboxStates.some((departmentState) => (
    departmentState.offers.some((offerState) => (
      offerState.items.some((itemState) => itemState.checked)
    ))
  ));

  return {
    allSelected,
    disableRemoveBtn,
    checkboxStates: newCheckboxStates,
  };
};

export const getSandboxDeletionItemsRequestBody = (
  departments: RetailerSandboxDepartment[],
  checkboxStates: RetailerSandboxDepartmentCheckboxState[],
): RetailerSandboxDeleteItemsRequestBody => {
  const sandboxItemsCountData: Record<'departments' | 'offers', Record<number, number>> = {
    departments: {},
    offers: {},
  };

  departments.forEach((department) => {
    const { id: departmentId } = department;
    sandboxItemsCountData.departments[departmentId] = 0;

    department.sandboxOffers.forEach((offer) => {
      const { id: offerId, sandboxItems } = offer;
      const itemsCount = sandboxItems.length;
      sandboxItemsCountData.departments[departmentId] += itemsCount;
      sandboxItemsCountData.offers[offerId] = itemsCount;
    });
  });

  const requestBody: RetailerSandboxDeleteItemsRequestBody = {
    departments: [],
    offers: [],
    items: [],
  };

  checkboxStates.forEach((departmentState) => {
    const itemsCountInDepartment = sandboxItemsCountData.departments[departmentState.id];
    const filteredItemsCountInDepartment = departmentState.offers.reduce(
      (itemsCountInOffer, { items }) => (
        itemsCountInOffer + items.length
      ),
      0,
    );

    if (departmentState.checked && itemsCountInDepartment === filteredItemsCountInDepartment) {
      requestBody.departments!.push(departmentState.id);
      return;
    }

    departmentState.offers.forEach((offerState) => {
      const itemsCountInOffer = sandboxItemsCountData.offers[offerState.id];
      const filteredItemsCountInOffer = offerState.items.length;

      if (offerState.checked && itemsCountInOffer === filteredItemsCountInOffer) {
        requestBody.offers!.push(offerState.id);
        return;
      }
      offerState.items.forEach((itemState) => {
        if (itemState.checked) {
          requestBody.items!.push(itemState.id);
        }
      });
    });
  });

  return requestBody;
};

export const mergeAdSandboxImpItemsWithExcelResult = (
  upcList: string[],
  impItems: RetailerImpItem[],
  excelResult: RetailerImpItemExcelResultRow[],
  selectedPlacement: RetailerSandboxOfferPlacement,
): Record<string, RetailerImpItem[]> => {
  const itemsGroups = impItems.reduce<Record<string, RetailerImpItem[]>>(
    (total, importedItem) => {
      const importedItemTrimmedUpc = trimUpc(importedItem.upc);

      const excelRow = excelResult.find(
        (row) => {
          if (!row.upc) {
            return false;
          }
          const trimmedRowUpc = trimUpc(row.upc);
          if (trimmedRowUpc === importedItemTrimmedUpc) {
            return true;
          }
          if (importedItem.commodityUpcs) {
            return importedItem.commodityUpcs.some(
              (commodityUpc) => trimmedRowUpc === trimUpc(commodityUpc),
            );
          }
          return false;
        },
      );

      const memo = excelRow?.memo;
      const adUnitCost = Number(excelRow?.adUnitCost);
      const offerPrice = Number(excelRow?.offerPrice);
      const promoNumber = Number(excelRow?.promoNumber);
      const blockNumber = Number(excelRow?.blockNumber);

      let checked = upcList.includes(importedItemTrimmedUpc);
      if (!checked && importedItem.commodityUpcs !== undefined) {
        checked = importedItem.commodityUpcs.some(
          (commodityUpc) => upcList.includes(trimUpc(commodityUpc)),
        );
      }

      const item: RetailerImpItem = {
        ...importedItem,
        upc: importedItemTrimmedUpc,
        checked,
        ...(memo ? { memo } : {}),
        ...(Number.isNaN(adUnitCost) ? {} : { adUnitCost }),
        ...(Number.isNaN(offerPrice) ? {} : { offerPrice }),
        ...(Number.isNaN(promoNumber) ? {} : { promoNumber }),
        ...(Number.isNaN(blockNumber) ? {} : { blockNumber }),
        placement: selectedPlacement,
      };

      if (validateItemLinkCode(item.linkCode)) {
        const groupCode = item.linkCode || '';
        item.groupCode = groupCode;
        return {
          ...total,
          [groupCode]: total[groupCode] ? [...total[groupCode], item] : [item],
        };
      }

      item.groupCode = item.upc;
      return {
        ...total,
        [item.upc]: [item],
      };
    },
    {},
  );

  Object.keys(itemsGroups).forEach(
    (groupCode: string) => {
      const group = itemsGroups[groupCode];
      const firstAdUnitCost = group.find((item) => typeof item.adUnitCost === 'number');
      const firstOfferPrice = group.find((item) => typeof item.offerPrice === 'number');
      group.forEach((item: RetailerImpItem) => {
        item.adUnitCost = firstAdUnitCost?.adUnitCost;
        item.offerPrice = firstOfferPrice?.offerPrice;
      });
    },
  );

  return itemsGroups;
};

export const orderSandboxDepartmentColumns = (
  columns: Array<TableCol<RetailerSandboxDepartment, undefined>>,
  columnsKeys: Array<string>,
): Array<TableCol<RetailerSandboxDepartment, undefined>> => {
  const [checkbox, arrow, ...orderingColumns] = columns;
  const empty = orderingColumns.pop() as TableCol<RetailerSandboxDepartment, undefined>;
  const orderedColumns = columnsKeys.reduce(
    (totalColumns: Array<TableCol<RetailerSandboxDepartment, undefined>>, columnKey: string) => {
      const departmentColumn = orderingColumns.find((col) => col.key === columnKey);
      return departmentColumn ? [...totalColumns, departmentColumn] : totalColumns;
    },
    [],
  );
  return [checkbox, arrow, ...orderedColumns, empty];
};

export const orderSandboxItemsColumns = <T extends TableDataSource, U extends TableDataSource>(
  columns: TableCol<T, U>[],
  columnsKeys: string[],
): TableCol<T, U>[] => {
  const [
    checkbox,
    arrow,
    selectedIcon,
    effect,
    expandIcon,
    duplicateIcon,
    deleteIcon,
    empty,
    ...orderingColumns
  ] = columns;

  const orderedColumns = columnsKeys.reduce<TableCol<T, U>[]>(
    (totalColumns, columnKey) => {
      const departmentColumn = orderingColumns.find((col) => col.key === columnKey);
      return departmentColumn ? [...totalColumns, departmentColumn] : totalColumns;
    },
    [],
  );

  const effectIndex = orderedColumns.findIndex((col) => col.key === 'lift') + 1;
  const step1 = insertInArrayAtIndex(orderedColumns, effectIndex, effect);

  const expandIconIndex = step1.findIndex((col) => col.key === 'offerGrossMargin') + 1;
  const step2 = insertInArrayAtIndex(step1, expandIconIndex, expandIcon);

  return [checkbox, arrow, selectedIcon, ...step2, duplicateIcon, deleteIcon, empty];
};

export const generateSandboxOfferUploadingBody = (
  impItemsGroups: { [key: string]: Array<RetailerImpItem> },
): RetailerSandboxOffersUploadingBody => {
  const uploadingItems = Object.keys(impItemsGroups)
    .reduce(
      (total: RetailerSandboxOffersUploadingBody['items'], groupCode: string) => {
        const currentGroupsSelectedItems = impItemsGroups[groupCode]
          ?.filter((item) => item.checked) || [];
        if (currentGroupsSelectedItems.length) {
          const [firstItem] = currentGroupsSelectedItems;
          const sandboxItems = currentGroupsSelectedItems.map((item) => ({ upc: item.upc }));
          const uploadingOffer = {
            sandboxItems,
            linkCode: firstItem.linkCode || '',
            offerName: firstItem.description || '',
          };
          return [...total, uploadingOffer];
        }
        return total;
      },
      [],
    );
  return { items: uploadingItems };
};

export const getRelativeNumbersHighlightColor = (
  params: {
    first: string,
    second: string,
    theme: Theme,
    sandboxStatus?: RetailerSandboxStatus | null,
  },
) => {
  const {
    first, second, theme, sandboxStatus,
  } = params;
  const firstPercent: number = decimalToPercent(Number(first));
  const secondPercent: number = decimalToPercent(Number(second));
  const color = firstPercent >= secondPercent ? (
    theme.palette.success.light
  ) : (
    theme.palette.deepOrange.main
  );
  const hightLightCondition = (
    first != null
    && second != null
    && sandboxStatus === 'SANDBOX_CALCULATED'
  );
  return hightLightCondition ? color : 'transparent';
};

export const getSandboxCalculationErrors = (
  sandboxDepartmentsErrorData: RetailerSandboxErrorData,
): Array<string> => {
  const calculationErrors: Array<string> = [];
  const duplicationMessage: string = 'There are duplicate items in the Sandbox';
  const dealCostOfferPriceMessage: string = 'Deal Cost or Offer Price are missing';
  const projectedAdVolumeMessage: string = 'Projected Ad Volume can\'t be 0 or empty';

  let duplicateItems = 0;
  let itemsWithoutDealCost = 0;
  let itemsWithoutProjectedAdVolume = 0;
  let offersWithoutOfferPrice = 0;

  Object.values(sandboxDepartmentsErrorData).forEach((sandboxDepartmentErrorData) => {
    Object.values(sandboxDepartmentErrorData.offers).forEach((sandboxOfferErrorData) => {
      if (sandboxOfferErrorData.offerPrice && sandboxOfferErrorData.error) {
        offersWithoutOfferPrice += 1;
      }
      Object.values(sandboxOfferErrorData.items).forEach((sandboxItemErrorData) => {
        if (sandboxItemErrorData.duplicating && sandboxItemErrorData.error) {
          duplicateItems += 1;
        }
        if (sandboxItemErrorData.adUnitCost && sandboxItemErrorData.error) {
          itemsWithoutDealCost += 1;
        }
        if (sandboxItemErrorData.projectedAdVolume && sandboxItemErrorData.error) {
          itemsWithoutProjectedAdVolume += 1;
        }
      });
    });
  });

  if (duplicateItems > 0) {
    calculationErrors.push(duplicationMessage);
  }
  if (itemsWithoutDealCost > 0 || offersWithoutOfferPrice > 0) {
    calculationErrors.push(dealCostOfferPriceMessage);
  }
  if (itemsWithoutProjectedAdVolume > 0) {
    calculationErrors.push(projectedAdVolumeMessage);
  }

  return calculationErrors;
};

export const generateOffersPricesBatchRequestData = (
  impItemsGroups: { [key: string]: RetailerImpItem[] },
  uploadedOffers: RetailerSandboxOffersUploadingResponse['items'],
): RetailerSandboxOfferPricesRequestBody => {
  const sandboxOfferPriceRequestOffers = uploadedOffers.reduce(
    (total: Array<RetailerSandboxOfferPriceRequestOffer>, uploadedOffer) => {
      const { sandboxItems } = uploadedOffer;
      const [firstItem] = sandboxItems;

      const selectedImpItemsGroup = Object.values(impItemsGroups)
        .find((impItemsGroup: Array<RetailerImpItem>) => (
          impItemsGroup.some((impItem) => trimUpc(impItem.upc) === trimUpc(firstItem?.upc))
        ));

      if (selectedImpItemsGroup && selectedImpItemsGroup.length) {
        const [firsItem] = selectedImpItemsGroup;
        const placement = firsItem.placement ? firsItem.placement : null;
        const offerPrice = firsItem.offerPrice != null ? firsItem.offerPrice : null;

        const memo = firsItem.memo != null ? firsItem.memo : undefined;
        const adUnitCost = firsItem.adUnitCost != null ? firsItem.adUnitCost : undefined;
        const promoNumber = firsItem.promoNumber != null ? firsItem.promoNumber : undefined;
        const blockNumber = firsItem.blockNumber != null ? firsItem.blockNumber : undefined;

        const itemsData = uploadedOffer.sandboxItems.map((item) => ({
          id: item.id,
          upc: item.upc,
          adUnitCost,
          baseRetailPrice: item.baseRetailPrice,
          memo,
        }));

        const sandboxOfferPriceRequestOffer: RetailerSandboxOfferPriceRequestOffer = {
          offerPrice,
          memo,
          placement,
          promoNumber,
          blockNumber,
          id: uploadedOffer.id,
          sandboxItems: itemsData,
        };

        return [...total, sandboxOfferPriceRequestOffer];
      }
      return total;
    },
    [],
  );
  return { items: sandboxOfferPriceRequestOffers };
};

export const orderSandboxVendorsColumns = (
  columns: TableCol<RetailerSandboxVendor, RetailerSandboxItem>[],
  columnsKeys: string[],
): Array<TableCol<RetailerSandboxVendor, RetailerSandboxItem>> => {
  const [vendorName, empty, ...orderingColumns] = columns;

  const orderedColumns = columnsKeys.reduce(
    (totalColumns: Array<TableCol<any, any>>, columnKey: string) => {
      const departmentColumn = orderingColumns.find((col) => col.key === columnKey);
      return departmentColumn ? [...totalColumns, departmentColumn] : totalColumns;
    },
    [],
  );

  return [vendorName, ...orderedColumns, empty];
};
