import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  ListQueries,
  ListResponse,
  RetailerStore,
  RetailerStoreBody,
  RetailerStoreGroup,
} from 'resources/types';
import services from 'services';

const GET_RETAILER_STORES = 'retailer/stores/getRetailerStores';
const DELETE_RETAILER_STORE = 'retailer/stores/deleteRetailerStore';
const CREATE_RETAILER_STORE = 'retailer/stores/createRetailerStore';
const UPDATE_RETAILER_STORE = 'retailer/stores/updateRetailerStore';
const GET_RETAILER_STORE_GROUPS = 'retailer/stores/getRetailerStoreGroups';

const getRetailerStores = createAsyncThunk<
  ListResponse<RetailerStore>,
  { companyId: number, query?: ListQueries }
>(
  GET_RETAILER_STORES,
  async (params) => {
    const { companyId, query } = params;
    const response = await services.getRetailerStores(companyId, query);
    return response.data;
  },
);

const createRetailerStore = createAsyncThunk<
  RetailerStore,
  { companyId: number, data: RetailerStoreBody }
>(
  CREATE_RETAILER_STORE,
  async (params) => {
    const { companyId, data } = params;
    const response = await services.createRetailerStore(companyId, data);
    return response.data;
  },
);

const updateRetailerStore = createAsyncThunk<
  RetailerStore,
  { companyId: number, storeId: number, data: RetailerStoreBody }
>(
  UPDATE_RETAILER_STORE,
  async (params) => {
    const { companyId, storeId, data } = params;
    const response = await services.updateRetailerStore(companyId, storeId, data);
    return response.data;
  },
);

const deleteRetailerStore = createAsyncThunk<
  { value: boolean },
  { companyId: number, storeId: number }
>(
  DELETE_RETAILER_STORE,
  async (params) => {
    const { companyId, storeId } = params;
    const response = await services.deleteRetailerStore(companyId, storeId);
    return response.data;
  },
);

const getRetailerStoreGroups = createAsyncThunk<
  ListResponse<RetailerStoreGroup>,
  number
>(
  GET_RETAILER_STORE_GROUPS,
  async (companyId) => {
    const response = await services.getRetailerStoreGroups(companyId);
    return response.data;
  },
);

export {
  getRetailerStores,
  createRetailerStore,
  updateRetailerStore,
  deleteRetailerStore,
  getRetailerStoreGroups,
};
