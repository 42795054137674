import { AxiosResponse } from 'axios';
import instance from 'services/instance';
import { endpoints } from 'resources/constants';
import { RetailerImpItem, ListQueries, ListResponse } from 'resources/types';
import { adWeekSetupEvent, upcBatchUploadEvent } from 'resources/constants/mixpanel';

const getRetailerImpItems = (
  companyId: number,
  adId: number,
  params?: ListQueries,
): Promise<AxiosResponse<ListResponse<RetailerImpItem>>> => (
  instance.get(
    endpoints.RETAILER_IMP_ITEMS(companyId, adId),
    {
      params,
      failureMessage: 'Could not get retailer imp items',
      mixpanelEvent: !params?.search
        ? undefined
        : {
          EventName: adWeekSetupEvent.searchUPC,
          Value: params.search,
        },
    },
  )
);

const getRetailerImpItemsByLinkCodes = (
  companyId: number,
  adId: number,
  data?: { items: string[] },
): Promise<AxiosResponse<ListResponse<RetailerImpItem>>> => (
  instance.post(
    endpoints.RETAILER_IMP_ITEMS_BY_LINK_CODES(companyId, adId),
    data,
    {
      failureMessage: 'Could not get retailer imp items',
    },
  )
);

const getRetailerImpItemsByUPC = (
  companyId: number,
  adId: number,
  data: { items: string[] },
): Promise<AxiosResponse<ListResponse<RetailerImpItem>>> => (
  instance.post(
    endpoints.RETAILER_IMP_ITEMS_BY_UPC(companyId, adId),
    data,
    {
      failureMessage: 'Could not get retailer imp items',
      mixpanelEvent: {
        EventName: upcBatchUploadEvent.confirmGetSelectedUPCs,
      },
    },
  )
);

export default {
  getRetailerImpItems,
  getRetailerImpItemsByUPC,
  getRetailerImpItemsByLinkCodes,
};
