import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import './App.css';
import store from 'store';
import theme from 'resources/theme';
import { Router, NotifierProvider } from 'components/main';
import * as Sentry from '@sentry/react';
import ErrorPage from 'components/main/ErrorPage';

function App() {
  return (
    <Sentry.ErrorBoundary fallback={<ErrorPage />}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <Provider store={store}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <NotifierProvider>
                <DndProvider backend={HTML5Backend}>
                  <Router />
                </DndProvider>
              </NotifierProvider>
            </LocalizationProvider>
          </Provider>
        </ThemeProvider>
      </BrowserRouter>
    </Sentry.ErrorBoundary>
  );
}

export default App;
