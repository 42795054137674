import { AxiosResponse } from 'axios';

import {
  User,
  ProfileBody,
  ResetPasswordData,
  ChangePasswordBody,
  UpdatePasswordData,
  UploadingRequestMethod,
} from 'resources/types';
import { retry } from 'utils';
import instance from 'services/instance';
import { endpoints } from 'resources/constants';
import { profileManagementEvent } from 'resources/constants/mixpanel';

const getProfile = (): Promise<AxiosResponse<User>> => (
  instance.get(endpoints.USER_PROFILE)
);

const updateProfile = (data: ProfileBody): Promise<AxiosResponse<User>> => (
  instance.put(
    endpoints.USER_PROFILE,
    data,
    {
      successMessage: 'Your profile successfully saved.',
      failureMessage: 'Something went wrong, could not update profile.',
      mixpanelEvent: {
        EventName: profileManagementEvent.editAccount,
      },
    },
  )
);

const changePassword = (data: ChangePasswordBody): Promise<AxiosResponse<{ value: boolean }>> => (
  instance.put(
    endpoints.USER_PROFILE_CHANGE_PASSWORD,
    data,
    {
      successMessage: 'Your password successfully changed.',
      failureMessage: 'Something went wrong, could not update password.',
    },
  )
);

const resetPassword = (params: ResetPasswordData): Promise<AxiosResponse<{ value: boolean }>> => (
  instance.get(
    endpoints.USER_PASSWORD_RESET,
    {
      params,
      successMessage: 'We have sent you an email with a link to reset your password.',
      failureMessage: 'Something went wrong, could not reset password',
    },
  )
);

const checkAuthKey = (email: string, key: string): Promise<AxiosResponse<{ value: boolean }>> => (
  instance.get(endpoints.USER_CHECK_AUTH_KEY(email, key))
);

const changePasswordWithKey = (
  email: string,
  key: string,
  data: UpdatePasswordData,
): Promise<AxiosResponse<{ value: boolean }>> => (
  instance.put(
    endpoints.USER_CHANGE_PASSWORD_WITH_KEY(email, key),
    data,
    {
      successMessage: 'Your password successfully changed',
      failureMessage: 'Could not update password',
    },
  )
);

const getProfilePhoto = (): Promise<AxiosResponse<Blob>> => (
  instance.get(endpoints.USER_PROFILE_AVATAR, { responseType: 'blob' })
);

const deleteProfilePhoto = async (): Promise<AxiosResponse<{ value: boolean }>> => (
  instance.delete(
    endpoints.USER_PROFILE_AVATAR,
    {
      failureMessage: 'Could not delete profile photo',
    },
  )
);

const uploadProfilePhoto = async (
  file: File,
  method: UploadingRequestMethod,
): Promise<AxiosResponse<Blob> | null> => {
  const formData = new FormData();

  const newName = file.name; // TODO: rename file
  const newFile = new File([file], newName, { type: file.type, lastModified: file.lastModified });

  formData.set('file', newFile);

  try {
    await instance({
      method,
      data: formData,
      url: endpoints.USER_PROFILE_AVATAR,
      headers: { 'Content-Type': 'multipart/form-data' },
      successMessage: 'Successfully uploaded',
      failureMessage: 'Could not upload the image. Please try later',
    });

    const response: void | AxiosResponse<Blob> = await retry(getProfilePhoto);
    return response || null;
  } catch (error) {
    return null;
  }
};

export default {
  getProfile,
  checkAuthKey,
  resetPassword,
  updateProfile,
  changePassword,
  getProfilePhoto,
  deleteProfilePhoto,
  uploadProfilePhoto,
  changePasswordWithKey,
};
