import { AxiosResponse } from 'axios';

import {
  Retailer,
  ListQueries,
  ListResponse,
  RetailerBody,
} from 'resources/types';
import instance from 'services/instance';
import { endpoints } from 'resources/constants';

const getRetailers = (params?: ListQueries): Promise<AxiosResponse<ListResponse<Retailer>>> => (
  instance.get(
    endpoints.ADMIN_RETAILERS,
    {
      params,
      failureMessage: 'Could not get retailers',
    },
  )
);

const createRetailer = (data: RetailerBody): Promise<AxiosResponse<Retailer>> => (
  instance.post(
    endpoints.ADMIN_RETAILERS,
    data,
    {
      successMessage: 'The retailer created successfully',
      failureMessage: 'Could not create retailer',
    },
  )
);

const deleteRetailer = (retailerId: number): Promise<AxiosResponse<{ value: boolean }>> => (
  instance.delete(
    endpoints.ADMIN_RETAILER(retailerId),
    {
      successMessage: 'The retailer deleted successfully',
      failureMessage: 'Could not delete retailer data',
    },
  )
);

const suspendRetailerCompany = (
  retailerId: number,
): Promise<AxiosResponse<{ value: boolean }>> => (
  instance.put(
    endpoints.ADMIN_RETAILER_SUSPEND_COMPANY(retailerId),
    {
      successMessage: 'The retailer company suspended successfully',
      failureMessage: 'Could not suspended retailer company',
    },
  )
);

const reactivateRetailerCompany = (
  retailerId: number,
): Promise<AxiosResponse<{ value: boolean }>> => (
  instance.put(
    endpoints.ADMIN_RETAILER_REACTIVATE_COMPANY(retailerId),
    {
      successMessage: 'The retailer company reactivated successfully',
      failureMessage: 'Could not reactivate retailer company',
    },
  )
);

export default {
  getRetailers,
  createRetailer,
  deleteRetailer,
  suspendRetailerCompany,
  reactivateRetailerCompany,
};
