import { combineReducers } from '@reduxjs/toolkit';

import stores, { resetRetailerStoresState } from './stores';
import company, { resetRetailerCompanyState } from './company';
import margins, { resetRetailerMarginsState } from './margins';
import adWeeks, { resetRetailerAdWeeksState } from './adWeeks';
import sandbox, { resetRetailerSandboxState } from './sandbox';
import impItems, { resetRetailerImpItemsState } from './impItems';
import employees, { resetRetailerEmployeesState } from './employees';
import warehouses, { resetRetailerWarehousesState } from './warehouses';
import rulesSetupReducers, {
  resetRetailerRoundingRulesState,
  resetRetailerRulesSetupRootState,
} from './rulesSetup';
import marginEnhancementReducers, {
  resetRetailerMERootState,
  resetRetailerBasePriceSandboxState,
} from './marginEnhancement';

const retailerReducers = combineReducers({
  stores: stores.reducer,
  company: company.reducer,
  adWeeks: adWeeks.reducer,
  sandbox: sandbox.reducer,
  margins: margins.reducer,
  impItems: impItems.reducer,
  employees: employees.reducer,
  warehouses: warehouses.reducer,
  rulesSetup: rulesSetupReducers,
  marginEnhancement: marginEnhancementReducers,
});

export {
  resetRetailerMERootState,
  resetRetailerStoresState,
  resetRetailerMarginsState,
  resetRetailerAdWeeksState,
  resetRetailerCompanyState,
  resetRetailerSandboxState,
  resetRetailerImpItemsState,
  resetRetailerEmployeesState,
  resetRetailerWarehousesState,
  resetRetailerRoundingRulesState,
  resetRetailerRulesSetupRootState,
  resetRetailerBasePriceSandboxState,
};

export default retailerReducers;
