import {
  Grid,
  Link,
  Theme,
  Typography,
} from '@mui/material';

import { Layout } from 'components/main';
import signInCover from 'assets/covers/sign_in_cover.jpg';
import SignInForm from './SignInForm';

function SignIn() {
  return (
    <Layout
      form={<SignInForm />}
      coverImage={signInCover}
      content={(
        <Grid container direction="column" justifyContent="end">
          <Typography
            variant="h4"
            fontWeight="bold"
            sx={(theme: Theme) => ({ color: theme.palette.common.white })}
          >
            Well, Hello Profitability!
          </Typography>
          <Typography
            variant="h6"
            fontWeight="bold"
            sx={(theme: Theme) => ({ mt: 2, color: theme.palette.common.white })}
          >
            {`At Puzl, we are all about one thing - helping independent grocers like you achieve
            profits you've only dreamt of. If you need help using Puzl more effectively, please
            reach out to our Profit Growth Team at`}
            <Link
              href="mailto:profit@puzl.ai"
              sx={(theme: Theme) => ({ marginLeft: 0.5, color: theme.palette.info.main })}
            >
              profit@puzl.ai
            </Link>
            .
          </Typography>
        </Grid>
      )}
    />
  );
}

export default SignIn;
