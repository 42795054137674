import { Suspense, ReactNode } from 'react';
import './index.css';
import CircularProgress from '@mui/material/CircularProgress';

function withSuspense(Component: ReactNode) {
  return (
    <Suspense fallback={(
      <div className="lazy-loader">
        <CircularProgress color="secondary" />
      </div>
    )}
    >
      {Component}
    </Suspense>
  );
}
export default withSuspense;
