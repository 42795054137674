import moment from 'moment';
import { createAsyncThunk } from '@reduxjs/toolkit';

import services from 'services';
import { RetailerAd, RetailerSandboxStatus } from 'resources/types';

const GET_RETAILER_ME_CURRENT_ADS = 'retailer/marginEnhancement/root/getRetailerMECurrentAds';
const GET_RETAILER_SANDBOX_STATUS = 'retailer/marginEnhancement/root/getRetailerSandboxStatus';

const getRetailerMECurrentAds = createAsyncThunk<
  Array<RetailerAd>,
  number
>(
  GET_RETAILER_ME_CURRENT_ADS,
  async (companyId) => {
    const startDate = moment().format('YYYY-MM-DD');
    const response = await services.getRetailerCurrentAds(companyId, { startDate, type: 'WEEKLY' });
    return response.data.items;
  },
);

const getRetailerSandboxStatus = createAsyncThunk<
  RetailerSandboxStatus,
  { companyId: number, adId: number }
>(
  GET_RETAILER_SANDBOX_STATUS,
  async (params) => {
    const { companyId, adId } = params;
    const response = await services.getRetailerSandboxStatus(companyId, adId);
    return response.data.value;
  },
);

export { getRetailerMECurrentAds, getRetailerSandboxStatus };
