import {
  Grid,
  Theme,
  Typography,
} from '@mui/material';

import { Layout } from 'components/main';
import forgotPasswordCover from 'assets/covers/forgot_password_cover.jpg';
import ForgotPasswordForm from './ForgotPasswordForm';

function ForgotPassword() {
  return (
    <Layout
      form={<ForgotPasswordForm />}
      coverImage={forgotPasswordCover}
      content={(
        <Grid container direction="column" justifyContent="start" pt={6}>
          <Typography
            variant="h4"
            fontWeight="bold"
            sx={(theme: Theme) => ({ color: theme.palette.common.white })}
          >
            {'Doh! Don\'t Worry.'}
            <br />
            {'We\'ve Got You Covered.'}
          </Typography>
          <Typography
            variant="h6"
            fontWeight="bold"
            sx={(theme: Theme) => ({ mt: 2, color: theme.palette.common.white })}
          >
            {'We all have senior moments. We\'ll have you back on Puzl in jiffy.'}
          </Typography>
        </Grid>
      )}
    />
  );
}

export default ForgotPassword;
