import moment from 'moment';
import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  RetailerAd,
  ListQueries,
  ListResponse,
  RetailerFreezeWeek,
  RetailerWeeklyMargins,
  RetailerQuarterLiftBody,
  RetailerWeeklyMarginsBody,
  RetailerCategoryWeeklyMargin,
  RetailerCategoryAnnualMargin,
  RetailerAdWeekCalculationBody,
  RetailerQuartersLiftsResponse,
  RetailerDepartmentAnnualMargin,
  RetailerDepartmentWeeklyMargin,
  RetailerChangeCategoryOrderBody,
  RetailerChangeDepartmentOrderBody,
  RetailerDepartmentAnnualMarginResponse,
  RetailerCategoryWeeklyBudgetedShrinkBody,
} from 'resources/types';
import services from 'services';
import { RootState } from 'store';
import { percentToDecimal } from 'utils';

const GET_RETAILER_CURRENT_ADS = 'retailer/margins/getRetailerCurrentAds';
const GET_AVAILABLE_FREEZE_WEEKS = 'retailer/margins/getAvailableFreezeWeeks';
const GET_RETAILER_CURRENT_AD_YEAR = 'retailer/margins/getRetailerCurrentAdYear';
const GET_RETAILER_AVAILABLE_YEARS = 'retailer/margins/getRetailerAvailableYears';
const GET_RETAILER_AD_WEEKLY_MARGINS = 'retailer/margins/getRetailerAdWeeklyMargins';
const CHANGE_RETAILER_CATEGORY_ORDER = 'retailer/margins/changeRetailerCategoryOrder';
const GET_RETAILER_ADS_QUARTERS_LIFTS = 'retailer/margins/getRetailerAdsQuartersLifts';
const UPDATE_RETAILER_ADS_QUARTER_LIFT = 'retailer/margins/updateRetailerAdsQuarterLift';
const CONFIRM_RETAILER_AD_WEEK_MARGINS = 'retailer/margins/confirmRetailerAdWeekMargins';
const CHANGE_RETAILER_DEPARTMENT_ORDER = 'retailer/margins/changeRetailerDepartmentOrder';
const UPDATE_RETAILER_AD_REFERENCE_WEEK = 'retailer/margins/updateRetailerAdReferenceWeek';
const GET_RETAILER_COMPANY_ANNUAL_MARGIN = 'retailer/margins/getRetailerCompanyAnnualMargin';
const UPDATE_RETAILER_COMPANY_ANNUAL_MARGIN = 'retailer/margins/updateRetailerCompanyAnnualMargin';
const UPDATE_RETAILER_CATEGORY_ANNUAL_MARGIN = 'retailer/margins/updateRetailerCategoryAnnualMargin';
const UPDATE_RETAILER_CATEGORY_WEEKLY_MARGIN = 'retailer/margins/updateRetailerCategoryWeeklyMargin';
const GET_RETAILER_DEPARTMENTS_ANNUAL_MARGINS = 'retailer/margins/getRetailerDepartmentsAnnualMargins';
const UPDATE_RETAILER_DEPARTMENT_ANNUAL_MARGIN = 'retailer/margins/updateRetailerDepartmentAnnualMargin';
const UPDATE_RETAILER_DEPARTMENT_WEEKLY_MARGIN = 'retailer/margins/updateRetailerDepartmentWeeklyMargin';
const CALCULATE_RETAILER_AD_WEEK_PROJECTED_SALES = 'retailer/margins/calculateRetailerAdWeekProjectedSales';
const FILL_DOWN_RETAILER_DEPARTMENT_ANNUAL_MARGINS = 'retailer/margins/fillDownRetailerDepartmentAnnualMargins';
const FILL_DOWN_RETAILER_DEPARTMENT_WEEKLY_MARGINS = 'retailer/margins/fillDownRetailerDepartmentWeeklyMargins';
const UPDATE_RETAILER_CATEGORY_ANNUAL_BUDGETED_SHRINK = 'retailer/margins/updateRetailerCategoryAnnualBudgetedShrink';
const UPDATE_RETAILER_CATEGORY_WEEKLY_BUDGETED_SHRINK = 'retailer/margins/updateRetailerCategoryWeeklyBudgetedShrink';
const UPDATE_RETAILER_DEPARTMENT_ANNUAL_BUDGETED_SHRINK = 'retailer/margins/updateRetailerDepartmentAnnualBudgetedShrink';
const GET_RETAILER_DEPARTMENTS_CATEGORIES_WEEKLY_MARGINS = 'retailer/margins/getRetailerDepartmentsCategoriesWeeklyMargins';
const FILL_DOWN_RETAILER_DEPARTMENT_ANNUAL_BUDGETED_SHRINKS = 'retailer/margins/fillDownRetailerDepartmentAnnualBudgetedShrinks';
const FILL_DOWN_RETAILER_DEPARTMENT_WEEKLY_BUDGETED_SHRINKS = 'retailer/margins/fillDownRetailerDepartmentWeeklyBudgetedShrinks';

const getRetailerCompanyAnnualMargin = createAsyncThunk<
  number,
  { companyId: number, query?: ListQueries }
>(
  GET_RETAILER_COMPANY_ANNUAL_MARGIN,
  async (params) => {
    const { companyId, query } = params;
    const response = await services.getRetailerCompanyAnnualMargin(companyId, query);
    return response.data.value;
  },
);

const updateRetailerCompanyAnnualMargin = createAsyncThunk<
  number,
  { companyId: number, marginTarget: number, year: number }
>(
  UPDATE_RETAILER_COMPANY_ANNUAL_MARGIN,
  async (params) => {
    const { companyId, marginTarget, year } = params;
    const response = await services.updateRetailerCompanyAnnualMargin(
      companyId,
      { marginTarget, year },
    );
    return response.data.value;
  },
);

const getRetailerCurrentAds = createAsyncThunk<Array<RetailerAd>, number>(
  GET_RETAILER_CURRENT_ADS,
  async (companyId) => {
    const startDate = moment().format('YYYY-MM-DD');
    const response = await services.getRetailerCurrentAds(companyId, { startDate, type: 'WEEKLY' });
    return response.data.items;
  },
);

const getRetailerCurrentAdYear = createAsyncThunk<number, number>(
  GET_RETAILER_CURRENT_AD_YEAR,
  async (companyId) => {
    const response = await services.getRetailerCurrentAdYear(companyId);
    return response.data.value;
  },
);

const getRetailerAvailableYears = createAsyncThunk<Array<number>, number>(
  GET_RETAILER_AVAILABLE_YEARS,
  async (companyId) => {
    const response = await services.getRetailerAvailableYears(companyId);
    return response.data.items;
  },
);

const getRetailerDepartmentsAnnualMargins = createAsyncThunk<
  ListResponse<RetailerDepartmentAnnualMargin>,
  { companyId: number, query?: ListQueries, refetch?: boolean }
>(
  GET_RETAILER_DEPARTMENTS_ANNUAL_MARGINS,
  async (params) => {
    const { companyId, query } = params;
    const response = await services.getRetailerDepartmentsAnnualMargins(companyId, query);
    return response.data;
  },
);

const updateRetailerDepartmentAnnualMargin = createAsyncThunk<
  RetailerDepartmentAnnualMarginResponse,
  {
    year: number,
    companyId: number,
    departmentId: number,
    marginTarget: number,
  }
>(
  UPDATE_RETAILER_DEPARTMENT_ANNUAL_MARGIN,
  async (params) => {
    const {
      companyId, departmentId, marginTarget, year,
    } = params;
    const response = await services.updateRetailerDepartmentAnnualMargin(
      companyId,
      departmentId,
      { marginTarget, year },
    );
    const [departmentAnnualMarginsData] = response.data;
    return departmentAnnualMarginsData;
  },
);

const updateRetailerDepartmentAnnualBudgetedShrink = createAsyncThunk<
  RetailerDepartmentAnnualMarginResponse,
  {
    year: number,
    companyId: number,
    departmentId: number,
    averageWeeklyBudgetedShrinkVolume: number,
  }
>(
  UPDATE_RETAILER_DEPARTMENT_ANNUAL_BUDGETED_SHRINK,
  async (params) => {
    const {
      companyId, departmentId, averageWeeklyBudgetedShrinkVolume, year,
    } = params;
    const response = await services.updateRetailerDepartmentAnnualBudgetedShrink(
      companyId,
      departmentId,
      { averageWeeklyBudgetedShrinkVolume, year },
    );
    const [departmentAnnualMarginsData] = response.data;
    return departmentAnnualMarginsData;
  },
);

const fillDownRetailerDepartmentAnnualMargins = createAsyncThunk<
  Array<RetailerCategoryAnnualMargin>,
  {
    year: number,
    companyId: number,
    departmentId: number,
  }
>(
  FILL_DOWN_RETAILER_DEPARTMENT_ANNUAL_MARGINS,
  async (params) => {
    const { companyId, departmentId, year } = params;
    const response = await services.fillDownRetailerDepartmentAnnualMargins(
      companyId,
      departmentId,
      { value: year },
    );
    return response.data;
  },
);

const fillDownRetailerDepartmentWeeklyMargins = createAsyncThunk<
  Array<RetailerCategoryWeeklyMargin>,
  {
    companyId: number,
    adId: number,
    departmentId: number
  }
>(
  FILL_DOWN_RETAILER_DEPARTMENT_WEEKLY_MARGINS,
  async (params) => {
    const { companyId, adId, departmentId } = params;
    const response = await services.fillDownRetailerDepartmentWeeklyMargins(
      companyId,
      adId,
      departmentId,
    );
    return response.data;
  },
);

const fillDownRetailerDepartmentAnnualBudgetedShrinks = createAsyncThunk<
  Array<RetailerCategoryAnnualMargin>,
  {
    year: number,
    companyId: number,
    departmentId: number,
  }
>(
  FILL_DOWN_RETAILER_DEPARTMENT_ANNUAL_BUDGETED_SHRINKS,
  async (params) => {
    const { companyId, departmentId, year } = params;
    const response = await services.fillDownRetailerDepartmentAnnualBudgetedShrinks(
      companyId,
      departmentId,
      { value: year },
    );
    return response.data;
  },
);

const fillDownRetailerDepartmentWeeklyBudgetedShrinks = createAsyncThunk<
  [RetailerDepartmentWeeklyMargin, Array<RetailerCategoryWeeklyMargin>],
  {
    companyId: number,
    adId: number,
    departmentId: number,
  }
>(
  FILL_DOWN_RETAILER_DEPARTMENT_WEEKLY_BUDGETED_SHRINKS,
  async (params, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const { companyId, adId, departmentId } = params;
    const { departmentsWeeklyBudgetShrinksInputValues } = state.retailer.margins;
    const value = departmentsWeeklyBudgetShrinksInputValues[departmentId];
    const fillDownResponse = await services.fillDownRetailerDepartmentWeeklyBudgetedShrinks(
      companyId,
      adId,
      departmentId,
      { value: percentToDecimal(Number(value)) },
    );
    const departmentResponse = await services.getRetailerDepartmentWeeklyMargins(
      companyId,
      adId,
      departmentId,
    );
    return [departmentResponse.data, fillDownResponse.data];
  },
);

const updateRetailerCategoryAnnualMargin = createAsyncThunk<
  RetailerCategoryAnnualMargin,
  {
    year: number,
    companyId: number,
    categoryId: number,
    departmentId: number,
    marginTarget: number,
  }
>(
  UPDATE_RETAILER_CATEGORY_ANNUAL_MARGIN,
  async (params) => {
    const {
      companyId, categoryId, marginTarget, year,
    } = params;
    const response = await services.updateRetailerCategoryAnnualMargin(
      companyId,
      categoryId,
      { marginTarget, year },
    );
    const [categoryAnnualMarginsData] = response.data;
    return categoryAnnualMarginsData;
  },
);

const updateRetailerCategoryWeeklyMargin = createAsyncThunk<
  RetailerCategoryWeeklyMargin,
  {
    companyId: number,
    adId: number,
    categoryId: number,
    data: RetailerWeeklyMarginsBody,
  }
>(
  UPDATE_RETAILER_CATEGORY_WEEKLY_MARGIN,
  async (params) => {
    const {
      companyId, adId, categoryId, data,
    } = params;
    const response = await services.updateRetailerCategoryWeeklyMargin(
      companyId,
      adId,
      categoryId,
      data,
    );
    return response.data;
  },
);

const updateRetailerCategoryWeeklyBudgetedShrink = createAsyncThunk<
  RetailerCategoryWeeklyMargin,
  {
    companyId: number,
    adId: number,
    categoryId: number,
    data: RetailerCategoryWeeklyBudgetedShrinkBody,
  }
>(
  UPDATE_RETAILER_CATEGORY_WEEKLY_BUDGETED_SHRINK,
  async (params) => {
    const {
      companyId, adId, categoryId, data,
    } = params;
    const response = await services.updateRetailerCategoryWeeklyBudgetedShrink(
      companyId,
      adId,
      categoryId,
      data,
    );
    return response.data;
  },
);

const updateRetailerCategoryAnnualBudgetedShrink = createAsyncThunk<
  RetailerCategoryAnnualMargin,
  {
    year: number,
    companyId: number,
    categoryId: number,
    departmentId: number,
    shrinkVolume: number,
  }
>(
  UPDATE_RETAILER_CATEGORY_ANNUAL_BUDGETED_SHRINK,
  async (params) => {
    const {
      companyId, categoryId, shrinkVolume, year,
    } = params;
    const response = await services.updateRetailerCategoryAnnualBudgetedShrink(
      companyId,
      categoryId,
      { shrinkVolume, year },
    );
    const [categoryAnnualMarginsData] = response.data;
    return categoryAnnualMarginsData;
  },
);

const getRetailerAdWeeklyMargins = createAsyncThunk<
  RetailerWeeklyMargins,
  { companyId: number, adId: number }
>(
  GET_RETAILER_AD_WEEKLY_MARGINS,
  async (params) => {
    const { companyId, adId } = params;
    const response = await services.getRetailerAdWeeklyMargins(companyId, adId);
    return response.data;
  },
);

const getRetailerDepartmentsCategoriesWeeklyMargins = createAsyncThunk<
  [Array<RetailerDepartmentWeeklyMargin>, Array<RetailerCategoryWeeklyMargin>],
  { companyId: number, adId: number }
>(
  GET_RETAILER_DEPARTMENTS_CATEGORIES_WEEKLY_MARGINS,
  async (params) => {
    const { companyId, adId } = params;
    const [departmentWeeklyMarginsResponse, categoriesWeeklyMarginsResponse] = await Promise.all([
      services.getRetailerDepartmentsWeeklyMargins(companyId, adId),
      services.getRetailerCategoriesWeeklyMargins(companyId, adId),
    ]);
    return [departmentWeeklyMarginsResponse.data, categoriesWeeklyMarginsResponse.data];
  },
);

const calculateRetailerAdWeekProjectedSales = createAsyncThunk<
  RetailerWeeklyMargins,
  {
    companyId: number,
    adId: number,
    data: RetailerAdWeekCalculationBody,
  }
>(
  CALCULATE_RETAILER_AD_WEEK_PROJECTED_SALES,
  async (params) => {
    const { companyId, adId, data } = params;
    const response = await services.calculateRetailerAdWeekProjectedSales(companyId, adId, data);
    return response.data;
  },
);

const confirmRetailerAdWeekMargins = createAsyncThunk<
  RetailerWeeklyMargins,
  { companyId: number, adId: number }
>(
  CONFIRM_RETAILER_AD_WEEK_MARGINS,
  async (params) => {
    const { companyId, adId } = params;
    const response = await services.confirmRetailerAdWeekMargins(companyId, adId);
    return response.data;
  },
);

const updateRetailerAdReferenceWeek = createAsyncThunk<
  RetailerWeeklyMargins,
  {
    companyId: number,
    adId: number,
    data: RetailerFreezeWeek
  }
>(
  UPDATE_RETAILER_AD_REFERENCE_WEEK,
  async (params) => {
    const { companyId, adId, data } = params;
    const response = await services.updateRetailerAdReferenceWeek(companyId, adId, data);
    return response.data;
  },
);

const getAvailableFreezeWeeks = createAsyncThunk<
  Array<RetailerFreezeWeek>,
  { companyId: number, adId: number }
>(
  GET_AVAILABLE_FREEZE_WEEKS,
  async (params) => {
    const { companyId, adId } = params;
    const response = await services.getAvailableFreezeWeeks(companyId, adId);
    return response.data;
  },
);

const updateRetailerDepartmentWeeklyMargin = createAsyncThunk<
  RetailerDepartmentWeeklyMargin,
  {
    companyId: number,
    adId: number,
    departmentId: number,
    data: RetailerWeeklyMarginsBody,
  }
>(
  UPDATE_RETAILER_DEPARTMENT_WEEKLY_MARGIN,
  async (params) => {
    const {
      companyId, adId, departmentId, data,
    } = params;
    const response = await services.updateRetailerDepartmentWeeklyMargin(
      companyId,
      adId,
      departmentId,
      data,
    );
    return response.data;
  },
);

const getRetailerAdsQuartersLifts = createAsyncThunk<
  RetailerQuartersLiftsResponse,
  {
    companyId: number,
    year: number
  }
>(
  GET_RETAILER_ADS_QUARTERS_LIFTS,
  async (params) => {
    const { companyId, year } = params;
    const response = await services.getRetailerAdsQuartersLifts(companyId, { year });
    return response.data;
  },
);

const updateRetailerAdsQuarterLift = createAsyncThunk<
  RetailerQuartersLiftsResponse,
  {
    companyId: number,
    data: RetailerQuarterLiftBody
  }
>(
  UPDATE_RETAILER_ADS_QUARTER_LIFT,
  async (params) => {
    const { companyId, data } = params;
    const response = await services.updateRetailerAdsQuarterLift(companyId, data);
    return response.data;
  },
);

const changeRetailerDepartmentOrder = createAsyncThunk<
  void,
  { companyId: number, data: RetailerChangeDepartmentOrderBody }
>(
  CHANGE_RETAILER_DEPARTMENT_ORDER,
  async (params) => {
    const { companyId, data } = params;
    await services.changeRetailerDepartmentOrder(companyId, data);
  },
);

const changeRetailerCategoryOrder = createAsyncThunk(
  CHANGE_RETAILER_CATEGORY_ORDER,
  async (params: { companyId: number, data: RetailerChangeCategoryOrderBody }) => {
    const { companyId, data } = params;
    await services.changeRetailerCategoryOrder(companyId, data);
  },
);

export {
  getRetailerCurrentAds,
  getAvailableFreezeWeeks,
  getRetailerCurrentAdYear,
  getRetailerAvailableYears,
  getRetailerAdWeeklyMargins,
  getRetailerAdsQuartersLifts,
  changeRetailerCategoryOrder,
  updateRetailerAdsQuarterLift,
  confirmRetailerAdWeekMargins,
  updateRetailerAdReferenceWeek,
  changeRetailerDepartmentOrder,
  getRetailerCompanyAnnualMargin,
  updateRetailerCompanyAnnualMargin,
  updateRetailerCategoryAnnualMargin,
  updateRetailerCategoryWeeklyMargin,
  getRetailerDepartmentsAnnualMargins,
  updateRetailerDepartmentAnnualMargin,
  updateRetailerDepartmentWeeklyMargin,
  calculateRetailerAdWeekProjectedSales,
  fillDownRetailerDepartmentAnnualMargins,
  fillDownRetailerDepartmentWeeklyMargins,
  updateRetailerCategoryAnnualBudgetedShrink,
  updateRetailerCategoryWeeklyBudgetedShrink,
  updateRetailerDepartmentAnnualBudgetedShrink,
  getRetailerDepartmentsCategoriesWeeklyMargins,
  fillDownRetailerDepartmentWeeklyBudgetedShrinks,
  fillDownRetailerDepartmentAnnualBudgetedShrinks,
};
