import { combineReducers } from '@reduxjs/toolkit';

import root, { resetRetailerRulesSetupRootState } from './root';
import roundingRules, { resetRetailerRoundingRulesState } from './roundingRules';

const rulesSetupReducers = combineReducers({
  root: root.reducer,
  roundingRules: roundingRules.reducer,
});

export {
  resetRetailerRoundingRulesState,
  resetRetailerRulesSetupRootState,
};

export default rulesSetupReducers;
