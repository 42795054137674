import mixPanel from 'mixpanel-browser';

import store from 'store';
import { MixpanelEvent } from 'resources/types';

const token = import.meta.env.VITE_MIX_PANEL_TOKEN;
const debug = import.meta.env.MODE === 'development';

if (token) {
  mixPanel.init(token, { debug });
}

const trackEvent = (mixpanelProperties: MixpanelEvent, errorMessage?: string) => {
  if (!token) return;
  const companyData = store.getState().retailer.company.data;
  const { EventName, ...properties } = mixpanelProperties;

  mixPanel.track(EventName, {
    ...properties,
    ...(companyData ? {
      'Company ID': companyData.id,
      'Company Name': companyData.companyName,
    } : {}),
    Reason: errorMessage,
    Status: errorMessage ? 'fail' : 'success',
  });
};

export { trackEvent };
