import { createAsyncThunk } from '@reduxjs/toolkit';

import services from 'services';
import {
  RetailerAd,
  ListQueries,
  ListResponse,
  RetailerImpItem,
  RetailerStoreGroup,
  RetailerWeeklyMargins,
  RetailerSandboxItemType,
  RetailerBasePriceSandbox,
  RetailerBasePriceSandboxItem,
  RetailerBasePriceSandboxOffer,
  RetailerItemPricesHistoryItem,
  RetailerBasePriceSandboxStatus,
  RetailerItemSalesHistoryResponse,
  RetailerBasePriceSandboxCategory,
  RetailerBasePriceSandboxTabStatus,
  RetailerBasePriceSandboxDepartment,
  RetailerBasePriceSandboxExcludedItem,
  RetailerBasePriceSandboxCategoryOrderBody,
  RetailerBasePriceSandboxOutOfPlanningItem,
  RetailerBasePriceSandboxDepartmentOrderBody,
  RetailerBasePriceSandboxNewPricesRequestBody,
  RetailerBasePriceSandboxOffersUploadingResponse,
} from 'resources/types';
import {
  getPriceChangeNameError,
  getBasePriceSandboxCalculationErrors,
  generateBasePriceSandboxOfferUploadingBody,
  getBasePriceSandboxDeletionItemsRequestBody,
} from 'utils';
import { RootState, showMessage } from 'store';

const GET_RETAILER_BASE_PRICE_SANDBOX = 'retailer/marginEnhancement/basePriceSandbox/getRetailerBasePriceSandbox';
const ROUND_RETAILER_BASE_PRICE_SANDBOX = 'retailer/marginEnhancement/basePriceSandbox/roundRetailerBasePriceSandbox';
const EXPORT_RETAILER_BASE_PRICE_SANDBOX = 'retailer/marginEnhancement/basePriceSandbox/exportRetailerBasePriceSandbox';
const ROUND_RETAILER_BASE_PRICE_SANDBOX_DEPARTMENT = 'retailer/marginEnhancement/roundRetailerBasePriceSandboxDepartment';
const GET_RETAILER_BASE_PRICE_STORE_GROUPS = 'retailer/marginEnhancement/basePriceSandbox/getRetailerBasePriceStoreGroups';
const CALCULATE_RETAILER_BASE_PRICE_SANDBOX = 'retailer/marginEnhancement/basePriceSandbox/calculateRetailerBasePriceSandbox';
const GET_RETAILER_BASE_PRICE_SANDBOX_STATUS = 'retailer/marginEnhancement/basePriceSandbox/getRetailerBasePriceSandboxStatus';
const UPDATE_RETAILER_BASE_PRICE_SANDBOX_ITEM = 'retailer/marginEnhancement/basePriceSandbox/updateRetailerBasePriceSandboxItem';
const REMOVE_RETAILER_BASE_PRICE_SANDBOX_ITEM = 'retailer/marginEnhancement/basePriceSandbox/removeRetailerBasePriceSandboxItem';
const REMOVE_RETAILER_BASE_PRICE_SANDBOX_ITEMS = 'retailer/marginEnhancement/basePriceSandbox/removeRetailerBasePriceSandboxItems';
const REMOVE_RETAILER_BASE_PRICE_SANDBOX_OFFER = 'retailer/marginEnhancement/basePriceSandbox/removeRetailerBasePriceSandboxOffer';
const UPDATE_RETAILER_BASE_PRICE_SANDBOX_OFFER = 'retailer/marginEnhancement/basePriceSandbox/updateRetailerBasePriceSandboxOffer';
const GET_RETAILER_BASE_PRICE_AD_WEEKLY_MARGINS = 'retailer/marginEnhancement/basePriceSandbox/getRetailerBasePriceAdWeeklyMargins';
const UPLOAD_RETAILER_BASE_PRICE_SANDBOX_OFFERS = 'retailer/marginEnhancement/basePriceSandbox/uploadRetailerBasePriceSandboxOffers';
const GET_RETAILER_BASE_PRICE_SANDBOX_TAB_STATUS = 'retailer/marginEnhancement/basePriceSandbox/getRetailerBasePriceSandboxTabStatus';
const ROUND_RETAILER_BASE_PRICE_SANDBOX_CATEGORY = 'retailer/marginEnhancement/basePriceSandbox/roundRetailerBasePriceSandboxCategory';
const DUPLICATE_RETAILER_BASE_PRICE_SANDBOX_ITEM = 'retailer/marginEnhancement/basePriceSandbox/duplicateRetailerBasePriceSandboxItem';
const UPDATE_RETAILER_BASE_PRICE_SANDBOX_CATEGORY = 'retailer/marginEnhancement/basePriceSandbox/updateRetailerBasePriceSandboxCategory';
const DUPLICATE_RETAILER_BASE_PRICE_SANDBOX_OFFER = 'retailer/marginEnhancement/basePriceSandbox/duplicateRetailerBasePriceSandboxOffer';
const UPDATE_RETAILER_BASE_PRICE_SANDBOX_NEW_PRICES = 'retailer/marginEnhancement/basePriceSandbox/updateRetailerBasePriceSandboxNewPrices';
const UPDATE_RETAILER_BASE_PRICE_SANDBOX_DEPARTMENT = 'retailer/marginEnhancement/basePriceSandbox/updateRetailerBasePriceSandboxDepartment';
const UPDATE_RETAILER_BASE_PRICE_SANDBOX_OFFER_NAMES = 'retailer/marginEnhancement/basePriceSandbox/updateRetailerBasePriceSandboxOfferNames';
const CHANGE_RETAILER_BASE_PRICE_SANDBOX_ITEM_STATUS = 'retailer/marginEnhancement/basePriceSandbox/changeRetailerBasePriceSandboxItemStatus';
const GET_RETAILER_BASE_PRICE_SANDBOX_EXCLUDED_ITEMS = 'retailer/marginEnhancement/basePriceSandbox/getRetailerBasePriceSandboxExcludedItems';
const REMOVE_RETAILER_BASE_PRICE_SANDBOX_EXCLUDED_ITEMS = 'retailer/marginEnhancement/basePriceSandbox/removeRetailerBasePriceSandboxExcludedItems';
const GET_RETAILER_BASE_PRICE_SANDBOX_ITEM_SALES_HISTORY = 'retailer/marginEnhancement/basePriceSandbox/getRetailerBasePriceSandboxItemSalesHistory';
const UPDATE_RETAILER_BASE_PRICE_SANDBOX_ITEM_LOCK_STATUS = 'retailer/marginEnhancement/basePriceSandbox/updateRetailerBasePriceSandboxItemLockStatus';
const CHANGE_RETAILER_BASE_PRICE_SANDBOX_DEPARTMENT_ORDER = 'retailer/sandbox/changeRetailerBasePriceSandboxDepartmentOrder';
const CHANGE_RETAILER_BASE_PRICE_SANDBOX_CATEGORY_ORDER = 'retailer/sandbox/changeRetailerBasePriceSandboxCategoryOrder';
const GET_RETAILER_BASE_PRICE_SANDBOX_ITEM_PRICES_HISTORY = 'retailer/marginEnhancement/basePriceSandbox/getRetailerBasePriceSandboxItemPricesHistory';
const UPDATE_RETAILER_BASE_PRICE_SANDBOX_OFFER_LOCK_STATUS = 'retailer/marginEnhancement/basePriceSandbox/updateRetailerBasePriceSandboxOfferLockStatus';
const GET_RETAILER_BASE_PRICE_SANDBOX_OUT_OF_PLANNING_ITEMS = 'retailer/marginEnhancement/basePriceSandbox/getRetailerBasePriceSandboxOutOfPlanningItems';
const UPDATE_RETAILER_BASE_PRICE_SANDBOX_CATEGORY_LOCK_STATUS = 'retailer/marginEnhancement/basePriceSandbox/updateRetailerBasePriceSandboxCategoryLockStatus';
const REMOVE_RETAILER_BASE_PRICE_SANDBOX_OUT_OF_PLANNING_ITEMS = 'retailer/marginEnhancement/basePriceSandbox/removeRetailerBasePriceSandboxOutOfPlanningItems';
const FILL_DOWN_RETAILER_BASE_PRICE_SANDBOX_ITEMS_NEW_UNIT_COST = 'retailer/marginEnhancement/basePriceSandbox/fillDownRetailerBasePriceSandboxItemsNewUnitCost';
const UPDATE_RETAILER_BASE_PRICE_SANDBOX_DEPARTMENT_LOCK_STATUS = 'retailer/marginEnhancement/basePriceSandbox/updateRetailerBasePriceSandboxDepartmentLockStatus';
const UPDATE_RETAILER_BASE_PRICE_SANDBOX_PROJECTED_AD_VOLUMES = 'retailer/sandbox/updateRetailerBasePriceSandboxProjectedAdVolumes';

const getRetailerBasePriceSandbox = createAsyncThunk<
  RetailerBasePriceSandbox,
  { companyId: number, adId: number, query?: ListQueries, refetch?: boolean }
>(
  GET_RETAILER_BASE_PRICE_SANDBOX,
  async (params) => {
    const { companyId, adId, query } = params;
    const response = await services.getRetailerBasePriceSandbox(companyId, adId, query);
    return response.data;
  },
);

const getRetailerBasePriceSandboxStatus = createAsyncThunk<
  RetailerBasePriceSandboxStatus,
  { companyId: number, adId: number }
>(
  GET_RETAILER_BASE_PRICE_SANDBOX_STATUS,
  async (params) => {
    const { companyId, adId } = params;
    const response = await services.getRetailerBasePriceSandboxStatus(companyId, adId);
    return response.data.value;
  },
);

const getRetailerBasePriceSandboxTabStatus = createAsyncThunk<
  RetailerBasePriceSandboxTabStatus,
  {
    companyId: number,
    adId: number
  }
>(
  GET_RETAILER_BASE_PRICE_SANDBOX_TAB_STATUS,
  async (params) => {
    const { companyId, adId } = params;
    const response = await services.getRetailerBasePriceSandboxTabStatus(companyId, adId);
    return response.data.value;
  },
);

const getRetailerBasePriceStoreGroups = createAsyncThunk<
  ListResponse<RetailerStoreGroup>,
  number
>(
  GET_RETAILER_BASE_PRICE_STORE_GROUPS,
  async (companyId) => {
    const response = await services.getRetailerStoreGroups(companyId);
    return response.data;
  },
);

const getRetailerBasePriceAdWeeklyMargins = createAsyncThunk<
  RetailerWeeklyMargins,
  { companyId: number, adId: number }
>(
  GET_RETAILER_BASE_PRICE_AD_WEEKLY_MARGINS,
  async (params) => {
    const { companyId, adId } = params;
    const response = await services.getRetailerAdWeeklyMargins(companyId, adId);
    return response.data;
  },
);

const changeRetailerBasePriceSandboxItemStatus = createAsyncThunk<
  RetailerBasePriceSandboxOffer,
  {
    companyId: number,
    adId: number,
    itemType: RetailerSandboxItemType,
    itemId: number,
    data: { value: boolean }
  }
>(
  CHANGE_RETAILER_BASE_PRICE_SANDBOX_ITEM_STATUS,
  async (
    params,
  ) => {
    const {
      companyId, adId, itemType, itemId, data,
    } = params;
    const response = await services.changeRetailerBasePriceSandboxItemStatus(
      companyId,
      adId,
      itemType,
      itemId,
      data,
    );
    return response.data;
  },
);

const removeRetailerBasePriceSandboxItem = createAsyncThunk<
  RetailerBasePriceSandbox,
  { companyId: number, adId: number, itemId: number }
>(
  REMOVE_RETAILER_BASE_PRICE_SANDBOX_ITEM,
  async (params) => {
    const { companyId, adId, itemId } = params;
    const response = await services.removeRetailerBasePriceSandboxItem(companyId, adId, itemId);
    return response.data;
  },
);

const removeRetailerBasePriceSandboxOffer = createAsyncThunk<
  RetailerBasePriceSandbox,
  { companyId: number, adId: number, offerId: number }
>(
  REMOVE_RETAILER_BASE_PRICE_SANDBOX_OFFER,
  async (params) => {
    const { companyId, adId, offerId } = params;
    const response = await services.removeRetailerBasePriceSandboxOffer(companyId, adId, offerId);
    return response.data;
  },
);

const duplicateRetailerBasePriceSandboxItem = createAsyncThunk<
  RetailerBasePriceSandboxOffer,
  { companyId: number, adId: number, itemId: number }
>(
  DUPLICATE_RETAILER_BASE_PRICE_SANDBOX_ITEM,
  async (params) => {
    const { companyId, adId, itemId } = params;
    const response = await services.duplicateRetailerBasePriceSandboxItem(companyId, adId, itemId);
    return response.data;
  },
);

const duplicateRetailerBasePriceSandboxOffer = createAsyncThunk<
  RetailerBasePriceSandboxOffer,
  { companyId: number, adId: number, offerId: number }
>(
  DUPLICATE_RETAILER_BASE_PRICE_SANDBOX_OFFER,
  async (params) => {
    const { companyId, adId, offerId } = params;
    const response = await services.duplicateRetailerBasePriceSandboxOffer(
      companyId,
      adId,
      offerId,
    );
    return response.data;
  },
);

const updateRetailerBasePriceSandboxItem = createAsyncThunk<
  RetailerBasePriceSandboxOffer,
  {
    companyId: number,
    adId: number,
    itemId: number,
    item: RetailerBasePriceSandboxItem,
  }
>(
  UPDATE_RETAILER_BASE_PRICE_SANDBOX_ITEM,
  async (params) => {
    const {
      companyId, adId, itemId, item,
    } = params;
    const response = await services.updateRetailerBasePriceSandboxItem(
      companyId,
      adId,
      itemId,
      item,
    );
    return response.data;
  },
);

const updateRetailerBasePriceSandboxCategory = createAsyncThunk<
  RetailerBasePriceSandboxCategory,
  {
    companyId: number,
    adId: number,
    categoryId: number,
    data: { [key: string]: any },
  }
>(
  UPDATE_RETAILER_BASE_PRICE_SANDBOX_CATEGORY,
  async (params) => {
    const {
      companyId, adId, categoryId, data,
    } = params;
    const response = await services.updateRetailerBasePriceSandboxCategory(
      companyId,
      adId,
      categoryId,
      data,
    );
    return response.data;
  },
);

const updateRetailerBasePriceSandboxDepartment = createAsyncThunk<
  RetailerBasePriceSandboxDepartment,
  {
    companyId: number,
    adId: number,
    departmentId: number,
    data: { [key: string]: any },
  }
>(
  UPDATE_RETAILER_BASE_PRICE_SANDBOX_DEPARTMENT,
  async (params) => {
    const {
      companyId, adId, departmentId, data,
    } = params;
    const response = await services.updateRetailerBasePriceSandboxDepartment(
      companyId,
      adId,
      departmentId,
      data,
    );
    return response.data;
  },
);

const updateRetailerBasePriceSandboxOffer = createAsyncThunk<
  RetailerBasePriceSandboxOffer,
  {
    companyId: number,
    adId: number,
    offerId: number,
    data: RetailerBasePriceSandboxOffer,
    name: string
  }
>(
  UPDATE_RETAILER_BASE_PRICE_SANDBOX_OFFER,
  async (params) => {
    const {
      companyId, adId, offerId, data, name,
    } = params;
    const response = await services.updateRetailerBasePriceSandboxOffer(
      companyId,
      adId,
      offerId,
      data,
      name,
    );
    return response.data;
  },
);

const updateRetailerBasePriceSandboxOfferNames = createAsyncThunk<
  RetailerBasePriceSandboxOffer,
  {
    companyId: number,
    adId: number,
    offerId: number,
    data: RetailerBasePriceSandboxOffer,
    name: keyof RetailerBasePriceSandboxOffer,
  }
>(
  UPDATE_RETAILER_BASE_PRICE_SANDBOX_OFFER_NAMES,
  async (params) => {
    const {
      companyId, adId, offerId, data, name,
    } = params;

    if (name === 'priceChangeName') {
      const priceChangeNameError = getPriceChangeNameError(data.priceChangeName);
      if (priceChangeNameError) {
        showMessage({ messages: [priceChangeNameError], options: { variant: 'error' } });
        return Promise.reject();
      }
    }

    const response = await services.updateRetailerBasePriceSandboxOfferNames(
      companyId,
      adId,
      offerId,
      data,
    );
    return response.data;
  },
);

const uploadRetailerBasePriceSandboxOffers = createAsyncThunk<
  RetailerBasePriceSandboxOffersUploadingResponse['items'],
  {
    companyId: number,
    adId: number,
    impItemsGroups: { [key: string]: Array<RetailerImpItem> }
  }
>(
  UPLOAD_RETAILER_BASE_PRICE_SANDBOX_OFFERS,
  async (params) => {
    const { companyId, adId, impItemsGroups } = params;
    const meBasePriceMGRSandboxOffersUploadingBody = (
      generateBasePriceSandboxOfferUploadingBody(impItemsGroups)
    );

    const response = await services.uploadRetailerBasePriceSandboxOffers(
      companyId,
      adId,
      meBasePriceMGRSandboxOffersUploadingBody,
    );
    return response.data.items;
  },
);

const updateRetailerBasePriceSandboxNewPrices = createAsyncThunk<
  RetailerBasePriceSandbox,
  {
    companyId: number,
    adId: number,
    offersData: RetailerBasePriceSandboxNewPricesRequestBody
  }
>(
  UPDATE_RETAILER_BASE_PRICE_SANDBOX_NEW_PRICES,
  async (params) => {
    const { adId, companyId, offersData } = params;
    const response = await services.updateRetailerBasePriceSandboxOffers(
      companyId,
      adId,
      offersData,
    );
    return response.data;
  },
);

const fillDownRetailerBasePriceSandboxItemsNewUnitCost = createAsyncThunk<
  RetailerBasePriceSandboxOffer,
  { companyId: number, adId: number, itemId: number }
>(
  FILL_DOWN_RETAILER_BASE_PRICE_SANDBOX_ITEMS_NEW_UNIT_COST,
  async (params) => {
    const { companyId, adId, itemId } = params;
    const response = await services.fillDownRetailerBasePriceSandboxItemsNewUnitCost(
      companyId,
      adId,
      itemId,
    );
    return response.data;
  },
);

const calculateRetailerBasePriceSandbox = createAsyncThunk<
  RetailerBasePriceSandbox,
  { companyId: number, adId: number },
  { rejectValue: string[] }
>(
  CALCULATE_RETAILER_BASE_PRICE_SANDBOX,
  async (params, thunkAPI) => {
    const { companyId, adId } = params;
    const state = thunkAPI.getState() as RootState;
    const {
      basePriceSandboxDepartmentsErrorsData,
    } = state.retailer.marginEnhancement.basePriceSandbox;
    const calculationErrors = getBasePriceSandboxCalculationErrors(
      basePriceSandboxDepartmentsErrorsData,
    );
    if (calculationErrors.length) {
      throw thunkAPI.rejectWithValue(calculationErrors);
    }
    const response = await services.calculateRetailerBasePriceSandbox(companyId, adId);
    return response.data;
  },
);

const removeRetailerBasePriceSandboxItems = createAsyncThunk<
  void,
  { companyId: number, adId: number }
>(
  REMOVE_RETAILER_BASE_PRICE_SANDBOX_ITEMS,
  async (params, thunkAPI) => {
    const { companyId, adId } = params;
    const state = thunkAPI.getState() as RootState;
    const {
      basePriceSandbox,
      deletionOptions,
      filterBy,
    } = state.retailer.marginEnhancement.basePriceSandbox;

    let allDepartmentsSelected = deletionOptions.allSelected;
    if (filterBy !== null) {
      allDepartmentsSelected = false;
    }

    if (allDepartmentsSelected) {
      await services.removeRetailerBasePriceSandboxAllItems(companyId, adId);
      return;
    }

    const deletionItemsRequestBody = getBasePriceSandboxDeletionItemsRequestBody(
      basePriceSandbox?.baseTprSandboxDepartments || [],
      deletionOptions.checkboxStates,
    );
    await services.removeRetailerBasePriceSandboxSelectedItems(
      companyId,
      adId,
      deletionItemsRequestBody,
    );
  },
);

const getRetailerBasePriceSandboxExcludedItems = createAsyncThunk<
  Array<RetailerBasePriceSandboxExcludedItem>,
  {
    companyId: number,
    adId: number,
  }
>(
  GET_RETAILER_BASE_PRICE_SANDBOX_EXCLUDED_ITEMS,
  async (params) => {
    const { companyId, adId } = params;
    const response = await services.getRetailerBasePriceSandboxExcludedItems(companyId, adId);
    return response.data;
  },
);

const removeRetailerBasePriceSandboxExcludedItems = createAsyncThunk<
  { value: boolean },
  { companyId: number, adId: number }
>(
  REMOVE_RETAILER_BASE_PRICE_SANDBOX_EXCLUDED_ITEMS,
  async (params) => {
    const { companyId, adId } = params;
    const response = await services.removeRetailerBasePriceSandboxExcludedItems(companyId, adId);
    return response.data;
  },
);

const getRetailerBasePriceSandboxOutOfPlanningItems = createAsyncThunk<
  Array<RetailerBasePriceSandboxOutOfPlanningItem>,
  {
    companyId: number,
    adId: number,
  }
>(
  GET_RETAILER_BASE_PRICE_SANDBOX_OUT_OF_PLANNING_ITEMS,
  async (params) => {
    const { companyId, adId } = params;
    const response = await services.getRetailerBasePriceSandboxOutOfPlanningItems(companyId, adId);
    return response.data;
  },
);

const removeRetailerBasePriceSandboxOutOfPlanningItems = createAsyncThunk<
  Array<RetailerBasePriceSandboxOutOfPlanningItem>,
  {
    companyId: number,
    adId: number,
  }
>(
  REMOVE_RETAILER_BASE_PRICE_SANDBOX_OUT_OF_PLANNING_ITEMS,
  async (params) => {
    const { companyId, adId } = params;
    const response = await services.removeRetailerBasePriceSandboxOutOfPlanningItems(
      companyId,
      adId,
    );
    return response.data;
  },
);

const exportRetailerBasePriceSandbox = createAsyncThunk<
  void,
  { companyId: number, adWeek: RetailerAd }
>(
  EXPORT_RETAILER_BASE_PRICE_SANDBOX,
  async (params) => {
    const { companyId, adWeek } = params;
    await services.exportRetailerBasePriceSandbox(companyId, adWeek);
  },
);

const getRetailerBasePriceSandboxItemPricesHistory = createAsyncThunk<
  Array<RetailerItemPricesHistoryItem>,
  {
    companyId: number,
    adId: number,
    itemType: RetailerSandboxItemType,
    itemId: number,
  }
>(
  GET_RETAILER_BASE_PRICE_SANDBOX_ITEM_PRICES_HISTORY,
  async (params) => {
    const {
      companyId, adId, itemType, itemId,
    } = params;
    const response = await services.getRetailerBasePriceSandboxItemPricesHistory(
      companyId,
      adId,
      itemType,
      itemId,
    );

    if (response.data.historyComponents) {
      return (
        JSON.parse(response.data.historyComponents) as Array<RetailerItemPricesHistoryItem>
      );
    }
    return [];
  },
);

const getRetailerBasePriceSandboxItemSalesHistory = createAsyncThunk<
  RetailerItemSalesHistoryResponse,
  {
    companyId: number,
    adId: number,
    itemType: RetailerSandboxItemType,
    itemId: number,
  }
>(
  GET_RETAILER_BASE_PRICE_SANDBOX_ITEM_SALES_HISTORY,
  async (params) => {
    const {
      companyId, adId, itemType, itemId,
    } = params;
    const response = await services.getRetailerBasePriceSandboxItemSalesHistory(
      companyId,
      adId,
      itemType,
      itemId,
    );
    return response.data;
  },
);

const roundRetailerBasePriceSandboxDepartment = createAsyncThunk<
  RetailerBasePriceSandboxDepartment,
  {
    companyId: number,
    adId: number,
    departmentId: number,
  }
>(
  ROUND_RETAILER_BASE_PRICE_SANDBOX_DEPARTMENT,
  async (params) => {
    const { companyId, adId, departmentId } = params;
    const response = await services.roundRetailerBasePriceSandboxDepartment(
      companyId,
      adId,
      departmentId,
    );
    return response.data;
  },
);

const roundRetailerBasePriceSandboxCategory = createAsyncThunk<
  RetailerBasePriceSandboxCategory,
  {
    companyId: number,
    adId: number,
    categoryId: number,
  }
>(
  ROUND_RETAILER_BASE_PRICE_SANDBOX_CATEGORY,
  async (params) => {
    const { companyId, adId, categoryId } = params;
    const response = await services.roundRetailerBasePriceSandboxCategory(
      companyId,
      adId,
      categoryId,
    );
    return response.data;
  },
);

const updateRetailerBasePriceSandboxCategoryLockStatus = createAsyncThunk<
  RetailerBasePriceSandboxDepartment,
  {
    companyId: number,
    adId: number,
    category: RetailerBasePriceSandboxCategory,
  }
>(
  UPDATE_RETAILER_BASE_PRICE_SANDBOX_CATEGORY_LOCK_STATUS,
  async (params) => {
    const { companyId, adId, category } = params;
    const response = await services.updateRetailerBasePriceSandboxCategoryLockStatus(
      companyId,
      adId,
      category.id,
      { value: !category.locked },
    );
    return response.data;
  },
);

const updateRetailerBasePriceSandboxDepartmentLockStatus = createAsyncThunk<
  RetailerBasePriceSandboxDepartment,
  {
    companyId: number,
    adId: number,
    department: RetailerBasePriceSandboxDepartment,
  }
>(
  UPDATE_RETAILER_BASE_PRICE_SANDBOX_DEPARTMENT_LOCK_STATUS,
  async (params) => {
    const { companyId, adId, department } = params;
    const response = await services.updateRetailerBasePriceSandboxDepartmentLockStatus(
      companyId,
      adId,
      department.id,
      { value: !department.locked },
    );
    return response.data;
  },
);

const updateRetailerBasePriceSandboxOfferLockStatus = createAsyncThunk<
  RetailerBasePriceSandboxDepartment,
  {
    companyId: number,
    adId: number,
    offer: RetailerBasePriceSandboxOffer,
  }
>(
  UPDATE_RETAILER_BASE_PRICE_SANDBOX_OFFER_LOCK_STATUS,
  async (params) => {
    const { companyId, adId, offer } = params;
    const response = await services.updateRetailerBasePriceSandboxOfferLockStatus(
      companyId,
      adId,
      offer.id,
      { value: !offer.locked },
    );
    return response.data;
  },
);

const updateRetailerBasePriceSandboxItemLockStatus = createAsyncThunk<
  RetailerBasePriceSandboxDepartment,
  {
    companyId: number,
    adId: number,
    item: RetailerBasePriceSandboxItem,
  }
>(
  UPDATE_RETAILER_BASE_PRICE_SANDBOX_ITEM_LOCK_STATUS,
  async (params) => {
    const { companyId, adId, item } = params;
    const response = await services.updateRetailerBasePriceSandboxItemLockStatus(
      companyId,
      adId,
      item.id,
      { value: !item.locked },
    );
    return response.data;
  },
);

const roundRetailerBasePriceSandbox = createAsyncThunk<
  RetailerBasePriceSandbox,
  { companyId: number; adId: number; }
>(
  ROUND_RETAILER_BASE_PRICE_SANDBOX,
  async (params) => {
    const { companyId, adId } = params;
    const response = await services.roundRetailerBasePriceSandbox(companyId, adId);
    return response.data;
  },
);

const updateRetailerBasePriceSandboxProjectedAdVolumes = createAsyncThunk<
  RetailerBasePriceSandbox,
  { companyId: number; adId: number; }
>(
  UPDATE_RETAILER_BASE_PRICE_SANDBOX_PROJECTED_AD_VOLUMES,
  async (params) => {
    const { companyId, adId } = params;
    const response = await services.updateRetailerBasePriceSandboxProjectedAdVolumes(
      companyId,
      adId,
    );
    return response.data;
  },
);

const changeRetailerBasePriceSandboxDepartmentOrder = createAsyncThunk<
  void,
  { companyId: number; adId: number; data: RetailerBasePriceSandboxDepartmentOrderBody }
>(
  CHANGE_RETAILER_BASE_PRICE_SANDBOX_DEPARTMENT_ORDER,
  async (params) => {
    const { companyId, adId, data } = params;
    await services.changeRetailerBasePriceSandboxDepartmentOrder(
      companyId,
      adId,
      data,
    );
  },
);

const changeRetailerBasePriceSandboxCategoryOrder = createAsyncThunk<
  void,
  { companyId: number; adId: number; data: RetailerBasePriceSandboxCategoryOrderBody }
>(
  CHANGE_RETAILER_BASE_PRICE_SANDBOX_CATEGORY_ORDER,
  async (params) => {
    const { companyId, adId, data } = params;
    await services.changeRetailerBasePriceSandboxCategoryOrder(
      companyId,
      adId,
      data,
    );
  },
);

export {
  getRetailerBasePriceSandbox,
  roundRetailerBasePriceSandbox,
  exportRetailerBasePriceSandbox,
  getRetailerBasePriceStoreGroups,
  calculateRetailerBasePriceSandbox,
  getRetailerBasePriceSandboxStatus,
  removeRetailerBasePriceSandboxItem,
  updateRetailerBasePriceSandboxItem,
  removeRetailerBasePriceSandboxOffer,
  updateRetailerBasePriceSandboxOffer,
  removeRetailerBasePriceSandboxItems,
  getRetailerBasePriceAdWeeklyMargins,
  getRetailerBasePriceSandboxTabStatus,
  uploadRetailerBasePriceSandboxOffers,
  roundRetailerBasePriceSandboxCategory,
  duplicateRetailerBasePriceSandboxItem,
  updateRetailerBasePriceSandboxCategory,
  duplicateRetailerBasePriceSandboxOffer,
  updateRetailerBasePriceSandboxNewPrices,
  roundRetailerBasePriceSandboxDepartment,
  updateRetailerBasePriceSandboxDepartment,
  changeRetailerBasePriceSandboxItemStatus,
  updateRetailerBasePriceSandboxOfferNames,
  getRetailerBasePriceSandboxExcludedItems,
  removeRetailerBasePriceSandboxExcludedItems,
  getRetailerBasePriceSandboxItemSalesHistory,
  changeRetailerBasePriceSandboxCategoryOrder,
  getRetailerBasePriceSandboxItemPricesHistory,
  updateRetailerBasePriceSandboxItemLockStatus,
  changeRetailerBasePriceSandboxDepartmentOrder,
  updateRetailerBasePriceSandboxOfferLockStatus,
  getRetailerBasePriceSandboxOutOfPlanningItems,
  updateRetailerBasePriceSandboxCategoryLockStatus,
  removeRetailerBasePriceSandboxOutOfPlanningItems,
  fillDownRetailerBasePriceSandboxItemsNewUnitCost,
  updateRetailerBasePriceSandboxProjectedAdVolumes,
  updateRetailerBasePriceSandboxDepartmentLockStatus,
};
