import { combineReducers } from '@reduxjs/toolkit';

import root, { resetRetailerMERootState } from './root';
import basePriceSandbox, { resetRetailerBasePriceSandboxState } from './basePriceSandbox';

const marginEnhancementReducers = combineReducers({
  root: root.reducer,
  basePriceSandbox: basePriceSandbox.reducer,
});

export {
  resetRetailerMERootState,
  resetRetailerBasePriceSandboxState,
};

export default marginEnhancementReducers;
