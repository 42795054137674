import { AxiosResponse } from 'axios';

import {
  RetailerAd,
  ListQueries,
  ListResponse,
  RetailerFreezeWeek,
  RetailerWeeklyMargins,
  RetailerQuarterLiftBody,
  RetailerAnnualMarginBody,
  RetailerWeeklyMarginsBody,
  RetailerCategoryAnnualMargin,
  RetailerCategoryWeeklyMargin,
  RetailerAdWeekCalculationBody,
  RetailerQuartersLiftsResponse,
  RetailerDepartmentAnnualMargin,
  RetailerDepartmentWeeklyMargin,
  RetailerChangeCategoryOrderBody,
  RetailerChangeDepartmentOrderBody,
  RetailerDepartmentAnnualMarginResponse,
  RetailerCategoryAnnualBudgetedShrinkBody,
  RetailerCategoryWeeklyBudgetedShrinkBody,
  RetailerDepartmentAnnualBudgetedShrinkBody,
} from 'resources/types';
import instance from 'services/instance';
import { endpoints } from 'resources/constants';
import { departmentManagerEvent, grossMarginManagerEvent } from 'resources/constants/mixpanel';

const getRetailerCurrentAdYear = (
  companyId: number,
): Promise<AxiosResponse<{ value: number }>> => (
  instance.get(endpoints.RETAILER_ADS_CURRENT_AD_YEAR(companyId))
);

const getRetailerAvailableYears = (
  companyId: number,
): Promise<AxiosResponse<{ items: Array<number> }>> => (
  instance.get(endpoints.RETAILER_ADS_AVAILABLE_YEARS(companyId))
);

const getRetailerCompanyAnnualMargin = (
  companyId: number,
  params?: ListQueries,
): Promise<AxiosResponse<{ value: number }>> => (
  instance.get(
    endpoints.RETAILER_COMPANY_ANNUAL_MARGIN(companyId),
    {
      params,
      failureMessage: 'Could not get company annual margin',
    },
  )
);

const getRetailerCurrentAds = (
  companyId: number,
  params?: ListQueries,
): Promise<AxiosResponse<ListResponse<RetailerAd>>> => (
  instance.get(
    `${endpoints.RETAILER_ADS(companyId)}?sort=year,startDate,ASC&size=2000`,
    {
      params,
      failureMessage: 'Could not get retailer as weeks',
    },
  )
);

const getRetailerDepartmentsAnnualMargins = (
  companyId: number,
  params?: ListQueries,
): Promise<AxiosResponse<ListResponse<RetailerDepartmentAnnualMargin>>> => (
  instance.get(
    endpoints.RETAILER_DEPARTMENTS_ANNUAL_MARGINS(companyId),
    {
      params,
      failureMessage: 'Could not get retailer departments annual margins',
    },
  )
);

const updateRetailerCompanyAnnualMargin = (
  companyId: number,
  data: RetailerAnnualMarginBody,
): Promise<AxiosResponse<{ value: number }>> => (
  instance.put(
    endpoints.RETAILER_COMPANY_ANNUAL_MARGIN(companyId),
    data,
    {
      successMessage: 'The company target margin has been updated',
      failureMessage: 'Could not update company annual margin',
      mixpanelEvent: {
        EventName: departmentManagerEvent.updateCompanyAnnualMargin,
        Value: data.marginTarget,
      },
    },
  )
);

const updateRetailerDepartmentAnnualMargin = (
  companyId: number,
  departmentId: number,
  data: RetailerAnnualMarginBody,
): Promise<AxiosResponse<[RetailerDepartmentAnnualMarginResponse]>> => (
  instance.put(
    endpoints.RETAILER_DEPARTMENT_ANNUAL_MARGIN(companyId, departmentId),
    data,
    {
      successMessage: 'The department target margin has been updated.',
      failureMessage: 'Something went wrong, Failed to update department annual margin',
      mixpanelEvent: {
        EventName: departmentManagerEvent.updateAnnualMargin,
        Value: data.marginTarget,
      },
    },
  )
);

const updateRetailerDepartmentAnnualBudgetedShrink = (
  companyId: number,
  departmentId: number,
  data: RetailerDepartmentAnnualBudgetedShrinkBody,
): Promise<AxiosResponse<[RetailerDepartmentAnnualMarginResponse]>> => (
  instance.put(
    endpoints.RETAILER_DEPARTMENT_ANNUAL_MARGIN(companyId, departmentId),
    data,
    {
      successMessage: 'The department shrink volume has been updated.',
      failureMessage: 'Something went wrong, Failed to update department shrink volume',
      mixpanelEvent: {
        EventName: departmentManagerEvent.updateAverageWeeklyBudgetShrinkVolume,
        Value: data.averageWeeklyBudgetedShrinkVolume,
      },
    },
  )
);

const fillDownRetailerDepartmentAnnualMargins = (
  companyId: number,
  departmentId: number,
  data: { value: number },
): Promise<AxiosResponse<Array<RetailerCategoryAnnualMargin>>> => (
  instance.put(
    endpoints.RETAILER_DEPARTMENT_FILL_DOWN_ANNUAL_MARGINS(companyId, departmentId),
    data,
    {
      successMessage: 'Margins are successfully updated',
      failureMessage: 'Something went wrong, margins haven\'t been updated',
      mixpanelEvent: {
        EventName: departmentManagerEvent.fillDownAnnualMargins,
      },
    },
  )
);

const fillDownRetailerDepartmentWeeklyMargins = (
  companyId: number,
  adId: number,
  departmentId: number,
): Promise<AxiosResponse<Array<RetailerCategoryWeeklyMargin>>> => (
  instance.put(
    endpoints.RETAILER_DEPARTMENT_FILL_DOWN_WEEKLY_MARGINS(companyId, adId, departmentId),
    undefined,
    {
      successMessage: 'Margins are successfully updated',
      failureMessage: 'Something went wrong, margins haven\'t been updated',
      mixpanelEvent: {
        EventName: grossMarginManagerEvent.fillDownWeeklyMargins,
        Value: departmentId,
      },
    },
  )
);

const fillDownRetailerDepartmentAnnualBudgetedShrinks = (
  companyId: number,
  departmentId: number,
  data: { value: number },
): Promise<AxiosResponse<Array<RetailerCategoryAnnualMargin>>> => (
  instance.put(
    endpoints.RETAILER_DEPARTMENT_FILL_DOWN_ANNUAL_BUDGETED_SHRINKS(companyId, departmentId),
    data,
    {
      successMessage: 'Shrink volumes are successfully updated',
      failureMessage: 'Something went wrong, shrink volumes haven\'t been updated',
      mixpanelEvent: {
        EventName: departmentManagerEvent.fillDownAverageWeeklyBudgetShrinkVolume,
        Value: departmentId,
      },
    },
  )
);

const fillDownRetailerDepartmentWeeklyBudgetedShrinks = (
  companyId: number,
  adId: number,
  departmentId: number,
  data: { value: number },
): Promise<AxiosResponse<Array<RetailerCategoryWeeklyMargin>>> => (
  instance.put(
    endpoints.RETAILER_DEPARTMENT_FILL_DOWN_ANNUAL_WEEKLY_SHRINKS(companyId, adId, departmentId),
    data,
    {
      successMessage: 'Shrink volumes are successfully updated',
      failureMessage: 'Something went wrong, shrink volumes haven\'t been updated',
      mixpanelEvent: {
        EventName: grossMarginManagerEvent.fillDownBudgetShrinkVolumes,
        Value: departmentId,
      },
    },
  )
);

const updateRetailerCategoryAnnualMargin = (
  companyId: number,
  categoryId: number,
  data: RetailerAnnualMarginBody,
): Promise<AxiosResponse<[RetailerCategoryAnnualMargin]>> => (
  instance.put(
    endpoints.RETAILER_CATEGORY_ANNUAL_MARGIN(companyId, categoryId),
    data,
    {
      successMessage: 'The category target margin has been updated.',
      failureMessage: 'Something went wrong, Failed to update category annual margin',
    },
  )
);

const updateRetailerCategoryWeeklyMargin = (
  companyId: number,
  adId: number,
  categoryId: number,
  data: RetailerWeeklyMarginsBody,
): Promise<AxiosResponse<RetailerCategoryWeeklyMargin>> => (
  instance.put(
    endpoints.RETAILER_CATEGORY_WEEKLY_MARGIN(companyId, adId, categoryId),
    data,
    {
      successMessage: 'The category target margin has been updated.',
      failureMessage: 'Something went wrong, Failed to update category weekly margin',
    },
  )
);

const calculateRetailerAdWeekProjectedSales = (
  companyId: number,
  adId: number,
  data: RetailerAdWeekCalculationBody,
): Promise<AxiosResponse<RetailerWeeklyMargins>> => (
  instance.put(
    endpoints.RETAILER_AD_CALCULATE_PROJECTED_SALES(companyId, adId),
    data,
    {
      successMessage: 'Projected Sales are calculated',
      failureMessage: 'Something went wrong, could not calculate Projected Sales',
      mixpanelEvent: {
        EventName: grossMarginManagerEvent.calculateProjectSales,
        Value: adId,
      },
    },
  )
);

const confirmRetailerAdWeekMargins = (
  companyId: number,
  adId: number,
): Promise<AxiosResponse<RetailerWeeklyMargins>> => (
  instance.put(
    endpoints.RETAILER_AD_CONFIRM_MARGINS(companyId, adId),
    null,
    {
      successMessage: 'The company margins has been confirmed',
      failureMessage: 'Something went wrong, could not confirm margins',
      mixpanelEvent: {
        EventName: grossMarginManagerEvent.confirmMargins,
        Value: adId,
      },
    },
  )
);

const updateRetailerAdReferenceWeek = (
  companyId: number,
  adId: number,
  data: RetailerFreezeWeek,
): Promise<AxiosResponse<RetailerWeeklyMargins>> => (
  instance.put(
    endpoints.RETAILER_AD_UPDATE_REFERENCE_WEEK(companyId, adId),
    data,
    {
      successMessage: 'Current Reference Data Week is updated',
      failureMessage: 'Something went wrong, could not update current reference data week',
      mixpanelEvent: {
        EventName: grossMarginManagerEvent.updateReferenceWeek,
        Value: adId,
      },
    },
  )
);

const updateRetailerCategoryWeeklyBudgetedShrink = (
  companyId: number,
  adId: number,
  categoryId: number,
  data: RetailerCategoryWeeklyBudgetedShrinkBody,
): Promise<AxiosResponse<RetailerCategoryWeeklyMargin>> => (
  instance.put(
    endpoints.RETAILER_CATEGORY_WEEKLY_MARGIN(companyId, adId, categoryId),
    data,
    {
      successMessage: 'The category budgeted shrink has been updated.',
      failureMessage: 'Something went wrong, Failed to update category weekly budgeted shrink',
      mixpanelEvent: {
        EventName: grossMarginManagerEvent.updateCategoryBudgetShrink,
        Field: 'Budgeted Shrink',
        Value: data.shrinkVolume,
      },
    },
  )
);

const updateRetailerCategoryAnnualBudgetedShrink = (
  companyId: number,
  departmentId: number,
  data: RetailerCategoryAnnualBudgetedShrinkBody,
): Promise<AxiosResponse<[RetailerCategoryAnnualMargin]>> => (
  instance.put(
    endpoints.RETAILER_CATEGORY_ANNUAL_MARGIN(companyId, departmentId),
    data,
    {
      successMessage: 'The category shrink volume has been updated.',
      failureMessage: 'Something went wrong, Failed to update category shrink volume',
    },
  )
);

const getRetailerDepartmentsWeeklyMargins = (
  companyId: number,
  adId: number,
): Promise<AxiosResponse<Array<RetailerDepartmentWeeklyMargin>>> => (
  instance.get(
    endpoints.RETAILER_DEPARTMENTS_WEEKLY_MARGIN(companyId, adId),
    {
      failureMessage: 'Something went, could not get departments weekly margins.',
    },
  )
);

const getRetailerDepartmentWeeklyMargins = (
  companyId: number,
  adId: number,
  departmentId: number,
): Promise<AxiosResponse<RetailerDepartmentWeeklyMargin>> => (
  instance.get(
    endpoints.RETAILER_DEPARTMENT_WEEKLY_MARGIN(companyId, adId, departmentId),
    {
      failureMessage: 'Something went, could not get departments weekly margins.',
    },
  )
);

const updateRetailerDepartmentWeeklyMargin = (
  companyId: number,
  adId: number,
  departmentId: number,
  data: RetailerWeeklyMarginsBody,
): Promise<AxiosResponse<RetailerDepartmentWeeklyMargin>> => (
  instance.put(
    endpoints.RETAILER_DEPARTMENT_WEEKLY_MARGIN(companyId, adId, departmentId),
    data,
    {
      successMessage: 'The department weekly target margin has been updated.',
      failureMessage: 'Something went, could not get department weekly margins.',
      mixpanelEvent: {
        EventName: grossMarginManagerEvent.updateWeeklyTargetMargin,
        Value: data.targetMargin,
      },
    },
  )
);

const getRetailerCategoriesWeeklyMargins = (
  companyId: number,
  adId: number,
): Promise<AxiosResponse<Array<RetailerCategoryWeeklyMargin>>> => (
  instance.get(
    endpoints.RETAILER_CATEGORIES_WEEKLY_MARGIN(companyId, adId),
    {
      failureMessage: 'Something went, could not get categories weekly margins.',
    },
  )
);

const getAvailableFreezeWeeks = (
  companyId: number,
  adId: number,
): Promise<AxiosResponse<Array<RetailerFreezeWeek>>> => (
  instance.get(
    endpoints.RETAILER_AVAILABLE_FREEZE_WEEKS(companyId, adId),
    {
      failureMessage: 'Could not get available freeze weeks.',
    },
  )
);

const getRetailerAdsQuartersLifts = (
  companyId: number,
  params: { year: number },
): Promise<AxiosResponse<RetailerQuartersLiftsResponse>> => (
  instance.get(
    endpoints.RETAILER_ADS_LIFT_QUARTERS(companyId),
    {
      params,
      failureMessage: 'Could not get available quarters lifts.',
    },
  )
);

const updateRetailerAdsQuarterLift = (
  companyId: number,
  data: RetailerQuarterLiftBody,
): Promise<AxiosResponse<RetailerQuartersLiftsResponse>> => (
  instance.put(
    endpoints.RETAILER_ADS_LIFT_QUARTERS(companyId),
    data,
    {
      successMessage: 'Quarterly lift is updated',
      failureMessage: 'Could not update quarters lift value.',
      mixpanelEvent: {
        EventName: departmentManagerEvent.updateQuarterlyLift,
        Value: data,
      },
    },
  )
);

const changeRetailerDepartmentOrder = (
  companyId: number,
  data: RetailerChangeDepartmentOrderBody,
): Promise<void> => instance.put(
  endpoints.RETAILER_DEPARTMENT_CHANGE_ORDER(companyId),
  data,
  {
    successMessage: 'Department order has been changed',
    failureMessage: 'Could not change department order',
  },
);

const changeRetailerCategoryOrder = (
  companyId: number,
  data: RetailerChangeCategoryOrderBody,
): Promise<void> => instance.put(
  endpoints.RETAILER_CATEGORY_CHANGE_ORDER(companyId),
  data,
  {
    successMessage: 'Category order has been changed',
    failureMessage: 'Could not change category order',
  },
);

export default {
  getRetailerCurrentAds,
  getAvailableFreezeWeeks,
  getRetailerCurrentAdYear,
  getRetailerAvailableYears,
  changeRetailerCategoryOrder,
  getRetailerAdsQuartersLifts,
  confirmRetailerAdWeekMargins,
  updateRetailerAdsQuarterLift,
  updateRetailerAdReferenceWeek,
  changeRetailerDepartmentOrder,
  getRetailerCompanyAnnualMargin,
  updateRetailerCompanyAnnualMargin,
  getRetailerCategoriesWeeklyMargins,
  getRetailerDepartmentWeeklyMargins,
  updateRetailerCategoryAnnualMargin,
  updateRetailerCategoryWeeklyMargin,
  getRetailerDepartmentsAnnualMargins,
  getRetailerDepartmentsWeeklyMargins,
  updateRetailerDepartmentAnnualMargin,
  updateRetailerDepartmentWeeklyMargin,
  calculateRetailerAdWeekProjectedSales,
  fillDownRetailerDepartmentWeeklyMargins,
  fillDownRetailerDepartmentAnnualMargins,
  updateRetailerCategoryAnnualBudgetedShrink,
  updateRetailerCategoryWeeklyBudgetedShrink,
  updateRetailerDepartmentAnnualBudgetedShrink,
  fillDownRetailerDepartmentWeeklyBudgetedShrinks,
  fillDownRetailerDepartmentAnnualBudgetedShrinks,
};
