import { createSlice } from '@reduxjs/toolkit';

import { ROWS_PER_PAGES } from 'resources/constants';
import { Pagination, RetailerWarehouse } from 'resources/types';
import {
  getRetailerWarehouses,
  createRetailerWarehouse,
  updateRetailerWarehouse,
  deleteRetailerWarehouse,
} from './thunks';

export interface RetailerWarehousesState {
  items: Array<RetailerWarehouse>;
  pagination: Pagination;
  loading: boolean;
  actionLoading: boolean;
}

const initialState: RetailerWarehousesState = {
  items: [],
  pagination: {
    page: 0,
    totalPages: 1,
    totalItems: 0,
    size: ROWS_PER_PAGES[0],
  },
  loading: false,
  actionLoading: false,
};

/* eslint-disable no-param-reassign */

const retailerWarehousesSlice = createSlice({
  name: 'retailer/warehouses',
  initialState,
  reducers: {
    reset: () => initialState,
  },

  extraReducers: (builder) => {
    builder.addCase(getRetailerWarehouses.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getRetailerWarehouses.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getRetailerWarehouses.fulfilled, (state, action) => {
      const {
        items, page, size, totalPages, totalItems,
      } = action.payload;
      state.loading = false;
      state.items = items;
      state.pagination = {
        page, size, totalPages, totalItems,
      };
    });

    builder.addCase(deleteRetailerWarehouse.pending, (state) => {
      state.actionLoading = true;
    });
    builder.addCase(deleteRetailerWarehouse.rejected, (state) => {
      state.actionLoading = false;
    });
    builder.addCase(deleteRetailerWarehouse.fulfilled, (state) => {
      state.actionLoading = false;
    });
  },
});

const { reset: resetRetailerWarehousesState } = retailerWarehousesSlice.actions;

export {
  getRetailerWarehouses,
  createRetailerWarehouse,
  updateRetailerWarehouse,
  deleteRetailerWarehouse,
  resetRetailerWarehousesState,
};

export default retailerWarehousesSlice;
