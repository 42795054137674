import commaNumber from 'comma-number';

export const roundNumber = (number: number, count = 100): number => (
  Math.round(number * count) / count
);

export const percentToDecimal = (percent: number): number => (
  roundNumber((percent / 100), 10000)
);

export const decimalToPercent = (number: number): number => (
  roundNumber(100 * number)
);

export const decimalToPercentString = (
  decimalValue: number | string | null | undefined,
): string => {
  if (Number.isNaN(Number(decimalValue))) return '';
  return String(roundNumber(100 * Number(decimalValue)));
};

export const addZeros = (str: string): string => (
  str
    .replace(/^(-?([0-9]+(,?[0-9]+)*)+)$/g, '$1.00')
    .replace(/(\.[0-9]?)$/g, '$10')
);

export const decimalToPercentWithCommas = (
  number: number | string | undefined = '',
  withZeros = true,
): string => {
  if (Number.isNaN(Number(number))) return '';
  return withZeros ? (
    addZeros(
      commaNumber(roundNumber(100 * Number(number))).replace(/(^0+)((?=\.))/g, ''),
    )
  ) : (
    commaNumber(roundNumber(100 * Number(number))).replace(/(^0+)((?=\.))/g, '')
  );
};

export const withCommas = (
  number: number | string | undefined = '',
  count = 100,
  withZeros = true,
): string => {
  if (Number.isNaN(Number(number))) return '';
  return withZeros ? (
    addZeros(
      commaNumber(roundNumber(Number(number), count)).replace(/(^0+)((?=\.))/g, ''),
    )
  ) : (
    commaNumber(roundNumber(Number(number), count)).replace(/(^0+)((?=\.))/g, '')
  );
};

export const toTableCellNumeric = (value?: string | number): string => (
  value != null ? withCommas(value) : '-'
);

export const toTableCellIntegerNumeric = (value?: string | number): string => (
  value != null ? withCommas(value, 1, false) : '-'
);

export const toTableCellString = (value?: string | number): string => (
  value != null ? String(value) : '-'
);

export const toTableCellPercentString = (value?: string | number): string => (
  value != null ? `${decimalToPercentWithCommas(value)}%` : '-'
);

export const toTableCellDollarString = (value?: string | number): string => (
  value != null ? `$${withCommas(value)}` : '-'
);
