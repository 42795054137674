import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import {
  SignIn,
  SignUp,
  Dashboard,
  ResetPassword,
  ForgotPassword,
  // AdminVendors,
  // AdminVendorManagement,
} from 'components/content';
import StoreIcon from '@mui/icons-material/Store';
import EventIcon from '@mui/icons-material/Event';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ApartmentIcon from '@mui/icons-material/Apartment';
import StorefrontIcon from '@mui/icons-material/Storefront';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
// import RateReviewOutlinedIcon from '@mui/icons-material/RateReviewOutlined';
// import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import CurrencyExchangeOutlinedIcon from '@mui/icons-material/CurrencyExchangeOutlined';

import { Template } from 'components/main';
import { AppRouteObject } from 'resources/types';
import { routsPatterns } from 'resources/constants';
import userManagement from 'assets/icons/dashboard/user_management.svg';
import storeManagement from 'assets/icons/dashboard/store_management.svg';
// import productReviews from 'assets/icons/dashboard/product_reviews.svg';
// import vendorManagement from 'assets/icons/dashboard/vendor_management.svg';
import retailerManagement from 'assets/icons/dashboard/retailer_management.svg';
import companyInformation from 'assets/icons/dashboard/company_information.svg';
import grossMarginManager from 'assets/icons/dashboard/gross_margin_manager.svg';
import warehouseManagement from 'assets/icons/dashboard/warehouse_management.svg';
import marginEnhancementTool from 'assets/icons/dashboard/margin_enhancement_tool.png';
import withSuspense from '../Suspense';

const RetailerUserManagement = lazy(() => import('components/content/Retailer/UserManagement'));
const RetailerCompanyInformation = lazy(() => import('components/content/Retailer/CompanyInformation'));
const RetailerStoreManagement = lazy(() => import('components/content/Retailer/StoreManagement'));
const RetailerWarehouseManagement = lazy(() => import('components/content/Retailer/WarehouseManagement'));
const RetailerAdManager = lazy(() => import('components/content/Retailer/AdManager'));
const RetailerGrossMarginManager = lazy(() => import('components/content/Retailer/GrossMarginManager'));
const RetailerMarginEnhancementTool = lazy(() => import('components/content/Retailer/MarginEnhancementTool'));
const RetailerBasePriceSandbox = lazy(() => import('components/content/Retailer/MarginEnhancementTool/BasePriceSandbox'));
const RetailerElasticPrice = lazy(() => import('components/content/Retailer/MarginEnhancementTool/ElasticPrice'));
const RetailerTprPass = lazy(() => import('components/content/Retailer/MarginEnhancementTool/TprPass'));
const RetailerRulesSetup = lazy(() => import('components/content/Retailer/RulesSetup'));
const RetailerRoundingRules = lazy(() => import('components/content/Retailer/RulesSetup/RoundingRules'));
const Profile = lazy(() => import('components/content/Profile'));
const AdminRetailerManagement = lazy(() => import('components/content/Admin/RetailerManagement'));
const RetailerSandbox = lazy(() => import('components/content/Retailer/Sandbox'));
const RetailerImpItems = lazy(() => import('components/content/Retailer/ImpItems'));
const RetailerTPRPassthrough = lazy(() => import('components/content/Retailer/RulesSetup/TPRPassthrough'));
const RetailerDepartmentManager = lazy(() => import('components/content/Retailer/DepartmentManager'));

const routeObjects: AppRouteObject[] = [
  {
    index: true,
    element: <Template />,
  },
  {
    path: routsPatterns.ADMIN,
    element: <Template />,
    userScope: 'USER_SCOPE_ADMIN',
    children: [
      {
        index: true,
        userScope: 'USER_SCOPE_ADMIN',
        element: <Navigate to={routsPatterns.ADMIN_DASHBOARD} replace />,
      },
      {
        sidebarIcon: <DashboardIcon fontSize="small" />,
        label: 'Action Dashboard',
        path: routsPatterns.ADMIN_DASHBOARD,
        element: <Dashboard />,
        userScope: 'USER_SCOPE_ADMIN',
        placements: ['sidebar'],
      },
      // Hide this screen for now
      // {
      //   colorKey: 'warning',
      //   dashboardIcon: productReviews,
      //   icon: <RateReviewOutlinedIcon fontSize="small" />,
      //   label: 'Product Reviews',
      //   path: routsPatterns.ADMIN_VENDORS,
      //   element: <AdminVendors />,
      //   userScope: 'USER_SCOPE_ADMIN',
      // },
      // {
      //   colorKey: 'deepPurple',
      //   dashboardIcon: vendorManagement,
      //   icon: <LocalShippingOutlinedIcon fontSize="small" />,
      //   label: 'Vendor Management',
      //   path: routsPatterns.ADMIN_VENDOR_MANAGEMENT,
      //   element: <AdminVendorManagement />,
      //   userScope: 'USER_SCOPE_ADMIN',
      // },
      {
        colorKey: 'info',
        dashboardIcon: retailerManagement,
        sidebarIcon: <StorefrontIcon fontSize="small" />,
        label: 'Retailer Management',
        path: routsPatterns.ADMIN_RETAILER_MANAGEMENT,
        element: withSuspense(<AdminRetailerManagement />),
        userScope: 'USER_SCOPE_ADMIN',
        placements: ['sidebar', 'dashboard'],
      },
      {
        path: routsPatterns.ADMIN_PROFILE,
        element: <Profile />,
        userScope: 'USER_SCOPE_ADMIN',
      },
    ],
  },
  {
    path: routsPatterns.RETAILER,
    element: <Template />,
    userScope: 'USER_SCOPE_RETAILER',
    children: [
      {
        index: true,
        element: <Navigate to={routsPatterns.RETAILER_DASHBOARD} replace />,
        userScope: 'USER_SCOPE_RETAILER',
      },
      {
        sidebarIcon: <DashboardIcon fontSize="small" />,
        label: 'Action Dashboard',
        path: routsPatterns.RETAILER_DASHBOARD,
        element: <Dashboard />,
        userScope: 'USER_SCOPE_RETAILER',
        placements: ['sidebar'],
      },
      {
        colorKey: 'warning',
        dashboardIcon: userManagement,
        sidebarIcon: <PeopleAltIcon fontSize="small" />,
        label: 'User Management',
        path: routsPatterns.RETAILER_EMPLOYEES,
        element: withSuspense(<RetailerUserManagement />),
        userScope: 'USER_SCOPE_RETAILER',
        placements: ['sidebar', 'dashboard'],
      },
      {
        colorKey: 'deepPurple',
        dashboardIcon: companyInformation,
        sidebarIcon: <ApartmentIcon fontSize="small" />,
        label: 'Company Information',
        path: routsPatterns.RETAILER_COMPANY,
        element: withSuspense(<RetailerCompanyInformation />),
        userScope: 'USER_SCOPE_RETAILER',
        placements: ['sidebar', 'dashboard'],
      },
      {
        colorKey: 'info',
        dashboardIcon: storeManagement,
        sidebarIcon: <StorefrontIcon fontSize="small" />,
        label: 'Store Management',
        path: routsPatterns.RETAILER_STORE_MANAGEMENT,
        element: withSuspense(<RetailerStoreManagement />),
        userScope: 'USER_SCOPE_RETAILER',
        placements: ['sidebar', 'dashboard'],
      },
      {
        colorKey: 'info',
        dashboardIcon: warehouseManagement,
        sidebarIcon: <StoreIcon fontSize="small" />,
        label: 'Warehouse Management',
        path: routsPatterns.RETAILER_WAREHOUSE_MANAGEMENT,
        element: withSuspense(<RetailerWarehouseManagement />),
        userScope: 'USER_SCOPE_RETAILER',
        placements: ['sidebar', 'dashboard'],
      },
      {
        colorKey: 'deepOrange',
        dashboardIcon: grossMarginManager,
        sidebarIcon: <EventIcon fontSize="small" />,
        label: 'Ad Manager',
        path: routsPatterns.RETAILER_AD_MANAGER,
        element: withSuspense(<RetailerAdManager />),
        userScope: 'USER_SCOPE_RETAILER',
        placements: ['sidebar', 'dashboard'],
      },
      {
        colorKey: 'orange',
        dashboardIcon: grossMarginManager,
        sidebarIcon: <BarChartOutlinedIcon fontSize="small" />,
        label: 'Gross Margin Manager',
        path: routsPatterns.RETAILER_GROSS_MARGIN_MANAGER,
        element: withSuspense(<RetailerGrossMarginManager />),
        userScope: 'USER_SCOPE_RETAILER',
        placements: ['sidebar', 'dashboard'],
      },
      {
        colorKey: 'reddishOrange',
        dashboardIcon: marginEnhancementTool,
        sidebarIcon: <CurrencyExchangeOutlinedIcon fontSize="small" />,
        label: 'Margin Enhancement Tool',
        path: routsPatterns.RETAILER_MARGIN_ENHANCEMENT,
        element: withSuspense(<RetailerMarginEnhancementTool />),
        userScope: 'USER_SCOPE_RETAILER',
        placements: ['sidebar', 'dashboard'],
        children: [
          {
            index: true,
            element: <Navigate to={routsPatterns.RETAILER_BASE_PRICE_MANAGER} replace />,
            userScope: 'USER_SCOPE_RETAILER',
          },
          {
            path: routsPatterns.RETAILER_BASE_PRICE_MANAGER,
            element: withSuspense(<RetailerBasePriceSandbox />),
            userScope: 'USER_SCOPE_RETAILER',
          },
          {
            path: routsPatterns.RETAILER_ELASTIC_PRICE,
            element: withSuspense(<RetailerElasticPrice />),
            userScope: 'USER_SCOPE_RETAILER',
          },
          {
            path: routsPatterns.RETAILER_TPR_PASS,
            element: withSuspense(<RetailerTprPass />),
            userScope: 'USER_SCOPE_RETAILER',
          },
        ],
      },
      {
        path: routsPatterns.RETAILER_RULES_SETUP,
        element: withSuspense(<RetailerRulesSetup />),
        userScope: 'USER_SCOPE_RETAILER',
        children: [
          {
            index: true,
            element: <Navigate to={routsPatterns.RETAILER_ROUNDING_RULES} replace />,
            userScope: 'USER_SCOPE_RETAILER',
          },
          {
            path: routsPatterns.RETAILER_ROUNDING_RULES,
            element: withSuspense(<RetailerRoundingRules />),
            userScope: 'USER_SCOPE_RETAILER',
          },
          {
            path: routsPatterns.RETAILER_TPR_PASSTHROUGH,
            element: withSuspense(<RetailerTPRPassthrough />),
            userScope: 'USER_SCOPE_RETAILER',
          },
        ],
      },
      {
        path: routsPatterns.RETAILER_PROFILE,
        element: withSuspense(<Profile />),
        userScope: 'USER_SCOPE_RETAILER',
      },
      {
        path: routsPatterns.RETAILER_ME_IMP_ITEMS,
        element: withSuspense(<RetailerImpItems priceChange />),
        userScope: 'USER_SCOPE_RETAILER',
      },
      {
        path: routsPatterns.RETAILER_DEPARTMENT_MANAGER,
        element: withSuspense(<RetailerDepartmentManager />),
        userScope: 'USER_SCOPE_RETAILER',
      },
      {
        path: routsPatterns.RETAILER_SANDBOX,
        element: withSuspense(<RetailerSandbox />),
        userScope: 'USER_SCOPE_RETAILER',
      },
      {
        path: routsPatterns.RETAILER_IMP_ITEMS,
        element: withSuspense(<RetailerImpItems />),
        userScope: 'USER_SCOPE_RETAILER',
      },
    ],
  },
  {
    path: routsPatterns.SIGN_IN,
    element: <SignIn />,
  },
  {
    path: routsPatterns.SIGN_UP,
    element: <SignUp />,
  },
  {
    path: routsPatterns.RESET_PASSWORD,
    element: <ResetPassword />,
  },
  {
    path: routsPatterns.FORGOT_PASSWORD,
    element: <ForgotPassword />,
  },
  {
    path: '*',
    element: <Navigate to="/" replace />,
  },
];

export default routeObjects;
