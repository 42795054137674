import { useCallback, useEffect } from 'react';
import {
  Grid,
  Theme,
  Button,
  Typography,
  CircularProgress,
} from '@mui/material';
import { Formik, FormikHelpers } from 'formik';
import { useParams, useNavigate } from 'react-router-dom';

import services from 'services';
import { RootState } from 'store';
import { BackToSignIn } from 'components/main';
import { PasswordInput } from 'components/shared';
import { routsPatterns } from 'resources/constants';
import { UpdatePasswordData } from 'resources/types';
import { useAppSelector, useAppDispatch } from 'hooks';
import { resetPasswordDataSchema } from 'resources/scheme';
import { checkAuthKey, changePasswordWithKey } from 'store/user';

const initialValues: UpdatePasswordData = { password: '', confirmPassword: '' };

function ResetPasswordForm() {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const userState = useAppSelector((state: RootState) => state.user);

  const { email, key } = params;
  const { authKeyVerificationLoading, authKeyVerificationResult } = userState;

  useEffect(
    () => {
      if (services.checkAuthStatus()) navigate('/');
    },
    [navigate],
  );

  useEffect(
    () => {
      if (email && key) {
        dispatch(checkAuthKey({ email, key }));
      }
    },
    [dispatch, email, key],
  );

  const resetPassword = useCallback(
    (values: UpdatePasswordData, helpers: FormikHelpers<UpdatePasswordData>) => {
      if (email && key) {
        dispatch(changePasswordWithKey({ email, key, data: values }))
          .unwrap()
          .then(() => {
            helpers.resetForm();
            helpers.setSubmitting(false);
            navigate(routsPatterns.SIGN_IN);
          })
          .finally(() => {
            helpers.resetForm();
            helpers.setSubmitting(false);
          });
      }
    },
    [dispatch, email, key, navigate],
  );

  if (authKeyVerificationLoading) {
    return (
      <Grid item container xs={12} justifyContent="center">
        <CircularProgress />
      </Grid>
    );
  }

  return (
    authKeyVerificationResult ? (
      <Grid container>
        <Grid item xs={12} pt={2}>
          <Typography variant="h5" fontWeight="bold">Reset a new password</Typography>
          <Typography variant="subtitle2" sx={{ display: 'flex' }}>
            Create a new password for your account
          </Typography>
        </Grid>
        <Grid item container xs={12}>
          <Formik
            onSubmit={resetPassword}
            initialValues={initialValues}
            validationSchema={resetPasswordDataSchema}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit} noValidate>
                <Grid container>
                  <PasswordInput
                    required
                    color="info"
                    sx={{ marginTop: 3 }}
                    fullWidth
                    name="password"
                    label="Password"
                    onBlur={handleBlur}
                    value={values.password}
                    onChange={handleChange}
                    helperText={touched.password && errors.password}
                    error={touched.password && Boolean(errors.password)}
                  />
                  <PasswordInput
                    required
                    color="info"
                    sx={{ marginTop: 3 }}
                    fullWidth
                    name="confirmPassword"
                    label="Password (confirm)"
                    onBlur={handleBlur}
                    value={values.confirmPassword}
                    onChange={handleChange}
                    helperText={touched.confirmPassword && errors.confirmPassword}
                    error={touched.confirmPassword && Boolean(errors.confirmPassword)}
                  />
                  <Button
                    color="info"
                    type="submit"
                    variant="contained"
                    disabled={isSubmitting}
                    sx={{ marginTop: 3, borderRadius: 6, width: 'inherit' }}
                    endIcon={isSubmitting && (
                      <CircularProgress
                        size={18}
                        sx={(theme: Theme) => ({ color: theme.palette.info.contrastText })}
                      />
                    )}
                  >
                    Reset your password
                  </Button>
                  <BackToSignIn />
                </Grid>
              </form>
            )}
          </Formik>
        </Grid>
      </Grid>
    ) : (
      <Grid item container xs={12} justifyContent="center">
        <Typography variant="h6">
          This user does not exist or the link has expired. Please contact your manager.
        </Typography>
        <BackToSignIn />
      </Grid>
    )
  );
}

export default ResetPasswordForm;
