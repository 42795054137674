import { AxiosResponse } from 'axios';

import {
  RetailerCompany,
  RetailerCompanyBody,
  UploadingRequestMethod,
  RetailerCompanyImageType,
} from 'resources/types';
import instance from 'services/instance';
import { endpoints } from 'resources/constants';
import { generateCompanyImageName, retry } from 'utils';

const getRetailerCompany = (): Promise<AxiosResponse<RetailerCompany>> => (
  instance.get(
    endpoints.RETAILER_COMPANY,
    {
      failureMessage: 'Could not get retailer company',
    },
  )
);

const updateRetailerCompany = (
  companyId: number,
  data: RetailerCompanyBody,
): Promise<AxiosResponse<RetailerCompany>> => (
  instance.put(
    endpoints.RETAILER_COMPANY_ID(companyId),
    data,
    {
      successMessage: 'The retailer company information has been updated successfully',
      failureMessage: 'Could not update company information',
    },
  )
);

const getRetailerCompanyLogo = (companyId: number): Promise<AxiosResponse<Blob>> => (
  instance.get(endpoints.RETAILER_COMPANY_LOGO(companyId), { responseType: 'blob' })
);

const getRetailerCompanyCover = (companyId: number): Promise<AxiosResponse<Blob>> => (
  instance.get(endpoints.RETAILER_COMPANY_COVER(companyId), { responseType: 'blob' })
);

const deleteRetailerCompanyImage = (
  companyId: number,
  type: RetailerCompanyImageType,
): Promise<AxiosResponse<{ value: boolean }>> => (
  instance.delete(
    endpoints.RETAILER_COMPANY_IMAGE(companyId),
    {
      failureMessage: 'Could not delete company logo data',
      params: { type },
    },
  )
);

const uploadRetailerCompanyImage = async (
  companyId: number,
  file: File,
  type: RetailerCompanyImageType,
  method: UploadingRequestMethod,
): Promise<AxiosResponse<Blob> | null> => {
  const formData = new FormData();

  const newName = generateCompanyImageName(companyId, file, type);
  const newFile = new File([file], newName, { type: file.type, lastModified: file.lastModified });

  formData.set('file', newFile);
  formData.set('type', type);

  try {
    await instance({
      method,
      data: formData,
      url: endpoints.RETAILER_COMPANY_UPLOAD(companyId),
      headers: { 'Content-Type': 'multipart/form-data' },
      successMessage: 'Successfully uploaded',
      failureMessage: 'Could not upload the image. Please try later',
    });

    const callback = () => (
      type === 'LOGO' ? (
        getRetailerCompanyLogo(companyId)
      ) : (
        getRetailerCompanyCover(companyId)
      )
    );

    const response: void | AxiosResponse<Blob> = await retry(callback);
    return response || null;
  } catch (error) {
    return null;
  }
};

export default {
  getRetailerCompany,
  updateRetailerCompany,
  getRetailerCompanyLogo,
  getRetailerCompanyCover,
  deleteRetailerCompanyImage,
  uploadRetailerCompanyImage,
};
