import { createSlice } from '@reduxjs/toolkit';

import { USState } from 'resources/types';
import { getUsStates } from './thunks';

export interface USStateState {
  data: Array<USState>;
  loading: boolean;
}

const initialState: USStateState = {
  data: [],
  loading: false,
};

/* eslint-disable no-param-reassign */

const usStatesSlice = createSlice({
  name: 'usStates',
  initialState,
  reducers: {
    reset: () => initialState,
  },

  extraReducers: (builder) => {
    builder.addCase(getUsStates.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUsStates.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getUsStates.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
  },
});

const { reset: resetUSStates } = usStatesSlice.actions;

export { resetUSStates, getUsStates };

export default usStatesSlice;
