import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import {
  Grid,
  Zoom,
  Slide,
  Theme,
  IconButton,
} from '@mui/material';
import { CSSProperties, MouseEvent, useCallback } from 'react';
import { Link } from 'react-router-dom';

import Logo from 'assets/icons/logo/puzl_logo.svg';
import LogoWhite from 'assets/icons/logo/puzl_logo_white.svg';
import { useAppDispatch, useAppSelector } from 'hooks';
import { RootState } from 'store';
import { toggleMenu } from 'store/app';
import VersionInfo from './VersionInfo';
import Menu from './Menu';

const imageStyles: CSSProperties = {
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  height: 40,
  maxWidth: 100,
  margin: 'auto',
  position: 'absolute',
};

function Drawer() {
  const dispatch = useAppDispatch();

  const drawer = useAppSelector((state: RootState) => state.app.drawer);
  const companyState = useAppSelector((state: RootState) => state.retailer.company);

  const { menuOpened, menuWidth } = drawer;
  const { companyLogoURL } = companyState;

  const closeMenu = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      dispatch(toggleMenu(false));
    },
    [dispatch],
  );

  return (
    <Grid
      item
      container
      wrap="nowrap"
      flexShrink={0}
      alignItems="center"
      direction="column"
      sx={(theme: Theme) => ({
        height: '100vh',
        width: menuWidth,
        overflow: 'hidden',
        zIndex: theme.zIndex.drawer,
        boxShadow: theme.shadows[15],
        backgroundColor: theme.palette.template.main,
        transition: theme.transitions.create(['width'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.standard,
        }),
      })}
    >
      <Grid
        px={1}
        container
        height={65}
        alignItems="center"
        position="relative"
        justifyContent="space-between"
        sx={(theme: Theme) => ({ boxShadow: theme.shadows[3] })}
      >
        <Grid
          item
          flex={1}
          container
          height={65}
          maxWidth={230}
          position="relative"
          alignItems="center"
          justifyContent="center"
        >
          <Link to="/" style={{ width: '100%', height: '100%', display: 'flex' }}>
            <Zoom in={menuOpened} timeout={350}>
              <img
                src={LogoWhite}
                alt="Logo"
                style={{ ...imageStyles, zIndex: menuOpened ? 10 : 0 }}
              />
            </Zoom>
            <Zoom in={!menuOpened} timeout={350}>
              <img
                src={Logo}
                alt="Logo"
                style={{ ...imageStyles, zIndex: menuOpened ? 0 : 10, height: 40 }}
              />
            </Zoom>
          </Link>
        </Grid>
        <Zoom in={menuOpened} timeout={350}>
          <IconButton
            onClick={closeMenu}
            sx={(theme: Theme) => ({
              ...(menuOpened ? (
                { width: 50, height: 50, opacity: 1 }
              ) : (
                {
                  width: 0, height: 0, opacity: 0, padding: 0,
                }
              )),
              transition: theme.transitions.create(['width', 'opacity'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration[menuOpened ? 'complex' : 'shorter'],
              }),
            })}
          >
            <KeyboardArrowLeftIcon
              fontSize="large"
              sx={(theme: Theme) => ({ color: theme.palette.template.contrastText })}
            />
          </IconButton>
        </Zoom>
      </Grid>
      <Menu />

      {companyLogoURL && (
        <Grid
          px={1}
          py={3}
          container
          height={150}
          alignItems="flex-end"
          justifyContent="center"
          width={menuWidth}
          sx={(theme: Theme) => ({
            transition: theme.transitions.create(['width'], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration[menuOpened ? 'complex' : 'shorter'],
            }),
          })}
        >
          <Slide direction="up" in mountOnEnter unmountOnExit timeout={700}>
            <img
              alt="Company Logo"
              src={companyLogoURL}
              style={{ width: '100%', maxWidth: 110, maxHeight: 110 }}
            />
          </Slide>
        </Grid>
      )}

      <VersionInfo />

    </Grid>
  );
}

export default Drawer;
