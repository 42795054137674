import {
  RetailerWeeklyMargins,
  RetailerCategoryAnnualMargin,
  RetailerDepartmentAnnualMargin,
  RetailerDepartmentCategorySuggestion, RetailerQuartersLiftsResponse, RetailerQuartersLifts,
} from 'resources/types';
import { decimalToPercent, decimalToPercentWithCommas, withCommas } from '../numbers';

export const getGrossMarginManagerMarginsTableData = (
  companyAnnualMargin: number | null,
  weeklyMargins: RetailerWeeklyMargins | null,
  displayWeeklyMargins: boolean,
): Array<{ title: string, value: string, visible: boolean }> => (
  [
    {
      visible: true,
      title: 'Company Annual Target Margin',
      value: companyAnnualMargin ? `${withCommas(companyAnnualMargin)}%` : '-',
    },
    {
      title: 'Weekly Base Margin',
      value: weeklyMargins?.baseMargin ? `${decimalToPercentWithCommas(weeklyMargins.baseMargin)}%` : '-',
      visible: displayWeeklyMargins,
    },
    {
      title: 'Weekly Aggregated Target Margin',
      value: weeklyMargins?.targetMargin ? `${decimalToPercentWithCommas(weeklyMargins.targetMargin)}%` : '-',
      visible: displayWeeklyMargins,
    },
    {
      title: 'Weekly TPR Margin',
      value: weeklyMargins?.tprMargin ? `${decimalToPercentWithCommas(weeklyMargins.tprMargin)}%` : '-',
      visible: displayWeeklyMargins,
    },

    {
      title: 'Weekly Adjusted Target Margin',
      value: weeklyMargins?.adjustedTargetMargin ? `${decimalToPercentWithCommas(weeklyMargins.adjustedTargetMargin)}%` : '-',
      visible: displayWeeklyMargins,
    },
    {
      title: 'Company Aggregated Shrink',
      value: weeklyMargins?.aggregatedShrink ? `${decimalToPercentWithCommas(weeklyMargins.aggregatedShrink)}%` : '-',
      visible: displayWeeklyMargins,
    },
  ]
);

export const generateDepartmentCategorySuggestions = (
  departmentAnnualMargins: Array<RetailerDepartmentAnnualMargin>,
): Array<RetailerDepartmentCategorySuggestion> => (
  departmentAnnualMargins.reduce((
    departmentItems: Array<RetailerDepartmentCategorySuggestion>,
    departmentAnnualMargin: RetailerDepartmentAnnualMargin,
  ) => {
    const departmentNameSuggestion: RetailerDepartmentCategorySuggestion = {
      id: departmentAnnualMargin.id,
      type: 'department',
      label: departmentAnnualMargin.departmentName,
    };

    const departmentCodeSuggestion: RetailerDepartmentCategorySuggestion = {
      id: departmentAnnualMargin.id,
      type: 'department',
      label: departmentAnnualMargin.departmentCode,
    };

    const categoryTotalItems: Array<RetailerDepartmentCategorySuggestion> = (
      departmentAnnualMargin.categories.reduce((
        categoryItems: Array<RetailerDepartmentCategorySuggestion>,
        categoryAnnualMargin: RetailerCategoryAnnualMargin,
      ) => {
        const categoryNameSuggestion: RetailerDepartmentCategorySuggestion = {
          id: categoryAnnualMargin.id,
          type: 'category',
          label: categoryAnnualMargin.categoryName,
        };

        const categoryCodeSuggestion: RetailerDepartmentCategorySuggestion = {
          id: categoryAnnualMargin.id,
          type: 'category',
          label: categoryAnnualMargin.categoryCode,
        };

        return [...categoryItems, categoryNameSuggestion, categoryCodeSuggestion];
      }, [])
    );

    return [
      ...departmentItems,
      departmentNameSuggestion,
      departmentCodeSuggestion,
      ...categoryTotalItems,
    ];
  }, [])
);

export const formatQuartersLiftsResponse = (
  quartersLiftsResponse: RetailerQuartersLiftsResponse,
): RetailerQuartersLifts => {
  const percentLifts = quartersLiftsResponse.items.map(
    (decimalValue) => (decimalValue != null ? decimalToPercent(Number(decimalValue)) : null),
  );
  const [Q1, Q2, Q3, Q4] = percentLifts;
  return {
    Q1, Q2, Q3, Q4,
  };
};
