import { createAsyncThunk } from '@reduxjs/toolkit';

import services from 'services';
import { Role } from 'resources/types';

const GET_ROLES = 'roles/getRoles';

const getRoles = createAsyncThunk<Array<Role>>(
  GET_ROLES,
  async () => {
    const response = await services.getRoles();
    return response.data;
  },
);

export { getRoles };
