import axios, {
  AxiosError,
  AxiosInstance,
  AxiosResponse,
  AxiosRequestConfig,
} from 'axios';
import qs from 'qs';
import { trackEvent } from 'mixpanel';

import {
  endpoints,
  routsPatterns,
  defaultHeaders,
  // REQUEST_TIMEOUT,
  localStorageKeys,
} from 'resources/constants';
import { showMessage, resetAllState } from 'store';
import { ErrorResponse, SignInData } from 'resources/types';
import { profileManagementEvent } from 'resources/constants/mixpanel';

const createInstance = (): AxiosInstance => {
  const accessToken: string | null = localStorage.getItem(localStorageKeys.ACCESS_TOKEN);
  const refreshToken: string | null = localStorage.getItem(localStorageKeys.REFRESH_TOKEN);
  return axios.create({
    headers: {
      ...(accessToken && { [defaultHeaders.X_AUTH_TOKEN]: accessToken }),
      ...(refreshToken && { [defaultHeaders.REFRESH_TOKEN]: refreshToken }),
    },
    baseURL: import.meta.env.VITE_API_URL,
    // timeout: REQUEST_TIMEOUT,
    paramsSerializer: (params) => qs.stringify(
      { ...params },
      { arrayFormat: 'repeat', encode: false },
    ),
  });
};

const instance: AxiosInstance = createInstance();

const checkAuthStatus = (): boolean => {
  const accessToken: string | null = localStorage.getItem(localStorageKeys.ACCESS_TOKEN);
  const refreshToken: string | null = localStorage.getItem(localStorageKeys.REFRESH_TOKEN);
  return Boolean(accessToken && refreshToken);
};

const signOut = () => {
  delete instance.defaults.headers['X-AUTH-TOKEN'];
  delete instance.defaults.headers['REFRESH-TOKEN'];
  localStorage.removeItem(localStorageKeys.ACCESS_TOKEN);
  localStorage.removeItem(localStorageKeys.REFRESH_TOKEN);
  resetAllState();
};

const signIn = (signInData: SignInData): Promise<any> => {
  const requestConfig: AxiosRequestConfig = {
    failureMessage: 'Unable to sign in',
    mixpanelEvent: {
      EventName: profileManagementEvent.loginUser,
      Value: signInData.email,
    },
  };

  return (
    instance.post(endpoints.SIGN_IN, signInData, requestConfig)
      .then((response: AxiosResponse) => {
        const { accessToken: newAccessToken, refreshToken: newRefreshToken } = response.data;
        instance.defaults.headers['X-AUTH-TOKEN'] = newAccessToken;
        instance.defaults.headers['REFRESH-TOKEN'] = newRefreshToken;
        localStorage.setItem(localStorageKeys.ACCESS_TOKEN, newAccessToken);
        localStorage.setItem(localStorageKeys.REFRESH_TOKEN, newRefreshToken);
      })
      .catch((error) => {
        signOut();
        return Promise.reject(error);
      })
  );
};

instance.interceptors.response.use(
  (response: AxiosResponse) => {
    if (response.config?.successMessage) {
      showMessage({
        messages: [response.config?.successMessage],
        options: { variant: 'success' },
      });
    }

    if (response.config?.mixpanelEvent) {
      trackEvent(response.config?.mixpanelEvent);
    }

    return response;
  },
  (error: AxiosError) => {
    // TODO: Update this
    // const data = error.response?.data as { [key: string]: any };
    // const errorMessage: string = data?.message;

    const errorData = error.response?.data as ErrorResponse;
    const configMessage: string | undefined = error.config?.failureMessage;
    const preventDefaultError = error.config?.preventFeedbackMessage ?? false;
    const errorMessage = errorData.message || configMessage;

    if (error.response && error.response.status === 401) {
      /* eslint-disable no-param-reassign */
      if (!error.config?._retry) {
        return (
          instance.get(endpoints.REFRESH_TOKEN)
            .then((tokenResponse) => {
              const newAccessToken: string = tokenResponse.data.accessToken;
              instance.defaults.headers['X-AUTH-TOKEN'] = newAccessToken;
              localStorage.setItem(localStorageKeys.ACCESS_TOKEN, newAccessToken);
              if (error.config?.headers) error.config.headers['X-AUTH-TOKEN'] = newAccessToken;
              if (error.config !== undefined) {
                error.config._retry = true;
                return instance(error.config);
              }
              return Promise.reject(error);
            })
            .catch((tokenError) => {
              signOut();
              window.location.href = routsPatterns.SIGN_IN;
              return Promise.reject(tokenError);
            })
        );
      }
    }

    if (errorMessage && !preventDefaultError) {
      showMessage({ messages: [errorMessage], options: { variant: 'error' } });
      if (error.config?.mixpanelEvent) {
        trackEvent(error.config.mixpanelEvent, errorMessage);
      }
    }

    return Promise.reject(error);
  },
);

export { signIn, signOut, checkAuthStatus };

export default instance;
