import { createAsyncThunk } from '@reduxjs/toolkit';

import services from 'services';
import { RetailerAd, ListResponse } from 'resources/types';

const GET_RETAILER_ADS = 'retailer/adWeeks/getRetailerAds';
const EXPORT_RETAILER_AD = 'retailer/adWeeks/exportRetailerAd';
const EXPORT_MULTIPLE_RETAILER_ADS = 'retailer/adWeeks/exportMultipleRetailerAds';

const getRetailerAds = createAsyncThunk<
  ListResponse<RetailerAd>,
  number
>(
  GET_RETAILER_ADS,
  async (companyId) => {
    const response = await services.getRetailerAds(companyId);
    return response.data;
  },
);

const exportRetailerAd = createAsyncThunk<
  RetailerAd,
  { companyId: number, adWeek: RetailerAd }
>(
  EXPORT_RETAILER_AD,
  async (params) => {
    const { companyId, adWeek } = params;
    await services.exportRetailerAd(companyId, adWeek);
    return adWeek;
  },
);

const exportMultipleRetailerAds = createAsyncThunk<
  void,
  { companyId: number, adWeeks: Array<RetailerAd> }
>(
  EXPORT_MULTIPLE_RETAILER_ADS,
  async (params) => {
    const { companyId, adWeeks } = params;
    await services.exportMultipleRetailerAds(companyId, adWeeks);
  },
);

export { getRetailerAds, exportRetailerAd, exportMultipleRetailerAds };
