import { VariantType, SnackbarOrigin } from 'notistack';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const OPENED_SIZE: number = 260;
const CLOSED_SIZE: number = 60;

export interface AppState {
  drawer: {
    opened: number;
    closed: number;

    menuWidth: number;
    menuOpened: boolean;
  };

  notifier: {
    options?: {
      persist?: boolean | undefined;
      preventDuplicate?: boolean;
      autoHideDuration?: number | undefined;
      variant?: VariantType;
      anchorOrigin?: SnackbarOrigin;
    };
    message?: string;
  }
}

const initialState: AppState = {
  drawer: {
    opened: OPENED_SIZE,
    closed: CLOSED_SIZE,

    menuWidth: OPENED_SIZE,
    menuOpened: true,
  },

  notifier: {
    options: {
      variant: 'info' as VariantType,
      persist: false,
      preventDuplicate: true,
      autoHideDuration: 3000,
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
      } as SnackbarOrigin,
    },
    message: '',
  },
};

/* eslint-disable no-param-reassign */

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    reset: () => initialState,
    toggleMenu: (state, action: PayloadAction<boolean>) => {
      state.drawer.menuOpened = action.payload;
      state.drawer.menuWidth = action.payload ? state.drawer.opened : state.drawer.closed;
    },
    showMessage: (state, action: PayloadAction<AppState['notifier']>) => {
      const { message, options } = action.payload;

      const notifier = {
        ...initialState.notifier,
        message,
        options: { ...initialState.notifier.options, ...options },
      };

      state.notifier = notifier;
    },
  },
});

const { reset, toggleMenu, showMessage } = appSlice.actions;

export { reset, toggleMenu, showMessage };

export default appSlice;
