import { createSlice } from '@reduxjs/toolkit';

import { ROWS_PER_PAGES } from 'resources/constants';
import { Pagination, RetailerStore, RetailerStoreGroup } from 'resources/types';
import {
  getRetailerStores,
  createRetailerStore,
  updateRetailerStore,
  deleteRetailerStore,
  getRetailerStoreGroups,
} from './thunks';

export interface RetailerStoresState {
  items: Array<RetailerStore>;
  pagination: Pagination;
  storeGroups: Array<RetailerStoreGroup>;
  loading: boolean;
  actionLoading: boolean;
}

const initialState: RetailerStoresState = {
  items: [],
  pagination: {
    page: 0,
    totalPages: 1,
    totalItems: 0,
    size: ROWS_PER_PAGES[0],
  },
  storeGroups: [],
  loading: false,
  actionLoading: false,
};

/* eslint-disable no-param-reassign */

const retailerStoresSlice = createSlice({
  name: 'retailer/stores',
  initialState,
  reducers: {
    reset: () => initialState,
  },

  extraReducers: (builder) => {
    builder.addCase(getRetailerStores.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getRetailerStores.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getRetailerStores.fulfilled, (state, action) => {
      const {
        items, page, size, totalPages, totalItems,
      } = action.payload;
      state.loading = false;
      state.items = items;
      state.pagination = {
        page, size, totalPages, totalItems,
      };
    });

    builder.addCase(getRetailerStoreGroups.rejected, (state) => {
      state.storeGroups = [];
    });
    builder.addCase(getRetailerStoreGroups.fulfilled, (state, action) => {
      const { items } = action.payload;
      state.storeGroups = items;
    });

    builder.addCase(deleteRetailerStore.pending, (state) => {
      state.actionLoading = true;
    });
    builder.addCase(deleteRetailerStore.rejected, (state) => {
      state.actionLoading = false;
    });
    builder.addCase(deleteRetailerStore.fulfilled, (state) => {
      state.actionLoading = false;
    });
  },
});

const { reset: resetRetailerStoresState } = retailerStoresSlice.actions;

export {
  getRetailerStores,
  createRetailerStore,
  updateRetailerStore,
  deleteRetailerStore,
  getRetailerStoreGroups,
  resetRetailerStoresState,
};

export default retailerStoresSlice;
