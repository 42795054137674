import {
  useRef,
  useMemo,
  Fragment,
  useState,
  useEffect,
  useCallback,
} from 'react';
import {
  Box,
  Theme,
  Collapse,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  LinearProgress,
  Table as MuiTable,
} from '@mui/material';
import VisibilitySensor from 'react-visibility-sensor';

import { CollapsibleTableProps, TableDataSource } from 'resources/types';
import { swapListElements } from 'utils';
import Loading from './Loading';
import TableHeadCell from './TableHeadCell';
import TableBodyRow from './TableBodyRow';

const DEFAULT_LOADING_STEP: number = 20;

/* eslint-disable no-nested-ternary */

function CollapsibleTable<
  T extends TableDataSource, Parent extends TableDataSource | undefined
>(props: CollapsibleTableProps<T, Parent>) {
  const {
    open,
    empty,
    parent,
    loading,
    columns,
    headRef,
    heading,
    dataSource,
    actionLoading,
    partialLoad = false,
    loadingStep = DEFAULT_LOADING_STEP,
    enableRowOrdering = false,
    rowRef,
    getChild,
    onRowClick,
    onRowPositionChange,
    bodyStyles,
    headStyles,
    dragHandleStyles,
    debug = false,
  } = props;

  const headingRef = useRef<HTMLTableRowElement | null>(null);

  const [currentDataSource, setCurrentDataSource] = useState<Array<T>>([]);
  const [limit, setLimit] = useState<number>(loadingStep);
  const [hasMore, setHasMore] = useState<boolean>(partialLoad);

  useEffect(
    () => {
      if (!hasMore) setLimit(dataSource ? dataSource.length : 0);
    },
    [dataSource, hasMore],
  );

  const displayingColumns = useMemo(
    () => columns.filter(((col) => !col.hidden)),
    [columns],
  );

  const loadMoreItems = (visible: boolean) => {
    if (visible) {
      const newLimit: number = limit + loadingStep < dataSource.length ? (
        limit + loadingStep
      ) : (
        dataSource.length
      );
      setLimit(newLimit);
      setHasMore(limit + loadingStep < dataSource.length);
    }
  };

  const columnsCount = useMemo(
    () => displayingColumns.length + (enableRowOrdering ? 1 : 0),
    [displayingColumns.length, enableRowOrdering],
  );

  const handleRowMove = useCallback(
    (fromIndex: number, toIndex: number) => {
      setCurrentDataSource(
        (prevDataSource) => swapListElements([...prevDataSource], fromIndex, toIndex),
      );
    },
    [],
  );

  const handleRowDrop = useCallback(
    (fromIndex: number, toIndex: number) => {
      if (onRowPositionChange) {
        onRowPositionChange(fromIndex, toIndex);
      }
    },
    [onRowPositionChange],
  );

  useEffect(() => {
    if (!partialLoad) {
      setCurrentDataSource(dataSource);
      return;
    }

    setCurrentDataSource(dataSource.slice(0, limit) || []);
  }, [dataSource, limit, partialLoad]);

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        ...(loading ? { overflowY: 'hidden' } : {}),
      }}
    >
      <MuiTable>
        {heading && (
          <TableHead>
            <TableRow ref={headRef || headingRef}>
              {enableRowOrdering && (
                <TableCell
                  variant="head"
                  sx={(theme: Theme) => ({
                    width: '0%',
                    maxWidth: 20,
                    minWidth: 20,
                    ...headStyles?.(theme),
                  })}
                />
              )}
              {displayingColumns.map((column) => (
                <TableHeadCell
                  key={column.key}
                  column={column}
                  headStyles={headStyles}
                  debug={debug}
                />
              ))}
            </TableRow>
            {actionLoading && (
              <TableRow>
                <TableCell
                  colSpan={columnsCount}
                  sx={(theme: Theme) => ({
                    p: 0,
                    position: 'sticky',
                    zIndex: theme.zIndex.speedDial,
                    top: ((headRef || headingRef)?.current?.offsetHeight || 0) - 2,
                  })}
                >
                  <LinearProgress />
                </TableCell>
              </TableRow>
            )}
          </TableHead>
        )}

        <TableBody>
          {loading ? (
            <Loading
              count={25}
              displayingColumns={displayingColumns}
              rowsOrderingEnabled={enableRowOrdering}
            />
          ) : (
            empty && !dataSource.length ? (
              <TableRow>
                <TableCell variant="body" colSpan={columnsCount}>
                  {empty}
                </TableCell>
              </TableRow>
            ) : (
              currentDataSource.map((row: T, rowIndex: number) => (
                <Fragment key={row?.id || rowIndex}>
                  <TableBodyRow
                    row={row}
                    columns={displayingColumns}
                    rowIndex={rowIndex}
                    onRowClick={onRowClick}
                    onRowMove={handleRowMove}
                    onRowDrop={handleRowDrop}
                    bodyStyles={bodyStyles}
                    dragHandleStyles={dragHandleStyles}
                    rowRef={rowRef}
                    open={open}
                    parent={parent}
                    rowsOrderingEnabled={enableRowOrdering}
                    loading={loading || actionLoading}
                    debug={debug}
                  />
                  {getChild !== undefined && (
                    <TableRow>
                      <TableCell sx={{ p: 0 }} colSpan={columnsCount}>
                        <Collapse
                          in={typeof open === 'boolean' ? open : open?.(row)}
                          timeout="auto"
                          unmountOnExit
                        >
                          {getChild(row, rowIndex)}
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  )}
                  {partialLoad && rowIndex === currentDataSource.length - 1 && hasMore && (
                    <VisibilitySensor
                      disableShrink
                      partialVisibility
                      onChange={loadMoreItems}
                    >
                      <Loading
                        count={5}
                        displayingColumns={displayingColumns}
                        rowsOrderingEnabled={enableRowOrdering}
                      />
                    </VisibilitySensor>
                  )}
                </Fragment>
              ))
            )
          )}
        </TableBody>
      </MuiTable>
    </Box>
  );
}

CollapsibleTable.defaultProps = {
  heading: true,
  loading: false,
  open: undefined,
  empty: undefined,
  parent: undefined,
  rowRef: undefined,
  headRef: undefined,
  partialLoad: false,
  getChild: undefined,
  actionLoading: false,
  headStyles: undefined,
  bodyStyles: undefined,
  onRowClick: undefined,
  loadingStep: DEFAULT_LOADING_STEP,
};

export default CollapsibleTable;
