import {
  useCallback, useEffect, useMemo, useRef,
} from 'react';
import { useDrag, useDrop } from 'react-dnd';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Identifier } from 'dnd-core';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

import {
  IconButton, TableCell, TableRow,
} from '@mui/material';
import { CollapsibleTableBodyRowProps, TableDataSource, TableRowDragObject } from 'resources/types';
import TableBodyCell from './TableBodyCell';

let rowPositionChanged = false;

function TableBodyRow<T extends TableDataSource, Parent extends TableDataSource | undefined>(
  props: CollapsibleTableBodyRowProps<T, Parent>,
) {
  const {
    row,
    rowIndex,
    columns,
    open,
    parent,
    onRowClick,
    onRowMove,
    onRowDrop,
    onRowDragStart,
    bodyStyles,
    dragHandleStyles,
    rowsOrderingEnabled = false,
    loading = false,
    debug = false,
  } = props;

  const ref = useRef<HTMLTableRowElement>(null);

  const type = useMemo(
    () => {
      if (parent && parent.id) {
        return Symbol.for(parent.id.toString());
      }
      return Symbol.for('puzl.row');
    },
    [parent],
  );

  const handleRowMove = useCallback(
    (fromIndex: number, toIndex: number) => {
      if (!onRowMove) {
        return;
      }

      onRowMove(fromIndex, toIndex);
    },
    [onRowMove],
  );

  const [{ isDragging, handlerId }, connectDrag, connectPreview] = useDrag<
    TableRowDragObject<T>,
    void,
    { isDragging: boolean, handlerId: Identifier | null }
  >(
    () => ({
      type,
      item: {
        draftIndex: rowIndex,
        originIndex: rowIndex,
        data: row,
        size: {
          width: ref.current?.getBoundingClientRect().width ?? 0,
          height: ref.current?.getBoundingClientRect().height ?? 0,
        },
      },
      canDrag: rowsOrderingEnabled && !loading,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
        handlerId: monitor.getHandlerId(),
      }),
      end: (item, monitor) => {
        rowPositionChanged = false;

        if (!monitor.didDrop()) {
          handleRowMove(item.draftIndex, item.originIndex);
        }
      },
    }),
    [rowIndex, row, rowsOrderingEnabled, loading, handleRowMove, type],
  );

  const [, connectDrop] = useDrop<
    TableRowDragObject<T>,
    void
  >(
    () => ({
      accept: type,
      canDrop: () => rowsOrderingEnabled,
      drop: (item, monitor) => {
        if (monitor.canDrop() && onRowDrop) {
          if (!rowPositionChanged || item.originIndex === item.draftIndex) {
            return;
          }

          onRowDrop(item.originIndex, item.draftIndex);
        }
      },
      hover: (item, monitor) => {
        if (!monitor.canDrop()) {
          return;
        }

        const dragIndex = item.draftIndex;
        const hoverIndex = rowIndex;

        if (dragIndex === hoverIndex) {
          return;
        }

        // Determine rectangle on screen
        const hoverBoundingRect = ref.current?.getBoundingClientRect();
        // Determine mouse position
        const clientOffset = monitor.getClientOffset();

        if (!hoverBoundingRect || !clientOffset) {
          return;
        }

        // Get vertical middle
        const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

        // Get pixels to the top
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;

        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%

        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return;
        }

        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return;
        }

        handleRowMove(dragIndex, hoverIndex);

        rowPositionChanged = true;

        // eslint-disable-next-line no-param-reassign
        item.draftIndex = hoverIndex;
      },
    }),
    [rowIndex, handleRowMove, onRowDrop, rowsOrderingEnabled, type],
  );

  const handleRowClick = useCallback(
    () => {
      if (onRowClick) {
        onRowClick(row);
      }
    },
    [onRowClick, row],
  );

  // TODO: ref={rowRef?.(row)} ??????

  connectDrop(connectPreview(ref));

  const containerStyles = useMemo(
    () => ({
      opacity: isDragging ? 0 : 1,
    }),
    [isDragging],
  );

  useEffect(() => {
    if (isDragging && onRowDragStart) {
      onRowDragStart();
    }
  }, [isDragging, onRowDragStart]);

  return (
    <TableRow
      hover
      ref={ref}
      onClick={handleRowClick}
      sx={containerStyles}
      data-handler-id={handlerId}
    >
      {rowsOrderingEnabled && (
        <TableCell
          ref={connectDrag}
          sx={(theme) => ({
            width: '0%',
            maxWidth: 20,
            minWidth: 20,
            align: 'center',
            ...bodyStyles?.(
              theme,
              row,
              Boolean(typeof open === 'boolean' ? open : open?.(row)),
            ),
          })}
        >
          <IconButton
            size="small"
            disableRipple
            sx={{
              cursor: 'move',
            }}
          >
            <DragIndicatorIcon
              fontSize="small"
              sx={(theme) => ({
                ...dragHandleStyles?.(theme),
              })}
            />
          </IconButton>
        </TableCell>
      )}
      {columns.map((column) => (
        <TableBodyCell
          key={column.key}
          row={row}
          rowIndex={rowIndex}
          column={column}
          bodyStyles={bodyStyles}
          open={open}
          parent={parent}
          debug={debug}
        />
      ))}
    </TableRow>
  );
}

export default TableBodyRow;
