import { AxiosResponse } from 'axios';

import {
  RetailerRoundingRule,
  RetailerDepartmentRoundingRule,
} from 'resources/types';
import instance from 'services/instance';
import { endpoints } from 'resources/constants';

const getRetailerDefaultRoundingRules = (
  companyId: number,
): Promise<AxiosResponse<Array<RetailerRoundingRule>>> => (
  instance.get(
    endpoints.RETAILER_ROUNDING_RULES_DEFAULT(companyId),
    {
      failureMessage: 'Could not get retailer default rounding rules',
    },
  )
);

const createRetailerDefaultRoundingRule = (
  companyId: number,
  roundingRule: RetailerRoundingRule,
): Promise<AxiosResponse<RetailerRoundingRule>> => (
  instance.post(
    endpoints.RETAILER_ROUNDING_RULES_DEFAULT(companyId),
    roundingRule,
    {
      successMessage: 'The default rounding rule added successfully',
      failureMessage: 'Could not create default rounding rule',
    },
  )
);

const updateRetailerDefaultRoundingRule = (
  companyId: number,
  ruleId: number,
  roundingRule: RetailerRoundingRule,
): Promise<AxiosResponse<RetailerRoundingRule>> => (
  instance.put(
    endpoints.RETAILER_ROUNDING_RULE(companyId, ruleId),
    roundingRule,
    {
      successMessage: 'The default rounding rule updated successfully',
      failureMessage: 'Could not update default rounding rule',
    },
  )
);

const getRetailerDepartmentsRoundingRules = (
  companyId: number,
): Promise<AxiosResponse<{ items: Array<RetailerDepartmentRoundingRule> }>> => (
  instance.get(
    endpoints.RETAILER_ROUNDING_RULES_LIST(companyId),
    {
      failureMessage: 'Could not get retailer departments rounding rules',
    },
  )
);

const getRetailerDepartmentRoundingRules = (
  companyId: number,
  departmentId: number,
): Promise<AxiosResponse<Array<RetailerRoundingRule>>> => (
  instance.get(
    endpoints.RETAILER_ROUNDING_RULES_DEPARTMENT(companyId, departmentId),
    {
      failureMessage: 'Could not get retailer department rounding rules',
    },
  )
);

const updateRetailerDepartmentRoundingRule = (
  companyId: number,
  ruleId: number,
  roundingRule: RetailerRoundingRule,
): Promise<AxiosResponse<RetailerRoundingRule>> => (
  instance.put(
    endpoints.RETAILER_ROUNDING_RULE(companyId, ruleId),
    roundingRule,
    {
      successMessage: 'The department rounding rule updated successfully',
      failureMessage: 'Could not update department rounding rule',
    },
  )
);

const createRetailerDepartmentRoundingRule = (
  companyId: number,
  departmentId: number,
  roundingRule: RetailerRoundingRule,
): Promise<AxiosResponse<RetailerRoundingRule>> => (
  instance.post(
    endpoints.RETAILER_DEPARTMENTS_ROUNDING_RULE(companyId, departmentId),
    roundingRule,
    {
      successMessage: 'The department rounding rule added successfully',
      failureMessage: 'Could not create department rounding rule',
    },
  )
);

const getRetailerCategoryRoundingRules = (
  companyId: number,
  categoryId: number,
): Promise<AxiosResponse<Array<RetailerRoundingRule>>> => (
  instance.get(
    endpoints.RETAILER_ROUNDING_RULES_CATEGORY(companyId, categoryId),
    {
      failureMessage: 'Could not get retailer category rounding rules',
    },
  )
);

const updateRetailerCategoryRoundingRule = (
  companyId: number,
  ruleId: number,
  roundingRule: RetailerRoundingRule,
): Promise<AxiosResponse<RetailerRoundingRule>> => (
  instance.put(
    endpoints.RETAILER_ROUNDING_RULE(companyId, ruleId),
    roundingRule,
    {
      successMessage: 'The category rounding rule updated successfully',
      failureMessage: 'Could not update category rounding rule',
    },
  )
);

const createRetailerCategoryRoundingRule = (
  companyId: number,
  categoryId: number,
  roundingRule: RetailerRoundingRule,
): Promise<AxiosResponse<RetailerRoundingRule>> => (
  instance.post(
    endpoints.RETAILER_CATEGORIES_ROUNDING_RULE(companyId, categoryId),
    roundingRule,
    {
      successMessage: 'The category rounding rule added successfully',
      failureMessage: 'Could not create category rounding rule',
    },
  )
);

const deleteRetailerRoundingRule = (
  companyId: number,
  ruleId: number,
): Promise<AxiosResponse<{ value: boolean }>> => (
  instance.delete(
    endpoints.RETAILER_ROUNDING_RULE(companyId, ruleId),
    {
      successMessage: 'The rounding rule has been deleted successfully',
      failureMessage: 'Could not delete rounding rule',
    },
  )
);

const fillDownRetailerDefaultRoundingRules = (
  companyId: number,
): Promise<AxiosResponse<Array<RetailerDepartmentRoundingRule>>> => (
  instance.put(
    endpoints.RETAILER_DEFAULT_ROUNDING_RULE_FILL_DOWN(companyId),
    undefined,
    {
      successMessage: 'The rounding rues has ben updated successfully',
      failureMessage: 'Could not updated rounding rules',
    },
  )
);

const fillDownRetailerDepartmentRoundingRules = (
  companyId: number,
  departmentId: number,
): Promise<AxiosResponse<RetailerDepartmentRoundingRule>> => (
  instance.put(
    endpoints.RETAILER_DEPARTMENTS_ROUNDING_RULE_FILL_DOWN(companyId, departmentId),
    undefined,
    {
      successMessage: 'The categories rounding rues has ben updated successfully',
      failureMessage: 'Could not updated rounding rules',
    },
  )
);

const updateRetailerDepartmentLockStatus = (
  companyId: number,
  departmentId: number,
  data: { value: boolean },
): Promise<AxiosResponse<RetailerDepartmentRoundingRule>> => (
  instance.put(
    endpoints.RETAILER_DEPARTMENTS_ROUNDING_RULE_LOCK(companyId, departmentId),
    data,
    {
      successMessage: 'The department status has been updated',
      failureMessage: 'Could not update department status',
    },
  )
);

const updateRetailerCategoryLockStatus = (
  companyId: number,
  categoryId: number,
  data: { value: boolean },
): Promise<AxiosResponse<RetailerDepartmentRoundingRule>> => (
  instance.put(
    endpoints.RETAILER_CATEGORIES_ROUNDING_RULE_LOCK(companyId, categoryId),
    data,
    {
      successMessage: 'The category status has been updated',
      failureMessage: 'Could not update category status',
    },
  )
);

export default {
  deleteRetailerRoundingRule,
  getRetailerDefaultRoundingRules,
  getRetailerCategoryRoundingRules,
  updateRetailerCategoryLockStatus,
  createRetailerDefaultRoundingRule,
  updateRetailerDefaultRoundingRule,
  getRetailerDepartmentRoundingRules,
  createRetailerCategoryRoundingRule,
  updateRetailerDepartmentLockStatus,
  updateRetailerCategoryRoundingRule,
  getRetailerDepartmentsRoundingRules,
  fillDownRetailerDefaultRoundingRules,
  updateRetailerDepartmentRoundingRule,
  createRetailerDepartmentRoundingRule,
  fillDownRetailerDepartmentRoundingRules,
};
