import moment from 'moment/moment';

import { API_DATE_FORMAT } from 'resources/constants';
import {
  RetailerImpItem,
  RetailerImpItemExcelResultRow,
  RetailerImpItemsPayload,
  TableCol,
} from 'resources/types';
import { trimUpc, validateItemLinkCode } from 'utils/index';

const removeSymbols = (cell: string): string => cell.replace(/[^\d.]/g, '');

export const getCurrentImpItemsSelectionStatus = (
  impItems: Array<RetailerImpItem>,
  selectedImpItems: Array<RetailerImpItem>,
): boolean => (
  Boolean(impItems.length)
  && impItems.every(
    (item) => selectedImpItems.some((selectedImpItem) => selectedImpItem.upc === item.upc),
  )
);

export const getImpItemsPayload = (impItems: Array<RetailerImpItem>): RetailerImpItemsPayload => (
  impItems.reduce(
    (total: RetailerImpItemsPayload, impItem: RetailerImpItem) => {
      if (validateItemLinkCode(impItem.linkCode)) {
        if (impItem.linkCode && !total.linkCodes.includes(impItem.linkCode)) {
          total.linkCodes.push(impItem.linkCode);
        }
      } else {
        total.itemsWithoutLinkCode.push({
          ...impItem,
          checked: true,
          groupCode: impItem.upc,
        });
      }
      return total;
    },
    { linkCodes: [], itemsWithoutLinkCode: [] },
  )
);

export const getExcelResult = (
  inputtedData: string,
  batchUploadColumns: Array<keyof RetailerImpItem>,
): Array<RetailerImpItemExcelResultRow> => {
  const rows: Array<string> = inputtedData.split('\n').filter((row) => row !== '');
  return rows.map((row: string) => {
    const memoIndex = batchUploadColumns.findIndex((col) => col === 'memo');
    const startDateIndex = batchUploadColumns.findIndex((col) => col === 'startDate');
    const tokens = row.split('\t')
      .map(
        (item, index) => {
          // Specific cases memo and startDate fields
          if (memoIndex === index) return item;
          if (startDateIndex === index) {
            return moment(item).isValid() ? moment(item)?.format(API_DATE_FORMAT) : null;
          }
          return removeSymbols(item);
        },
      );
    const result: RetailerImpItemExcelResultRow = batchUploadColumns.reduce(
      (total: RetailerImpItemExcelResultRow, columnKey: string, index: number) => {
        const value = tokens[index];
        return {
          ...total,
          [columnKey]: value,
        };
      },
      {},
    );
    return { ...result, upc: trimUpc(result?.upc || '') };
  });
};

export const orderImpItemsColumns = (
  columns: Array<TableCol<any, any>>,
  columnsKeys: Array<string>,
): Array<TableCol<any, any>> => {
  const [description, ...orderingColumns] = columns;
  const orderedColumns = columnsKeys.reduce(
    (totalColumns: Array<TableCol<any, any>>, columnKey: string) => {
      const orderedColumn = orderingColumns.find((col) => col.key === columnKey);
      return orderedColumn ? [...totalColumns, orderedColumn] : totalColumns;
    },
    [],
  );
  return [description, ...orderedColumns];
};

export const filterOutInvalidUpcList = (
  upcList: string[],
  impItems: RetailerImpItem[],
) => (
  upcList.filter(
    (upc) => !impItems.find((impItem) => {
      const normalizedImpItemUpc = Number.parseInt(impItem.upc, 10).toString();
      const normalizedCommodityUpcs = (impItem.commodityUpcs || []).map(
        (commodityUpc) => Number.parseInt(commodityUpc, 10).toString(),
      );
      return (normalizedImpItemUpc === upc || normalizedCommodityUpcs.includes(upc));
    }),
  )
);
