import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  RetailerCompany,
  RetailerCompanyBody,
  UploadingRequestMethod,
  RetailerCompanyImageType,
} from 'resources/types';
import services from 'services';
import { RootState } from 'store';

const GET_RETAILER_COMPANY = 'retailer/company/getRetailerCompany';
const UPDATE_RETAILER_COMPANY = 'retailer/company/updateRetailerCompany';
const GET_RETAILER_COMPANY_LOGO = 'retailer/company/getRetailerCompanyLogo';
const GET_RETAILER_COMPANY_COVER = 'retailer/company/getRetailerCompanyCover';
const UPLOAD_RETAILER_COMPANY_IMAGE = 'retailer/company/uploadRetailerCompanyImage';
const DELETE_RETAILER_COMPANY_IMAGE = 'retailer/company/deleteRetailerCompanyImage';

const getRetailerCompany = createAsyncThunk<RetailerCompany>(
  GET_RETAILER_COMPANY,
  async () => {
    const response = await services.getRetailerCompany();
    return response.data;
  },
);

const updateRetailerCompany = createAsyncThunk<
  RetailerCompany,
  { companyId: number, data: RetailerCompanyBody }
>(
  UPDATE_RETAILER_COMPANY,
  async (params) => {
    const { companyId, data } = params;
    const response = await services.updateRetailerCompany(companyId, data);
    return response.data;
  },
);

const getRetailerCompanyLogo = createAsyncThunk<string, number>(
  GET_RETAILER_COMPANY_LOGO,
  async (companyId) => {
    const response = await services.getRetailerCompanyLogo(companyId);
    return URL.createObjectURL(response.data);
  },
);

const getRetailerCompanyCover = createAsyncThunk<string, number>(
  GET_RETAILER_COMPANY_COVER,
  async (companyId) => {
    const response = await services.getRetailerCompanyCover(companyId);
    return URL.createObjectURL(response.data);
  },
);

const deleteRetailerCompanyImage = createAsyncThunk<
  { value: boolean },
  { companyId: number, type: RetailerCompanyImageType }
>(
  DELETE_RETAILER_COMPANY_IMAGE,
  async (params, thunkAPI) => {
    const { companyId, type } = params;
    const state = thunkAPI.getState() as RootState;
    const { companyLogoURL, companyCoverURL } = state.retailer.company;
    const currentURL = type === 'LOGO' ? companyLogoURL : companyCoverURL;
    const response = await services.deleteRetailerCompanyImage(companyId, type);
    if (currentURL) URL.revokeObjectURL(currentURL);
    return response.data;
  },
);

const uploadRetailerCompanyImage = createAsyncThunk<
  string,
  { companyId: number, file: File, type: RetailerCompanyImageType }
>(
  UPLOAD_RETAILER_COMPANY_IMAGE,
  async (params, thunkAPI) => {
    const { companyId, file, type } = params;
    const state = thunkAPI.getState() as RootState;
    const { companyLogoURL, companyCoverURL } = state.retailer.company;
    const currentURL = type === 'LOGO' ? companyLogoURL : companyCoverURL;
    const method: UploadingRequestMethod = currentURL ? 'PUT' : 'POST';

    const response = await services.uploadRetailerCompanyImage(companyId, file, type, method);
    if (response) {
      if (currentURL) {
        URL.revokeObjectURL(currentURL);
      }
      return URL.createObjectURL(response.data);
    }
    return currentURL;
  },
);

export {
  getRetailerCompany,
  updateRetailerCompany,
  getRetailerCompanyLogo,
  getRetailerCompanyCover,
  deleteRetailerCompanyImage,
  uploadRetailerCompanyImage,
};
