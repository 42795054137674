import { createTheme, Theme, alpha } from '@mui/material/styles';

const defaultTheme: Theme = createTheme({});

const theme: Theme = createTheme({
  palette: {
    primary: {
      main: '#344052',
      contrastText: '#fff',
    },

    warning: {
      main: '#FBB03B',
      background: alpha('#FBB03B', 0.2),
      contrastText: '#fff',
    },

    error: {
      main: '#C1272D',
      background: alpha('#C1272D', 0.2),
      contrastText: '#fff',
    },

    info: {
      light: '#53bbe7',
      main: '#29AAE2',
      dark: '#1c769e',
      background: alpha('#29AAE2', 0.2),
      contrastText: '#fff',
    },

    blue: {
      light: '#3d8dc7',
      main: '#0D71BA',
      dark: '#094f82',
      contrastText: '#fff',
    },

    white: {
      main: '#fff',
      contrastText: '#344052',
    },

    template: {
      light: '#404652',
      main: '#111827',
      dark: '#0b101b',
      contrastText: defaultTheme.palette.grey[200],
    },

    orange: {
      light: '#f8a94c',
      main: '#F79420',
      dark: '#ac6716',
      contrastText: '#fff',
    },

    yellow: {
      light: '#fff59d',
      main: '#ffee58',
      dark: '#fbc02d',
      contrastText: '#fff',
    },

    deepOrange: {
      light: '#f37b51',
      main: '#F15B26',
      dark: '#a83f1a',
      contrastText: '#fff',
    },

    reddishOrange: {
      light: '#e87f6f',
      main: '#E3604C',
      dark: '#9e4335',
      contrastText: '#fff',
    },

    deepPurple: {
      light: '#8458a6',
      main: '#662F90',
      dark: '#472064',
      contrastText: '#fff',
    },

    purple: {
      light: '#a753a3',
      main: '#92298D',
      dark: '#661c62',
      contrastText: '#fff',
    },

    action: {
      disabled: '',
      disabledBackground: '',
    },
  },

  typography: {
    h6: {
      lineHeight: 1.2,
    },
  },

  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          color: '#344052',
        },
      },
    },

    MuiButtonBase: {
      styleOverrides: {
        root: {
          '& .MuiTypography-root': {
            color: 'unset',
          },
          height: 42,
        },
      },
    },

    MuiTableCell: {
      styleOverrides: {
        root: {
          color: '#344052',
          fontSize: '1em',
        },
      },
    },

    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          flexDirection: 'row-reverse',
          '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(90deg)',
          },
          '& .MuiAccordionSummary-content': {
            display: 'flex',
            alignItems: 'center',
          },
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '.MuiSelect-icon': {
            transition: '350ms',
          },
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 20,
          textTransform: 'none',
          '&.Mui-disabled': {
            opacity: 0.5,
          },
        },
      },
    },

    MuiFab: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            opacity: 0.7,
          },
        },
      },
    },

    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            opacity: 0.5,
          },
        },
      },
    },

    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& .MuiAutocomplete-inputRoot': {
            padding: '10px 14px',
            '& .MuiInputBase-input': {
              paddingTop: 0,
              paddingBottom: 0,
            },
          },
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        root: {
          color: '#344052',
          '&.MuiInputBase-adornedEnd': {
            paddingRight: 4,
          },
          '& .MuiInputBase-input': {
            padding: '10px 14px',
          },
          '&.MuiInputBase-multiline': {
            padding: '10px 14px',
            '& .MuiInputBase-input': {
              padding: 0,
            },
          },
        },
      },
    },

    MuiInputLabel: {
      styleOverrides: {
        root: {
          transform: 'translate(14px, 11px) scale(1)',
        },
        shrink: {
          transform: 'translate(14px, -9px) scale(0.8)',
        },
      },
    },

    MuiIconButton: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            opacity: 0.5,
          },
        },
      },
    },

    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
  },

  breakpoints: {
    ...defaultTheme.breakpoints,
    keys: ['xs', 'xs_sm', 'sm', 'sm_md', 'md', 'md_lg', 'lg', 'lg_xl', 'xl'],
    values: {
      xs: defaultTheme.breakpoints.values.xs,
      xs_sm: 300,
      sm: defaultTheme.breakpoints.values.sm,
      sm_md: 700,
      md: defaultTheme.breakpoints.values.md,
      md_lg: 1100,
      lg: defaultTheme.breakpoints.values.lg,
      lg_xl: 1540,
      xl: defaultTheme.breakpoints.values.xl,
    },
  },
});

export default theme;

// TODO: Add following colors in to theme
// #344052 + primary
// #F79420 + orange
// #0D71BA + blue
// #92298D + purple
// #FBB03B + warning
// #F15B26 + deepOrange
// #C1272D + error
// #29AAE2 + info
// #662F90 + deepPurple
// #8795A1
// #525252

// #E3604C
