import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  ListQueries,
  ListResponse,
  RetailerImpItem,
  RetailerWeeklyMargins,
  RetailerImpItemExcelResultRow,
  RetailerDepartmentAnnualMargin,
} from 'resources/types';
import services from 'services';

const GET_RETAILER_IMP_ITEMS = 'retailer/impItems/getRetailerImpItems';
const GET_RETAILER_IMP_ITEMS_GROUPS = 'retailer/impItems/getRetailerImpItemsGroups';
const GET_RETAILER_AD_WEEKLY_MARGINS = 'retailer/impItems/getRetailerAdWeeklyMargins';
const GET_RETAILER_IMP_ITEMS_GROUPS_BY_UPC = 'retailer/impItems/getRetailerImpItemsGroupsByUPC';
const GET_RETAILER_DEPARTMENTS_ANNUAL_MARGINS = 'retailer/impItems/getRetailerDepartmentsAnnualMargins';
const GET_RETAILER_IMP_ITEMS_SANDBOX_ORDERS_CONFIGS = 'retailer/impItems/getRetailerImpItemsOrdersConfigs';

const getRetailerImpItems = createAsyncThunk<
  ListResponse<RetailerImpItem>,
  { companyId: number, adId: number, query?: ListQueries }
>(
  GET_RETAILER_IMP_ITEMS,
  async (params) => {
    const { companyId, adId, query } = params;
    const response = await services.getRetailerImpItems(companyId, adId, query);
    return response.data;
  },
);

const getRetailerImpItemsGroupsByUPC = createAsyncThunk<
  { items: RetailerImpItem[], upcList: string[] },
  {
    companyId: number;
    adId: number;
    excelResult: Array<RetailerImpItemExcelResultRow>;
    priceChange: boolean;
  }
>(
  GET_RETAILER_IMP_ITEMS_GROUPS_BY_UPC,
  async (params) => {
    const { companyId, adId, excelResult } = params;
    const upcList: Array<string> = excelResult
      .reduce((total: Array<string>, row: RetailerImpItemExcelResultRow) => (
        row.upc ? [...total, row.upc] : total
      ), []);
    const response = await services.getRetailerImpItemsByUPC(companyId, adId, { items: upcList });
    return { ...response.data, upcList };
  },
);

const getRetailerImpItemsGroups = createAsyncThunk<
  { items: RetailerImpItem[] },
  {
    companyId: number,
    adId: number,
    data: { linkCodes: string[] },
    itemsWithoutLinkCode: Array<RetailerImpItem>
  }
>(
  GET_RETAILER_IMP_ITEMS_GROUPS,
  async (params) => {
    const { companyId, adId, data } = params;
    if (!data.linkCodes.length) {
      return { items: [] };
    }
    const response = await services.getRetailerImpItemsByLinkCodes(companyId, adId, {
      items: data.linkCodes,
    });
    return response.data;
  },
);

const getRetailerDepartmentsAnnualMargins = createAsyncThunk<
  ListResponse<RetailerDepartmentAnnualMargin>,
  { companyId: number, query?: ListQueries }
>(
  GET_RETAILER_DEPARTMENTS_ANNUAL_MARGINS,
  async (params) => {
    const { companyId, query } = params;
    const response = await services.getRetailerDepartmentsAnnualMargins(companyId, query);
    return response.data;
  },
);

const getRetailerAdWeeklyMargins = createAsyncThunk<
  RetailerWeeklyMargins,
  { companyId: number, adId: number }
>(
  GET_RETAILER_AD_WEEKLY_MARGINS,
  async (params) => {
    const { companyId, adId } = params;
    const response = await services.getRetailerAdWeeklyMargins(companyId, adId);
    return response.data;
  },
);

const getRetailerImpItemsOrdersConfigs = createAsyncThunk<
  string[],
  {
    companyId: number;
    adId: number;
    priceChange: boolean;
  }

>(
  GET_RETAILER_IMP_ITEMS_SANDBOX_ORDERS_CONFIGS,
  async (params) => {
    const { companyId, adId } = params;
    const response = await services.getRetailerSandboxOrdersConfigs(companyId, adId);
    const orders = JSON.parse(response.data.sandboxConfigJson);
    return orders.sandboxUpload.uploadOrder as Array<string>;
  },
);

export {
  getRetailerImpItems,
  getRetailerImpItemsGroups,
  getRetailerAdWeeklyMargins,
  getRetailerImpItemsGroupsByUPC,
  getRetailerImpItemsOrdersConfigs,
  getRetailerDepartmentsAnnualMargins,
};
