import { createSlice } from '@reduxjs/toolkit';

import { RetailerCompany } from 'resources/types';
import {
  getRetailerCompany,
  updateRetailerCompany,
  getRetailerCompanyLogo,
  getRetailerCompanyCover,
  deleteRetailerCompanyImage,
  uploadRetailerCompanyImage,
} from './thunks';

export interface RetailerCompanyState {
  data: RetailerCompany | null;
  loading: boolean;

  companyLogoURL: string;
  companyLogoLoading: boolean;

  companyCoverURL: string;
  companyCoverLoading: boolean;
}

const initialState: RetailerCompanyState = {
  data: null,
  loading: false,

  companyLogoURL: '',
  companyLogoLoading: false,

  companyCoverURL: '',
  companyCoverLoading: false,
};

/* eslint-disable no-param-reassign */

const retailerCompanySlice = createSlice({
  name: 'retailer/company',
  initialState,
  reducers: {
    reset: (state) => {
      URL.revokeObjectURL(state.companyLogoURL);
      URL.revokeObjectURL(state.companyCoverURL);
      return initialState;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getRetailerCompany.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getRetailerCompany.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getRetailerCompany.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });

    builder.addCase(updateRetailerCompany.fulfilled, (state, action) => {
      state.data = action.payload;
    });

    builder.addCase(getRetailerCompanyLogo.pending, (state) => {
      state.companyLogoLoading = true;
    });
    builder.addCase(getRetailerCompanyLogo.rejected, (state) => {
      state.companyLogoLoading = false;
      state.companyLogoURL = '';
    });
    builder.addCase(getRetailerCompanyLogo.fulfilled, (state, action) => {
      state.companyLogoLoading = false;
      state.companyLogoURL = action.payload;
    });

    builder.addCase(getRetailerCompanyCover.pending, (state) => {
      state.companyCoverLoading = true;
    });
    builder.addCase(getRetailerCompanyCover.rejected, (state) => {
      state.companyCoverLoading = false;
      state.companyCoverURL = '';
    });
    builder.addCase(getRetailerCompanyCover.fulfilled, (state, action) => {
      state.companyCoverLoading = false;
      state.companyCoverURL = action.payload;
    });

    builder.addCase(deleteRetailerCompanyImage.fulfilled, (state, action) => {
      const { type } = action.meta.arg;
      if (type === 'LOGO') {
        state.companyLogoURL = '';
        state.companyLogoLoading = false;
      } else {
        state.companyCoverURL = '';
        state.companyCoverLoading = false;
      }
    });

    builder.addCase(uploadRetailerCompanyImage.pending, (state, action) => {
      const { type } = action.meta.arg;
      if (type === 'LOGO') {
        state.companyLogoLoading = true;
      } else {
        state.companyCoverLoading = true;
      }
    });
    builder.addCase(uploadRetailerCompanyImage.rejected, (state, action) => {
      const { type } = action.meta.arg;
      if (type === 'LOGO') {
        state.companyLogoLoading = false;
      } else {
        state.companyCoverLoading = false;
      }
    });
    builder.addCase(uploadRetailerCompanyImage.fulfilled, (state, action) => {
      const { type } = action.meta.arg;
      if (type === 'LOGO') {
        state.companyLogoLoading = false;
        state.companyLogoURL = action.payload;
      } else {
        state.companyCoverLoading = false;
        state.companyCoverURL = action.payload;
      }
    });
  },
});

const { reset: resetRetailerCompanyState } = retailerCompanySlice.actions;

export {
  getRetailerCompany,
  updateRetailerCompany,
  getRetailerCompanyLogo,
  getRetailerCompanyCover,
  resetRetailerCompanyState,
  deleteRetailerCompanyImage,
  uploadRetailerCompanyImage,
};

export default retailerCompanySlice;
