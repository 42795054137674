import { RetailerCompanyImageType } from 'resources/types';

export const generateCompanyImageName = (
  companyId: number,
  file: File,
  type: RetailerCompanyImageType,
): string => {
  const fileName: string = `retailer_${companyId}_${type.toLowerCase()}_${Date.now()}`;
  const extension: string = file.name?.split('.').pop() || '';
  return `${fileName}.${extension}`;
};
