import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  User,
  ProfileBody,
  ResetPasswordData,
  ChangePasswordBody,
  UpdatePasswordData,
  UploadingRequestMethod,
} from 'resources/types';
import services from 'services';
import { RootState } from 'store';

const GET_PROFILE = 'user/getProfile';
const CHECK_AUTH_KEY = 'user/checkAuthKey';
const UPDATE_PROFILE = 'user/updateProfile';
const RESET_PASSWORD = 'user/resetPassword';
const CHANGE_PASSWORD = 'user/changePassword';
const GET_PROFILE_PHOTO = 'user/getProfilePhoto';
const DELETE_PROFILE_PHOTO = 'user/deleteProfilePhoto';
const UPLOAD_PROFILE_PHOTO = 'user/uploadProfilePhoto';
const CHANGE_PASSWORD_WITH_KEY = 'user/changePasswordWithKey';

const getProfile = createAsyncThunk<User>(
  GET_PROFILE,
  async () => {
    const response = await services.getProfile();
    return response.data;
  },
);

const updateProfile = createAsyncThunk<User, ProfileBody>(
  UPDATE_PROFILE,
  async (data) => {
    const response = await services.updateProfile(data);
    return response.data;
  },
);

const getProfilePhoto = createAsyncThunk<string>(
  GET_PROFILE_PHOTO,
  async () => {
    const response = await services.getProfilePhoto();
    return URL.createObjectURL(response.data);
  },
);

const deleteProfilePhoto = createAsyncThunk<{ value: boolean }>(
  DELETE_PROFILE_PHOTO,
  async (_, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const { profilePhotoURL } = state.user;
    const response = await services.deleteProfilePhoto();
    if (profilePhotoURL) URL.revokeObjectURL(profilePhotoURL);
    return response.data;
  },
);

const uploadProfilePhoto = createAsyncThunk<string, File>(
  UPLOAD_PROFILE_PHOTO,
  async (file, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const { profilePhotoURL } = state.user;
    const method: UploadingRequestMethod = profilePhotoURL ? 'PUT' : 'POST';
    const response = await services.uploadProfilePhoto(file, method);
    if (response) {
      if (profilePhotoURL) URL.revokeObjectURL(profilePhotoURL);
      return URL.createObjectURL(response.data);
    }
    return profilePhotoURL;
  },
);

const changePassword = createAsyncThunk<
  { value: boolean },
  ChangePasswordBody
>(
  CHANGE_PASSWORD,
  async (data) => {
    const response = await services.changePassword(data);
    return response.data;
  },
);

const resetPassword = createAsyncThunk<
  { value: boolean },
  ResetPasswordData
>(
  RESET_PASSWORD,
  async (params) => {
    const response = await services.resetPassword(params);
    return response.data;
  },
);

const checkAuthKey = createAsyncThunk<
  { value: boolean },
  { email: string, key: string }
>(
  CHECK_AUTH_KEY,
  async (params) => {
    const { email, key } = params;
    const response = await services.checkAuthKey(email, key);
    return response.data;
  },
);

const changePasswordWithKey = createAsyncThunk<
  { value: boolean },
  { email: string, key: string, data: UpdatePasswordData }
>(
  CHANGE_PASSWORD_WITH_KEY,
  async (params) => {
    const { email, key, data } = params;
    const response = await services.changePasswordWithKey(email, key, data);
    return response.data;
  },
);

export {
  getProfile,
  checkAuthKey,
  updateProfile,
  resetPassword,
  changePassword,
  getProfilePhoto,
  deleteProfilePhoto,
  uploadProfilePhoto,
  changePasswordWithKey,
};
