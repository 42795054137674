import { useMemo } from 'react';
import { Grid, Theme, Typography } from '@mui/material';

import { RootState } from 'store';
import { useAppSelector } from 'hooks';
import { getRoutesForPlacement } from 'utils';
import { CoverLayout, routeObjects } from 'components/main';
import DashboardItem from './DashboardItem';

function Dashboard() {
  let timeOut: number = 200;

  const userState = useAppSelector((state: RootState) => state.user);
  const { userScope } = userState;

  const dashboardItems = useMemo(
    () => getRoutesForPlacement(routeObjects, userScope, 'dashboard'),
    [userScope],
  );

  return (
    <CoverLayout
      title={(
        <Grid sx={{ textAlign: 'center' }}>
          <Typography
            variant="h4"
            fontWeight="bold"
            sx={(theme: Theme) => ({ mt: 8, color: theme.palette.common.white })}
          >
            Puzl
          </Typography>

          <Typography
            variant="h3"
            fontWeight="bold"
            sx={(theme: Theme) => ({ mt: 2, color: theme.palette.common.white })}
          >
            Action Dashboard
          </Typography>
        </Grid>
      )}
    >
      <Grid
        px={2}
        mt={-14}
        container
        width="100%"
        maxWidth={1100}
        justifyContent="center"
      >
        {dashboardItems.map((dashboardItem) => {
          timeOut += 100;
          return (
            <DashboardItem
              timeout={timeOut}
              item={dashboardItem}
              key={dashboardItem.path}
            />
          );
        })}
      </Grid>
    </CoverLayout>
  );
}

export default Dashboard;
