import { TableCell } from '@mui/material';
import { CollapsibleTableBodyCellProps, TableDataSource } from 'resources/types';

function TableBodyCell<T extends TableDataSource, Parent extends TableDataSource | undefined>(
  props: CollapsibleTableBodyCellProps<T, Parent>,
) {
  const {
    row,
    parent,
    open,
    column,
    rowIndex,
    bodyStyles,
    debug = false,
  } = props;

  return (
    <TableCell
      key={column.key}
      variant="body"
      align={column.align}
      sx={(theme) => ({
        width: column.width,
        minWidth: column.minWidth,
        maxWidth: column.maxWidth,
        ...bodyStyles?.(
          theme,
          row,
          Boolean(typeof open === 'boolean' ? open : open?.(row)),
        ),
        ...column?.cellStyles?.(theme),
        ...(debug && { outline: '1px red solid' }),
      })}
    >
      {
        column.renderTableData
          ? column.renderTableData(row, rowIndex, parent)
          : row[column.dataKey]
      }
    </TableCell>
  );
}

export default TableBodyCell;
