import { Box, Typography } from '@mui/material';
import { useAppSelector } from 'hooks';
import { RootState } from 'store';

function VersionInfo() {
  const drawer = useAppSelector((state: RootState) => state.app.drawer);
  const { menuOpened } = drawer;

  return (
    <Box>
      <Typography
        variant="h6"
        sx={(theme) => ({
          color: theme.palette.white.main,
          mb: 2,
          fontSize: 12,
        })}
        aria-label={`Current Platform Version: ${import.meta.env.VITE_APP_VERSION}`}
      >
        {menuOpened ? 'Platform Version' : 'V'}
        {' '}
        {import.meta.env.VITE_APP_VERSION}
      </Typography>
    </Box>
  );
}

export default VersionInfo;
