import { CollapsibleTableHeadCellProps, TableDataSource } from 'resources/types';
import { TableCell, Theme } from '@mui/material';

function TableHeadCell<T extends TableDataSource, Parent extends TableDataSource | undefined>(
  props: CollapsibleTableHeadCellProps<T, Parent>,
) {
  const { column, headStyles, debug = false } = props;

  return (
    <TableCell
      variant="head"
      align={column.align}
      sx={(theme: Theme) => ({
        width: column.width,
        minWidth: column.minWidth,
        maxWidth: column.maxWidth,
        ...headStyles?.(theme),
        ...column.cellStyles?.(theme),
        ...(debug && { outline: '1px red solid' }),
      })}
    >
      {column.renderTableHead ? (
        column.renderTableHead(column.title)
      ) : (
        column.title
      )}
    </TableCell>
  );
}

export default TableHeadCell;
