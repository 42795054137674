import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';

import {
  RetailerStoreGroup,
  SandboxFilterByType,
  RetailerWeeklyMargins,
  RetailerBasePriceSandbox,
  RetailerItemSalesHistory,
  RetailerSalesHistoryState,
  RetailerPricesHistoryState,
  RetailerBasePriceSandboxItem,
  RetailerBasePriceSandboxOffer,
  RetailerSalesHistoryChartDialog,
  RetailerBasePriceSandboxErrorData,
  RetailerBasePriceSandboxExcludedItem,
  RetailerBasePriceSandboxOutOfPlanningItem,
  RetailerBasePriceSandboxItemsDeletionOptions,
} from 'resources/types';
import moment from 'moment';
import {
  generateChartsData,
  getPriceChangeNameError,
  getBasePriceSandboxAllOffersIds,
  getBasePriceSandboxCategoryOffersIds,
  updateBasePriceSandboxDeletionOptions,
  getBasePriceSandboxDepartmentOffersIds,
  getBasePriceSandboxItemsDeletionOptions,
  generateBasePriceSandboxDepartmentsErrorsData,
} from 'utils';
import {
  getRetailerBasePriceSandbox,
  roundRetailerBasePriceSandbox,
  exportRetailerBasePriceSandbox,
  getRetailerBasePriceStoreGroups,
  getRetailerBasePriceSandboxStatus,
  calculateRetailerBasePriceSandbox,
  removeRetailerBasePriceSandboxItem,
  updateRetailerBasePriceSandboxItem,
  removeRetailerBasePriceSandboxItems,
  removeRetailerBasePriceSandboxOffer,
  updateRetailerBasePriceSandboxOffer,
  getRetailerBasePriceAdWeeklyMargins,
  getRetailerBasePriceSandboxTabStatus,
  uploadRetailerBasePriceSandboxOffers,
  duplicateRetailerBasePriceSandboxItem,
  roundRetailerBasePriceSandboxCategory,
  duplicateRetailerBasePriceSandboxOffer,
  updateRetailerBasePriceSandboxCategory,
  roundRetailerBasePriceSandboxDepartment,
  updateRetailerBasePriceSandboxNewPrices,
  updateRetailerBasePriceSandboxDepartment,
  changeRetailerBasePriceSandboxItemStatus,
  updateRetailerBasePriceSandboxOfferNames,
  getRetailerBasePriceSandboxExcludedItems,
  removeRetailerBasePriceSandboxExcludedItems,
  changeRetailerBasePriceSandboxCategoryOrder,
  getRetailerBasePriceSandboxItemSalesHistory,
  getRetailerBasePriceSandboxItemPricesHistory,
  updateRetailerBasePriceSandboxItemLockStatus,
  changeRetailerBasePriceSandboxDepartmentOrder,
  getRetailerBasePriceSandboxOutOfPlanningItems,
  updateRetailerBasePriceSandboxOfferLockStatus,
  removeRetailerBasePriceSandboxOutOfPlanningItems,
  fillDownRetailerBasePriceSandboxItemsNewUnitCost,
  updateRetailerBasePriceSandboxCategoryLockStatus,
  updateRetailerBasePriceSandboxProjectedAdVolumes,
  updateRetailerBasePriceSandboxDepartmentLockStatus,
} from './thunks';

const aiPredictionKeys: Array<keyof RetailerBasePriceSandboxOffer> = [
  'margin',
  'newPrice',
  'priceChange',
];

export interface RetailerBasePriceSandboxState {
  basePriceSandbox: RetailerBasePriceSandbox | null;
  loading: boolean;
  actionLoading: boolean;
  calculationLoading: boolean;
  meBasePriceMGRSandboxOffersUploadLoading: boolean;
  offerPriceLoadings: Array<number>;
  exportLoading: boolean;

  isEndedWeek: boolean;
  offerSalesExpanded: boolean;
  departmentSalesExpanded: boolean;
  openedDepartment?: number;
  openedCategories: Array<number>;
  openedOffers: Array<number>;
  openedItems: Array<number>;
  weeklyMargins: RetailerWeeklyMargins | null;
  actionItem: RetailerBasePriceSandboxItem | null;
  actionOffer: RetailerBasePriceSandboxOffer | null;
  deletionOptions: RetailerBasePriceSandboxItemsDeletionOptions;

  priceChangeNameErrors: Array<number>;
  displayBasePriceSandboxErrors: boolean;
  basePriceSandboxDepartmentsErrorsData: RetailerBasePriceSandboxErrorData;

  basePriceSandboxExcludedItemsRemoveLoading: boolean,
  basePriceSandboxOutOfPlanningItemsRemoveLoading: boolean;
  basePriceSandboxExcludedItems: Array<RetailerBasePriceSandboxExcludedItem>;
  basePriceSandboxOutOfPlanningItems: Array<RetailerBasePriceSandboxOutOfPlanningItem>;

  storeGroups: Array<RetailerStoreGroup>;

  salesHistoryChartDialog: RetailerSalesHistoryChartDialog;
  pricesHistory: RetailerPricesHistoryState;
  salesHistory: RetailerSalesHistoryState;
  filterBy: SandboxFilterByType;
}

const initialState: RetailerBasePriceSandboxState = {
  basePriceSandbox: null,
  loading: false,
  actionLoading: false,
  calculationLoading: false,
  meBasePriceMGRSandboxOffersUploadLoading: false,
  offerPriceLoadings: [],
  exportLoading: false,

  isEndedWeek: false,
  offerSalesExpanded: false,
  departmentSalesExpanded: false,
  openedCategories: [],
  openedOffers: [],
  openedItems: [],
  weeklyMargins: null,
  actionItem: null,
  actionOffer: null,
  deletionOptions: {
    checkboxStates: [],
    allSelected: false,
    disableRemoveBtn: true,
  },

  priceChangeNameErrors: [],
  displayBasePriceSandboxErrors: false,
  basePriceSandboxDepartmentsErrorsData: {},

  basePriceSandboxExcludedItems: [],
  basePriceSandboxOutOfPlanningItems: [],
  basePriceSandboxExcludedItemsRemoveLoading: false,
  basePriceSandboxOutOfPlanningItemsRemoveLoading: false,

  storeGroups: [],

  salesHistoryChartDialog: {
    open: false,
    title: '',
  },
  pricesHistory: {
    loading: false,
    historyComponents: [],
  },
  salesHistory: {
    loading: false,
    salesChartsData: null,
  },
  filterBy: null,
};

/* eslint-disable no-param-reassign */

const basePriceSandboxSlice = createSlice({
  name: 'retailer/marginEnhancement/basePriceSandbox',
  initialState,
  reducers: {
    reset: () => initialState,
    resetWeeklyData: (state) => ({
      ...initialState,
      storeGroups: state.storeGroups,
      filterBy: state.filterBy,
    }),
    setFilterBy: (state, action: PayloadAction<SandboxFilterByType>) => {
      state.deletionOptions = getBasePriceSandboxItemsDeletionOptions({
        departments: state.basePriceSandbox?.baseTprSandboxDepartments,
        filterBy: action.payload,
        sandboxErrorData: state.basePriceSandboxDepartmentsErrorsData,
      });
      state.filterBy = action.payload;
    },
    setOpenedDepartment: (state, action: PayloadAction<number>) => {
      state.openedDepartment = state.openedDepartment === action.payload
        ? undefined
        : action.payload;
      state.priceChangeNameErrors = [];
    },
    setOpenedCategories: (state, action: PayloadAction<number>) => {
      state.openedCategories = state.openedCategories.includes(action.payload) ? (
        state.openedCategories.filter((id) => id !== action.payload)
      ) : (
        [...state.openedCategories, action.payload]
      );
      state.priceChangeNameErrors = [];
    },
    setOpenedOffers: (state, action: PayloadAction<number>) => {
      state.openedOffers = state.openedOffers.includes(action.payload) ? (
        state.openedOffers.filter((id) => id !== action.payload)
      ) : (
        [...state.openedOffers, action.payload]
      );
    },
    setOpenedItems: (state, action: PayloadAction<number>) => {
      state.openedItems = state.openedItems.includes(action.payload) ? (
        state.openedItems.filter((id) => id !== action.payload)
      ) : (
        [...state.openedItems, action.payload]
      );
    },
    setOfferSalesExpanded: (state, action: PayloadAction<boolean>) => {
      state.offerSalesExpanded = action.payload;
    },
    setDepartmentSalesExpanded: (state, action: PayloadAction<boolean>) => {
      state.departmentSalesExpanded = action.payload;
    },
    setActionItem: (state, action: PayloadAction<RetailerBasePriceSandboxItem | null>) => {
      state.actionItem = action.payload;
    },
    setActionOffer: (state, action: PayloadAction<RetailerBasePriceSandboxOffer | null>) => {
      state.actionOffer = action.payload;
    },
    selectBasePriceSandboxDepartment: (
      state,
      action: PayloadAction<{ departmentId: number | null, checked: boolean }>,
    ) => {
      const { departmentId, checked } = action.payload;
      if (departmentId == null) {
        state.deletionOptions = {
          allSelected: checked,
          disableRemoveBtn: !checked,
          checkboxStates: state.deletionOptions.checkboxStates.map(
            (departmentState) => ({
              ...departmentState,
              checked,
              categories: departmentState.categories?.map(
                (categoryState) => ({
                  ...categoryState,
                  checked,
                  offers: categoryState.offers?.map(
                    (offerState) => ({
                      ...offerState,
                      checked,
                      items: offerState.items?.map((itemState) => ({ ...itemState, checked })),
                    }),
                  ),
                }),
              ),
            }),
          ),
        };
      } else {
        const checkboxStates = JSON.parse(JSON.stringify(state.deletionOptions.checkboxStates));
        state.deletionOptions = updateBasePriceSandboxDeletionOptions({
          checkboxStates,
          checked,
          departmentId,
        });
      }
    },
    selectBasePriceSandboxCategory: (
      state,
      action: PayloadAction<{
        departmentId: number,
        categoryId: number,
        checked: boolean
      }>,
    ) => {
      const { departmentId, categoryId, checked } = action.payload;
      const checkboxStates = JSON.parse(JSON.stringify(state.deletionOptions.checkboxStates));
      state.deletionOptions = updateBasePriceSandboxDeletionOptions({
        checkboxStates,
        checked,
        departmentId,
        categoryId,
      });
    },
    selectBasePriceSandboxOffer: (
      state,
      action: PayloadAction<{
        departmentId: number,
        categoryId: number,
        offerId: number,
        checked: boolean
      }>,
    ) => {
      const {
        departmentId, categoryId, offerId, checked,
      } = action.payload;
      const checkboxStates = JSON.parse(JSON.stringify(state.deletionOptions.checkboxStates));
      state.deletionOptions = updateBasePriceSandboxDeletionOptions({
        checkboxStates,
        checked,
        departmentId,
        categoryId,
        offerId,
      });
    },
    selectBasePriceSandboxItem: (
      state,
      action: PayloadAction<{
        departmentId: number,
        categoryId: number,
        offerId: number,
        itemId: number,
        checked: boolean
      }>,
    ) => {
      const {
        departmentId, categoryId, offerId, itemId, checked,
      } = action.payload;
      const checkboxStates = JSON.parse(JSON.stringify(state.deletionOptions.checkboxStates));
      state.deletionOptions = updateBasePriceSandboxDeletionOptions({
        checkboxStates,
        checked,
        departmentId,
        categoryId,
        offerId,
        itemId,
      });
    },
    updateBasePriceSandboxDepartmentsErrorsData: (state) => {
      if (state.basePriceSandbox) {
        state.basePriceSandboxDepartmentsErrorsData = generateBasePriceSandboxDepartmentsErrorsData(
          state.basePriceSandbox.baseTprSandboxDepartments,
        );
      }
    },
    setHistoryDialogData: (
      state,
      action: PayloadAction<RetailerSalesHistoryChartDialog>,
    ) => {
      state.salesHistoryChartDialog = action.payload;
      state.salesHistory = { ...initialState.salesHistory };
      state.pricesHistory = { ...initialState.pricesHistory };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getRetailerBasePriceSandbox.pending, (state, action) => {
      const { refetch = false } = action.meta.arg;
      if (!refetch) {
        state.basePriceSandbox = null;
        state.loading = true;
      }
    });
    builder.addCase(getRetailerBasePriceSandbox.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getRetailerBasePriceAdWeeklyMargins.rejected, (state) => {
      state.weeklyMargins = null;
    });
    builder.addCase(getRetailerBasePriceAdWeeklyMargins.fulfilled, (state, action) => {
      const { endDate } = action.payload;
      const isEndedWeek = moment().isAfter(endDate, 'day');
      state.weeklyMargins = action.payload;
      state.isEndedWeek = isEndedWeek;
    });

    builder.addCase(getRetailerBasePriceStoreGroups.rejected, (state) => {
      state.storeGroups = [];
    });
    builder.addCase(getRetailerBasePriceStoreGroups.fulfilled, (state, action) => {
      state.storeGroups = action.payload.items;
    });

    builder.addCase(uploadRetailerBasePriceSandboxOffers.pending, (state) => {
      state.meBasePriceMGRSandboxOffersUploadLoading = true;
    });
    builder.addCase(uploadRetailerBasePriceSandboxOffers.rejected, (state) => {
      state.meBasePriceMGRSandboxOffersUploadLoading = false;
    });
    builder.addCase(uploadRetailerBasePriceSandboxOffers.fulfilled, (state) => {
      state.meBasePriceMGRSandboxOffersUploadLoading = false;
    });

    builder.addCase(exportRetailerBasePriceSandbox.pending, (state) => {
      state.exportLoading = true;
    });
    builder.addCase(exportRetailerBasePriceSandbox.rejected, (state) => {
      state.exportLoading = false;
    });
    builder.addCase(exportRetailerBasePriceSandbox.fulfilled, (state) => {
      state.exportLoading = false;
    });

    builder.addCase(updateRetailerBasePriceSandboxNewPrices.pending, (state, action) => {
      const { offersData } = action.meta.arg;
      const offersIds = offersData.items.map((offer) => offer.id);
      state.offerPriceLoadings = [...state.offerPriceLoadings, ...offersIds];
      state.actionLoading = true;
    });
    builder.addCase(updateRetailerBasePriceSandboxNewPrices.rejected, (state, action) => {
      const { offersData } = action.meta.arg;
      const offersIds = offersData.items.map((offer) => offer.id);
      state.offerPriceLoadings = state.offerPriceLoadings
        .filter((offerId) => !offersIds.includes(offerId));
      state.actionLoading = false;
    });
    builder.addCase(updateRetailerBasePriceSandboxNewPrices.fulfilled, (state, action) => {
      const { offersData } = action.meta.arg;
      const offersIds = offersData.items.map((offer) => offer.id);
      state.offerPriceLoadings = state.offerPriceLoadings
        .filter((offerId) => !offersIds.includes(offerId));
      state.actionLoading = false;
    });

    builder.addCase(updateRetailerBasePriceSandboxOffer.pending, (state, action) => {
      const { name, data } = action.meta.arg;
      if (aiPredictionKeys.includes(name as keyof RetailerBasePriceSandboxOffer)) {
        state.offerPriceLoadings = [...state.offerPriceLoadings, data.id];
      }
    });
    builder.addCase(updateRetailerBasePriceSandboxOffer.rejected, (state, action) => {
      const { name, data } = action.meta.arg;
      if (aiPredictionKeys.includes(name as keyof RetailerBasePriceSandboxOffer)) {
        state.offerPriceLoadings = state.offerPriceLoadings
          .filter((offerId) => offerId !== data.id);
      }
    });
    builder.addCase(updateRetailerBasePriceSandboxOffer.fulfilled, (state, action) => {
      const { name, data } = action.meta.arg;
      if (aiPredictionKeys.includes(name as keyof RetailerBasePriceSandboxOffer)) {
        state.offerPriceLoadings = state.offerPriceLoadings
          .filter((offerId) => offerId !== data.id);
      }
    });

    builder.addCase(getRetailerBasePriceSandboxStatus.fulfilled, (state, action) => {
      if (state.basePriceSandbox) {
        state.basePriceSandbox.baseTprSandboxStatus = action.payload;
      }
    });

    builder.addCase(getRetailerBasePriceSandboxTabStatus.fulfilled, (state, action) => {
      if (state.basePriceSandbox) {
        state.basePriceSandbox.baseTprSandboxTabStatus = action.payload;
      }
    });

    builder.addCase(calculateRetailerBasePriceSandbox.pending, (state) => {
      if (state.basePriceSandbox) {
        state.basePriceSandbox.baseTprSandboxStatus = 'SANDBOX_CALCULATING_IN_PROGRESS';
      }
      state.actionLoading = true;
      state.calculationLoading = true;
    });
    builder.addCase(calculateRetailerBasePriceSandbox.rejected, (state) => {
      if (state.basePriceSandbox) {
        state.basePriceSandbox.baseTprSandboxStatus = 'SANDBOX_NOT_CALCULATED';
      }
      state.actionLoading = false;
      state.calculationLoading = false;
      state.displayBasePriceSandboxErrors = true;
    });

    builder.addCase(getRetailerBasePriceSandboxExcludedItems.rejected, (state) => {
      state.basePriceSandboxExcludedItems = [];
    });
    builder.addCase(getRetailerBasePriceSandboxExcludedItems.fulfilled, (state, action) => {
      state.basePriceSandboxExcludedItems = action.payload;
    });

    builder.addCase(removeRetailerBasePriceSandboxExcludedItems.pending, (state) => {
      state.basePriceSandboxExcludedItemsRemoveLoading = true;
    });
    builder.addCase(removeRetailerBasePriceSandboxExcludedItems.rejected, (state) => {
      state.basePriceSandboxExcludedItemsRemoveLoading = true;
      state.basePriceSandboxExcludedItems = [];
    });
    builder.addCase(removeRetailerBasePriceSandboxExcludedItems.fulfilled, (state) => {
      state.basePriceSandboxExcludedItemsRemoveLoading = true;
      state.basePriceSandboxExcludedItems = [];
    });

    builder.addCase(getRetailerBasePriceSandboxOutOfPlanningItems.rejected, (state) => {
      state.basePriceSandboxOutOfPlanningItems = [];
    });
    builder.addCase(getRetailerBasePriceSandboxOutOfPlanningItems.fulfilled, (state, action) => {
      state.basePriceSandboxOutOfPlanningItems = action.payload;
    });

    builder.addCase(removeRetailerBasePriceSandboxOutOfPlanningItems.pending, (state) => {
      state.basePriceSandboxOutOfPlanningItemsRemoveLoading = true;
    });
    builder.addCase(removeRetailerBasePriceSandboxOutOfPlanningItems.rejected, (state) => {
      state.basePriceSandboxOutOfPlanningItemsRemoveLoading = false;
      state.basePriceSandboxOutOfPlanningItems = [];
    });
    builder.addCase(removeRetailerBasePriceSandboxOutOfPlanningItems.fulfilled, (state) => {
      state.basePriceSandboxOutOfPlanningItemsRemoveLoading = false;
      state.basePriceSandboxOutOfPlanningItems = [];
    });

    builder.addCase(updateRetailerBasePriceSandboxOfferNames.rejected, (state, action) => {
      const { data: updatingOffer, name } = action.meta.arg;
      if (name === 'priceChangeName') {
        const priceChangeNameError = getPriceChangeNameError(updatingOffer.priceChangeName);
        if (priceChangeNameError) {
          state.priceChangeNameErrors = [...state.priceChangeNameErrors, updatingOffer.id];
        }
      }
    });

    builder.addCase(updateRetailerBasePriceSandboxOfferNames.fulfilled, (state, action) => {
      const { data: updatingOffer, name } = action.meta.arg;
      if (name === 'priceChangeName') {
        const priceChangeNameError = getPriceChangeNameError(updatingOffer.priceChangeName);
        if (!priceChangeNameError) {
          state.priceChangeNameErrors = state.priceChangeNameErrors.filter((offerId) => (
            offerId !== updatingOffer.id
          ));
        }
      }
    });

    builder.addCase(getRetailerBasePriceSandboxItemSalesHistory.pending, (state) => {
      state.salesHistory.loading = true;
    });
    builder.addCase(getRetailerBasePriceSandboxItemSalesHistory.rejected, (state) => {
      state.salesHistory.loading = false;
    });
    builder.addCase(getRetailerBasePriceSandboxItemSalesHistory.fulfilled, (state, action) => {
      state.salesHistory.loading = false;
      const salesHistory = { ...action.payload, historyComponents: [] };
      if (action.payload.historyComponents) {
        const historyComponents = (
          JSON.parse(action.payload.historyComponents) as RetailerItemSalesHistory['historyComponents']
        );
        state.salesHistory.salesChartsData = generateChartsData(
          { ...salesHistory, historyComponents },
          'R',
          state.weeklyMargins,
        );
      }
    });

    builder.addCase(getRetailerBasePriceSandboxItemPricesHistory.pending, (state) => {
      state.pricesHistory.loading = true;
    });
    builder.addCase(getRetailerBasePriceSandboxItemPricesHistory.rejected, (state) => {
      state.pricesHistory.loading = false;
    });
    builder.addCase(getRetailerBasePriceSandboxItemPricesHistory.fulfilled, (state, action) => {
      state.pricesHistory.loading = false;
      state.pricesHistory.historyComponents = action.payload;
    });

    builder.addCase(roundRetailerBasePriceSandbox.pending, (state) => {
      state.offerPriceLoadings = getBasePriceSandboxAllOffersIds(state.basePriceSandbox);
    });
    builder.addCase(roundRetailerBasePriceSandbox.rejected, (state) => {
      state.offerPriceLoadings = [];
    });
    builder.addCase(roundRetailerBasePriceSandbox.fulfilled, (state) => {
      state.actionLoading = false;
      state.offerPriceLoadings = [];
    });

    builder.addCase(getRetailerBasePriceSandbox.fulfilled, (state, action) => {
      state.basePriceSandbox = action.payload;
      const basePriceSandboxDepartmentsErrorsData = generateBasePriceSandboxDepartmentsErrorsData(
        state.basePriceSandbox.baseTprSandboxDepartments,
      );
      const deletionOptions = getBasePriceSandboxItemsDeletionOptions({
        departments: action.payload.baseTprSandboxDepartments,
        filterBy: state.filterBy,
        sandboxErrorData: basePriceSandboxDepartmentsErrorsData,
        deletionOptions: state.deletionOptions,
      });
      state.basePriceSandboxDepartmentsErrorsData = basePriceSandboxDepartmentsErrorsData;
      state.deletionOptions = deletionOptions;
    });

    // For Department Level
    builder.addMatcher(isAnyOf(
      roundRetailerBasePriceSandboxDepartment.pending,
      updateRetailerBasePriceSandboxDepartment.pending,
    ), (state, action) => {
      const { departmentId } = action.meta.arg;
      const offers = getBasePriceSandboxDepartmentOffersIds(departmentId, state.basePriceSandbox);
      state.actionLoading = true;
      state.offerPriceLoadings = [...state.offerPriceLoadings, ...offers];
    });

    builder.addMatcher(isAnyOf(
      roundRetailerBasePriceSandboxDepartment.rejected,
      updateRetailerBasePriceSandboxDepartment.rejected,
    ), (state, action) => {
      const { departmentId } = action.meta.arg;
      const offers = getBasePriceSandboxDepartmentOffersIds(departmentId, state.basePriceSandbox);
      state.actionLoading = false;
      state.offerPriceLoadings = state.offerPriceLoadings
        .filter((offerId) => !offers.includes(offerId));
    });

    // For Category Level
    builder.addMatcher(isAnyOf(
      roundRetailerBasePriceSandboxCategory.pending,
      updateRetailerBasePriceSandboxCategory.pending,
    ), (state, action) => {
      const { categoryId } = action.meta.arg;
      const offers = getBasePriceSandboxCategoryOffersIds(categoryId, state.basePriceSandbox);
      state.offerPriceLoadings = [...state.offerPriceLoadings, ...offers];
    });

    builder.addMatcher(isAnyOf(
      roundRetailerBasePriceSandboxCategory.rejected,
      updateRetailerBasePriceSandboxCategory.rejected,
    ), (state, action) => {
      const { categoryId } = action.meta.arg;
      const offers = getBasePriceSandboxCategoryOffersIds(categoryId, state.basePriceSandbox);
      state.offerPriceLoadings = state.offerPriceLoadings
        .filter((offerId) => !offers.includes(offerId));
    });

    builder.addMatcher(isAnyOf(
      getRetailerBasePriceSandbox.pending,
      roundRetailerBasePriceSandbox.pending,
      updateRetailerBasePriceSandboxItem.pending,
      removeRetailerBasePriceSandboxItem.pending,
      removeRetailerBasePriceSandboxOffer.pending,
      updateRetailerBasePriceSandboxOffer.pending,
      removeRetailerBasePriceSandboxItems.pending,
      roundRetailerBasePriceSandboxCategory.pending,
      updateRetailerBasePriceSandboxCategory.pending,
      duplicateRetailerBasePriceSandboxItem.pending,
      duplicateRetailerBasePriceSandboxOffer.pending,
      roundRetailerBasePriceSandboxDepartment.pending,
      updateRetailerBasePriceSandboxOfferNames.pending,
      changeRetailerBasePriceSandboxItemStatus.pending,
      updateRetailerBasePriceSandboxItemLockStatus.pending,
      updateRetailerBasePriceSandboxOfferLockStatus.pending,
      fillDownRetailerBasePriceSandboxItemsNewUnitCost.pending,
      updateRetailerBasePriceSandboxCategoryLockStatus.pending,
      updateRetailerBasePriceSandboxDepartmentLockStatus.pending,
      updateRetailerBasePriceSandboxProjectedAdVolumes.pending,
      changeRetailerBasePriceSandboxDepartmentOrder.pending,
      changeRetailerBasePriceSandboxCategoryOrder.pending,
    ), (state) => {
      state.actionLoading = true;
    });

    builder.addMatcher(isAnyOf(
      getRetailerBasePriceSandbox.rejected,
      roundRetailerBasePriceSandbox.rejected,
      updateRetailerBasePriceSandboxItem.rejected,
      removeRetailerBasePriceSandboxItem.rejected,
      removeRetailerBasePriceSandboxOffer.rejected,
      updateRetailerBasePriceSandboxOffer.rejected,
      removeRetailerBasePriceSandboxItems.rejected,
      roundRetailerBasePriceSandboxCategory.rejected,
      duplicateRetailerBasePriceSandboxItem.rejected,
      duplicateRetailerBasePriceSandboxOffer.rejected,
      roundRetailerBasePriceSandboxDepartment.rejected,
      updateRetailerBasePriceSandboxOfferNames.rejected,
      updateRetailerBasePriceSandboxCategory.rejected,
      changeRetailerBasePriceSandboxItemStatus.rejected,
      updateRetailerBasePriceSandboxItemLockStatus.rejected,
      updateRetailerBasePriceSandboxOfferLockStatus.rejected,
      fillDownRetailerBasePriceSandboxItemsNewUnitCost.rejected,
      updateRetailerBasePriceSandboxCategoryLockStatus.rejected,
      updateRetailerBasePriceSandboxDepartmentLockStatus.rejected,
      updateRetailerBasePriceSandboxProjectedAdVolumes.rejected,
      changeRetailerBasePriceSandboxDepartmentOrder.rejected,
      changeRetailerBasePriceSandboxCategoryOrder.rejected,
    ), (state) => {
      state.actionLoading = false;
    });

    // Get Sandbox reset errors and loading
    builder.addMatcher(isAnyOf(
      getRetailerBasePriceSandbox.fulfilled,
      calculateRetailerBasePriceSandbox.fulfilled,
      removeRetailerBasePriceSandboxItem.fulfilled,
      removeRetailerBasePriceSandboxOffer.fulfilled,
      updateRetailerBasePriceSandboxProjectedAdVolumes.fulfilled,
    ), (state) => {
      state.loading = false;
      state.actionLoading = false;
      state.calculationLoading = false;
      state.displayBasePriceSandboxErrors = false;
      state.offerPriceLoadings = [];
    });
  },
});

const {
  setFilterBy,
  setActionItem,
  setActionOffer,
  setOpenedItems,
  setOpenedOffers,
  resetWeeklyData,
  setOpenedCategories,
  setOpenedDepartment,
  setHistoryDialogData,
  setOfferSalesExpanded,
  selectBasePriceSandboxItem,
  selectBasePriceSandboxOffer,
  setDepartmentSalesExpanded,
  selectBasePriceSandboxCategory,
  selectBasePriceSandboxDepartment,
  reset: resetRetailerBasePriceSandboxState,
  updateBasePriceSandboxDepartmentsErrorsData,
} = basePriceSandboxSlice.actions;

export {
  setFilterBy,
  setActionItem,
  setActionOffer,
  setOpenedItems,
  setOpenedOffers,
  resetWeeklyData,
  setOpenedCategories,
  setOpenedDepartment,
  setHistoryDialogData,
  setOfferSalesExpanded,
  selectBasePriceSandboxItem,
  setDepartmentSalesExpanded,
  selectBasePriceSandboxOffer,
  getRetailerBasePriceSandbox,
  roundRetailerBasePriceSandbox,
  selectBasePriceSandboxCategory,
  exportRetailerBasePriceSandbox,
  getRetailerBasePriceStoreGroups,
  selectBasePriceSandboxDepartment,
  calculateRetailerBasePriceSandbox,
  getRetailerBasePriceSandboxStatus,
  removeRetailerBasePriceSandboxItem,
  updateRetailerBasePriceSandboxItem,
  resetRetailerBasePriceSandboxState,
  removeRetailerBasePriceSandboxItems,
  removeRetailerBasePriceSandboxOffer,
  updateRetailerBasePriceSandboxOffer,
  getRetailerBasePriceAdWeeklyMargins,
  getRetailerBasePriceSandboxTabStatus,
  uploadRetailerBasePriceSandboxOffers,
  duplicateRetailerBasePriceSandboxItem,
  roundRetailerBasePriceSandboxCategory,
  duplicateRetailerBasePriceSandboxOffer,
  updateRetailerBasePriceSandboxCategory,
  roundRetailerBasePriceSandboxDepartment,
  updateRetailerBasePriceSandboxNewPrices,
  updateRetailerBasePriceSandboxDepartment,
  changeRetailerBasePriceSandboxItemStatus,
  getRetailerBasePriceSandboxExcludedItems,
  updateRetailerBasePriceSandboxOfferNames,
  updateBasePriceSandboxDepartmentsErrorsData,
  removeRetailerBasePriceSandboxExcludedItems,
  getRetailerBasePriceSandboxItemSalesHistory,
  updateRetailerBasePriceSandboxItemLockStatus,
  changeRetailerBasePriceSandboxCategoryOrder,
  getRetailerBasePriceSandboxItemPricesHistory,
  updateRetailerBasePriceSandboxOfferLockStatus,
  changeRetailerBasePriceSandboxDepartmentOrder,
  getRetailerBasePriceSandboxOutOfPlanningItems,
  removeRetailerBasePriceSandboxOutOfPlanningItems,
  fillDownRetailerBasePriceSandboxItemsNewUnitCost,
  updateRetailerBasePriceSandboxCategoryLockStatus,
  updateRetailerBasePriceSandboxProjectedAdVolumes,
  updateRetailerBasePriceSandboxDepartmentLockStatus,
};

export default basePriceSandboxSlice;
