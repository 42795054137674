import { createSlice } from '@reduxjs/toolkit';

export interface RetailerRulesSetupRootState {
  departments: Array<any>;
}

const initialState: RetailerRulesSetupRootState = {
  departments: [],
};

/* eslint-disable no-param-reassign */

const retailerRulesSetupRootSlice = createSlice({
  name: 'retailer/rulesSetup/root',
  initialState,
  reducers: {
    reset: () => initialState,
  },
});

const { reset: resetRetailerRulesSetupRootState } = retailerRulesSetupRootSlice.actions;

export { resetRetailerRulesSetupRootState };

export default retailerRulesSetupRootSlice;
