import { ReactNode } from 'react';
import {
  Zoom,
  Theme,
  Dialog,
  Button,
  Typography,
  DialogTitle,
  DialogProps,
  DialogActions,
  DialogContent,
  CircularProgress,
  DialogContentText,
  ButtonPropsColorOverrides,
} from '@mui/material';
import { SystemStyleObject } from '@mui/system';

export interface ActionDialogOptions {
  title: string;
  actionLabel: string;
  cancelLabel?: string;
  actionContent?: ReactNode;
  actionContentText?: string | Array<string>;
  colorKey?: keyof ButtonPropsColorOverrides;
}

export interface ActionsDialogProps {
  open: boolean;
  loading?: boolean;
  disableCancel?: boolean;
  maxWidth?: DialogProps['maxWidth'];
  disableAction?: boolean;
  onClose: () => void;
  actionFunction: () => void;
  dialogOptions: ActionDialogOptions | null;
  transitionDuration?: number;
  contentStyles?: SystemStyleObject<Theme>
}

const blankValues: ActionDialogOptions = {
  title: '',
  actionLabel: '',
  cancelLabel: 'Cancel',
  actionContent: null,
  actionContentText: '',
  colorKey: 'warning',
};

function ActionDialog(props: ActionsDialogProps) {
  const {
    open,
    loading,
    maxWidth,
    disableAction,
    disableCancel,
    onClose,
    actionFunction,
    dialogOptions,
    transitionDuration,
    contentStyles,
  } = props;

  const {
    title,
    actionLabel,
    actionContentText,
    actionContent,
    colorKey = 'warning',
    cancelLabel = 'Cancel',
  } = dialogOptions || blankValues;

  return (
    <Dialog
      fullWidth
      open={open}
      maxWidth={maxWidth}
      TransitionComponent={Zoom}
      transitionDuration={transitionDuration}
    >
      <DialogTitle variant="h5">{title}</DialogTitle>
      <DialogContent dividers sx={contentStyles}>
        {actionContent}
        <DialogContentText>
          {typeof actionContentText === 'string' ? (
            actionContentText
          ) : (
            actionContentText?.map((row) => <Typography>{row}</Typography>)
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button disabled={disableCancel} onClick={onClose}>{cancelLabel}</Button>
        <Button
          color={colorKey}
          variant="contained"
          disabled={disableAction || loading}
          onClick={actionFunction}
          endIcon={loading && (
            <CircularProgress
              size={18}
              sx={(theme: Theme) => ({ color: theme.palette[colorKey].contrastText })}
            />
          )}
        >
          {actionLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ActionDialog.defaultProps = {
  loading: false,
  maxWidth: 'sm',
  disableCancel: false,
  disableAction: false,
  transitionDuration: 300,
  contentStyles: undefined,
};

export default ActionDialog;
