import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  ListQueries,
  ListResponse,
  RetailerWarehouse,
  RetailerWarehouseBody,
} from 'resources/types';
import services from 'services';

const GET_RETAILER_WAREHOUSES = 'retailer/warehouses/getRetailerWarehouses';
const DELETE_RETAILER_WAREHOUSE = 'retailer/warehouses/deleteRetailerWarehouse';
const UPDATE_RETAILER_WAREHOUSE = 'retailer/warehouses/updateRetailerWarehouse';
const CREATE_RETAILER_WAREHOUSE = 'retailer/warehouses/createRetailerWarehouse';

const getRetailerWarehouses = createAsyncThunk<
  ListResponse<RetailerWarehouse>,
  { companyId: number, query?: ListQueries }
>(
  GET_RETAILER_WAREHOUSES,
  async (params) => {
    const { companyId, query } = params;
    const response = await services.getRetailerWarehouses(companyId, query);
    return response.data;
  },
);

const createRetailerWarehouse = createAsyncThunk<
  RetailerWarehouse,
  { companyId: number, data: RetailerWarehouseBody }
>(
  CREATE_RETAILER_WAREHOUSE,
  async (params) => {
    const { companyId, data } = params;
    const response = await services.createRetailerWarehouse(companyId, data);
    return response.data;
  },
);

const updateRetailerWarehouse = createAsyncThunk<
  RetailerWarehouse,
  { companyId: number, warehouseId: number, data: RetailerWarehouseBody }
>(
  UPDATE_RETAILER_WAREHOUSE,
  async (params) => {
    const { companyId, warehouseId, data } = params;
    const response = await services.updateRetailerWarehouse(companyId, warehouseId, data);
    return response.data;
  },
);

const deleteRetailerWarehouse = createAsyncThunk<
  { value: boolean },
  { companyId: number, warehouseId: number }
>(
  DELETE_RETAILER_WAREHOUSE,
  async (params) => {
    const { companyId, warehouseId } = params;
    const response = await services.deleteRetailerWarehouse(companyId, warehouseId);
    return response.data;
  },
);

export {
  getRetailerWarehouses,
  createRetailerWarehouse,
  updateRetailerWarehouse,
  deleteRetailerWarehouse,
};
