import { AxiosResponse } from 'axios';

import {
  ListQueries,
  ListResponse,
  RetailerStore,
  RetailerStoreBody,
  RetailerStoreGroup,
} from 'resources/types';
import instance from 'services/instance';
import { endpoints } from 'resources/constants';
import { storeManagementEvent } from 'resources/constants/mixpanel';

const getRetailerStores = (
  companyId: number,
  params?: ListQueries,
): Promise<AxiosResponse<ListResponse<RetailerStore>>> => (
  instance.get(
    endpoints.RETAILER_STORES(companyId),
    {
      params,
      failureMessage: 'Could not get retailer stores',
      mixpanelEvent: {
        EventName: storeManagementEvent.getStores,
      },
    },
  )
);

const getRetailerStoreGroups = (
  companyId: number,
): Promise<AxiosResponse<ListResponse<RetailerStoreGroup>>> => (
  instance.get(
    endpoints.RETAILER_STORE_GROUPS(companyId),
    {
      failureMessage: 'Could not get retailer stores groups',
    },
  )
);

const deleteRetailerStore = (
  companyId: number,
  storeId: number,
): Promise<AxiosResponse<{ value: boolean }>> => (
  instance.delete(
    endpoints.RETAILER_STORE(companyId, storeId),
    {
      failureMessage: 'Could not delete retailer store',
      mixpanelEvent: {
        EventName: storeManagementEvent.deleteStore,
      },
    },
  )
);

const updateRetailerStore = (
  companyId: number,
  storeId: number,
  data: RetailerStoreBody,
): Promise<AxiosResponse<RetailerStore>> => (
  instance.put(
    endpoints.RETAILER_STORE(companyId, storeId),
    data,
    {
      successMessage: 'The store info updated successfully',
      failureMessage: 'Could not update retailer store',
      mixpanelEvent: {
        EventName: storeManagementEvent.updateStore,
      },
    },
  )
);

const createRetailerStore = (
  companyId: number,
  data: RetailerStoreBody,
): Promise<AxiosResponse<RetailerStore>> => (
  instance.post(
    endpoints.RETAILER_STORES(companyId),
    data,
    {
      successMessage: 'The store created successfully',
      failureMessage: 'Could not create store',
      mixpanelEvent: {
        EventName: storeManagementEvent.createStore,
      },
    },
  )
);

export default {
  getRetailerStores,
  createRetailerStore,
  updateRetailerStore,
  deleteRetailerStore,
  getRetailerStoreGroups,
};
