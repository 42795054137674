import {
  Grid,
  Theme,
  Typography,
} from '@mui/material';

import { Layout } from 'components/main';
import signUpCover from 'assets/covers/sign_up_cover.jpg';
import SignUpForm from './SignUpForm';

function SignUp() {
  return (
    <Layout
      form={<SignUpForm />}
      coverImage={signUpCover}
      content={(
        <Grid container direction="column" justifyContent="end">
          <Typography
            variant="h4"
            fontWeight="bold"
            sx={(theme: Theme) => ({ color: theme.palette.common.white })}
          >
            {'Puzl\'s Hot Tip:'}
            <br />
            {'Don\'t Choose the Password "Password"'}
          </Typography>
          <Typography
            variant="h6"
            fontWeight="bold"
            sx={(theme: Theme) => ({ mt: 2, color: theme.palette.common.white })}
          >
            {`Create a high-quality password. Make sure it has at least 8 characters
            including a capital letter, a number and a symbol.`}
          </Typography>
        </Grid>
      )}
    />
  );
}

export default SignUp;
