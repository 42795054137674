import {
  Skeleton,
  TableCell,
  TableRow as MuiTableRow,
  Theme,
} from '@mui/material';
import { TableCol, TableDataSource } from 'resources/types';

interface Props<T extends TableDataSource, Parent extends TableDataSource | undefined> {
  count: number;
  displayingColumns: Array<TableCol<T, Parent>>;
  // eslint-disable-next-line react/require-default-props
  rowsOrderingEnabled?: boolean;
}

function Loading<
  T extends TableDataSource, Parent extends TableDataSource | undefined
>(props: Props<T, Parent>) {
  const { displayingColumns, count, rowsOrderingEnabled = false } = props;

  return (
    /* eslint-disable react/no-array-index-key */
    <>
      {
        [...Array(count)].map((_, index: number) => (
          <MuiTableRow key={index}>
            {rowsOrderingEnabled && (
              <TableCell
                sx={(theme: Theme) => ({
                  p: 0.5,
                  height: 40,
                  width: '0%',
                  maxWidth: 20,
                  minWidth: 20,
                  ...displayingColumns[0]?.cellStyles?.(theme),
                })}
              >
                <Skeleton variant="text" sx={{ height: 20 }} />
              </TableCell>
            )}
            {displayingColumns.map((column) => (
              <TableCell
                key={column.key}
                scope="row"
                component="th"
                sx={(theme: Theme) => ({
                  p: 0.5,
                  height: 40,
                  width: column.width,
                  minWidth: column.minWidth,
                  maxWidth: column.maxWidth,
                  ...column?.cellStyles?.(theme),
                })}
              >
                {column.width && (
                  <Skeleton variant="text" sx={{ height: 20 }} />
                )}
              </TableCell>
            ))}
          </MuiTableRow>
        ))
      }
    </>
  );
}

export default Loading;

Loading.defaultProps = {
  rowsOrderingEnabled: false,
};
