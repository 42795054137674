import { useCallback, useEffect } from 'react';
import {
  Grid,
  Theme,
  Button,
  TextField,
  Typography,
  CircularProgress,
} from '@mui/material';
import { Formik, FormikHelpers } from 'formik';
import { useNavigate } from 'react-router-dom';

import services from 'services';
import { useAppDispatch } from 'hooks';
import { resetPassword } from 'store/user';
import { BackToSignIn } from 'components/main';
import { routsPatterns } from 'resources/constants';
import { ResetPasswordData } from 'resources/types';
import { forgotPasswordSchema } from 'resources/scheme';

const initialValues: ResetPasswordData = { email: '' };

function ForgotPasswordForm() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(
    () => {
      if (services.checkAuthStatus()) navigate('/');
    },
    [navigate],
  );

  const sendResetLink = useCallback(
    (values: ResetPasswordData, helpers: FormikHelpers<ResetPasswordData>) => {
      dispatch(resetPassword(values))
        .unwrap()
        .then(() => {
          helpers.resetForm();
          helpers.setSubmitting(false);
          navigate(routsPatterns.SIGN_IN);
        })
        .finally(() => {
          helpers.resetForm();
          helpers.setSubmitting(false);
        });
    },
    [dispatch, navigate],
  );

  return (
    <Grid container>
      <Grid item xs={12} pt={2}>
        <Typography variant="h5" fontWeight="bold">Forgot Your Password</Typography>
        <Typography variant="subtitle2" sx={{ display: 'flex' }}>
          Fill in your Puzl email to reset your password
        </Typography>
      </Grid>
      <Grid item container xs={12}>
        <Formik
          onSubmit={sendResetLink}
          initialValues={initialValues}
          validationSchema={forgotPasswordSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit} noValidate>
              <Grid container>
                <TextField
                  required
                  color="info"
                  sx={{ marginTop: 3 }}
                  fullWidth
                  name="email"
                  label="Email"
                  onBlur={handleBlur}
                  value={values.email}
                  onChange={handleChange}
                  helperText={touched.email && errors.email}
                  error={touched.email && Boolean(errors.email)}
                />
                <Button
                  color="info"
                  type="submit"
                  variant="contained"
                  disabled={isSubmitting}
                  sx={{ marginTop: 3, borderRadius: 6, width: 'inherit' }}
                  endIcon={isSubmitting && (
                    <CircularProgress
                      size={18}
                      sx={(theme: Theme) => ({ color: theme.palette.info.contrastText })}
                    />
                  )}
                >
                  Send Reset Link
                </Button>
                <BackToSignIn />
              </Grid>
            </form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
}

export default ForgotPasswordForm;
