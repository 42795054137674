import {
  useState,
  useEffect,
  useCallback,
  ChangeEvent,
} from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';

import { useDebounce } from 'hooks';
import { queries } from 'resources/constants';

function SearchInput(props: TextFieldProps) {
  const { sx, disabled, InputProps } = props;
  const [searchParams, setSearchParams] = useSearchParams();

  const [inputValue, setInputValue] = useState<string>('');

  const search: string = searchParams.get(queries.SEARCH) || '';

  useEffect(() => {
    setInputValue(search);
  }, [search]);

  const onSearch = useCallback(
    (value: string) => {
      setSearchParams((prevSearchParams) => {
        prevSearchParams.delete(queries.PAGE);
        if (value) {
          prevSearchParams.set(queries.SEARCH, value);
        } else {
          prevSearchParams.delete(queries.SEARCH);
        }
        return prevSearchParams;
      });
    },
    [setSearchParams],
  );

  const debounceSearch = useDebounce(onSearch);

  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setInputValue(value);
      debounceSearch(value);
    },
    [debounceSearch],
  );

  return (
    <TextField
      sx={sx}
      fullWidth
      value={inputValue}
      disabled={disabled}
      autoComplete="off"
      onChange={onChange}
      InputProps={{
        endAdornment: null,
        startAdornment: <SearchIcon />,
        ...InputProps,
      }}
    />
  );
}

export default SearchInput;

SearchInput.defaultProps = {
  sx: undefined,
};
