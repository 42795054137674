import { Moment } from 'moment';
import { DateTimeRange } from 'resources/types';
import { Grid, TextField } from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

export interface TimeRangePickerProps {
  labels: { [key: number]: string }
  names?: { [key: number]: string }
  errors?: { [key: number]: string }

  disabled?: boolean,
  range: DateTimeRange,
  onChange: (range: DateTimeRange, name: string) => void,
}

/* eslint-disable react/jsx-props-no-spreading */

function TimeRangePicker(props: TimeRangePickerProps) {
  const {
    range,
    labels,
    errors,
    disabled,
    onChange,
    names = ['', ''],
  } = props;

  const [startTime, endTime] = range;

  const startChange = (name: string) => (newValue: Moment | null) => {
    onChange([newValue, endTime], name);
  };

  const endChange = (name: string) => (newValue: Moment | null) => {
    onChange([startTime, newValue], name);
  };

  return (
    <Grid container spacing={3} width="inherit">
      <Grid item xs={6}>
        <TimePicker
          label={labels[0]}
          value={startTime}
          disabled={disabled}
          onChange={startChange(names?.[0])}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              error={Boolean(errors?.[0])}
              helperText={errors?.[0]}
              sx={{
                '& .MuiInputAdornment-root': {
                  '& .MuiIconButton-edgeEnd': {
                    marginRight: 0,
                  },
                },
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <TimePicker
          label={labels[1]}
          value={endTime}
          disabled={disabled}
          onChange={endChange(names?.[1])}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              error={Boolean(errors?.[1])}
              helperText={errors?.[1]}
              sx={{
                '& .MuiInputAdornment-root': {
                  '& .MuiIconButton-edgeEnd': {
                    marginRight: 0,
                  },
                },
              }}
            />
          )}
        />
      </Grid>
    </Grid>
  );
}

TimeRangePicker.defaultProps = {
  disabled: false,
  errors: undefined,
  names: undefined,
};

export default TimeRangePicker;
