import moment from 'moment';
import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';

import {
  RetailerSandbox,
  RetailerStoreGroup,
  RetailerSandboxItem,
  RetailerSandboxOffer,
  SandboxFilterByType,
  RetailerWeeklyMargins,
  RetailerSandboxErrorData,
  RetailerItemSalesHistory,
  RetailerSalesHistoryState,
  RetailerPricesHistoryState,
  RetailerSandboxTabOptions,
  RetailerSandboxExcludedItem,
  RetailerSandboxOrdersConfigs,
  RetailerSandboxOfferPlacement,
  RetailerSalesHistoryChartDialog,
  RetailerSandboxItemsDeletionOptions,
} from 'resources/types';
import {
  getOfferNameError,
  generateChartsData,
  updateSandboxDeletionOptions,
  getSandboxItemsDeletionOptions,
  generateSandboxDepartmentsErrorsData,
} from 'utils';
import {
  getRetailerSandbox,
  getRetailerStoreGroups,
  finalizeRetailerSandbox,
  calculateRetailerSandbox,
  getRetailerSandboxStatus,
  updateRetailerSandboxItem,
  removeRetailerSandboxItem,
  removeRetailerSandboxOffer,
  updateRetailerSandboxOffer,
  removeRetailerSandboxItems,
  getRetailerAdWeeklyMargins,
  uploadRetailerSandboxOffers,
  duplicateRetailerSandboxItem,
  duplicateRetailerSandboxOffer,
  updateRetailerSandboxItemNames,
  getRetailerSandboxOrdersConfigs,
  updateRetailerSandboxOfferNames,
  changeRetailerSandboxItemStatus,
  getRetailerSandboxExcludedItems,
  updateRetailerSandboxOffersPrices,
  removeRetailerSandboxExcludedItems,
  getRetailerSandboxItemSalesHistory,
  getRetailerSandboxItemPricesHistory,
  fillDownRetailerSandboxItemsDealCost,
  changeRetailerSandboxDepartmentOrder,
  updateRetailerSandboxProjectedAdVolumes,
} from './thunks';

const aiPredictionKeys: Array<keyof RetailerSandboxOffer> = ['offerPrice', 'placement'];

export interface RetailerSandboxState {
  sandbox: RetailerSandbox | null;
  loading: boolean;
  actionLoading: boolean;
  finalizeLoading: boolean;
  calculationLoading: boolean;
  offerPriceLoadings: Array<number>;
  excludedItemsRemoveLoading: boolean;
  sandboxOffersUploadLoading: boolean;

  excludedItems: Array<RetailerSandboxExcludedItem>;
  weeklyMargins: RetailerWeeklyMargins | null;
  isEndedWeek: boolean;
  storeGroups: Array<RetailerStoreGroup>;

  actionItem: RetailerSandboxItem | null;
  actionOffer: RetailerSandboxOffer | null;
  selectedPlacements: Array<RetailerSandboxOfferPlacement>;
  openedDepartment?: number;
  openedOffers: Array<number>;
  openedItems: Array<number>;
  salesExpanded: boolean;
  deletionOptions: RetailerSandboxItemsDeletionOptions;
  ordersConfigs: RetailerSandboxOrdersConfigs;

  salesHistoryChartDialog: RetailerSalesHistoryChartDialog;
  pricesHistory: RetailerPricesHistoryState;
  salesHistory: RetailerSalesHistoryState;

  tabOptions: RetailerSandboxTabOptions;

  displayWarnings: boolean;
  displaySandboxErrors: boolean;
  offerNameErrors: Array<number>;
  sandboxDepartmentsErrorsData: RetailerSandboxErrorData;
  filterBy: SandboxFilterByType;
}

const initialState: RetailerSandboxState = {
  sandbox: null,
  loading: false,
  actionLoading: false,
  finalizeLoading: false,
  offerPriceLoadings: [],
  calculationLoading: false,
  excludedItemsRemoveLoading: false,
  sandboxOffersUploadLoading: false,

  excludedItems: [],
  weeklyMargins: null,
  isEndedWeek: false,
  storeGroups: [],

  actionItem: null,
  actionOffer: null,
  selectedPlacements: [],
  openedOffers: [],
  openedItems: [],
  salesExpanded: false,
  deletionOptions: {
    checkboxStates: [],
    allSelected: false,
    disableRemoveBtn: true,
  },
  ordersConfigs: {
    sandbox: {
      itemOrder: [],
      offerOrder: [],
      departmentOrder: [],
      itemVendorOrder: [],
    },
    sandboxUpload: {
      uploadOrder: [],
    },
  },

  salesHistoryChartDialog: {
    open: false,
    title: '',
  },
  pricesHistory: {
    loading: false,
    historyComponents: [],
  },
  salesHistory: {
    loading: false,
    salesChartsData: null,
  },

  tabOptions: {
    tabKey: null,
    offerTabIndex: null,
    departmentTabIndex: null,
  },

  displayWarnings: false,
  displaySandboxErrors: false,
  offerNameErrors: [],
  sandboxDepartmentsErrorsData: {},
  filterBy: null,
};

/* eslint-disable no-param-reassign */

const sandboxSlice = createSlice({
  name: 'retailer/sandbox',
  initialState,
  reducers: {
    reset: () => initialState,
    setFilterBy: (state, action: PayloadAction<SandboxFilterByType>) => {
      state.deletionOptions = getSandboxItemsDeletionOptions({
        departments: state.sandbox?.sandboxDepartments,
        selectedPlacements: state.selectedPlacements,
        filterBy: action.payload,
        sandboxErrorData: state.sandboxDepartmentsErrorsData,
      });
      state.filterBy = action.payload;
    },
    setSelectedPlacements: (state, action: PayloadAction<Array<RetailerSandboxOfferPlacement>>) => {
      state.deletionOptions = getSandboxItemsDeletionOptions({
        departments: state.sandbox?.sandboxDepartments,
        selectedPlacements: action.payload,
        filterBy: state.filterBy,
        sandboxErrorData: state.sandboxDepartmentsErrorsData,
      });
      state.selectedPlacements = action.payload;
    },
    setOpenedDepartment: (state, action: PayloadAction<number>) => {
      state.openedDepartment = action.payload === state.openedDepartment
        ? undefined
        : action.payload;
      state.offerNameErrors = [];
    },
    setOpenedOffers: (state, action: PayloadAction<number>) => {
      state.openedOffers = state.openedOffers.includes(action.payload) ? (
        state.openedOffers.filter((id) => id !== action.payload)
      ) : (
        [...state.openedOffers, action.payload]
      );
      state.offerNameErrors = [];
    },
    setOpenedItems: (state, action: PayloadAction<number>) => {
      state.openedItems = state.openedItems.includes(action.payload) ? (
        state.openedItems.filter((id) => id !== action.payload)
      ) : (
        [...state.openedItems, action.payload]
      );
    },
    setSalesExpanded: (state, action: PayloadAction<boolean>) => {
      state.salesExpanded = action.payload;
    },
    setActionOffer: (state, action: PayloadAction<RetailerSandboxOffer | null>) => {
      state.actionOffer = action.payload;
    },
    setActionItem: (state, action: PayloadAction<RetailerSandboxItem | null>) => {
      state.actionItem = action.payload;
    },
    resetTabOptions: (state) => {
      state.tabOptions = { ...initialState.tabOptions };
    },
    setDisplayWarnings: (state, action: PayloadAction<boolean>) => {
      state.displayWarnings = action.payload;
    },
    offerFocus: (state, action: PayloadAction<{
      tabKey: string;
      isLastItem: boolean;
      departmentIndex: number;
    }>) => {
      const { tabKey, departmentIndex, isLastItem } = action.payload;
      state.tabOptions = {
        tabKey,
        offerTabIndex: null,
        departmentTabIndex: isLastItem ? null : departmentIndex,
      };
    },
    itemFocus: (state, action: PayloadAction<{
      tabKey: string;
      offerIndex: number;
      isLastItem: boolean;
      departmentIndex: number;
    }>) => {
      const {
        tabKey, departmentIndex, offerIndex, isLastItem,
      } = action.payload;
      state.tabOptions = {
        tabKey,
        offerTabIndex: isLastItem ? null : offerIndex,
        departmentTabIndex: isLastItem ? null : departmentIndex,
      };
    },
    selectSandboxDepartment: (
      state,
      action: PayloadAction<{ departmentId: number | null, checked: boolean }>,
    ) => {
      const { departmentId = null, checked } = action.payload;
      if (departmentId == null) {
        state.deletionOptions = {
          allSelected: checked,
          disableRemoveBtn: !checked,
          checkboxStates: state.deletionOptions.checkboxStates.map(
            (departmentState) => ({
              ...departmentState,
              checked,
              offers: departmentState.offers.map(
                (offerState) => ({
                  ...offerState,
                  checked,
                  items: offerState.items.map((itemState) => ({ ...itemState, checked })),
                }),
              ),
            }),
          ),
        };
      } else {
        const checkboxStates = JSON.parse(JSON.stringify(state.deletionOptions.checkboxStates));
        state.deletionOptions = updateSandboxDeletionOptions({
          checkboxStates,
          checked,
          departmentId,
        });
      }
    },
    selectSandboxOffer: (
      state,
      action: PayloadAction<{
        departmentId: number,
        offerId: number,
        checked: boolean
      }>,
    ) => {
      const { departmentId, offerId, checked } = action.payload;
      const checkboxStates = JSON.parse(JSON.stringify(state.deletionOptions.checkboxStates));
      state.deletionOptions = updateSandboxDeletionOptions({
        checkboxStates,
        checked,
        departmentId,
        offerId,
      });
    },
    selectSandboxItem: (
      state,
      action: PayloadAction<{
        departmentId: number,
        offerId: number,
        itemId: number,
        checked: boolean
      }>,
    ) => {
      const {
        departmentId, offerId, itemId, checked,
      } = action.payload;
      const checkboxStates = JSON.parse(JSON.stringify(state.deletionOptions.checkboxStates));
      state.deletionOptions = updateSandboxDeletionOptions({
        checkboxStates,
        checked,
        offerId,
        departmentId,
        itemId,
      });
    },
    setHistoryDialogData: (
      state,
      action: PayloadAction<RetailerSalesHistoryChartDialog>,
    ) => {
      state.salesHistoryChartDialog = action.payload;
      state.pricesHistory = { ...initialState.pricesHistory };
      state.salesHistory = { ...initialState.salesHistory };
    },
    updateSandboxDepartmentsErrorsData: (state, action: PayloadAction<RetailerSandbox>) => {
      state.sandboxDepartmentsErrorsData = generateSandboxDepartmentsErrorsData(
        action.payload.sandboxDepartments,
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getRetailerSandbox.pending, (state, action) => {
      const { refetch = false } = action.meta.arg;
      if (!refetch) {
        state.sandbox = null;
        state.loading = true;
      }
    });
    builder.addCase(getRetailerSandbox.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getRetailerAdWeeklyMargins.rejected, (state) => {
      state.weeklyMargins = null;
    });
    builder.addCase(getRetailerAdWeeklyMargins.fulfilled, (state, action) => {
      const { endDate } = action.payload;
      const isEndedWeek = moment().isAfter(endDate, 'day');
      state.weeklyMargins = action.payload;
      state.isEndedWeek = isEndedWeek;
    });

    builder.addCase(getRetailerSandboxExcludedItems.rejected, (state) => {
      state.excludedItems = [];
    });
    builder.addCase(getRetailerSandboxExcludedItems.fulfilled, (state, action) => {
      state.excludedItems = action.payload;
    });

    builder.addCase(removeRetailerSandboxExcludedItems.pending, (state) => {
      state.excludedItemsRemoveLoading = true;
    });
    builder.addCase(removeRetailerSandboxExcludedItems.rejected, (state) => {
      state.excludedItemsRemoveLoading = false;
      state.excludedItems = [];
    });
    builder.addCase(removeRetailerSandboxExcludedItems.fulfilled, (state) => {
      state.excludedItemsRemoveLoading = false;
      state.excludedItems = [];
    });

    builder.addCase(getRetailerStoreGroups.rejected, (state) => {
      state.storeGroups = [];
    });
    builder.addCase(getRetailerStoreGroups.fulfilled, (state, action) => {
      state.storeGroups = action.payload.items;
    });

    builder.addCase(getRetailerSandboxStatus.fulfilled, (state, action) => {
      if (state.sandbox) {
        state.sandbox.sandboxStatus = action.payload;
      }
    });

    builder.addCase(getRetailerSandboxItemSalesHistory.pending, (state) => {
      state.salesHistory.loading = true;
    });
    builder.addCase(getRetailerSandboxItemSalesHistory.rejected, (state) => {
      state.salesHistory.loading = false;
    });
    builder.addCase(getRetailerSandboxItemSalesHistory.fulfilled, (state, action) => {
      state.salesHistory.loading = false;
      const salesHistory = { ...action.payload, historyComponents: [] };
      if (action.payload.historyComponents) {
        const historyComponents = (
          JSON.parse(action.payload.historyComponents) as RetailerItemSalesHistory['historyComponents']
        );
        state.salesHistory.salesChartsData = generateChartsData(
          { ...salesHistory, historyComponents },
          'A',
          state.weeklyMargins,
        );
      }
    });

    builder.addCase(getRetailerSandboxItemPricesHistory.pending, (state) => {
      state.pricesHistory.loading = true;
    });
    builder.addCase(getRetailerSandboxItemPricesHistory.rejected, (state) => {
      state.pricesHistory.loading = false;
    });
    builder.addCase(getRetailerSandboxItemPricesHistory.fulfilled, (state, action) => {
      state.pricesHistory.loading = false;
      state.pricesHistory.historyComponents = action.payload;
    });

    builder.addCase(finalizeRetailerSandbox.pending, (state) => {
      state.finalizeLoading = true;
    });
    builder.addCase(finalizeRetailerSandbox.rejected, (state) => {
      state.displayWarnings = true;
      state.finalizeLoading = false;
    });
    builder.addCase(finalizeRetailerSandbox.fulfilled, (state) => {
      state.displayWarnings = false;
      state.finalizeLoading = false;
    });

    builder.addCase(updateRetailerSandboxOffersPrices.pending, (state, action) => {
      const { offersData } = action.meta.arg;
      const offersIds = offersData.items.map((offer) => offer.id);
      state.offerPriceLoadings = [...state.offerPriceLoadings, ...offersIds];
      state.actionLoading = true;
    });
    builder.addCase(updateRetailerSandboxOffersPrices.rejected, (state, action) => {
      const { offersData } = action.meta.arg;
      const offersIds = offersData.items.map((offer) => offer.id);
      state.offerPriceLoadings = state.offerPriceLoadings
        .filter((offerId) => !offersIds.includes(offerId));
      state.actionLoading = false;
    });
    builder.addCase(updateRetailerSandboxOffersPrices.fulfilled, (state, action) => {
      const { offersData } = action.meta.arg;
      const offersIds = offersData.items.map((offer) => offer.id);
      state.offerPriceLoadings = state.offerPriceLoadings
        .filter((offerId) => !offersIds.includes(offerId));
      state.actionLoading = false;
    });

    builder.addCase(updateRetailerSandboxOffer.pending, (state, action) => {
      const { name, data } = action.meta.arg;
      if (aiPredictionKeys.includes(name as keyof RetailerSandboxOffer)) {
        state.offerPriceLoadings = [...state.offerPriceLoadings, data.id];
      }
    });
    builder.addCase(updateRetailerSandboxOffer.rejected, (state, action) => {
      const { name, data } = action.meta.arg;
      if (aiPredictionKeys.includes(name as keyof RetailerSandboxOffer)) {
        state.offerPriceLoadings = state.offerPriceLoadings
          .filter((offerId) => offerId !== data.id);
      }
    });
    builder.addCase(updateRetailerSandboxOffer.fulfilled, (state, action) => {
      const { name, data } = action.meta.arg;
      if (aiPredictionKeys.includes(name as keyof RetailerSandboxOffer)) {
        state.offerPriceLoadings = state.offerPriceLoadings
          .filter((offerId) => offerId !== data.id);
      }
    });

    builder.addCase(uploadRetailerSandboxOffers.pending, (state) => {
      state.sandboxOffersUploadLoading = true;
    });
    builder.addCase(uploadRetailerSandboxOffers.rejected, (state) => {
      state.sandboxOffersUploadLoading = false;
    });
    builder.addCase(uploadRetailerSandboxOffers.fulfilled, (state) => {
      state.sandboxOffersUploadLoading = false;
    });

    builder.addCase(updateRetailerSandboxOfferNames.rejected, (state, action) => {
      const { data: updatingOffer, name } = action.meta.arg;
      if (name === 'offerName') {
        const offerNameError = getOfferNameError(updatingOffer.offerName);
        if (offerNameError) {
          state.offerNameErrors = [...state.offerNameErrors, updatingOffer.id];
        }
      }
    });

    builder.addCase(updateRetailerSandboxOfferNames.fulfilled, (state, action) => {
      const { data: updatingOffer, name } = action.meta.arg;
      if (name === 'offerName') {
        const offerNameError = getOfferNameError(updatingOffer.offerName);
        if (!offerNameError) {
          state.offerNameErrors = state.offerNameErrors.filter((offerId) => (
            offerId !== updatingOffer.id
          ));
        }
      }
    });

    builder.addCase(calculateRetailerSandbox.pending, (state) => {
      if (state.sandbox) {
        state.sandbox.sandboxStatus = 'SANDBOX_CALCULATING_IN_PROGRESS';
      }
      state.actionLoading = true;
      state.calculationLoading = true;
    });
    builder.addCase(calculateRetailerSandbox.rejected, (state) => {
      if (state.sandbox) {
        state.sandbox.sandboxStatus = 'SANDBOX_NOT_CALCULATED';
      }
      state.actionLoading = false;
      state.calculationLoading = false;
      state.displaySandboxErrors = true;
    });

    builder.addCase(getRetailerSandboxOrdersConfigs.fulfilled, (state, action) => {
      const { sandboxConfigJson } = action.payload;
      state.ordersConfigs = JSON.parse(sandboxConfigJson);
    });

    builder.addCase(getRetailerSandbox.fulfilled, (state, action) => {
      state.sandbox = action.payload;
      const sandboxDepartmentsErrorsData = generateSandboxDepartmentsErrorsData(
        action.payload.sandboxDepartments,
      );
      const deletionOptions = getSandboxItemsDeletionOptions({
        departments: action.payload.sandboxDepartments,
        selectedPlacements: state.selectedPlacements,
        filterBy: state.filterBy,
        sandboxErrorData: sandboxDepartmentsErrorsData,
        deletionOptions: state.deletionOptions,
      });
      state.sandboxDepartmentsErrorsData = sandboxDepartmentsErrorsData;
      state.deletionOptions = deletionOptions;
    });

    builder.addMatcher(isAnyOf(
      getRetailerSandbox.fulfilled,
      calculateRetailerSandbox.fulfilled,
      removeRetailerSandboxItem.fulfilled,
      removeRetailerSandboxOffer.fulfilled,
      updateRetailerSandboxProjectedAdVolumes.fulfilled,
    ), (state) => {
      state.loading = false;
      state.actionLoading = false;
      state.displayWarnings = false;
      state.calculationLoading = false;
      state.displaySandboxErrors = false;
    });

    builder.addMatcher(isAnyOf(
      getRetailerSandbox.pending,
      removeRetailerSandboxItem.pending,
      updateRetailerSandboxItem.pending,
      removeRetailerSandboxOffer.pending,
      removeRetailerSandboxItems.pending,
      updateRetailerSandboxOffer.pending,
      duplicateRetailerSandboxItem.pending,
      duplicateRetailerSandboxOffer.pending,
      updateRetailerSandboxItemNames.pending,
      updateRetailerSandboxOfferNames.pending,
      changeRetailerSandboxItemStatus.pending,
      fillDownRetailerSandboxItemsDealCost.pending,
      changeRetailerSandboxDepartmentOrder.pending,
      updateRetailerSandboxProjectedAdVolumes.pending,
    ), (state) => {
      state.actionLoading = true;
    });

    builder.addMatcher(isAnyOf(
      getRetailerSandbox.rejected,
      removeRetailerSandboxItem.rejected,
      updateRetailerSandboxItem.rejected,
      updateRetailerSandboxOffer.rejected,
      removeRetailerSandboxOffer.rejected,
      removeRetailerSandboxItems.rejected,
      duplicateRetailerSandboxItem.rejected,
      duplicateRetailerSandboxOffer.rejected,
      updateRetailerSandboxItemNames.rejected,
      updateRetailerSandboxOfferNames.rejected,
      changeRetailerSandboxItemStatus.rejected,
      fillDownRetailerSandboxItemsDealCost.rejected,
      changeRetailerSandboxDepartmentOrder.rejected,
      updateRetailerSandboxProjectedAdVolumes.rejected,
    ), (state) => {
      state.actionLoading = false;
    });
  },
});

const {
  itemFocus,
  offerFocus,
  setFilterBy,
  setActionItem,
  setActionOffer,
  setOpenedItems,
  resetTabOptions,
  setOpenedOffers,
  setSalesExpanded,
  selectSandboxItem,
  setDisplayWarnings,
  selectSandboxOffer,
  setHistoryDialogData,
  setOpenedDepartment,
  setSelectedPlacements,
  selectSandboxDepartment,
  reset: resetRetailerSandboxState,
  updateSandboxDepartmentsErrorsData,
} = sandboxSlice.actions;

export {
  itemFocus,
  offerFocus,
  setFilterBy,
  setActionItem,
  setActionOffer,
  setOpenedItems,
  setOpenedOffers,
  resetTabOptions,
  setSalesExpanded,
  selectSandboxItem,
  selectSandboxOffer,
  getRetailerSandbox,
  setDisplayWarnings,
  setHistoryDialogData,
  setOpenedDepartment,
  setSelectedPlacements,
  getRetailerStoreGroups,
  selectSandboxDepartment,
  finalizeRetailerSandbox,
  calculateRetailerSandbox,
  getRetailerSandboxStatus,
  resetRetailerSandboxState,
  updateRetailerSandboxItem,
  removeRetailerSandboxItem,
  removeRetailerSandboxOffer,
  removeRetailerSandboxItems,
  updateRetailerSandboxOffer,
  getRetailerAdWeeklyMargins,
  uploadRetailerSandboxOffers,
  duplicateRetailerSandboxItem,
  duplicateRetailerSandboxOffer,
  updateRetailerSandboxItemNames,
  getRetailerSandboxOrdersConfigs,
  updateRetailerSandboxOfferNames,
  changeRetailerSandboxItemStatus,
  getRetailerSandboxExcludedItems,
  updateRetailerSandboxOffersPrices,
  removeRetailerSandboxExcludedItems,
  updateSandboxDepartmentsErrorsData,
  getRetailerSandboxItemSalesHistory,
  getRetailerSandboxItemPricesHistory,
  fillDownRetailerSandboxItemsDealCost,
  changeRetailerSandboxDepartmentOrder,
  updateRetailerSandboxProjectedAdVolumes,
};

export default sandboxSlice;
