import {
  Tooltip as MuiTooltip,
  TooltipProps as MuiTooltipProps,
} from '@mui/material';

interface TooltipProps extends MuiTooltipProps {
  disabled?: boolean
}

function Tooltip(props: TooltipProps) {
  const {
    title, children, disabled, arrow, placement,
  } = props;
  return (
    disabled ? (
      children
    ) : (
      <MuiTooltip title={title} arrow={arrow} placement={placement}>
        {children}
      </MuiTooltip>
    )
  );
}

Tooltip.defaultProps = {
  disabled: false,
};

export default Tooltip;
