import { createSlice } from '@reduxjs/toolkit';

import { TimeZone } from 'resources/types';
import { getTimeZones } from './thunks';

export interface USStateState {
  data: Array<TimeZone>;
  loading: boolean;
}

const initialState: USStateState = {
  data: [],
  loading: false,
};

/* eslint-disable no-param-reassign */

const timeZonesSlice = createSlice({
  name: 'timeZones',
  initialState,
  reducers: {
    reset: () => initialState,
  },

  extraReducers: (builder) => {
    builder.addCase(getTimeZones.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTimeZones.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getTimeZones.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
  },
});

const { reset: resetTimeZonesState } = timeZonesSlice.actions;

export { resetTimeZonesState, getTimeZones };

export default timeZonesSlice;
