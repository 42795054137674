import { useCallback, useEffect } from 'react';
import {
  Grid,
  Theme,
  Button,
  TextField,
  Typography,
  CircularProgress,
} from '@mui/material';
import { Formik, FormikHelpers } from 'formik';
import { Link, useNavigate } from 'react-router-dom';

import services from 'services';
import { useAppDispatch } from 'hooks';
import { getProfile } from 'store/user';
import { SignInData } from 'resources/types';
import { signInSchema } from 'resources/scheme';
import { PasswordInput } from 'components/shared';
import { routsPatterns } from 'resources/constants';
import { getRetailerCompany } from 'store/retailer/company';

const initialValues: SignInData = { email: '', password: '' };

function SignInForm() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(
    () => {
      if (services.checkAuthStatus()) navigate('/');
    },
    [navigate],
  );

  const signIn = useCallback(
    (values: SignInData, helpers: FormikHelpers<SignInData>) => {
      services.signIn(values)
        .then(() => dispatch(getProfile()).unwrap())
        .then((profile) => {
          const [firstRole] = profile.roles;
          const userScope = firstRole?.userScope || null;
          if (userScope !== 'USER_SCOPE_ADMIN') {
            return dispatch(getRetailerCompany()).unwrap();
          }
          return null;
        })
        .then(() => navigate('/'))
        .finally(() => {
          helpers.resetForm();
          helpers.setSubmitting(false);
        });
    },
    [dispatch, navigate],
  );

  return (
    <Grid container>
      <Grid item xs={12} pt={2}>
        <Typography variant="h5" fontWeight="bold">Sign in</Typography>
      </Grid>
      <Grid item container xs={12}>
        <Formik
          onSubmit={signIn}
          initialValues={initialValues}
          validationSchema={signInSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit} noValidate>
              <Grid container>
                <TextField
                  required
                  color="info"
                  sx={{ marginTop: 3 }}
                  fullWidth
                  name="email"
                  label="Email"
                  onBlur={handleBlur}
                  value={values.email}
                  onChange={handleChange}
                  helperText={touched.email && errors.email}
                  error={touched.email && Boolean(errors.email)}
                />
                <PasswordInput
                  required
                  color="info"
                  sx={{ marginTop: 3 }}
                  fullWidth
                  name="password"
                  label="Password"
                  onBlur={handleBlur}
                  value={values.password}
                  onChange={handleChange}
                  helperText={touched.password && errors.password}
                  error={touched.password && Boolean(errors.password)}
                />
                <Grid item container xs={12} justifyContent="end" mt={3}>
                  <Link to={routsPatterns.FORGOT_PASSWORD} style={{ textDecoration: 'none' }}>
                    <Typography
                      sx={(theme: Theme) => ({ color: theme.palette.info.main, marginLeft: 0.2 })}
                      variant="subtitle2"
                    >
                      Forgot password?
                    </Typography>
                  </Link>
                </Grid>
                <Button
                  color="info"
                  type="submit"
                  variant="contained"
                  disabled={isSubmitting}
                  sx={{ marginTop: 3, borderRadius: 6, width: 'inherit' }}
                  endIcon={isSubmitting && (
                    <CircularProgress
                      size={18}
                      sx={(theme: Theme) => ({ color: theme.palette.info.contrastText })}
                    />
                  )}
                >
                  Sign In
                </Button>
              </Grid>
            </form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
}

export default SignInForm;
