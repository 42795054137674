import { createAsyncThunk } from '@reduxjs/toolkit';

import services from 'services';
import { TimeZone } from 'resources/types';

const GET_TIME_ZONES = 'stores/getTimeZones';

const getTimeZones = createAsyncThunk<Array<TimeZone>>(
  GET_TIME_ZONES,
  async () => {
    const response = await services.getTimeZones();
    return response.data;
  },
);

export { getTimeZones };
