import { Link } from 'react-router-dom';
import {
  Grid,
  Theme,
  Paper,
  Slide,
  Typography,
} from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { AppRouteObject } from 'resources/types';

interface Props {
  timeout: number,
  item: AppRouteObject,
}

function DashboardItem(props: Props) {
  const { item, timeout } = props;
  const {
    path, colorKey, dashboardIcon, label,
  } = item;

  return (
    <Grid
      item
      xs={3}
      container
      alignItems="center"
      justifyContent="center"
      sx={(theme: Theme) => ({
        p: 2,
        transition: theme.transitions.create(['padding', 'box-shadow', 'transform'], {
          duration: theme.transitions.duration.standard,
        }),
        '&:hover': {
          transform: 'scale(1.1)',
          '& .dashboard-item-paper': {
            boxShadow: theme.shadows[8],
          },
        },
      })}
    >
      <Slide direction="up" timeout={timeout} in mountOnEnter unmountOnExit>
        <Link
          to={`../${path}` || ''}
          style={{ color: 'inherit', textDecoration: 'none' }}
        >
          <Paper
            className="dashboard-item-paper"
            sx={(theme: Theme) => ({
              borderRadius: 4,
              cursor: 'pointer',
              overflow: 'hidden',
              boxShadow: theme.shadows[3],
            })}
          >
            <Grid container direction="column">
              <Grid item flex={1} p={2} container alignItems="center" justifyContent="center">
                <img width="55%" height="55%" src={dashboardIcon} alt={label} />
              </Grid>
              <Grid
                item
                height={70}
                container
                flexWrap="nowrap"
                alignItems="center"
                justifyContent="space-between"
                sx={(theme: Theme) => ({
                  px: 2,
                  ...(colorKey ? ({
                    color: theme.palette[colorKey]?.contrastText,
                    backgroundColor: theme.palette[colorKey]?.main,
                  }) : ({
                    backgroundColor: theme.palette.grey[100],
                  })),
                })}
              >
                <Grid maxWidth={180}>
                  <Typography color="inherit" fontWeight="bold">
                    {label}
                  </Typography>
                </Grid>
                <ArrowForwardIosIcon fontSize="small" color="inherit" />
              </Grid>
            </Grid>
          </Paper>
        </Link>
      </Slide>
    </Grid>

  );
}

export default DashboardItem;
