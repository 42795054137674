import {
  Box,
  Button,
  Grid,
  Typography,
} from '@mui/material';
import logo from 'assets/icons/logo/puzl_logo.svg';

function ErrorPage() {
  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <Grid
      container
      height="100vh"
      direction="column"
      justifyContent="center"
      alignItems="center"
      gap={4}
      textAlign="center"
      sx={(theme) => ({
        bgcolor: theme.palette.common.white,
      })}
    >
      <Grid item>
        <Box
          component="img"
          src={logo}
          width="40%"
          alt="Puzl"
        />
      </Grid>
      <Grid item>
        <Typography variant="h4" component="h4">
          Oops, Something went wrong...
        </Typography>
      </Grid>
      <Grid item>
        <Typography>
          Please refresh the page and try again. If the problem persists, contact us.
        </Typography>
      </Grid>
      <Grid item>
        <Button variant="contained" onClick={refreshPage}>
          Refresh
        </Button>
      </Grid>
    </Grid>
  );
}

export default ErrorPage;
