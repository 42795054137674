import { configureStore } from '@reduxjs/toolkit';

import { batch } from 'react-redux';
import userSlice, { resetUserState } from './user';
import usStates, { resetUSStates } from './usStates';
import rolesSlice, { resetRolesSlice } from './roles';
import timeZones, { resetTimeZonesState } from './timeZones';
import retailerReducers, {
  resetRetailerStoresState,
  resetRetailerCompanyState,
  resetRetailerMarginsState,
  resetRetailerAdWeeksState,
  resetRetailerSandboxState,
  resetRetailerImpItemsState,
  resetRetailerEmployeesState,
  resetRetailerWarehousesState,
  resetRetailerRoundingRulesState,
  resetRetailerRulesSetupRootState,

  resetRetailerMERootState,
  resetRetailerBasePriceSandboxState,
} from './retailer';
import adminReducers, { resetAdminRetailersState } from './admin';

import appSlice, { AppState, showMessage as showMessageAction } from './app';

const NOTIFIER_TIMEOUT: number = 10;

const store = configureStore({
  reducer: {
    app: appSlice.reducer,
    user: userSlice.reducer,
    roles: rolesSlice.reducer,
    usStates: usStates.reducer,
    timeZones: timeZones.reducer,
    admin: adminReducers,
    retailer: retailerReducers,
  },
});

export const resetAllState = () => (
  batch(() => {
    store.dispatch(resetUSStates());
    store.dispatch(resetUserState());
    store.dispatch(resetRolesSlice());
    store.dispatch(resetTimeZonesState());

    store.dispatch(resetAdminRetailersState());

    store.dispatch(resetRetailerStoresState());
    store.dispatch(resetRetailerMarginsState());
    store.dispatch(resetRetailerCompanyState());
    store.dispatch(resetRetailerAdWeeksState());
    store.dispatch(resetRetailerSandboxState());
    store.dispatch(resetRetailerImpItemsState());
    store.dispatch(resetRetailerEmployeesState());
    store.dispatch(resetRetailerWarehousesState());
    store.dispatch(resetRetailerRoundingRulesState());
    store.dispatch(resetRetailerRulesSetupRootState());

    store.dispatch(resetRetailerMERootState());
    store.dispatch(resetRetailerBasePriceSandboxState());
  })
);

export const showMessage = (
  notifier: Omit<AppState['notifier'], 'message'> & { messages: Array<string> },
) => {
  notifier.messages.forEach((message) => {
    setTimeout(() => {
      store.dispatch(showMessageAction({ ...notifier, message }));
    }, NOTIFIER_TIMEOUT);
  });
};

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>

export default store;

export { userSlice };
