import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  ListQueries,
  ListResponse,
  RetailerSandbox,
  RetailerImpItem,
  RetailerStoreGroup,
  RetailerSandboxItem,
  RetailerSandboxOffer,
  RetailerWeeklyMargins,
  RetailerSandboxStatus,
  RetailerSandboxItemType,
  RetailerSandboxExcludedItem,
  RetailerItemPricesHistoryItem,
  RetailerItemSalesHistoryResponse,
  RetailerSandboxOrdersConfigsResponse,
  RetailerSandboxOfferPricesRequestBody,
  RetailerSandboxOffersUploadingResponse,
  RetailerSandboxChangeDepartmentOrderBody,
} from 'resources/types';
import services from 'services';
import { RootState, showMessage } from 'store';
import {
  getOfferNameError,
  getSandboxCalculationErrors,
  generateSandboxOfferUploadingBody,
  getSandboxDeletionItemsRequestBody,
} from 'utils';

const GET_RETAILER_SANDBOX = 'retailer/sandbox/getRetailerSandbox';
const GET_RETAILER_STORE_GROUPS = 'retailer/sandbox/getRetailerStoreGroups';
const FINALIZE_RETAILER_SANDBOX = 'retailer/sandbox/finalizeRetailerSandbox';
const CALCULATE_RETAILER_SANDBOX = 'retailer/sandbox/calculateRetailerSandbox';
const GET_RETAILER_SANDBOX_STATUS = 'retailer/sandbox/getRetailerSandboxStatus';
const UPDATE_RETAILER_SANDBOX_ITEM = 'retailer/sandbox/updateRetailerSandboxItem';
const REMOVE_RETAILER_SANDBOX_ITEM = 'retailer/sandbox/removeRetailerSandboxItem';
const REMOVE_RETAILER_SANDBOX_ITEMS = 'retailer/sandbox/removeRetailerSandboxItems';
const UPDATE_RETAILER_SANDBOX_OFFER = 'retailer/sandbox/updateRetailerSandboxOffer';
const REMOVE_RETAILER_SANDBOX_OFFER = 'retailer/sandbox/removeRetailerSandboxOffer';
const GET_RETAILER_AD_WEEKLY_MARGINS = 'retailer/sandbox/getRetailerAdWeeklyMargins';
const UPLOAD_RETAILER_SANDBOX_OFFERS = 'retailer/sandbox/uploadRetailerSandboxOffers';
const DUPLICATE_RETAILER_SANDBOX_ITEM = 'retailer/sandbox/duplicateRetailerSandboxItem';
const DUPLICATE_RETAILER_SANDBOX_OFFER = 'retailer/sandbox/duplicateRetailerSandboxOffer';
const CHANGE_RETAILER_DEPARTMENT_ORDER = 'retailer/sandbox/changeRetailerDepartmentOrder';
const UPDATE_RETAILER_SANDBOX_OFFER_NAMES = 'retailer/sandbox/updateRetailerSandboxOfferNames';
const GET_RETAILER_SANDBOX_EXCLUDED_ITEMS = 'retailer/sandbox/getRetailerSandboxExcludedItems';
const GET_RETAILER_SANDBOX_ORDERS_CONFIGS = 'retailer/sandbox/getRetailerSandboxOrdersConfigs';
const CHANGE_RETAILER_SANDBOX_ITEM_STATUS = 'retailer/sandbox/changeRetailerSandboxItemStatus';
const UPDATE_RETAILER_SANDBOX_OFFERS_PRICES = 'retailer/sandbox/updateRetailerSandboxOffersPrices';
const REMOVE_RETAILER_SANDBOX_EXCLUDED_ITEMS = 'retailer/sandbox/removeRetailerSandboxExcludedItems';
const GET_RETAILER_SANDBOX_ITEM_SALES_HISTORY = 'retailer/sandbox/getRetailerSandboxItemSalesHistory';
const GET_RETAILER_SANDBOX_ITEM_PRICES_HISTORY = 'retailer/sandbox/getRetailerSandboxItemPricesHistory';
const FILL_DOWN_RETAILER_SANDBOX_ITEMS_DEAL_COST = 'retailer/sandbox/fillDownRetailerSandboxItemsDealCost';
const UPDATE_RETAILER_SANDBOX_PROJECTED_AD_VOLUMES = 'retailer/sandbox/updateRetailerSandboxProjectedAdVolumes';

const getRetailerSandbox = createAsyncThunk<
  RetailerSandbox,
  { companyId: number, adId: number, query?: ListQueries, refetch?: boolean }
>(
  GET_RETAILER_SANDBOX,
  async (params) => {
    const { companyId, adId, query } = params;
    const response = await services.getRetailerSandbox(companyId, adId, query);
    return response.data;
  },
);

const getRetailerAdWeeklyMargins = createAsyncThunk<
  RetailerWeeklyMargins,
  { companyId: number, adId: number }
>(
  GET_RETAILER_AD_WEEKLY_MARGINS,
  async (params) => {
    const { companyId, adId } = params;
    const response = await services.getRetailerAdWeeklyMargins(companyId, adId);
    return response.data;
  },
);

const getRetailerStoreGroups = createAsyncThunk<
  ListResponse<RetailerStoreGroup>,
  number
>(
  GET_RETAILER_STORE_GROUPS,
  async (companyId) => {
    const response = await services.getRetailerStoreGroups(companyId);
    return response.data;
  },
);

const getRetailerSandboxExcludedItems = createAsyncThunk<
  Array<RetailerSandboxExcludedItem>,
  { companyId: number, adId: number }
>(
  GET_RETAILER_SANDBOX_EXCLUDED_ITEMS,
  async (params) => {
    const { companyId, adId } = params;
    const response = await services.getRetailerSandboxExcludedItems(companyId, adId);
    return response.data;
  },
);

const removeRetailerSandboxExcludedItems = createAsyncThunk<
  { value: boolean },
  { companyId: number, adId: number }
>(
  REMOVE_RETAILER_SANDBOX_EXCLUDED_ITEMS,
  async (params) => {
    const { companyId, adId } = params;
    const response = await services.removeRetailerSandboxExcludedItems(companyId, adId);
    return response.data;
  },
);

const changeRetailerSandboxItemStatus = createAsyncThunk<
  RetailerSandboxOffer,
  {
    companyId: number,
    adId: number,
    itemType: RetailerSandboxItemType,
    itemId: number,
    data: { value: boolean }
  }
>(
  CHANGE_RETAILER_SANDBOX_ITEM_STATUS,
  async (params) => {
    const {
      companyId, adId, itemType, itemId, data,
    } = params;
    const response = await services.changeRetailerSandboxItemStatus(
      companyId,
      adId,
      itemType,
      itemId,
      data,
    );
    return response.data;
  },
);

const updateRetailerSandboxOfferNames = createAsyncThunk<
  RetailerSandboxOffer,
  {
    companyId: number,
    adId: number,
    offerId: number,
    data: RetailerSandboxOffer,
    name: keyof RetailerSandboxOffer,
  }
>(
  UPDATE_RETAILER_SANDBOX_OFFER_NAMES,
  async (params) => {
    const {
      companyId, adId, offerId, data, name,
    } = params;
    if (name === 'offerName') {
      const offerNameError = getOfferNameError(data.offerName);
      if (offerNameError) {
        showMessage({ messages: [offerNameError], options: { variant: 'error' } });
        return Promise.reject();
      }
    }

    const response = await services.updateRetailerSandboxOfferNames(
      companyId,
      adId,
      offerId,
      data,
      name,
    );
    return response.data;
  },
);

const updateRetailerSandboxItem = createAsyncThunk<
  RetailerSandboxOffer,
  {
    companyId: number,
    adId: number,
    itemId: number,
    item: RetailerSandboxItem,
    name: string,
  }
>(
  UPDATE_RETAILER_SANDBOX_ITEM,
  async (params) => {
    const {
      companyId, adId, itemId, item, name,
    } = params;
    const response = await services.updateRetailerSandboxItem(companyId, adId, itemId, item, name);
    return response.data;
  },
);

const updateRetailerSandboxItemNames = createAsyncThunk<
  RetailerSandboxOffer,
  {
    companyId: number,
    adId: number,
    itemId: number,
    item: RetailerSandboxItem,
    name: string,
  }
>(
  UPDATE_RETAILER_SANDBOX_ITEM,
  async (params) => {
    const {
      companyId, adId, itemId, item, name,
    } = params;
    const response = await services.updateRetailerSandboxItemNames(
      companyId,
      adId,
      itemId,
      item,
      name,
    );
    return response.data;
  },
);

const updateRetailerSandboxOffer = createAsyncThunk<
  RetailerSandboxOffer,
  {
    companyId: number,
    adId: number,
    offerId: number,
    data: RetailerSandboxOffer,
    name: string,
  }
>(
  UPDATE_RETAILER_SANDBOX_OFFER,
  async (params) => {
    const {
      companyId, adId, offerId, data, name,
    } = params;
    const response = await services.updateRetailerSandboxOffer(
      companyId,
      adId,
      offerId,
      data,
      name,
    );
    return response.data;
  },
);

const updateRetailerSandboxOffersPrices = createAsyncThunk<
  RetailerSandbox,
  {
    companyId: number;
    adId: number;
    offersData: RetailerSandboxOfferPricesRequestBody
  }
>(
  UPDATE_RETAILER_SANDBOX_OFFERS_PRICES,
  async (params) => {
    const { companyId, adId, offersData } = params;
    const response = await services.updateRetailerSandboxOffers(companyId, adId, offersData);
    return response.data;
  },
);

const duplicateRetailerSandboxOffer = createAsyncThunk<
  RetailerSandboxOffer,
  { companyId: number, adId: number, offerId: number }
>(
  DUPLICATE_RETAILER_SANDBOX_OFFER,
  async (params) => {
    const { companyId, adId, offerId } = params;
    const response = await services.duplicateRetailerSandboxOffer(companyId, adId, offerId);
    return response.data;
  },
);

const duplicateRetailerSandboxItem = createAsyncThunk<
  RetailerSandboxOffer,
  { companyId: number, adId: number, itemId: number }
>(
  DUPLICATE_RETAILER_SANDBOX_ITEM,
  async (params) => {
    const { companyId, adId, itemId } = params;
    const response = await services.duplicateRetailerSandboxItem(companyId, adId, itemId);
    return response.data;
  },
);

const removeRetailerSandboxOffer = createAsyncThunk<
  RetailerSandbox,
  { companyId: number, adId: number, offerId: number, }
>(
  REMOVE_RETAILER_SANDBOX_OFFER,
  async (params) => {
    const { companyId, adId, offerId } = params;
    const response = await services.removeRetailerSandboxOffer(companyId, adId, offerId);
    return response.data;
  },
);

const removeRetailerSandboxItem = createAsyncThunk<
  RetailerSandbox,
  { companyId: number, adId: number, itemId: number }
>(
  REMOVE_RETAILER_SANDBOX_ITEM,
  async (params) => {
    const { companyId, adId, itemId } = params;
    const response = await services.removeRetailerSandboxItem(companyId, adId, itemId);
    return response.data;
  },
);

const removeRetailerSandboxItems = createAsyncThunk<
  void,
  { companyId: number, adId: number }
>(
  REMOVE_RETAILER_SANDBOX_ITEMS,
  async (params, thunkAPI) => {
    const { companyId, adId } = params;
    const state = thunkAPI.getState() as RootState;
    const {
      deletionOptions, sandbox, filterBy, selectedPlacements,
    } = state.retailer.sandbox;

    let allDepartmentsSelected = deletionOptions.allSelected;
    if (filterBy !== null || selectedPlacements.length) {
      allDepartmentsSelected = false;
    }

    if (allDepartmentsSelected) {
      await services.removeRetailerSandboxAllItems(companyId, adId);
      return;
    }

    const deletionItemsRequestBody = getSandboxDeletionItemsRequestBody(
      sandbox?.sandboxDepartments || [],
      deletionOptions.checkboxStates,
    );
    await services.removeRetailerSandboxSelectedItems(
      companyId,
      adId,
      deletionItemsRequestBody,
    );
  },
);

const getRetailerSandboxOrdersConfigs = createAsyncThunk<
  RetailerSandboxOrdersConfigsResponse,
  { companyId: number, adId: number }
>(
  GET_RETAILER_SANDBOX_ORDERS_CONFIGS,
  async (params) => {
    const { companyId, adId } = params;
    const response = await services.getRetailerSandboxOrdersConfigs(companyId, adId);
    return response.data;
  },
);

const getRetailerSandboxStatus = createAsyncThunk<
  RetailerSandboxStatus,
  { companyId: number, adId: number }
>(
  GET_RETAILER_SANDBOX_STATUS,
  async (params) => {
    const { companyId, adId } = params;
    const response = await services.getRetailerSandboxStatus(companyId, adId);
    return response.data.value;
  },
);

const uploadRetailerSandboxOffers = createAsyncThunk<
  RetailerSandboxOffersUploadingResponse['items'],
  {
    companyId: number,
    adId: number,
    impItemsGroups: { [key: string]: Array<RetailerImpItem> }
  }
>(
  UPLOAD_RETAILER_SANDBOX_OFFERS,
  async (params) => {
    const { companyId, adId, impItemsGroups } = params;
    const sandboxOffersUploadingBody = generateSandboxOfferUploadingBody(impItemsGroups);
    const response = await services.uploadRetailerSandboxOffers(
      companyId,
      adId,
      sandboxOffersUploadingBody,
    );
    return response.data.items;
  },
);

const getRetailerSandboxItemPricesHistory = createAsyncThunk<
  Array<RetailerItemPricesHistoryItem>,
  {
    companyId: number,
    adId: number,
    itemType: RetailerSandboxItemType,
    itemId: number,
  }
>(
  GET_RETAILER_SANDBOX_ITEM_PRICES_HISTORY,
  async (params) => {
    const {
      companyId, adId, itemType, itemId,
    } = params;
    const response = await services.getRetailerSandboxItemPricesHistory(
      companyId,
      adId,
      itemType,
      itemId,
    );

    if (response.data.historyComponents) {
      return (
        JSON.parse(response.data.historyComponents) as Array<RetailerItemPricesHistoryItem>
      );
    }
    return [];
  },
);

const getRetailerSandboxItemSalesHistory = createAsyncThunk<
  RetailerItemSalesHistoryResponse,
  {
    companyId: number,
    adId: number,
    itemType: RetailerSandboxItemType,
    itemId: number,
  }
>(
  GET_RETAILER_SANDBOX_ITEM_SALES_HISTORY,
  async (params) => {
    const {
      companyId, adId, itemType, itemId,
    } = params;
    const response = await services.getRetailerSandboxItemSalesHistory(
      companyId,
      adId,
      itemType,
      itemId,
    );
    return response.data;
  },
);

const finalizeRetailerSandbox = createAsyncThunk<
  { value: boolean },
  { companyId: number, adId: number }
>(
  FINALIZE_RETAILER_SANDBOX,
  async (params) => {
    const { companyId, adId } = params;
    const response = await services.finalizeRetailerSandbox(companyId, adId);
    return response.data;
  },
);

const calculateRetailerSandbox = createAsyncThunk<
  RetailerSandbox,
  { companyId: number, adId: number },
  { rejectValue: string[] }
>(
  CALCULATE_RETAILER_SANDBOX,
  async (params, thunkAPI) => {
    const { companyId, adId } = params;
    const state = thunkAPI.getState() as RootState;
    const { sandboxDepartmentsErrorsData } = state.retailer.sandbox;
    const calculationErrors = getSandboxCalculationErrors(sandboxDepartmentsErrorsData);
    if (calculationErrors.length) {
      throw thunkAPI.rejectWithValue(calculationErrors);
    }
    const response = await services.calculateRetailerSandbox(companyId, adId);
    return response.data;
  },
);

const fillDownRetailerSandboxItemsDealCost = createAsyncThunk<
  RetailerSandboxOffer,
  { companyId: number, adId: number, itemId: number }
>(
  FILL_DOWN_RETAILER_SANDBOX_ITEMS_DEAL_COST,
  async (params) => {
    const { companyId, adId, itemId } = params;
    const response = await services.fillDownRetailerSandboxItemsDealCost(
      companyId,
      adId,
      itemId,
    );
    return response.data;
  },
);

const updateRetailerSandboxProjectedAdVolumes = createAsyncThunk<
  RetailerSandbox,
  { companyId: number, adId: number }
>(
  UPDATE_RETAILER_SANDBOX_PROJECTED_AD_VOLUMES,
  async (params) => {
    const { companyId, adId } = params;
    const response = await services.updateRetailerSandboxProjectedAdVolumes(
      companyId,
      adId,
    );
    return response.data;
  },
);

const changeRetailerSandboxDepartmentOrder = createAsyncThunk<
  void,
  { companyId: number, adId: number, data: RetailerSandboxChangeDepartmentOrderBody }
>(
  CHANGE_RETAILER_DEPARTMENT_ORDER,
  async (params) => {
    const { companyId, adId, data } = params;
    await services.changeRetailerSandboxDepartmentOrder(companyId, adId, data);
  },
);

export {
  getRetailerSandbox,
  getRetailerStoreGroups,
  finalizeRetailerSandbox,
  calculateRetailerSandbox,
  getRetailerSandboxStatus,
  removeRetailerSandboxItem,
  updateRetailerSandboxItem,
  updateRetailerSandboxOffer,
  removeRetailerSandboxOffer,
  getRetailerAdWeeklyMargins,
  removeRetailerSandboxItems,
  uploadRetailerSandboxOffers,
  duplicateRetailerSandboxItem,
  duplicateRetailerSandboxOffer,
  updateRetailerSandboxItemNames,
  getRetailerSandboxOrdersConfigs,
  updateRetailerSandboxOfferNames,
  changeRetailerSandboxItemStatus,
  getRetailerSandboxExcludedItems,
  updateRetailerSandboxOffersPrices,
  getRetailerSandboxItemSalesHistory,
  removeRetailerSandboxExcludedItems,
  getRetailerSandboxItemPricesHistory,
  fillDownRetailerSandboxItemsDealCost,
  changeRetailerSandboxDepartmentOrder,
  updateRetailerSandboxProjectedAdVolumes,
};
