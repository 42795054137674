import { Routes } from 'react-router-dom';

import * as Sentry from '@sentry/react';
import routeObjects from './routes';
import RenderRoutes from './RenderRoutes';

const SentryRoutes = Sentry.withSentryRouting(Routes);

function Router() {
  return (
    <SentryRoutes>{RenderRoutes(routeObjects)}</SentryRoutes>
  );
}

export { routeObjects };

export default Router;
