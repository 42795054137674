export const sandboxEvent = {
  openAdSandboxScreen: 'Open Ad Sandbox Screen',
  calculateSandbox: 'Calculate Sandbox',
  changeOfferPrice: 'Change Offer Price',
  updateOfferField: 'Update Offer Field',
  updateItemField: 'Update Item Field',
  duplicateSandboxOffer: 'Duplicate Sandbox Offer',
  duplicateSandboxItem: 'Duplicate Sandbox Item',
  finalizeSandbox: 'Finalize Sandbox',
  removeSandboxRow: 'Remove Sandbox Row',
  removeMultipleItems: 'Remove Multiple Items',
  changeRowStatus: 'Change Row Status',
  addSandboxOffer: 'Add Sandbox Offer',
  dealCostFillDown: 'Deal Cost Fill Down',
  openSalesChart: 'Open Sales Chart',
  addOfferButtonClick: 'Add Offer Button Click',
};

export const roundingRuleEvent = {
  openRoundingRule: 'Open Retailer Rounding Rules Screen',
};

export const marginEnhancementToolEvent = {
  openMarginEnhancementTool: 'Open Margin Enhancement Tool',
  selectPlanningWeek: 'Select Planning Week',
  selectBasePriceManager: 'Select Base Price Manager',
  selectTPRPassthrough: 'Select TPR Passthrough',
  clickShowDollarValues: 'Click Show $ Values',
};

export const basePriceMangerEvent = {
  calculateBPM: 'Calculate BPM',
  changeNewPrice: 'Change New Price',
  updateOfferField: 'Update Offer Field',
  duplicateBPMOffer: 'Duplicate BPM Offer',
  duplicateBPMItem: 'Duplicate BPM Item',
  removeBPMRow: 'Remove BPM Row',
  removeMultipleItems: 'Remove Multiple Items',
  changeRowStatus: 'Change Row Status',
  addBPMOffer: 'Add BPM Offer',
  newUnitCostFillDown: 'New Unit Cost Fill Down',
  openHistoricalSalesChart: 'Open Historical Sales Chart',
  addNewButtonClick: 'Add New Button Click',
  clickUPCBatchUpload: 'Click UPC Batch Upload',
  discardSelection: 'Discard Selection',
  clickMoveToSandbox: 'Click Move To Sandbox',
  clickMoveSelected: 'Click Move Selected (In Confirm Selected Items screen)',
  clickBackBasePriceManager: 'Click Back to Base Price Manager',
};

export const grossMarginManagerEvent = {
  selectAdWeek: 'Select Ad Week',
  searchDepartmentOrCategory: 'Search Department or Category',
  updateWeeklyTargetMargin: 'Update Weekly Target Margin',
  calculateProjectSales: 'Calculate Projected Sales',
  fillDownWeeklyMargins: 'Fill Down Weekly Margins',
  confirmMargins: 'Confirm Margins',
  updateReferenceWeek: 'Update Reference Week',
  redirectDepartmentManager: 'Redirect To Department Manager',
  updateDepartmentBudgetShrink: 'GMM: Update Department Budgeted Shrink',
  updateCategoryBudgetShrink: 'GMM: Update Category Budgeted Shrink',
  fillDownBudgetShrinkVolumes: 'GMM: Fill Down Budgeted Shrink Volumes',
};

export const departmentManagerEvent = {
  searchDepartmentOrCategory: 'Search Department or Category',
  selectYear: 'Select Year',
  updateCompanyAnnualMargin: 'Update Company Annual Margin',
  updateAnnualMargin: 'Update Annual Margin',
  fillDownAnnualMargins: 'Fill Down Annual Margins',
  selectQuarterlyLift: 'Select Quarterly Lift',
  updateQuarterlyLift: 'Update Quarterly Lift',
  goBack: 'Go Back',
  updateAverageWeeklyBudgetShrinkVolume: 'DM: Update Average Weekly Budgeted Shrink Volume',
  fillDownAverageWeeklyBudgetShrinkVolume: 'DM: Fill Down Average Weekly Budgeted Shrink Volume',
};

export const impItemsEvent = {
  openImpItemsScreen: 'Open IMP Items Screen',
};

export const adManagerEvent = {
  searchAd: 'Search Ad',
  exportAd: 'Export Ad',
  exportMultipleAds: 'Export Multiple Ads',
  redirectToSandbox: 'Redirect to Sandbox',
  redirectToCompanyAdSetup: 'Redirect to Company Ad Setup',
};

export const adWeekSetupEvent = {
  searchUPC: 'Search UPC',
  selectDepartment: 'Select Department',
  selectCategory: 'Select Category',
  goBackToSandbox: 'Go Back To Sandbox',
  upcBatchUploadClick: 'Upc Batch Upload Click',
  moveToSandboxClick: 'Move To Sandbox Click',
  discardSelection: 'Discard Selection',
};

export const upcBatchUploadEvent = {
  confirmGetSelectedUPCs: 'Confirm and get selected UPCs',
  cancel: 'UPC Batch Upload Cancel',
  selectPlacement: 'UPC Batch Upload Select Placement',
};

export const moveToSandboxEvent = {
  cancel: 'Move to Sandbox Cancel',
  confirm: 'Move to Sandbox Confirm',
};

export const storeManagementEvent = {
  getStores: 'Get Stores',
  searchStore: 'Search Store',
  selectStoreGroup: 'Select Store Group',
  deleteStore: 'Delete Store',
  updateStore: 'Update Store',
  createStore: 'Create Store',
};

export const profileManagementEvent = {
  editAccount: 'Edit Account',
  loginUser: 'Login User',
  logoutUser: 'Logout User',
};

export const employManagementEvent = {
  changeStatus: 'Change Status',
  addNewEmploy: 'Add New Employ',
  updateEmploy: 'Update Employ',
  deleteEmploy: 'Delete Employ',
  resetPassword: 'Reset Password',
};

export const warehouseManagementEvent = {
  createWarehouse: 'Create Warehouse',
  deleteWarehouse: 'Delete Warehouse',
  updateWarehouse: 'Update Warehouse',
};
