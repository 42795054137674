import {
  useRef,
  useState,
  useEffect,
  MouseEvent,
  useCallback,
  ChangeEvent,
} from 'react';
import {
  Grid,
  TextField,
  TextFieldProps,
  CircularProgress,
  TextFieldPropsColorOverrides,
} from '@mui/material';
import commaNumber from 'comma-number';

import { useDebounce } from 'hooks';
import { roundNumber, addZeros, changeCursorPosition } from 'utils';

const TIMEOUT: number = 2000;

export interface NumberInputProps {
  name?: string | undefined;
  label?: string | undefined;
  value: null | number | string;
  onChange?: (value: number | null, name: string) => void;
  onBlur?: TextFieldProps['onBlur'];
  onFocus?: TextFieldProps['onFocus'];

  submitting?: boolean;
  loading?: boolean;
  disabled?: boolean;
  withZeros?: boolean;
  fullWidth?: boolean;
  allowNegative?: boolean;
  debounceTimeout?: number;
  allowFractional?: boolean;
  maxCharactersCount?: number;
  className?: TextFieldProps['className'];
  inputProps?: TextFieldProps['inputProps'];
  InputProps?: TextFieldProps['InputProps'];
  color?: keyof TextFieldPropsColorOverrides;
}

function NumberInput(props: NumberInputProps) {
  const inputRef = useRef<HTMLInputElement>(null);

  const {
    label,
    value,
    color,
    onBlur,
    onFocus,
    onChange,
    loading,
    submitting = false,
    disabled,
    name = '',
    fullWidth,
    className,
    inputProps,
    InputProps,
    debounceTimeout,
    withZeros = true,
    allowNegative = true,
    allowFractional = true,
    maxCharactersCount = 20,
  } = props;

  const [displayValue, setDisplayValue] = useState<string>('');
  const [cursorPosition, setCursorPosition] = useState<number>(0);

  const zeroTimeOutRef = useRef<number>();

  const addZerosToDisplayValue: boolean = withZeros && allowFractional;

  const onClick = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
    },
    [],
  );

  const debounceFunction = useDebounce(
    (
      newValue: string,
      oldValue: NumberInputProps['value'],
      inputName: NumberInputProps['name'],
    ) => {
      if ((!Number.isNaN(Number(newValue)) && newValue !== oldValue) || newValue === '') {
        onChange?.(newValue ? Number(newValue) : null, inputName ?? '');
      }
    },
    debounceTimeout,
  );

  useEffect(() => changeCursorPosition(inputRef, cursorPosition), [cursorPosition]);

  useEffect(
    () => {
      if (submitting) {
        return;
      }
      if (typeof value !== 'number' && !value) {
        setDisplayValue('');
        return;
      }

      const newDisplayValue: string = commaNumber(
        roundNumber(Number(value)),
      ).replace(/(^0+)((?=\.))/g, '');

      clearTimeout(zeroTimeOutRef.current);

      if (debounceTimeout) {
        setDisplayValue(addZerosToDisplayValue ? addZeros(newDisplayValue) : newDisplayValue);
      } else {
        setDisplayValue(newDisplayValue);

        if (addZerosToDisplayValue) {
          zeroTimeOutRef.current = setTimeout(
            () => {
              setDisplayValue(addZeros(newDisplayValue));
            },
            TIMEOUT,
          );
        }
      }
    },
    [value, addZerosToDisplayValue, debounceTimeout, submitting],
  );

  const onInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value: targetValue } = event.target;

      const start: number = inputRef?.current?.selectionStart || 0;

      const lastChar: string = String.fromCharCode(targetValue.charCodeAt(start - 1));
      if (!allowNegative && lastChar === '-') return;
      if (!allowFractional && lastChar === '.') return;
      if (targetValue.length > maxCharactersCount) return;

      /* eslint-disable no-useless-escape */
      const withoutCommas: string = targetValue.replaceAll(/,/g, '');
      const numericValue: string = withoutCommas.match(/^(-?\d*)(\.(?=\d*))?\d*/)?.[0] || '';
      const newDisplayValue: string = commaNumber(numericValue).replace(/(^0+)((?=\.))/g, '');

      const lengthDiff: number = newDisplayValue.length - targetValue.length;
      const newCursorPosition: number = (
        ((inputRef?.current?.selectionStart || 0) + lengthDiff) || 0
      );

      setDisplayValue(newDisplayValue);
      setCursorPosition(newCursorPosition);
      debounceFunction(numericValue, value, name);
    },
    [allowNegative, allowFractional, maxCharactersCount, debounceFunction, value, name],
  );

  const focused = color === 'warning' || color === 'error' || color === 'info';

  return (
    <TextField
      name={name}
      label={label}
      color={color}
      onBlur={onBlur}
      onFocus={onFocus}
      focused={focused}
      onClick={onClick}
      autoComplete="off"
      inputRef={inputRef}
      className={className}
      fullWidth={fullWidth}
      value={displayValue}
      inputProps={inputProps}
      disabled={disabled || loading}
      InputProps={{
        ...InputProps,
        className: `color-${color}`,
        endAdornment: loading && (
          <Grid
            container
            position="absolute"
            alignItems="center"
            justifyContent="center"
            sx={{ backgroundColor: 'inherit' }}
          >
            <CircularProgress size={17} />
          </Grid>
        ),
      }}
      onChange={onInputChange}
    />
  );
}

NumberInput.defaultProps = {
  name: '',
  submitting: false,
  loading: false,
  disabled: false,
  withZeros: true,
  label: undefined,
  fullWidth: false,
  color: undefined,
  onBlur: undefined,
  debounceTimeout: 0,
  onFocus: undefined,
  allowNegative: true,
  onChange: undefined,
  className: undefined,
  allowFractional: true,
  inputProps: undefined,
  InputProps: undefined,
  maxCharactersCount: undefined,
};

export default NumberInput;
