import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RetailerAd, RetailerBasePriceMarginTableUnit } from 'resources/types';
import { getRetailerMECurrentAds, getRetailerSandboxStatus } from './thunks';

export interface RetailerMERootState {
  currentAds: Array<RetailerAd>;
  selectedPlaningWeek: RetailerAd | null;
  marginTableUnit: RetailerBasePriceMarginTableUnit;
  sandboxNeedsRecalculation: boolean;
  tableActionsExpanded: boolean,
}

const initialState: RetailerMERootState = {
  currentAds: [],
  selectedPlaningWeek: null,
  marginTableUnit: 'percentage',
  sandboxNeedsRecalculation: true,
  tableActionsExpanded: false,
};

/* eslint-disable no-param-reassign */

const meRootSlice = createSlice({
  name: 'retailer/marginEnhancement/root',
  initialState,
  reducers: {
    reset: () => initialState,
    toggleTableActionsExpanded: (state) => {
      state.tableActionsExpanded = !state.tableActionsExpanded;
    },
    setMarginTableUnit: (state, action: PayloadAction<RetailerBasePriceMarginTableUnit>) => {
      state.marginTableUnit = action.payload;
    },
    setSelectedPlaningWeek: (state, action: PayloadAction<RetailerAd | null>) => {
      state.selectedPlaningWeek = action.payload;
      if (!action.payload) {
        state.sandboxNeedsRecalculation = true;
      }
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getRetailerMECurrentAds.rejected, (state) => {
      state.currentAds = [];
    });
    builder.addCase(getRetailerMECurrentAds.fulfilled, (state, action) => {
      state.currentAds = action.payload;
    });

    builder.addCase(getRetailerSandboxStatus.fulfilled, (state, action) => {
      state.sandboxNeedsRecalculation = action.payload !== 'SANDBOX_CALCULATED';
    });
    builder.addCase(getRetailerSandboxStatus.rejected, (state) => {
      state.sandboxNeedsRecalculation = true;
    });
  },
});

const {
  setMarginTableUnit,
  setSelectedPlaningWeek,
  toggleTableActionsExpanded,
  reset: resetRetailerMERootState,
} = meRootSlice.actions;

export {
  setMarginTableUnit,
  setSelectedPlaningWeek,
  toggleTableActionsExpanded,
  getRetailerMECurrentAds,
  getRetailerSandboxStatus,
  resetRetailerMERootState,
};

export default meRootSlice;
