import { createSlice } from '@reduxjs/toolkit';

import { User, UserScope } from 'resources/types';
import {
  getProfile,
  checkAuthKey,
  resetPassword,
  updateProfile,
  changePassword,
  getProfilePhoto,
  deleteProfilePhoto,
  uploadProfilePhoto,
  changePasswordWithKey,
} from './thunks';

export interface UserState {
  profile: User | null;
  loading: boolean;
  userScope: UserScope | null;

  profilePhotoURL: string;
  profilePhotoLoading: boolean;

  authKeyVerificationLoading: boolean;
  authKeyVerificationResult: boolean;
}

const initialState: UserState = {
  profile: null,
  loading: false,
  userScope: null,

  profilePhotoURL: '',
  profilePhotoLoading: false,

  authKeyVerificationLoading: false,
  authKeyVerificationResult: false,
};

/* eslint-disable no-param-reassign */

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    reset: (state) => {
      URL.revokeObjectURL(state.profilePhotoURL);
      return initialState;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getProfile.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getProfile.rejected, (state) => {
      state.loading = false;
      state.userScope = null;
      state.profile = null;
    });
    builder.addCase(getProfile.fulfilled, (state, action) => {
      state.loading = false;
      const [firstRole] = action.payload.roles;
      const userScope = firstRole?.userScope || null;
      state.userScope = userScope;
      state.profile = action.payload;
    });

    builder.addCase(getProfilePhoto.pending, (state) => {
      state.profilePhotoLoading = true;
    });
    builder.addCase(getProfilePhoto.rejected, (state) => {
      state.profilePhotoLoading = false;
      state.profilePhotoURL = '';
    });
    builder.addCase(getProfilePhoto.fulfilled, (state, action) => {
      state.profilePhotoLoading = false;
      state.profilePhotoURL = action.payload;
    });

    builder.addCase(deleteProfilePhoto.fulfilled, (state) => {
      state.profilePhotoURL = '';
      state.profilePhotoLoading = false;
    });

    builder.addCase(uploadProfilePhoto.pending, (state) => {
      state.profilePhotoLoading = true;
    });
    builder.addCase(uploadProfilePhoto.rejected, (state) => {
      state.profilePhotoLoading = false;
    });
    builder.addCase(uploadProfilePhoto.fulfilled, (state, action) => {
      state.profilePhotoLoading = false;
      state.profilePhotoURL = action.payload;
    });

    builder.addCase(updateProfile.fulfilled, (state, action) => {
      state.profile = action.payload;
    });

    builder.addCase(checkAuthKey.pending, (state) => {
      state.authKeyVerificationLoading = true;
      state.authKeyVerificationResult = false;
    });
    builder.addCase(checkAuthKey.rejected, (state) => {
      state.authKeyVerificationLoading = false;
      state.authKeyVerificationResult = false;
    });
    builder.addCase(checkAuthKey.fulfilled, (state, action) => {
      const { value } = action.payload;
      state.authKeyVerificationLoading = false;
      state.authKeyVerificationResult = value;
    });
  },
});

const { reset: resetUserState } = userSlice.actions;

export {
  getProfile,
  checkAuthKey,
  updateProfile,
  resetPassword,
  resetUserState,
  changePassword,
  getProfilePhoto,
  deleteProfilePhoto,
  uploadProfilePhoto,
  changePasswordWithKey,
};

export default userSlice;
