import { ReactElement, ReactNode, useRef } from 'react';
import {
  Grid, Slide, Skeleton, Theme,
} from '@mui/material';

import { RootState } from 'store';
import { useAppSelector } from 'hooks';
import defaultCover from 'assets/covers/default_cover.jpg';

export interface CoverLayoutProps {
  children?: ReactNode,
  title: ReactElement<any, any>,
}

function CoverLayout(props: CoverLayoutProps) {
  const { title, children } = props;
  const containerRef = useRef(null);

  const userState = useAppSelector((state: RootState) => state.user);
  const companyState = useAppSelector((state: RootState) => state.retailer.company);

  const { userScope } = userState;
  const { companyCoverURL, companyCoverLoading } = companyState;

  const isAdmin: boolean = Boolean(userScope && userScope === 'USER_SCOPE_ADMIN');
  const coverURL: string = isAdmin ? defaultCover : companyCoverURL;

  return (
    <Grid container direction="column" ref={containerRef}>
      <Grid
        item
        container
        direction="column"
        alignItems="center"
        position="relative"
        sx={(theme: Theme) => ({
          width: '100%',
          height: '40vh',
          ...(!companyCoverLoading ? ({ bgcolor: theme.palette.grey[300] }) : {}),
          ...(!companyCoverLoading && coverURL ? ({
            bgcolor: 'unset',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundImage: (
              `linear-gradient(rgba(0, 0, 0, 0.2),rgba(0, 0, 0, 0.7)), url(${coverURL})`
            ),
          }) : {}),
        })}
      >
        {companyCoverLoading && (
          <Skeleton
            variant="rectangular"
            width="100%"
            height="100%"
            animation="wave"
            sx={{ position: 'absolute' }}
          />
        )}
        <Slide
          in
          mountOnEnter
          timeout={600}
          unmountOnExit
          direction="down"
          container={containerRef.current}
        >
          <Grid sx={(theme: Theme) => ({ zIndex: theme.zIndex.appBar })}>
            {title}
          </Grid>
        </Slide>
      </Grid>
      <Grid
        container
        width="100%"
        justifyContent="center"
        sx={(theme: Theme) => ({ zIndex: theme.zIndex.appBar })}
      >
        {children}
      </Grid>
    </Grid>
  );
}

CoverLayout.defaultProps = {
  children: undefined,
};

export default CoverLayout;
