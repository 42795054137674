import {
  ReactNode,
  MouseEvent,
  useCallback,
} from 'react';
import {
  Grow,
  Grid,
  Theme,
  IconButton,
  Typography,
} from '@mui/material';
import { Outlet } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';

import { RootState } from 'store';
import { toggleMenu } from 'store/app';
import { Drawer } from 'components/main';
import { SplashScreen } from 'components/content';
import { useAppDispatch, useAppSelector, useProfile } from 'hooks';
import ActionBar from './ActionBar';

function Template(props: { children?: ReactNode }) {
  useProfile();
  const dispatch = useAppDispatch();
  const app = useAppSelector((state: RootState) => state.app);

  const userState = useAppSelector((state: RootState) => state.user);
  const companyState = useAppSelector((state: RootState) => state.retailer.company);

  const { profile } = userState;
  const { data: companyData } = companyState;

  const { children } = props;
  const { menuOpened } = app.drawer;

  const openMenu = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      dispatch(toggleMenu(true));
    },
    [dispatch],
  );

  return (
    (profile ? (
      <Grid container flexWrap="nowrap">
        <Drawer />
        <Grid
          flex={1}
          container
          flexWrap="nowrap"
          direction="column"
          sx={(theme: Theme) => ({
            height: '100vh',
            overflow: 'hidden',
            backgroundColor: theme.palette.common.white,
          })}
        >
          <Grid
            item
            px={2}
            container
            flexShrink={0}
            alignItems="center"
            sx={(theme: Theme) => ({
              height: 65,
              backgroundColor: theme.palette.template.main,
            })}
          >
            <Grow in={!menuOpened} timeout={600}>
              <IconButton
                onClick={openMenu}
                sx={{ width: 50, height: 50 }}
              >
                <MenuIcon
                  fontSize="large"
                  sx={(theme: Theme) => ({ color: theme.palette.template.contrastText })}
                />
              </IconButton>
            </Grow>
            <Typography
              sx={(theme: Theme) => ({
                color: theme.palette.template.contrastText,
              })}
            >
              {companyData?.companyName}
            </Typography>
            <Grid item px={1} ml="auto"><ActionBar /></Grid>
          </Grid>

          {children || <Outlet />}
        </Grid>
      </Grid>
    ) : (
      <SplashScreen />
    ))
  );
}

Template.defaultProps = {
  children: undefined,
};

export default Template;
