import storeServices from './store';
import marginsService from './margins';
import adWeeksServices from './adWeeks';
import companyServices from './company';
import sandboxServices from './sandbox';
import employeeServices from './employee';
import impItemsServices from './impItems';
import rulesSetupServices from './rulesSetup';
import warehousesServices from './warehouses';
import marginEnhancementServices from './marginEnhancement';

export default {
  ...storeServices,
  ...marginsService,
  ...adWeeksServices,
  ...sandboxServices,
  ...companyServices,
  ...impItemsServices,
  ...employeeServices,
  ...warehousesServices,
  ...rulesSetupServices,
  ...marginEnhancementServices,
};
