import { Route } from 'react-router-dom';
import { AppRouteObject } from 'resources/types';

function RenderRoutes(routeObjects: AppRouteObject[]) {
  return (
    routeObjects.map((routeObject) => {
      const {
        path,
        index,
        element,
        children,
      } = routeObject;

      if (index === true) {
        return (
          <Route key={`${path + String(index)}`} index path={path} element={element}>
            {children && RenderRoutes(children)}
          </Route>
        );
      }

      return (
        <Route key={`${path + String(index)}`} index={false} path={path} element={element}>
          {children && RenderRoutes(children)}
        </Route>
      );
    })
  );
}

export default RenderRoutes;
