import { useMemo } from 'react';
import {
  Select, BaseSelectProps, MenuProps,
} from '@mui/material';

export interface SelectInputProps<T> extends BaseSelectProps<T> {
  width?: number;
  maxWidth?: number;
  menuItemsCount?: number;
}

const ITEM_HEIGHT: number = 42;
const ITEM_PADDING_TOP: number = 8;

function SelectInput<T>(props: SelectInputProps<T>) {
  const {
    maxWidth,
    children,
    width = 250,
    menuItemsCount = 5,
    ...selectProps
  } = props;

  const menuProps = useMemo<Partial<MenuProps>>(
    () => ({
      slotProps: {
        paper: {
          style: {
            width,
            maxWidth,
            maxHeight: ITEM_HEIGHT * menuItemsCount + ITEM_PADDING_TOP,
          },
        },
      },
    }),
    [width, maxWidth, menuItemsCount],
  );

  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <Select
      {...selectProps}
      MenuProps={{ ...selectProps.MenuProps, ...menuProps }}
    >
      {children}
    </Select>
  );
}

SelectInput.defaultProps = {
  width: undefined,
  maxWidth: undefined,
  menuItemsCount: undefined,
};

export default SelectInput;
