import { AxiosResponse } from 'axios';

import {
  ListQueries,
  ListResponse,
  RetailerWarehouse,
  RetailerWarehouseBody,
} from 'resources/types';
import instance from 'services/instance';
import { endpoints } from 'resources/constants';
import { warehouseManagementEvent } from 'resources/constants/mixpanel';

const getRetailerWarehouses = (
  companyId: number,
  params?: ListQueries,
): Promise<AxiosResponse<ListResponse<RetailerWarehouse>>> => (
  instance.get(
    endpoints.RETAILER_WAREHOUSES(companyId),
    {
      params,
      failureMessage: 'Could not get retailer warehouses',
    },
  )
);

const deleteRetailerWarehouse = (
  companyId: number,
  warehouseId: number,
): Promise<AxiosResponse<{ value: boolean }>> => (
  instance.delete(
    endpoints.RETAILER_WAREHOUSE(companyId, warehouseId),
    {
      failureMessage: 'Could not delete retailer warehouse',
      mixpanelEvent: {
        EventName: warehouseManagementEvent.deleteWarehouse,
      },
    },
  )
);

const updateRetailerWarehouse = (
  companyId: number,
  warehouseId: number,
  data: RetailerWarehouseBody,
): Promise<AxiosResponse<RetailerWarehouse>> => (
  instance.put(
    endpoints.RETAILER_WAREHOUSE(companyId, warehouseId),
    data,
    {
      successMessage: 'The warehouse info updated successfully',
      failureMessage: 'Could not update retailer warehouse',
      mixpanelEvent: {
        EventName: warehouseManagementEvent.updateWarehouse,
      },
    },
  )
);

const createRetailerWarehouse = (
  companyId: number,
  data: RetailerWarehouseBody,
): Promise<AxiosResponse<RetailerWarehouse>> => (
  instance.post(
    endpoints.RETAILER_WAREHOUSES(companyId),
    data,
    {
      successMessage: 'The warehouse created successfully',
      failureMessage: 'Could not create warehouse',
      mixpanelEvent: {
        EventName: warehouseManagementEvent.createWarehouse,
      },
    },
  )
);

export default {
  getRetailerWarehouses,
  createRetailerWarehouse,
  updateRetailerWarehouse,
  deleteRetailerWarehouse,
};
