import { useCallback, useRef, MutableRefObject } from 'react';

function useDebounce(callBack: Function, delay: number = 1000) {
  const timer: MutableRefObject<any> = useRef();

  const debouncedCallback = useCallback(
    (...args: Array<any>) => {
      if (timer.current) {
        clearTimeout(timer.current);
      }

      timer.current = setTimeout(
        () => { callBack(...args); },
        delay,
      );
    },
    [callBack, delay],
  );

  return debouncedCallback;
}

export { useDebounce };
