import {
  RetailerRoundingRule,
  RetailerRoundingRuleErrors,
  RetailerRoundingRuleNumbers,
} from 'resources/types';
import { showMessage } from 'store';

const toRangesOverLap = (
  firstRange: [number, number],
  secondRange: [number, number],
): boolean => (
  (firstRange[0] >= secondRange[0] && firstRange[0] <= secondRange[1])
  || (firstRange[1] >= secondRange[0] && firstRange[1] <= secondRange[1])
  || (secondRange[0] >= firstRange[0] && secondRange[0] <= firstRange[1])
  || (secondRange[1] >= firstRange[0] && secondRange[1] <= firstRange[1])
);

const convertRoundingRuleFieldToNumber = (stringValue: string | null): number | null => {
  const numericValue = Number(stringValue);
  if (!stringValue || Number.isNaN(numericValue)) return null;
  const firstChar = stringValue.charAt(0);
  if (firstChar === '0') return numericValue;
  return numericValue < 10 ? 10 * numericValue : numericValue;
};

const convertRoundingRulesFieldToNumber = (
  roundingRule: RetailerRoundingRule,
): RetailerRoundingRuleNumbers => ({
  ...roundingRule,
  roundingTo: convertRoundingRuleFieldToNumber(roundingRule.roundingTo),
  roundingValue: convertRoundingRuleFieldToNumber(roundingRule.roundingValue),
  roundingFrom: convertRoundingRuleFieldToNumber(
    roundingRule.roundingFrom || roundingRule.roundingTo,
  ),
});

const updateRoundingRuleFieldForAPI = (roundingRuleField: string | null) => {
  if (!roundingRuleField) return null;
  return roundingRuleField.length > 1 ? roundingRuleField : `${roundingRuleField}0`;
};

export const updateRoundingRuleForAPI = (
  roundingRule: RetailerRoundingRule,
): RetailerRoundingRule => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { touched, ...rest } = roundingRule;
  return {
    ...rest,
    roundingTo: updateRoundingRuleFieldForAPI(roundingRule.roundingTo),
    roundingValue: updateRoundingRuleFieldForAPI(roundingRule.roundingValue),
    roundingFrom: updateRoundingRuleFieldForAPI(
      roundingRule.roundingFrom || roundingRule.roundingTo,
    ),
  };
};

export const validateRoundingRules = (roundingRules: Array<RetailerRoundingRule>): {
  passed: boolean;
  roundingRulesErrors: Array<RetailerRoundingRuleErrors>;
} => {
  const updatedRules = roundingRules.map(
    (roundingRule) => convertRoundingRulesFieldToNumber(roundingRule),
  );

  const roundingRulesErrors = updatedRules.map(
    (updatedRuled) => {
      const { roundingFrom, roundingTo, selected } = updatedRuled;
      const toLessFrom: boolean = (
        selected
        && roundingFrom != null
        && roundingTo != null
        && (roundingTo < roundingFrom)
      );
      const rangesOverlap: boolean = updatedRules.some(
        (rule) => (
          selected
          && rule.selected
          && roundingTo != null
          && roundingFrom != null
          && rule.roundingTo != null
          && rule.roundingFrom != null
          && rule.id !== updatedRuled.id
          && toRangesOverLap(
            [roundingFrom, roundingTo],
            [rule.roundingFrom, rule.roundingTo],
          )
        ),
      );
      return { toLessFrom, rangesOverlap };
    },
  );

  const toLessFrom = roundingRulesErrors.some((error) => error.toLessFrom);
  const rangesOverlap = roundingRulesErrors.some((error) => error.rangesOverlap);

  if (toLessFrom) {
    showMessage({
      messages: ['To must always be greater or equal than From'],
      options: {
        variant: 'error',
      },
    });
  }

  if (rangesOverlap) {
    showMessage({
      messages: ['From and To ranges for multiple rounding rules must not overlap'],
      options: {
        variant: 'error',
      },
    });
  }

  if (toLessFrom || rangesOverlap) {
    showMessage({
      messages: ['All conflicts must be fixed for saving the rule/s'],
      options: {
        variant: 'error',
      },
    });
  }

  return { roundingRulesErrors, passed: !toLessFrom && !rangesOverlap };
};
