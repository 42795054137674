import { useState, MouseEvent, useCallback } from 'react';
import {
  Grid,
  Menu,
  Zoom,
  Theme,
  Avatar,
  MenuItem,
  Typography,
  ListItemIcon,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import { trackEvent } from 'mixpanel';
import services from 'services';
import { RootState } from 'store';
import { useAppSelector } from 'hooks';
import { generateFullName } from 'utils';
import { routsPatterns, userScopeProfileRoutes } from 'resources/constants';
import { profileManagementEvent } from 'resources/constants/mixpanel';

function ActionBar() {
  const navigate = useNavigate();

  const userData = useAppSelector((state: RootState) => state.user);
  const { profile, profilePhotoURL, userScope } = userData;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const signOut = useCallback(
    () => {
      trackEvent({
        EventName: profileManagementEvent.logoutUser,
        Value: profile?.email,
      });
      services.signOut();
      navigate(routsPatterns.SIGN_IN);
    },
    [navigate, profile?.email],
  );

  const openMenu = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Zoom in timeout={600}>
        <Grid
          container
          justifyContent="end"
          sx={{ cursor: 'pointer' }}
        >
          <Grid item mr={2}>
            <Typography
              textAlign="end"
              sx={(theme: Theme) => ({ color: theme.palette.template.contrastText })}
            >
              {generateFullName(profile?.firstName, profile?.lastName)}
            </Typography>
            <Typography
              textAlign="end"
              variant="subtitle2"
              sx={(theme: Theme) => ({ color: theme.palette.template.contrastText })}
            >
              {profile?.email}
            </Typography>
          </Grid>
          <Avatar
            id="header-profile-menu-button"
            onClick={openMenu}
            alt={profile?.firstName}
            src={profilePhotoURL}
          />
        </Grid>
      </Zoom>

      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={closeMenu}
        onClick={closeMenu}
        MenuListProps={{
          sx: (theme: Theme) => ({
            width: 180,
            overflow: 'hidden',
            color: theme.palette.template.contrastText,
            backgroundColor: theme.palette.template.main,
          }),
        }}
        slotProps={{
          paper: { sx: { borderRadius: 2 } },
        }}
        sx={{ mt: 1.8, ml: 1 }}
      >
        <MenuItem sx={{ p: 0 }}>
          <Link
            to={userScope ? userScopeProfileRoutes[userScope] : '/'}
            style={{
              width: '100%',
              height: '100%',
              color: 'unset',
              display: 'flex',
              paddingLeft: 16,
              alignItems: 'center',
              textDecoration: 'none',
            }}
          >
            <ListItemIcon>
              <AccountCircleIcon
                sx={(theme: Theme) => ({ color: theme.palette.template.contrastText })}
                fontSize="small"
              />
            </ListItemIcon>
            <Typography sx={(theme: Theme) => ({ color: theme.palette.template.contrastText })}>
              Account
            </Typography>
          </Link>
        </MenuItem>
        <MenuItem sx={{ p: 0 }} onClick={signOut}>
          <ListItemIcon sx={{ ml: 2 }}>
            <LogoutIcon
              sx={(theme: Theme) => ({ color: theme.palette.template.contrastText })}
              fontSize="small"
            />
          </ListItemIcon>
          <Typography
            display="inline"
            sx={(theme: Theme) => ({ color: theme.palette.template.contrastText })}
          >
            Logout
          </Typography>
        </MenuItem>
      </Menu>
    </>
  );
}

export default ActionBar;
