import { AxiosResponse } from 'axios';

import {
  RetailerAd,
  ListQueries,
  RetailerSandboxItemType,
  RetailerBasePriceSandbox,
  RetailerBasePriceSandboxItem,
  RetailerBasePriceSandboxOffer,
  RetailerBasePriceSandboxStatus,
  RetailerItemSalesHistoryResponse,
  RetailerBasePriceSandboxCategory,
  RetailerBasePriceSandboxTabStatus,
  RetailerItemPricesHistoryResponse,
  RetailerBasePriceSandboxDepartment,
  RetailerBasePriceSandboxExcludedItem,
  RetailerBasePriceSandboxOutOfPlanningItem,
  RetailerBasePriceSandboxCategoryOrderBody,
  RetailerBasePriceSandboxDepartmentOrderBody,
  RetailerBasePriceSandboxOffersUploadingBody,
  RetailerBasePriceSandboxNewPricesRequestBody,
  RetailerBasePriceSandboxDeleteItemsRequestBody,
  RetailerBasePriceSandboxOffersUploadingResponse,
} from 'resources/types';
import { downloadAdWeek } from 'utils';
import instance from 'services/instance';
import { endpoints } from 'resources/constants';
import { basePriceMangerEvent } from 'resources/constants/mixpanel';

const getRetailerBasePriceSandbox = (
  companyId: number,
  adId: number,
  params?: ListQueries,
): Promise<AxiosResponse<RetailerBasePriceSandbox>> => (
  instance.get(
    endpoints.RETAILER_BASE_PRICE_SANDBOX(companyId, adId),
    {
      params,
      failureMessage: 'Could not get retailer price change sandbox',
    },
  )
);

const uploadRetailerBasePriceSandboxOffers = (
  companyId: number,
  adId: number,
  data: RetailerBasePriceSandboxOffersUploadingBody,
): Promise<AxiosResponse<RetailerBasePriceSandboxOffersUploadingResponse>> => (
  instance.post(
    endpoints.RETAILER_BASE_PRICE_SANDBOX(companyId, adId),
    data,
    {
      failureMessage: 'Could not upload retailer price change sandbox offers',
      mixpanelEvent: {
        EventName: basePriceMangerEvent.addBPMOffer,
        Value: adId,
      },
    },
  )
);

const changeRetailerBasePriceSandboxItemStatus = (
  companyId: number,
  adId: number,
  itemType: RetailerSandboxItemType,
  itemId: number,
  data: { value: boolean },
): Promise<AxiosResponse<RetailerBasePriceSandboxOffer>> => (
  instance.put(
    endpoints.RETAILER_BASE_PRICE_SANDBOX_ITEM_STATUS(companyId, adId, itemType, itemId),
    data,
    {
      successMessage: 'The sandbox item status has been updated',
      failureMessage: 'Could not update sandbox item status',
      mixpanelEvent: {
        EventName: basePriceMangerEvent.changeRowStatus,
        Field: itemType,
        Value: itemId,
      },
    },
  )
);

const removeRetailerBasePriceSandboxItem = (
  companyId: number,
  adId: number,
  itemId: number,
): Promise<AxiosResponse<RetailerBasePriceSandbox>> => (
  instance.delete(
    endpoints.RETAILER_BASE_PRICE_SANDBOX_ITEM(companyId, adId, itemId),
    {
      successMessage: 'The item has been removed.',
      failureMessage: 'Something went wrong, could not remove item',
      mixpanelEvent: {
        EventName: basePriceMangerEvent.removeBPMRow,
        Field: 'item',
        Value: itemId,
      },
    },
  )
);

const removeRetailerBasePriceSandboxOffer = (
  companyId: number,
  adId: number,
  offerId: number,
): Promise<AxiosResponse<RetailerBasePriceSandbox>> => (
  instance.delete(
    endpoints.RETAILER_BASE_PRICE_SANDBOX_OFFER(companyId, adId, offerId),
    {
      successMessage: 'The offer has been removed.',
      failureMessage: 'Something went wrong, could not remove offer',
      mixpanelEvent: {
        EventName: basePriceMangerEvent.removeBPMRow,
        Field: 'offer',
        Value: offerId,
      },
    },
  )
);

const duplicateRetailerBasePriceSandboxItem = (
  companyId: number,
  adId: number,
  itemId: number,
): Promise<AxiosResponse<RetailerBasePriceSandboxOffer>> => (
  instance.put(
    endpoints.RETAILER_BASE_PRICE_SANDBOX_ITEM_DUPLICATE(companyId, adId, itemId),
    undefined,
    {
      successMessage: 'The item has been duplicated.',
      failureMessage: 'Something went wrong, could not duplicate item',
      mixpanelEvent: {
        EventName: basePriceMangerEvent.duplicateBPMItem,
        Value: itemId,
      },
    },
  )
);

const duplicateRetailerBasePriceSandboxOffer = (
  companyId: number,
  adId: number,
  offerId: number,
): Promise<AxiosResponse<RetailerBasePriceSandboxOffer>> => (
  instance.put(
    endpoints.RETAILER_BASE_PRICE_SANDBOX_OFFER_DUPLICATE(companyId, adId, offerId),
    undefined,
    {
      successMessage: 'The offer has been duplicated.',
      failureMessage: 'Something went wrong, could not duplicate offer',
      mixpanelEvent: {
        EventName: basePriceMangerEvent.duplicateBPMOffer,
        Value: offerId,
      },
    },
  )
);

const updateRetailerBasePriceSandboxItem = (
  companyId: number,
  adId: number,
  itemId: number,
  data: RetailerBasePriceSandboxItem,
): Promise<AxiosResponse<RetailerBasePriceSandboxOffer>> => (
  instance.put(
    endpoints.RETAILER_BASE_PRICE_SANDBOX_ITEM(companyId, adId, itemId),
    data,
    {
      successMessage: 'The item has been updated',
      failureMessage: 'Something went wrong, could not add update Sandbox item',
    },
  )
);

const updateRetailerBasePriceSandboxOffer = (
  companyId: number,
  adId: number,
  offerId: number,
  data: RetailerBasePriceSandboxOffer,
  name: string,
): Promise<AxiosResponse<RetailerBasePriceSandboxOffer>> => (
  instance.put(
    endpoints.RETAILER_BASE_PRICE_SANDBOX_OFFER(companyId, adId, offerId),
    data,
    {
      successMessage: 'Sandbox items successfully updated',
      failureMessage: 'Could not update sandbox offer',
      mixpanelEvent: {
        EventName: basePriceMangerEvent.changeNewPrice,
        Field: name,
        Value: data[name as keyof RetailerBasePriceSandboxOffer],
      },
    },
  )
);

const updateRetailerBasePriceSandboxOfferNames = (
  companyId: number,
  adId: number,
  offerId: number,
  data: RetailerBasePriceSandboxOffer,
): Promise<AxiosResponse<RetailerBasePriceSandboxOffer>> => (
  instance.put(
    endpoints.RETAILER_BASE_PRICE_SANDBOX_OFFER_NAMES(companyId, adId, offerId),
    data,
    {
      successMessage: 'Sandbox offer successfully updated',
      failureMessage: 'Could not update sandbox offer',
    },
  )
);

const updateRetailerBasePriceSandboxOffers = (
  companyId: number,
  adId: number,
  data: RetailerBasePriceSandboxNewPricesRequestBody,
): Promise<AxiosResponse<RetailerBasePriceSandbox>> => (
  instance.put(
    endpoints.RETAILER_BASE_PRICE_SANDBOX_OFFERS(companyId, adId),
    data,
    {
      successMessage: 'Base Price Manager items successfully updated',
      failureMessage: 'Could not duplicate Base Price Manager offers',
    },
  )
);

const updateRetailerBasePriceSandboxDepartment = (
  companyId: number,
  adId: number,
  departmentId: number,
  data: { [key: string]: any },
): Promise<AxiosResponse<RetailerBasePriceSandboxDepartment>> => (
  instance.put(
    endpoints.RETAILER_BASE_PRICE_SANDBOX_DEPARTMENT(companyId, adId, departmentId),
    data,
    {
      successMessage: 'The department has been updated',
      failureMessage: 'Something went wrong, could not add update Sandbox department',
    },
  )
);

const updateRetailerBasePriceSandboxCategory = (
  companyId: number,
  adId: number,
  categoryId: number,
  data: { [key: string]: any },
): Promise<AxiosResponse<RetailerBasePriceSandboxCategory>> => (
  instance.put(
    endpoints.RETAILER_BASE_PRICE_SANDBOX_CATEGORY(companyId, adId, categoryId),
    data,
    {
      successMessage: 'The category has been updated',
      failureMessage: 'Something went wrong, could not add update Sandbox category',
    },
  )
);

const getRetailerBasePriceSandboxStatus = (
  companyId: number,
  adId: number,
): Promise<AxiosResponse<{ value: RetailerBasePriceSandboxStatus }>> => (
  instance.get(
    endpoints.RETAILER_BASE_PRICE_SANDBOX_STATUS(companyId, adId),
  )
);

const getRetailerBasePriceSandboxTabStatus = (
  companyId: number,
  adId: number,
): Promise<AxiosResponse<{ value: RetailerBasePriceSandboxTabStatus }>> => (
  instance.get(
    endpoints.RETAILER_BASE_PRICE_SANDBOX_TAB_STATUS(companyId, adId),
  )
);

const fillDownRetailerBasePriceSandboxItemsNewUnitCost = (
  companyId: number,
  adId: number,
  itemId: number,
): Promise<AxiosResponse<RetailerBasePriceSandboxOffer>> => (
  instance.put(
    endpoints.RETAILER_BASE_PRICE_SANDBOX_ITEM_NEW_UNIT_COST(companyId, adId, itemId),
    undefined,
    {
      successMessage: 'The items have been updated.',
      failureMessage: 'Something went wrong, could not add update Sandbox items',
      mixpanelEvent: {
        EventName: basePriceMangerEvent.newUnitCostFillDown,
        Value: itemId,
      },
    },
  )
);

const calculateRetailerBasePriceSandbox = (
  companyId: number,
  adId: number,
): Promise<AxiosResponse<RetailerBasePriceSandbox>> => (
  instance.put(
    endpoints.RETAILER_BASE_PRICE_SANDBOX_CALCULATE(companyId, adId),
    undefined,
    {
      successMessage: 'Base Price Manager have been calculated',
      failureMessage: 'Something went wrong, could not calculate Base Price Manager',
      mixpanelEvent: {
        EventName: basePriceMangerEvent.calculateBPM,
        Value: adId,
      },
    },
  )
);

const getRetailerBasePriceSandboxExcludedItems = (
  companyId: number,
  adId: number,
): Promise<AxiosResponse<Array<RetailerBasePriceSandboxExcludedItem>>> => (
  instance.get(
    endpoints.RETAILER_BASE_PRICE_SANDBOX_EXCLUDED_ITEMS(companyId, adId),
  )
);

const removeRetailerBasePriceSandboxExcludedItems = (
  companyId: number,
  adId: number,
): Promise<AxiosResponse<{ value: boolean }>> => (
  instance.delete(
    endpoints.RETAILER_BASE_PRICE_SANDBOX_EXCLUDED_ITEMS(companyId, adId),
  )
);

const getRetailerBasePriceSandboxOutOfPlanningItems = (
  companyId: number,
  adId: number,
): Promise<AxiosResponse<Array<RetailerBasePriceSandboxOutOfPlanningItem>>> => (
  instance.get(
    endpoints.RETAILER_BASE_PRICE_SANDBOX_OUT_OF_PLANNING_ITEMS(companyId, adId),
  )
);

const removeRetailerBasePriceSandboxOutOfPlanningItems = (
  companyId: number,
  adId: number,
): Promise<AxiosResponse<Array<RetailerBasePriceSandboxOutOfPlanningItem>>> => (
  instance.delete(
    endpoints.RETAILER_BASE_PRICE_SANDBOX_OUT_OF_PLANNING_ITEMS(companyId, adId),
  )
);

const removeRetailerBasePriceSandboxAllItems = (
  companyId: number,
  adId: number,
): Promise<AxiosResponse<RetailerBasePriceSandbox>> => (
  instance.delete(
    endpoints.RETAILER_BASE_PRICE_SANDBOX(companyId, adId),
    {
      successMessage: 'The items has been removed.',
      failureMessage: 'Something went wrong, could not remove items',
      mixpanelEvent: {
        EventName: basePriceMangerEvent.removeMultipleItems,
      },
    },
  )
);

const removeRetailerBasePriceSandboxSelectedItems = (
  companyId: number,
  adId: number,
  params?: RetailerBasePriceSandboxDeleteItemsRequestBody,
): Promise<AxiosResponse<RetailerBasePriceSandbox>> => (
  instance.delete(
    endpoints.RETAILER_BASE_PRICE_SANDBOX_BY_CHECKED(companyId, adId),
    {
      params,
      successMessage: 'The items has been removed.',
      failureMessage: 'Something went wrong, could not remove items',
    },
  )
);

const exportRetailerBasePriceSandbox = (
  companyId: number,
  adWeek: RetailerAd,
): Promise<unknown> => (
  instance.get(
    endpoints.RETAILER_BASE_PRICE_EXPORT(companyId, adWeek.id),
    { responseType: 'blob', failureMessage: 'Could not download price change file' },
  )
    .then((response: AxiosResponse) => {
      const { data, headers } = response;
      const contentDisposition: string = headers['content-disposition'];
      return downloadAdWeek(data, adWeek, contentDisposition);
    })
);

const getRetailerBasePriceSandboxItemSalesHistory = (
  companyId: number,
  adId: number,
  itemType: RetailerSandboxItemType,
  itemId: number,
): Promise<AxiosResponse<RetailerItemSalesHistoryResponse>> => (
  instance.get(
    endpoints.RETAILER_BASE_PRICE_SANDBOX_ITEM_SALES_HISTORY(companyId, adId, itemType, itemId),
    {
      failureMessage: 'Could not get sales historical data',
    },
  )
);

const getRetailerBasePriceSandboxItemPricesHistory = (
  companyId: number,
  adId: number,
  itemType: RetailerSandboxItemType,
  itemId: number,
): Promise<AxiosResponse<RetailerItemPricesHistoryResponse>> => (
  instance.get(
    endpoints.RETAILER_BASE_PRICE_SANDBOX_ITEM_PRICES_HISTORY(companyId, adId, itemType, itemId),
    {
      failureMessage: 'Could not get prices historical data',
    },
  )
);

const updateRetailerBasePriceSandboxDepartmentLockStatus = (
  companyId: number,
  adId: number,
  departmentId: number,
  data: { value: boolean },
): Promise<AxiosResponse<RetailerBasePriceSandboxDepartment>> => (
  instance.put(
    endpoints.RETAILER_BASE_PRICE_SANDBOX_DEPARTMENT_LOCK(companyId, adId, departmentId),
    data,
    {
      successMessage: 'The department status has been updated successfully',
      failureMessage: 'Could not update the department status',
    },
  )
);

const roundRetailerBasePriceSandboxDepartment = (
  companyId: number,
  adId: number,
  departmentId: number,
): Promise<AxiosResponse<RetailerBasePriceSandboxDepartment>> => (
  instance.put(
    endpoints.RETAILER_BASE_PRICE_SANDBOX_DEPARTMENT_ROUND(companyId, adId, departmentId),
    undefined,
    {
      successMessage: 'Updated successfully',
      failureMessage: 'Could not round department values',
    },
  )
);

const roundRetailerBasePriceSandboxCategory = (
  companyId: number,
  adId: number,
  categoryId: number,
): Promise<AxiosResponse<RetailerBasePriceSandboxCategory>> => (
  instance.put(
    endpoints.RETAILER_BASE_PRICE_SANDBOX_CATEGORY_ROUND(companyId, adId, categoryId),
    undefined,
    {
      successMessage: 'Updated successfully',
      failureMessage: 'Could not round category values',
    },
  )
);

const updateRetailerBasePriceSandboxCategoryLockStatus = (
  companyId: number,
  adId: number,
  categoryId: number,
  data: { value: boolean },
): Promise<AxiosResponse<RetailerBasePriceSandboxDepartment>> => (
  instance.put(
    endpoints.RETAILER_BASE_PRICE_SANDBOX_CATEGORY_LOCK(companyId, adId, categoryId),
    data,
    {
      successMessage: 'The category status has been updated successfully',
      failureMessage: 'Could not update the category status',
    },
  )
);

const updateRetailerBasePriceSandboxOfferLockStatus = (
  companyId: number,
  adId: number,
  offerId: number,
  data: { value: boolean },
): Promise<AxiosResponse<RetailerBasePriceSandboxDepartment>> => (
  instance.put(
    endpoints.RETAILER_BASE_PRICE_SANDBOX_OFFER_LOCK(companyId, adId, offerId),
    data,
    {
      successMessage: 'The offer status has been updated successfully',
      failureMessage: 'Could not update the offer status',
    },
  )
);

const updateRetailerBasePriceSandboxItemLockStatus = (
  companyId: number,
  adId: number,
  itemId: number,
  data: { value: boolean },
): Promise<AxiosResponse<RetailerBasePriceSandboxDepartment>> => (
  instance.put(
    endpoints.RETAILER_BASE_PRICE_SANDBOX_ITEM_LOCK(companyId, adId, itemId),
    data,
    {
      successMessage: 'The item status has been updated successfully',
      failureMessage: 'Could not update the item status',
    },
  )
);

const roundRetailerBasePriceSandbox = (
  companyId: number,
  adId: number,
): Promise<AxiosResponse<RetailerBasePriceSandbox>> => (
  instance.put(
    endpoints.RETAILER_BASE_PRICE_SANDBOX_ROUND(companyId, adId),
    undefined,
    {
      successMessage: 'The sandbox values has been updated successfully',
      failureMessage: 'Could not update the sandbox values',
    },
  )
);

const updateRetailerBasePriceSandboxProjectedAdVolumes = (
  companyId: number,
  adId: number,
): Promise<AxiosResponse<RetailerBasePriceSandbox>> => (
  instance.put(
    endpoints.RETAILER_BASE_PRICE_SANDBOX_UPDATE_PROJECTED_AD_VOLUMES(companyId, adId),
    undefined,
    {
      successMessage: 'The AI volumes successfully updated.',
      failureMessage: 'Something went wrong, could not update AI volumes',
    },
  )
);

const changeRetailerBasePriceSandboxDepartmentOrder = (
  companyId: number,
  adId: number,
  data: RetailerBasePriceSandboxDepartmentOrderBody,
): Promise<void> => instance.put(
  endpoints.RETAILER_BASE_PRICE_SANDBOX_DEPARTMENT_CHANGE_ORDER(companyId, adId),
  data,
  {
    successMessage: 'The department order has been updated',
    failureMessage: 'Could not update the department order',
  },
);

const changeRetailerBasePriceSandboxCategoryOrder = (
  companyId: number,
  adId: number,
  data: RetailerBasePriceSandboxCategoryOrderBody,
): Promise<void> => instance.put(
  endpoints.RETAILER_BASE_PRICE_SANDBOX_CATEGORY_CHANGE_ORDER(companyId, adId),
  data,
  {
    successMessage: 'The category order has been updated',
    failureMessage: 'Could not update the category order',
  },
);

export default {
  getRetailerBasePriceSandbox,
  roundRetailerBasePriceSandbox,
  exportRetailerBasePriceSandbox,
  calculateRetailerBasePriceSandbox,
  getRetailerBasePriceSandboxStatus,
  removeRetailerBasePriceSandboxItem,
  updateRetailerBasePriceSandboxItem,
  updateRetailerBasePriceSandboxOffer,
  removeRetailerBasePriceSandboxOffer,
  getRetailerBasePriceSandboxTabStatus,
  uploadRetailerBasePriceSandboxOffers,
  updateRetailerBasePriceSandboxOffers,
  roundRetailerBasePriceSandboxCategory,
  duplicateRetailerBasePriceSandboxItem,
  duplicateRetailerBasePriceSandboxOffer,
  removeRetailerBasePriceSandboxAllItems,
  updateRetailerBasePriceSandboxCategory,
  roundRetailerBasePriceSandboxDepartment,
  changeRetailerBasePriceSandboxItemStatus,
  getRetailerBasePriceSandboxExcludedItems,
  updateRetailerBasePriceSandboxDepartment,
  updateRetailerBasePriceSandboxOfferNames,
  removeRetailerBasePriceSandboxSelectedItems,
  changeRetailerBasePriceSandboxCategoryOrder,
  removeRetailerBasePriceSandboxExcludedItems,
  getRetailerBasePriceSandboxItemSalesHistory,
  updateRetailerBasePriceSandboxItemLockStatus,
  getRetailerBasePriceSandboxItemPricesHistory,
  getRetailerBasePriceSandboxOutOfPlanningItems,
  changeRetailerBasePriceSandboxDepartmentOrder,
  updateRetailerBasePriceSandboxOfferLockStatus,
  removeRetailerBasePriceSandboxOutOfPlanningItems,
  fillDownRetailerBasePriceSandboxItemsNewUnitCost,
  updateRetailerBasePriceSandboxCategoryLockStatus,
  updateRetailerBasePriceSandboxProjectedAdVolumes,
  updateRetailerBasePriceSandboxDepartmentLockStatus,
};
