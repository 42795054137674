import { AxiosResponse } from 'axios';

import {
  ListQueries,
  ListResponse,
  RetailerEmployee,
  ResetPasswordData,
  RetailerEmployeeBody,
} from 'resources/types';
import instance from 'services/instance';
import { endpoints } from 'resources/constants';
import { employManagementEvent } from 'resources/constants/mixpanel';

const getRetailerEmployees = (
  params?: ListQueries,
): Promise<AxiosResponse<ListResponse<RetailerEmployee>>> => (
  instance.get(
    endpoints.RETAILER_EMPLOYEES,
    {
      params,
      failureMessage: 'Could not get retailer employees',
    },
  )
);

const updateRetailerEmployee = (
  employeeId: number,
  data: RetailerEmployeeBody,
): Promise<AxiosResponse<RetailerEmployee>> => (
  instance.put(
    endpoints.RETAILER_EMPLOYEE(employeeId),
    data,
    {
      successMessage: 'The employees info updated successfully',
      failureMessage: 'Could not update employee info',
      mixpanelEvent: {
        EventName: employManagementEvent.updateEmploy,
      },
    },
  )
);

const createRetailerEmployee = (
  data: RetailerEmployeeBody,
): Promise<AxiosResponse<RetailerEmployee>> => (
  instance.post(
    endpoints.RETAILER_EMPLOYEES,
    data,
    {
      successMessage: 'The employee created successfully',
      failureMessage: 'Could not create an employee',
      mixpanelEvent: {
        EventName: employManagementEvent.addNewEmploy,
      },
    },
  )
);

const updateRetailerEmployeeStatus = (
  employeeId: number,
  data: { locked: boolean },
): Promise<AxiosResponse> => (
  instance.put(
    endpoints.RETAILER_EMPLOYEE_STATUS(employeeId),
    data,
    {
      successMessage: 'The employees status updated successfully',
      failureMessage: 'Could not update employee status',
      mixpanelEvent: {
        EventName: employManagementEvent.changeStatus,
      },
    },
  )
);

const resendRetailerEmployeeActivation = (
  employeeId: number,
): Promise<AxiosResponse<{ value: boolean }>> => (
  instance.put(
    endpoints.RETAILER_EMPLOYEE_RESEND_ACTIVATION(employeeId),
    null,
    {
      successMessage: 'The activation link has sent successfully',
      failureMessage: 'Could not send the activation link',
    },
  )
);

const deleteRetailerEmployee = (
  employeeId: number,
): Promise<AxiosResponse<{ value: boolean }>> => (
  instance.delete(
    endpoints.RETAILER_EMPLOYEE(employeeId),
    {
      successMessage: 'The employee\'s data has been deleted successfully',
      failureMessage: 'Could not delete employee data',
      mixpanelEvent: {
        EventName: employManagementEvent.deleteEmploy,
      },
    },
  )
);

const makeOwnerRetailerEmployee = (
  employeeId: number,
): Promise<AxiosResponse<{ value: boolean }>> => (
  instance.put(
    endpoints.RETAILER_EMPLOYEE_MAKE_OWNER(employeeId),
    {
      successMessage: 'Make Owner successful',
      failureMessage: 'Could not make owner employee',
    },
  )
);

const getRetailerEmployeeById = (employeeId: number): Promise<AxiosResponse> => (
  instance.get(
    endpoints.RETAILER_EMPLOYEE(employeeId),
    {
      failureMessage: 'Could not get the employee info',
    },
  )
);

const resetRetailerEmployeePassword = (
  params: ResetPasswordData,
): Promise<AxiosResponse> => (
  instance.get(
    endpoints.USER_PASSWORD_RESET,
    {
      params,
      successMessage: 'We have sent you an email with a link to reset your password',
      failureMessage: 'Something went wrong, could not reset password',
      mixpanelEvent: {
        EventName: employManagementEvent.resetPassword,
      },
    },
  )
);

export default {
  getRetailerEmployees,
  updateRetailerEmployee,
  createRetailerEmployee,
  deleteRetailerEmployee,
  getRetailerEmployeeById,
  makeOwnerRetailerEmployee,
  updateRetailerEmployeeStatus,
  resetRetailerEmployeePassword,
  resendRetailerEmployeeActivation,
};
