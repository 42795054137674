import { RetailerSandboxItemType } from 'resources/types';

export const defaultHeaders: { [key: string]: string } = {
  REFRESH_TOKEN: 'REFRESH-TOKEN',
  X_AUTH_TOKEN: 'X-AUTH-TOKEN',
};

export const REQUEST_TIMEOUT: number = 300000;

export const endpoints = {
  ROLES: '/roles',
  US_STATES: '/data/usStates',
  TIME_ZONES: '/data/timeZones',
  USER_PROFILE: '/user/profile',
  USER_PROFILE_AVATAR: '/user/profile/avatar',
  USER_PROFILE_CHANGE_PASSWORD: '/user/profile/changePassword',
  SIGN_IN: '/user/authentication/login',
  REFRESH_TOKEN: '/user/authentication/refreshToken',
  USER_PASSWORD_RESET: '/user/authentication/password/change',
  USER_CHECK_AUTH_KEY: (email: string, key: string) => `/user/authentication/password/check/${encodeURIComponent(email)}/${key}`,
  USER_CHANGE_PASSWORD_WITH_KEY: (email: string, key: string) => `/user/authentication/password/change/${encodeURIComponent(email)}/${key}`,

  // Admin
  ADMIN_RETAILERS: '/admin/retailerAdmins',
  ADMIN_RETAILER: (retailerId: number) => `/admin/retailerAdmins/${retailerId}`,
  ADMIN_RETAILER_SUSPEND_COMPANY: (retailerId: number) => `/admin/retailerAdmins/${retailerId}/suspendAdminCompany`,
  ADMIN_RETAILER_REACTIVATE_COMPANY: (retailerId: number) => `/admin/retailerAdmins/${retailerId}/reactivateAdminCompany`,

  // Retailer
  RETAILER_COMPANY: '/retailerOrgs/company',
  RETAILER_COMPANY_ID: (companyId: number) => `/retailerOrgs/${companyId}`,

  RETAILER_COMPANY_LOGO: (companyId: number) => `/retailerOrgs/${companyId}/logo`,
  RETAILER_COMPANY_COVER: (companyId: number) => `/retailerOrgs/${companyId}/cover`,
  RETAILER_COMPANY_IMAGE: (companyId: number) => `/retailerOrgs/${companyId}/image`,
  RETAILER_COMPANY_UPLOAD: (companyId: number) => `retailerOrgs/${companyId}/upload`,

  RETAILER_COMPANY_ANNUAL_MARGIN: (companyId: number) => `/retailerOrgs/${companyId}/annualMarginTargets/company`,
  RETAILER_DEPARTMENTS_ANNUAL_MARGINS: (companyId: number) => `/retailerOrgs/${companyId}/annualMarginTargets/list`,
  RETAILER_DEPARTMENT_ANNUAL_MARGIN: (companyId: number, departmentId: number) => `/retailerOrgs/${companyId}/annualMarginTargets/department/${departmentId}`,
  RETAILER_DEPARTMENTS_WEEKLY_MARGIN: (companyId: number, adId: number) => `/retailerOrgs/${companyId}/weekMargins/${adId}/department`,
  RETAILER_DEPARTMENT_WEEKLY_MARGIN: (companyId: number, adId: number, departmentId: number) => `/retailerOrgs/${companyId}/weekMargins/${adId}/department/${departmentId}`,
  RETAILER_DEPARTMENT_FILL_DOWN_ANNUAL_MARGINS: (companyId: number, departmentId: number) => `/retailerOrgs/${companyId}/annualMarginTargets/department/${departmentId}/categories`,
  RETAILER_DEPARTMENT_FILL_DOWN_WEEKLY_MARGINS: (companyId: number, adId: number, departmentId: number) => `/retailerOrgs/${companyId}/weekMargins/${adId}/department/${departmentId}/categories`,
  RETAILER_DEPARTMENT_FILL_DOWN_ANNUAL_BUDGETED_SHRINKS: (companyId: number, departmentId: number) => `/retailerOrgs/${companyId}/annualMarginTargets/department/${departmentId}/shrinkVolumes`,
  RETAILER_DEPARTMENT_FILL_DOWN_ANNUAL_WEEKLY_SHRINKS: (companyId: number, adId: number, departmentId: number) => `/retailerOrgs/${companyId}/weekMargins/${adId}/department/${departmentId}/categories/shrinkVolume`,
  RETAILER_CATEGORY_ANNUAL_MARGIN: (companyId: number, categoryId: number) => `/retailerOrgs/${companyId}/annualMarginTargets/category/${categoryId}`,
  RETAILER_CATEGORIES_WEEKLY_MARGIN: (companyId: number, adId: number) => `/retailerOrgs/${companyId}/weekMargins/${adId}/category`,
  RETAILER_CATEGORY_WEEKLY_MARGIN: (companyId: number, adId: number, categoryId: number) => `/retailerOrgs/${companyId}/weekMargins/${adId}/category/${categoryId}`,
  RETAILER_AVAILABLE_FREEZE_WEEKS: (companyId: number, adId: number) => `/retailerOrgs/${companyId}/retailerAds/${adId}/availableFreezeWeeks`,
  RETAILER_DEPARTMENT_CHANGE_ORDER: (companyId: number) => `/retailerOrgs/${companyId}/retailerDepartments/order`,
  RETAILER_CATEGORY_CHANGE_ORDER: (companyId: number) => `/retailerOrgs/${companyId}/retailerCategories/order`,

  RETAILER_ADS: (companyId: number) => `/retailerOrgs/${companyId}/retailerAds`,
  RETAILER_AD: (companyId: number, adId: number) => `/retailerOrgs/${companyId}/retailerAds/${adId}`,
  RETAILER_ADS_AVAILABLE_YEARS: (companyId: number) => `/retailerOrgs/${companyId}/retailerAds/years`,
  RETAILER_ADS_LIFT_QUARTERS: (companyId: number) => `/retailerOrgs/${companyId}/retailerAds/liftQuarters`,
  RETAILER_ADS_CURRENT_AD_YEAR: (companyId: number) => `/retailerOrgs/${companyId}/retailerAds/currentYear`,
  RETAILER_AD_EXPORT: (companyId: number, adId: number) => `/retailerOrgs/${companyId}/sandbox/${adId}/download`,
  RETAILER_AD_CALCULATE_PROJECTED_SALES: (companyId: number, adId: number) => `/retailerOrgs/${companyId}/retailerAds/${adId}/calculate`,
  RETAILER_AD_CONFIRM_MARGINS: (companyId: number, adId: number) => `/retailerOrgs/${companyId}/retailerAds/${adId}/confirmMarginsSetup`,
  RETAILER_AD_UPDATE_REFERENCE_WEEK: (companyId: number, adId: number) => `/retailerOrgs/${companyId}/retailerAds/${adId}/updateFreezeWeek`,

  RETAILER_IMP_ITEMS: (companyId: number, adId: number) => `/retailerOrgs/${companyId}/retailerAds/${adId}/retailerImpRetailItems`,
  RETAILER_IMP_ITEMS_BY_UPC: (companyId: number, adId: number) => `/retailerOrgs/${companyId}/retailerAds/${adId}/retailerImpRetailItems/byUpc`,
  RETAILER_IMP_ITEMS_BY_LINK_CODES: (companyId: number, adId: number) => `/retailerOrgs/${companyId}/retailerAds/${adId}/retailerImpRetailItems/byLinkCode`,

  RETAILER_STORES: (companyId: number) => `/retailerOrgs/${companyId}/retailerStores`,
  RETAILER_STORE_GROUPS: (companyId: number) => `retailerOrgs/${companyId}/retailerStoreGroups`,
  RETAILER_STORE: (companyId: number, storeId: number) => `/retailerOrgs/${companyId}/retailerStores/${storeId}`,

  RETAILER_WAREHOUSES: (companyId: number) => `/retailerOrgs/${companyId}/retailerWarehouses`,
  RETAILER_WAREHOUSE: (companyId: number, warehouseId: number) => `/retailerOrgs/${companyId}/retailerWarehouses/${warehouseId}`,

  RETAILER_EMPLOYEES: '/retailerEmployees',
  RETAILER_EMPLOYEE: (employeeId: number) => `/retailerEmployees/${employeeId}`,
  RETAILER_EMPLOYEE_STATUS: (employeeId: number) => `/retailerEmployees/${employeeId}/changeStatus`,
  RETAILER_EMPLOYEE_MAKE_OWNER: (employeeId: number) => `/retailerEmployees/${employeeId}/makeOwner`,
  RETAILER_EMPLOYEE_RESEND_ACTIVATION: (employeeId: number) => `/retailerEmployees/${employeeId}/resendActivation`,

  RETAILER_ROUNDING_RULES_LIST: (companyId: number) => `/retailerOrgs/${companyId}/roundingRules/list`,
  RETAILER_ROUNDING_RULES_DEFAULT: (companyId: number) => `/retailerOrgs/${companyId}/roundingRules/defaultRule`,
  RETAILER_ROUNDING_RULE: (companyId: number, ruleId: number) => `/retailerOrgs/${companyId}/roundingRules/${ruleId}`,
  RETAILER_DEFAULT_ROUNDING_RULE_FILL_DOWN: (companyId: number) => `/retailerOrgs/${companyId}/roundingRules/defaultRule/rules`,
  RETAILER_ROUNDING_RULES_CATEGORY: (companyId: number, categoryId: number) => `/retailerOrgs/${companyId}/roundingRules/categories/${categoryId}`,
  RETAILER_CATEGORIES_ROUNDING_RULE: (companyId: number, categoryId: number) => `/retailerOrgs/${companyId}/roundingRules/categories/${categoryId}`,
  RETAILER_ROUNDING_RULES_DEPARTMENT: (companyId: number, departmentId: number) => `/retailerOrgs/${companyId}/roundingRules/departments/${departmentId}`,
  RETAILER_DEPARTMENTS_ROUNDING_RULE: (companyId: number, departmentId: number) => `/retailerOrgs/${companyId}/roundingRules/departments/${departmentId}`,
  RETAILER_CATEGORIES_ROUNDING_RULE_LOCK: (companyId: number, categoryId: number) => `/retailerOrgs/${companyId}/roundingRules/categories/${categoryId}/lock`,
  RETAILER_DEPARTMENTS_ROUNDING_RULE_LOCK: (companyId: number, departmentId: number) => `/retailerOrgs/${companyId}/roundingRules/departments/${departmentId}/lock`,
  RETAILER_DEPARTMENTS_ROUNDING_RULE_FILL_DOWN: (companyId: number, departmentId: number) => `/retailerOrgs/${companyId}/roundingRules/departments/${departmentId}/rules`,

  RETAILER_SANDBOX: (companyId: number, adId: number) => `/retailerOrgs/${companyId}/sandbox/${adId}`,
  RETAILER_SANDBOX_STATUS: (companyId: number, adId: number) => `/retailerOrgs/${companyId}/sandbox/${adId}/status`,
  RETAILER_SANDBOX_OFFERS: (companyId: number, adId: number) => `/retailerOrgs/${companyId}/sandbox/${adId}/offers`,
  RETAILER_SANDBOX_FINALIZE: (companyId: number, adId: number) => `/retailerOrgs/${companyId}/sandbox/${adId}/finalize`,
  RETAILER_SANDBOX_CALCULATE: (companyId: number, adId: number) => `/retailerOrgs/${companyId}/sandbox/${adId}/calculate`,
  RETAILER_SANDBOX_BY_CHECKED: (companyId: number, adId: number) => `/retailerOrgs/${companyId}/sandbox/${adId}/byChecked`,
  RETAILER_SANDBOX_ORDERS_CONFIG: (companyId: number, adId: number) => `/retailerOrgs/${companyId}/sandbox/${adId}/ordersConfig`,
  RETAILER_SANDBOX_ITEM: (companyId: number, adId: number, itemId: number) => `/retailerOrgs/${companyId}/sandbox/${adId}/items/${itemId}`,
  RETAILER_SANDBOX_OFFER: (companyId: number, adId: number, offerId: number) => `/retailerOrgs/${companyId}/sandbox/${adId}/offers/${offerId}`,
  RETAILER_SANDBOX_EXCLUDED_ITEMS: (companyId: number, adId: number) => `/retailerOrgs/${companyId}/sandbox/${adId}/sandboxUpdateRemovedItems`,
  RETAILER_SANDBOX_ITEM_NAMES: (companyId: number, adId: number, itemId: number) => `/retailerOrgs/${companyId}/sandbox/${adId}/items/${itemId}/names`,
  RETAILER_SANDBOX_OFFER_NAMES: (companyId: number, adId: number, offerId: number) => `/retailerOrgs/${companyId}/sandbox/${adId}/offers/${offerId}/names`,
  RETAILER_SANDBOX_ITEM_DUPLICATE: (companyId: number, adId: number, itemId: number) => `/retailerOrgs/${companyId}/sandbox/${adId}/items/${itemId}/duplicate`,
  RETAILER_SANDBOX_ITEM_DEAL_COST: (companyId: number, adId: number, itemId: number) => `/retailerOrgs/${companyId}/sandbox/${adId}/items/${itemId}/dealCosts`,
  RETAILER_SANDBOX_OFFER_DUPLICATE: (companyId: number, adId: number, offerId: number) => `/retailerOrgs/${companyId}/sandbox/${adId}/offers/${offerId}/duplicate`,
  RETAILER_SANDBOX_ITEM_STATUS: (companyId: number, adId: number, itemType: RetailerSandboxItemType, itemId: number) => `/retailerOrgs/${companyId}/sandbox/${adId}/${itemType}/${itemId}/selected`,
  RETAILER_SANDBOX_ITEM_SALES_HISTORY: (companyId: number, adId: number, itemType: RetailerSandboxItemType, itemId: number) => `/retailerOrgs/${companyId}/sandbox/${adId}/${itemType}/${itemId}/history`,
  RETAILER_SANDBOX_ITEM_PRICES_HISTORY: (companyId: number, adId: number, itemType: RetailerSandboxItemType, itemId: number) => `/retailerOrgs/${companyId}/sandbox/${adId}/${itemType}/${itemId}/priceHistory`,
  RETAILER_SANDBOX_UPDATE_PROJECTED_AD_VOLUMES: (companyId: number, adId: number) => `/retailerOrgs/${companyId}/sandbox/${adId}/aiVolumes`,
  RETAILER_SANDBOX_DEPARTMENT_CHANGE_ORDER: (companyId: number, adId: number) => `/retailerOrgs/${companyId}/sandbox/${adId}/order`,

  RETAILER_BASE_PRICE_SANDBOX: (companyId: number, adId: number) => `/retailerOrgs/${companyId}/baseTprSandbox/${adId}`,
  RETAILER_BASE_PRICE_EXPORT: (companyId: number, adId: number) => `/retailerOrgs/${companyId}/baseTprSandbox/${adId}/download`,
  RETAILER_BASE_PRICE_SANDBOX_ROUND: (companyId: number, adId: number) => `/retailerOrgs/${companyId}/baseTprSandbox/${adId}/round`,
  RETAILER_BASE_PRICE_SANDBOX_STATUS: (companyId: number, adId: number) => `/retailerOrgs/${companyId}/baseTprSandbox/${adId}/status`,
  RETAILER_BASE_PRICE_SANDBOX_OFFERS: (companyId: number, adId: number) => `/retailerOrgs/${companyId}/baseTprSandbox/${adId}/offers`,
  RETAILER_BASE_PRICE_SANDBOX_TAB_STATUS: (companyId: number, adId: number) => `/retailerOrgs/${companyId}/baseTprSandbox/${adId}/tabStatus`,
  RETAILER_BASE_PRICE_SANDBOX_BY_CHECKED: (companyId: number, adId: number) => `/retailerOrgs/${companyId}/baseTprSandbox/${adId}/byChecked`,
  RETAILER_BASE_PRICE_SANDBOX_CALCULATE: (companyId: number, adId: number) => `/retailerOrgs/${companyId}/baseTprSandbox/${adId}/calculateTab`,
  RETAILER_BASE_PRICE_SANDBOX_ITEM: (companyId: number, adId: number, itemId: number) => `/retailerOrgs/${companyId}/baseTprSandbox/${adId}/items/${itemId}`,
  RETAILER_BASE_PRICE_SANDBOX_EXCLUDED_ITEMS: (companyId: number, adId: number) => `/retailerOrgs/${companyId}/baseTprSandbox/${adId}/sandboxUpdateRemovedItems`,
  RETAILER_BASE_PRICE_SANDBOX_OUT_OF_PLANNING_ITEMS: (companyId: number, adId: number) => `/retailerOrgs/${companyId}/baseTprSandbox/${adId}/outOfPlaningOffers`,
  RETAILER_BASE_PRICE_SANDBOX_OFFER: (companyId: number, adId: number, offerId: number) => `/retailerOrgs/${companyId}/baseTprSandbox/${adId}/offers/${offerId}`,
  RETAILER_BASE_PRICE_SANDBOX_ITEM_LOCK: (companyId: number, adId: number, itemId: number) => `/retailerOrgs/${companyId}/baseTprSandbox/${adId}/items/${itemId}/lock`,
  RETAILER_BASE_PRICE_SANDBOX_OFFER_LOCK: (companyId: number, adId: number, offerId: number) => `/retailerOrgs/${companyId}/baseTprSandbox/${adId}/offers/${offerId}/lock`,
  RETAILER_BASE_PRICE_SANDBOX_OFFER_NAMES: (companyId: number, adId: number, offerId: number) => `/retailerOrgs/${companyId}/baseTprSandbox/${adId}/offers/${offerId}/names`,
  RETAILER_BASE_PRICE_SANDBOX_CATEGORY: (companyId: number, adId: number, categoryId: number) => `/retailerOrgs/${companyId}/baseTprSandbox/${adId}/categories/${categoryId}`,
  RETAILER_BASE_PRICE_SANDBOX_ITEM_DUPLICATE: (companyId: number, adId: number, itemId: number) => `/retailerOrgs/${companyId}/baseTprSandbox/${adId}/items/${itemId}/duplicate`,
  RETAILER_BASE_PRICE_SANDBOX_OFFER_DUPLICATE: (companyId: number, adId: number, offerId: number) => `/retailerOrgs/${companyId}/baseTprSandbox/${adId}/offers/${offerId}/duplicate`,
  RETAILER_BASE_PRICE_SANDBOX_DEPARTMENT: (companyId: number, adId: number, departmentId: number) => `/retailerOrgs/${companyId}/baseTprSandbox/${adId}/departments/${departmentId}`,
  RETAILER_BASE_PRICE_SANDBOX_CATEGORY_LOCK: (companyId: number, adId: number, categoryId: number) => `/retailerOrgs/${companyId}/baseTprSandbox/${adId}/categories/${categoryId}/lock`,
  RETAILER_BASE_PRICE_SANDBOX_CATEGORY_ROUND: (companyId: number, adId: number, categoryId: number) => `/retailerOrgs/${companyId}/baseTprSandbox/${adId}/categories/${categoryId}/round`,
  RETAILER_BASE_PRICE_SANDBOX_DEPARTMENT_LOCK: (companyId: number, adId: number, departmentId: number) => `/retailerOrgs/${companyId}/baseTprSandbox/${adId}/departments/${departmentId}/lock`,
  RETAILER_BASE_PRICE_SANDBOX_DEPARTMENT_ROUND: (companyId: number, adId: number, departmentId: number) => `/retailerOrgs/${companyId}/baseTprSandbox/${adId}/departments/${departmentId}/round`,
  RETAILER_BASE_PRICE_SANDBOX_UPDATE_PROJECTED_AD_VOLUMES: (companyId: number, adId: number) => `/retailerOrgs/${companyId}/baseTprSandbox/${adId}/aiVolumes`,
  RETAILER_BASE_PRICE_SANDBOX_DEPARTMENT_CHANGE_ORDER: (companyId: number, adId: number) => `/retailerOrgs/${companyId}/baseTprSandbox/${adId}/departments/order`,
  RETAILER_BASE_PRICE_SANDBOX_CATEGORY_CHANGE_ORDER: (companyId: number, adId: number) => `/retailerOrgs/${companyId}/baseTprSandbox/${adId}/categories/order`,

  RETAILER_BASE_PRICE_SANDBOX_ITEM_NEW_UNIT_COST: (companyId: number, adId: number, itemId: number) => `/retailerOrgs/${companyId}/baseTprSandbox/${adId}/items/${itemId}/newUnitCost`,
  RETAILER_BASE_PRICE_SANDBOX_ITEM_STATUS: (companyId: number, adId: number, itemType: RetailerSandboxItemType, itemId: number) => `/retailerOrgs/${companyId}/baseTprSandbox/${adId}/${itemType}/${itemId}/selected`,
  RETAILER_BASE_PRICE_SANDBOX_ITEM_SALES_HISTORY: (companyId: number, adId: number, itemType: RetailerSandboxItemType, itemId: number) => `/retailerOrgs/${companyId}/baseTprSandbox/${adId}/${itemType}/${itemId}/history`,
  RETAILER_BASE_PRICE_SANDBOX_ITEM_PRICES_HISTORY: (companyId: number, adId: number, itemType: RetailerSandboxItemType, itemId: number) => `/retailerOrgs/${companyId}/baseTprSandbox/${adId}/${itemType}/${itemId}/priceHistory`,
};
