import { PayloadAction, createSlice, isAnyOf } from '@reduxjs/toolkit';
import {
  ROWS_PER_PAGES,
  batchUploadPossibleColumns,
  basePriceMGRBatchUploadPossibleColumns,
} from 'resources/constants';

import {
  Pagination,
  RetailerImpItem,
  BasePriceChangeType,
  RetailerWeeklyMargins,
  RetailerSandboxOfferPlacement,
  RetailerDepartmentAnnualMargin,
} from 'resources/types';
import {
  groupListItemsByKey,
  filterOutInvalidUpcList,
  mergeAdSandboxImpItemsWithExcelResult,
  mergeBasePriceSandboxImpItemsWithExcelResult,
} from 'utils';
import {
  getRetailerImpItems,
  getRetailerImpItemsGroups,
  getRetailerAdWeeklyMargins,
  getRetailerImpItemsGroupsByUPC,
  getRetailerImpItemsOrdersConfigs,
  getRetailerDepartmentsAnnualMargins,
} from './thunks';

export interface RetailerImpItemsState {
  items: Array<RetailerImpItem>;
  pagination: Pagination;
  loading: boolean;

  weeklyMargins: RetailerWeeklyMargins | null;
  departments: Array<RetailerDepartmentAnnualMargin>;
  ordersConfigs: Array<string>;
  batchUploadColumns: Array<keyof RetailerImpItem>;

  selectedImpItems: Array<RetailerImpItem>;
  impItemsGroups: { [key: string]: Array<RetailerImpItem> };
  impItemsGroupsLoading: boolean;

  batchUploadInput: string;
  selectedPlacement: RetailerSandboxOfferPlacement;
  selectedPriceChangeType: BasePriceChangeType;
  invalidUpcList: Array<string>;
}

const initialState: RetailerImpItemsState = {
  items: [],
  pagination: {
    page: 0,
    totalPages: 1,
    totalItems: 0,
    size: ROWS_PER_PAGES[0],
  },
  loading: false,

  weeklyMargins: null,
  departments: [],
  ordersConfigs: [],
  batchUploadColumns: [],

  selectedImpItems: [],
  impItemsGroups: {},
  impItemsGroupsLoading: false,

  batchUploadInput: '',
  selectedPlacement: '',
  selectedPriceChangeType: '',
  invalidUpcList: [],
};

/* eslint-disable no-param-reassign */

const impItemsSlice = createSlice({
  name: 'retailer/impItems',
  initialState,
  reducers: {
    reset: () => initialState,
    setSelectedImpItems: (
      state,
      action: PayloadAction<{ impItem: RetailerImpItem, checked: boolean }>,
    ) => {
      const { impItem, checked } = action.payload;
      state.selectedImpItems = checked ? (
        [...state.selectedImpItems, impItem]
      ) : (
        state.selectedImpItems.filter((item) => item.upc !== impItem.upc)
      );
    },
    selectAllImpItems: (
      state,
      action: PayloadAction<{ checked?: boolean, discardSelection?: boolean }>,
    ) => {
      const { checked, discardSelection } = action.payload;
      if (discardSelection) {
        state.selectedImpItems = [];
        return;
      }
      const selectedImpItems = [...state.selectedImpItems];
      if (checked) {
        state.items.forEach((item) => {
          if (!selectedImpItems.find((i) => i.upc === item.upc)) {
            selectedImpItems.push(item);
          }
        });
      } else {
        state.items.forEach((item) => {
          const index = selectedImpItems.findIndex((i) => i.upc === item.upc);
          if (index > -1) {
            selectedImpItems.splice(index, 1);
          }
        });
      }
      state.selectedImpItems = selectedImpItems;
    },
    changeSelectedImpItem: (
      state,
      action: PayloadAction<{
        groupCode: RetailerImpItem['groupCode'],
        fieldName: keyof RetailerImpItem,
        value: number | RetailerSandboxOfferPlacement | null | string
      }>,
    ) => {
      const { groupCode, fieldName, value } = action.payload;
      if (groupCode) {
        const impItemsGroup = (state.impItemsGroups[groupCode] || [])
          .map((item) => ({ ...item, [fieldName]: value }));
        state.impItemsGroups = { ...state.impItemsGroups, [groupCode]: impItemsGroup };
      }
    },
    checkSelectedImpItem: (
      state,
      action: PayloadAction<{
        groupCode: RetailerImpItem['groupCode'],
        checked: boolean,
        upc?: string
      }>,
    ) => {
      const { groupCode, checked, upc } = action.payload;
      if (groupCode) {
        const impItemsGroup = (state.impItemsGroups[groupCode] || [])
          .map((item) => {
            if (upc) return item.upc === upc ? { ...item, checked } : item;
            return { ...item, checked };
          });
        state.impItemsGroups = { ...state.impItemsGroups, [groupCode]: impItemsGroup };
      }
    },
    setBatchUploadInput: (state, action: PayloadAction<string>) => {
      state.batchUploadInput = action.payload;
    },
    setSelectedPlacement: (state, action: PayloadAction<RetailerSandboxOfferPlacement>) => {
      state.selectedPlacement = action.payload;
    },
    setSelectedPriceChangeType: (state, action: PayloadAction<BasePriceChangeType>) => {
      state.selectedPriceChangeType = action.payload;
    },
    resetSelectedItems: (state) => {
      state.impItemsGroups = {};
      state.invalidUpcList = [];
      state.batchUploadInput = '';
      state.impItemsGroupsLoading = false;
      state.selectedPlacement = initialState.selectedPlacement;
      state.selectedPriceChangeType = initialState.selectedPriceChangeType;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getRetailerImpItems.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getRetailerImpItems.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getRetailerImpItems.fulfilled, (state, action) => {
      const {
        items, page, size, totalPages, totalItems,
      } = action.payload;
      state.loading = false;
      state.items = items;
      state.pagination = {
        page, size, totalPages, totalItems,
      };
    });

    builder.addCase(getRetailerAdWeeklyMargins.rejected, (state) => {
      state.weeklyMargins = null;
    });
    builder.addCase(getRetailerAdWeeklyMargins.fulfilled, (state, action) => {
      state.weeklyMargins = action.payload;
    });

    builder.addCase(getRetailerDepartmentsAnnualMargins.rejected, (state) => {
      state.departments = [];
    });
    builder.addCase(getRetailerDepartmentsAnnualMargins.fulfilled, (state, action) => {
      const { items } = action.payload;
      state.departments = items;
    });

    builder.addCase(getRetailerImpItemsOrdersConfigs.fulfilled, (state, action) => {
      const { priceChange } = action.meta.arg;
      const batchUploadColumns = action.payload
        .filter(
          (col) => batchUploadPossibleColumns.includes(col as keyof RetailerImpItem),
        ) as Array<keyof RetailerImpItem>;
      state.ordersConfigs = action.payload;
      state.batchUploadColumns = priceChange ? (
        basePriceMGRBatchUploadPossibleColumns
      ) : (
        batchUploadColumns
      );
    });

    builder.addCase(getRetailerImpItemsGroups.fulfilled, (state, action) => {
      const { items } = action.payload;
      const { itemsWithoutLinkCode } = action.meta.arg;
      const checkedItems: Array<RetailerImpItem> = items.map(
        (item) => ({
          ...item,
          groupCode: item.linkCode,
          checked: Boolean(
            state.selectedImpItems.find((selectedImpItem) => selectedImpItem.upc === item.upc),
          ),
        }),
      );
      const impItemsGroups = groupListItemsByKey(checkedItems, 'linkCode');
      itemsWithoutLinkCode.forEach((itemWithoutLinkCode) => {
        impItemsGroups[itemWithoutLinkCode.upc] = [itemWithoutLinkCode];
      });
      state.impItemsGroupsLoading = false;
      state.impItemsGroups = impItemsGroups;
    });

    builder.addCase(getRetailerImpItemsGroupsByUPC.fulfilled, (state, action) => {
      const { items, upcList } = action.payload;
      const { excelResult, priceChange } = action.meta.arg;
      state.impItemsGroupsLoading = false;
      state.invalidUpcList = filterOutInvalidUpcList(upcList, items);
      const impItemsGroups = priceChange ? (
        mergeBasePriceSandboxImpItemsWithExcelResult(
          upcList,
          items,
          excelResult,
          state.selectedPriceChangeType,
        )
      ) : (
        mergeAdSandboxImpItemsWithExcelResult(
          upcList,
          items,
          excelResult,
          state.selectedPlacement,
        )
      );
      state.impItemsGroups = impItemsGroups;
    });

    builder.addMatcher(isAnyOf(
      getRetailerImpItemsGroups.pending,
      getRetailerImpItemsGroupsByUPC.pending,
    ), (state) => {
      state.invalidUpcList = [];
      state.impItemsGroupsLoading = true;
    });

    builder.addMatcher(isAnyOf(
      getRetailerImpItemsGroups.rejected,
      getRetailerImpItemsGroupsByUPC.rejected,
    ), (state) => {
      state.invalidUpcList = [];
      state.impItemsGroupsLoading = false;
    });
  },
});

const {
  selectAllImpItems,
  resetSelectedItems,
  setBatchUploadInput,
  setSelectedImpItems,
  setSelectedPlacement,
  checkSelectedImpItem,
  changeSelectedImpItem,
  setSelectedPriceChangeType,
  reset: resetRetailerImpItemsState,
} = impItemsSlice.actions;

export {
  selectAllImpItems,
  resetSelectedItems,
  getRetailerImpItems,
  setBatchUploadInput,
  setSelectedImpItems,
  setSelectedPlacement,
  checkSelectedImpItem,
  changeSelectedImpItem,
  getRetailerImpItemsGroups,
  setSelectedPriceChangeType,
  getRetailerAdWeeklyMargins,
  resetRetailerImpItemsState,
  getRetailerImpItemsGroupsByUPC,
  getRetailerImpItemsOrdersConfigs,
  getRetailerDepartmentsAnnualMargins,
};

export default impItemsSlice;
