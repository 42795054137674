import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: 'https://0776b9873eacdc4c84d19cc8001af2a1@o4506778595688448.ingest.sentry.io/4506830780432384',
  environment: import.meta.env.MODE,
  enabled: import.meta.env.MODE !== 'development',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});
