import {
  Grid,
  Theme,
  Typography,
} from '@mui/material';

import { Layout } from 'components/main';
import resetPasswordCover from 'assets/covers/reset_password_cover.jpg';
import ResetPasswordForm from './ResetPasswordForm';

function ResetPassword() {
  return (
    <Layout
      form={<ResetPasswordForm />}
      coverImage={resetPasswordCover}
      content={(
        <Grid container direction="column" justifyContent="center" pt={18}>
          <Typography
            variant="h4"
            fontWeight="bold"
            sx={(theme: Theme) => ({ color: theme.palette.common.white })}
          >
            Well That Was Easy!
          </Typography>
          <Typography
            variant="h6"
            fontWeight="bold"
            sx={(theme: Theme) => ({ mt: 2, color: theme.palette.common.white })}
          >
            {'We\'ll have you back up and running in no time!'}
          </Typography>
        </Grid>
      )}
    />
  );
}

export default ResetPassword;
