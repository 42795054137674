import { useEffect } from 'react';
import { useLocation, matchRoutes, useNavigate } from 'react-router-dom';

import {
  getRetailerCompany,
  getRetailerCompanyLogo,
  getRetailerCompanyCover,
} from 'store/retailer/company';
import { RootState } from 'store';
import { routeObjects } from 'components/main';
import { userScopeRoutes } from 'resources/constants';
import { useAppDispatch, useAppSelector } from 'hooks';
import { getProfile, getProfilePhoto } from 'store/user';

function useProfile() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const userState = useAppSelector((state: RootState) => state.user);
  const companyState = useAppSelector((state: RootState) => state.retailer.company);

  const { profile, profilePhotoURL, userScope } = userState;
  const { data: companyData, companyLogoURL, companyCoverURL } = companyState;

  const isAdmin: boolean = Boolean(userScope && userScope === 'USER_SCOPE_ADMIN');

  useEffect(
    () => {
      if (userScope) {
        const matchedRoutes = matchRoutes(routeObjects, location.pathname);
        const currentRouteObject = matchedRoutes
          ?.find((routeObject) => routeObject.pathname === location.pathname);
        if (currentRouteObject?.route.userScope !== userScope) {
          navigate(userScopeRoutes[userScope]);
        }
      }
    },
    [userScope, location.pathname, navigate],
  );

  useEffect(
    () => {
      if (!profile) dispatch(getProfile());
    },
    [dispatch, profile],
  );

  useEffect(
    () => {
      if (!companyData && !isAdmin && profile) dispatch(getRetailerCompany());
    },
    [companyData, dispatch, isAdmin, profile],
  );

  useEffect(
    () => {
      if (profile && !profilePhotoURL) {
        dispatch(getProfilePhoto());
      }
    },
    [dispatch, profile, profilePhotoURL, userScope],
  );

  useEffect(
    () => {
      if (companyData && !isAdmin) {
        if (!companyLogoURL) dispatch(getRetailerCompanyLogo(companyData.id));
        if (!companyCoverURL) dispatch(getRetailerCompanyCover(companyData.id));
      }
    },
    [companyCoverURL, companyData, companyLogoURL, dispatch, isAdmin],
  );
}

export { useProfile };
