import { memo } from 'react';
import { Box, ChipPropsColorOverrides, Theme } from '@mui/material';

import { Retailer } from 'resources/types';

function RetailerStatus(props: { retailer: Retailer }) {
  const { retailer } = props;

  let colorKey: keyof ChipPropsColorOverrides = 'primary';
  const status: string = String(retailer.company.status).replace(/_/g, ' ');

  switch (retailer.company.status) {
    case 'NEW':
      colorKey = 'info';
      break;
    case 'DRAFT':
      colorKey = 'orange';
      break;
    case 'READY':
      colorKey = 'success';
      break;
    case 'PENDING':
      colorKey = 'warning';
      break;
    case 'APPROVED':
      colorKey = 'blue';
      break;
    case 'REJECTED':
      colorKey = 'deepOrange';
      break;
    case 'IN_REVIEW':
      colorKey = 'purple';
      break;
    case 'SUSPENDED':
      colorKey = 'error';
      break;
    default:
  }

  return (
    <Box
      sx={(theme: Theme) => ({
        px: 2,
        py: 0.2,
        borderRadius: 4,
        display: 'flex',
        width: 'fit-content',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: theme.typography.body2,
        ...(colorKey ? { color: theme.palette[colorKey].contrastText } : {}),
        ...(colorKey ? { backgroundColor: theme.palette[colorKey].main } : {}),
      })}
    >
      {status}
    </Box>
  );
}

export default memo(RetailerStatus);
