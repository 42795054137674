import { combineReducers } from '@reduxjs/toolkit';

import retailers, { resetAdminRetailersState } from './retailers';

const adminReducers = combineReducers({
  retailers: retailers.reducer,
});

export { resetAdminRetailersState };

export default adminReducers;
