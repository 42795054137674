/* eslint-disable no-param-reassign */

import { localStorageKeys, OFFER_NAME_REGEX } from 'resources/constants';
import {
  RetailerImpItem,
  BasePriceChangeType,
  SandboxFilterByType,
  RetailerBasePriceSandbox,
  RetailerImpItemExcelResultRow,
  RetailerBasePriceSandboxCategory,
  RetailerBasePriceSandboxErrorData,
  RetailerBasePriceSandboxStorageKey,
  RetailerBasePriceSandboxDepartment,
  RetailerBasePriceSandboxItemErrorData,
  RetailerBasePriceSandboxOfferErrorData,
  RetailerBasePriceSandboxCategoryErrorData,
  RetailerBasePriceSandboxOfferCheckboxState,
  RetailerBasePriceSandboxItemCheckboxState,
  RetailerBasePriceSandboxOffersUploadingBody,
  RetailerBasePriceSandboxNewPricesRequestBody,
  RetailerBasePriceSandboxNewPriceRequestOffer,
  RetailerBasePriceSandboxItemsDeletionOptions,
  RetailerBasePriceSandboxCategoryCheckboxState,
  RetailerBasePriceSandboxDeleteItemsRequestBody,
  RetailerBasePriceSandboxDepartmentCheckboxState,
  RetailerBasePriceSandboxOffersUploadingResponse,
} from 'resources/types';
import {
  trimUpc,
  getStoredValueForKey,
  setStoredValueForKey,
  removeStoredValueForKey,
  toTableCellDollarString,
  toTableCellPercentString, validateItemLinkCode,
} from 'utils';

export const getRetailerBasePriceSandboxStoredValueForKey = (
  weekId: number | null,
  key: RetailerBasePriceSandboxStorageKey,
) => {
  if (!weekId) {
    return null;
  }

  const valueForKey = getStoredValueForKey(localStorageKeys.BASE_PRICE_SANDBOX, key);
  if (!valueForKey) {
    return null;
  }

  try {
    const valueByWeeks = JSON.parse(valueForKey);
    if (typeof valueByWeeks !== 'object') {
      return null;
    }
    return valueByWeeks[weekId] ?? null;
  } catch (e) {
    return null;
  }
};

export const setRetailerBasePriceSandboxStoredValueForKey = (
  weekId: number | null,
  key: RetailerBasePriceSandboxStorageKey,
  value: string,
) => {
  if (!weekId) {
    return;
  }

  const valueForKey = getStoredValueForKey(localStorageKeys.BASE_PRICE_SANDBOX, key);

  if (!valueForKey) {
    setStoredValueForKey(
      localStorageKeys.BASE_PRICE_SANDBOX,
      key,
      JSON.stringify({ [weekId]: value }),
    );
    return;
  }

  try {
    const valueByWeeks = JSON.parse(valueForKey);
    valueByWeeks[weekId] = value;
    setStoredValueForKey(localStorageKeys.BASE_PRICE_SANDBOX, key, JSON.stringify(valueByWeeks));
  } catch (e) {
    setStoredValueForKey(
      localStorageKeys.BASE_PRICE_SANDBOX,
      key,
      JSON.stringify({ [weekId]: value }),
    );
  }
};

export const removeRetailerBasePriceSandboxStoredValueForKey = (
  weekId: number | null,
  key: RetailerBasePriceSandboxStorageKey,
) => {
  if (!weekId) {
    return;
  }

  const valueForKey = getStoredValueForKey(localStorageKeys.BASE_PRICE_SANDBOX, key);

  if (!valueForKey) {
    return;
  }

  try {
    const valueByWeeks = JSON.parse(valueForKey);
    delete valueByWeeks[weekId];
    if (Object.keys(valueByWeeks).length) {
      setStoredValueForKey(localStorageKeys.BASE_PRICE_SANDBOX, key, JSON.stringify(valueByWeeks));
      return;
    }

    removeStoredValueForKey(localStorageKeys.BASE_PRICE_SANDBOX, key);
  } catch (e) {
    removeStoredValueForKey(localStorageKeys.BASE_PRICE_SANDBOX, key);
  }
};

const getBasePriceSandboxDuplicatedItems = (
  baseTprSandboxDepartments: Array<RetailerBasePriceSandboxDepartment> = [],
): { duplicateItems: number[], duplicateOffers: number[] } => {
  const duplicateItems: number[] = [];
  const duplicateOffers: number[] = [];

  const sandboxItems: Record<string, {
    hasDuplicate: boolean;
    itemIds: number[];
    offerIds: number[];
  }> = {};

  baseTprSandboxDepartments.forEach((department) => (
    department.baseTprSandboxCategories.forEach((category) => (
      category.baseTprSandboxOffers.forEach((offer) => {
        if (!offer.selected) {
          return;
        }

        offer.baseTprSandboxItems.forEach((item) => {
          if (!item.selected) {
            return;
          }

          if (!sandboxItems[item.upc]) {
            sandboxItems[item.upc] = {
              hasDuplicate: false,
              itemIds: [],
              offerIds: [],
            };
          } else {
            sandboxItems[item.upc].hasDuplicate = true;
          }
          sandboxItems[item.upc].itemIds.push(item.id);
          sandboxItems[item.upc].offerIds.push(offer.id);
        });
      })
    ))
  ));

  Object.values(sandboxItems).forEach((item) => {
    const { itemIds, offerIds, hasDuplicate } = item;
    if (hasDuplicate) {
      duplicateItems.push(...itemIds);
      duplicateOffers.push(...offerIds);
    }
  });

  return { duplicateItems, duplicateOffers };
};

export const generateBasePriceSandboxDepartmentsErrorsData = (
  baseTprSandboxDepartments: Array<RetailerBasePriceSandboxDepartment> = [],
): RetailerBasePriceSandboxErrorData => {
  const {
    duplicateItems,
    duplicateOffers,
  } = getBasePriceSandboxDuplicatedItems(baseTprSandboxDepartments);

  const departmentsErrorData: RetailerBasePriceSandboxErrorData = {};

  baseTprSandboxDepartments.forEach((department) => {
    const categoriesErrorData: Record<number, RetailerBasePriceSandboxCategoryErrorData> = {};
    let departmentErrorCount = 0;
    let departmentActiveOffersCount = 0;

    department.baseTprSandboxCategories.forEach((category) => {
      const offersErrorData: Record<number, RetailerBasePriceSandboxOfferErrorData> = {};
      let categoryErrorCount = 0;
      let categoryActiveOffersCount = 0;

      category.baseTprSandboxOffers.forEach((offer) => {
        const itemsErrorData: Record<number, RetailerBasePriceSandboxItemErrorData> = {};
        let itemErrorCount = 0;

        offer.baseTprSandboxItems.forEach((item) => {
          const newUnitCost = (
            item.newUnitCost == null && item.selected
          );
          const duplicating = (
            duplicateItems.includes(item.id) && item.selected
          );

          const itemProjectedNweVolume = item.projectedNewVolumeManual == null
            ? item.projectedNewVolume
            : item.projectedNewVolumeManual;

          const projectedNewVolume = (
            item.selected
            && (itemProjectedNweVolume == null || itemProjectedNweVolume < 0.5)
          );

          const error = duplicating || newUnitCost || projectedNewVolume;

          if (error) {
            itemErrorCount += 1;
          }

          itemsErrorData[item.id] = {
            id: item.id,
            error,
            newUnitCost,
            duplicating,
            projectedNewVolume,
          };
        });

        const duplicating = duplicateOffers.includes(offer.id) && offer.selected;

        const offerProjectedNweVolume = offer.projectedNewVolumeManual == null
          ? offer.projectedNewVolume
          : offer.projectedNewVolumeManual;

        const offerProjNweVolError = (
          offer.selected
          && (offerProjectedNweVolume == null || offerProjectedNweVolume < 0.5)
        );

        const itemProjNweVolError = Object.values(itemsErrorData).some(
          (itemErrorData) => itemErrorData.projectedNewVolume,
        );
        const projectedNewVolume = offerProjNweVolError || itemProjNweVolError;
        const error = (Boolean(itemErrorCount) || itemProjNweVolError || duplicating);
        const newPrice = offer.newPrice == null;

        if (error) {
          categoryErrorCount += 1;
        }
        if (offer.selected) {
          categoryActiveOffersCount += 1;
        }

        offersErrorData[offer.id] = {
          id: offer.id,
          items: itemsErrorData,
          error,
          newPrice,
          duplicating,
          projectedNewVolume,
        };
      });

      categoriesErrorData[category.id] = {
        id: category.id,
        offers: offersErrorData,
        errorCount: categoryErrorCount,
        activeOffersCount: categoryActiveOffersCount,
      };

      departmentErrorCount += categoryErrorCount;
      departmentActiveOffersCount += categoryActiveOffersCount;
    });

    departmentsErrorData[department.id] = {
      id: department.id,
      categories: categoriesErrorData,
      errorCount: departmentErrorCount,
      activeOffersCount: departmentActiveOffersCount,
    };
  });

  return departmentsErrorData;
};

export const getBasePriceSandboxCalculationErrors = (
  baseTprSandboxDepartmentsErrorData: RetailerBasePriceSandboxErrorData,
): Array<string> => {
  const calculationErrors: Array<string> = [];
  const duplicationMessage = 'There are duplicate items in the Sandbox';
  const newUnitCostNewPriceMessage = 'New Unit Cost or New Price are missing';
  const projectedNewVolumeMessage = 'Projected New Volume can\'t be 0 or empty';

  let duplicateItems = 0;
  let itemsWithoutNewUnitCost = 0;
  let itemsWithoutProjectedNewVolume = 0;
  let offersWithoutNewPrice = 0;

  Object.values(baseTprSandboxDepartmentsErrorData).forEach(({ categories }) => {
    Object.values(categories).forEach(({ offers }) => {
      Object.values(offers).forEach((baseTprSandboxOfferErrorData) => {
        const { error, newPrice, items } = baseTprSandboxOfferErrorData;
        if (newPrice && error) {
          offersWithoutNewPrice += 1;
        }
        Object.values(items).forEach((baseTprSandboxItemErrorData) => {
          if (baseTprSandboxItemErrorData.duplicating && baseTprSandboxItemErrorData.error) {
            duplicateItems += 1;
          }
          if (baseTprSandboxItemErrorData.newUnitCost && baseTprSandboxItemErrorData.error) {
            itemsWithoutNewUnitCost += 1;
          }
          if (baseTprSandboxItemErrorData.projectedNewVolume && baseTprSandboxItemErrorData.error) {
            itemsWithoutProjectedNewVolume += 1;
          }
        });
      });
    });
  });

  if (duplicateItems > 0) {
    calculationErrors.push(duplicationMessage);
  }
  if (itemsWithoutNewUnitCost > 0 || offersWithoutNewPrice > 0) {
    calculationErrors.push(newUnitCostNewPriceMessage);
  }
  if (itemsWithoutProjectedNewVolume > 0) {
    calculationErrors.push(projectedNewVolumeMessage);
  }

  return calculationErrors;
};

export const generateBasePriceSandboxNewPricesBatchRequestData = (
  impItemsGroups: { [key: string]: RetailerImpItem[] },
  uploadedOffers: RetailerBasePriceSandboxOffersUploadingResponse['items'],
): RetailerBasePriceSandboxNewPricesRequestBody => {
  const baseTprSandboxOfferPriceRequestOffers = uploadedOffers.reduce(
    (total: Array<RetailerBasePriceSandboxNewPriceRequestOffer>, uploadedOffer) => {
      const { baseTprSandboxItems } = uploadedOffer;
      const [firstItem] = baseTprSandboxItems;

      const selectedImpItemsGroup = Object.values(impItemsGroups)
        .find((impItemsGroup: Array<RetailerImpItem>) => (
          impItemsGroup.some((impItem) => trimUpc(impItem.upc) === trimUpc(firstItem?.upc))
        ));

      if (selectedImpItemsGroup && selectedImpItemsGroup.length) {
        const [firsItem] = selectedImpItemsGroup;
        const newPrice = firsItem.newPrice != null ? firsItem.newPrice : uploadedOffer.newPrice;
        const firsItemNewUnitCost = firsItem.newUnitCost != null ? firsItem.newUnitCost : null;

        const aipItems = uploadedOffer.baseTprSandboxItems.map((item) => ({
          id: item.id,
          upc: item.upc,
          oldBaseRetailPrice: item.oldBaseRetailPrice,
          newUnitCost: firsItemNewUnitCost === null ? item.newUnitCost : firsItemNewUnitCost,
        }));

        const sandboxOfferPriceRequestOffer: RetailerBasePriceSandboxNewPriceRequestOffer = {
          newPrice,
          id: uploadedOffer.id,
          baseTprSandboxItems: aipItems,
          startDate: firsItem.startDate,
          priceChangeType: firsItem.priceChangeType,
        };

        return [...total, sandboxOfferPriceRequestOffer];
      }
      return total;
    },
    [],
  );

  return { items: baseTprSandboxOfferPriceRequestOffers };
};

export const getBasePriceSandboxItemsDeletionOptions = ({
  departments = [],
  filterBy,
  sandboxErrorData,
  deletionOptions,
}: {
  departments?: RetailerBasePriceSandboxDepartment[],
  filterBy: SandboxFilterByType,
  sandboxErrorData: RetailerBasePriceSandboxErrorData,
  deletionOptions?: RetailerBasePriceSandboxItemsDeletionOptions,
}): RetailerBasePriceSandboxItemsDeletionOptions => {
  const checkboxStates: RetailerBasePriceSandboxDepartmentCheckboxState[] = [];
  let allSelected = true;
  let disableRemoveBtn = true;

  const checkboxPrevStates: Record<'department' | 'category' | 'offer' | 'item', Record<number, boolean>> = {
    department: {},
    category: {},
    offer: {},
    item: {},
  };
  if (deletionOptions) {
    deletionOptions.checkboxStates.forEach((departmentState) => {
      checkboxPrevStates.department[departmentState.id] = departmentState.checked;
      departmentState.categories.forEach((categoryState) => {
        checkboxPrevStates.category[categoryState.id] = categoryState.checked;
        categoryState.offers.forEach((offerState) => {
          checkboxPrevStates.offer[offerState.id] = offerState.checked;
          offerState.items.forEach((itemState) => {
            checkboxPrevStates.item[itemState.id] = itemState.checked;
          });
        });
      });
    });
  }

  departments.forEach((department) => {
    const departmentChecked = checkboxPrevStates.department[department.id] ?? false;

    const departmentCheckboxState: RetailerBasePriceSandboxDepartmentCheckboxState = {
      id: department.id,
      checked: departmentChecked,
      categories: [],
    };
    const departmentErrorData = sandboxErrorData[department.id];

    department.baseTprSandboxCategories.forEach((category) => {
      const categoryChecked = checkboxPrevStates.category[category.id] ?? false;

      const categoryCheckboxState: RetailerBasePriceSandboxCategoryCheckboxState = {
        id: category.id,
        checked: categoryChecked,
        offers: [],
      };

      const categoryErrorData = departmentErrorData?.categories[category.id];

      category.baseTprSandboxOffers.forEach((offer) => {
        const offerErrorData = categoryErrorData?.offers[offer.id];
        const excludedOfferByFilter = filterBy !== null && !offerErrorData?.error;

        if (excludedOfferByFilter) {
          return;
        }

        const offerChecked = checkboxPrevStates.offer[offer.id] ?? false;

        const offerCheckboxState: RetailerBasePriceSandboxOfferCheckboxState = {
          id: offer.id,
          checked: offerChecked,
          items: [],
        };

        offer.baseTprSandboxItems.forEach((item) => {
          const itemErrorData = offerErrorData?.items[item.id];
          const excludedItemByFilter = filterBy !== null && !itemErrorData?.error;

          if (excludedItemByFilter) {
            return;
          }

          const itemChecked = checkboxPrevStates.item[item.id] ?? false;

          const itemCheckboxState: RetailerBasePriceSandboxItemCheckboxState = {
            id: item.id,
            checked: itemChecked,
          };

          if (itemCheckboxState.checked) {
            disableRemoveBtn = false;
          } else {
            offerCheckboxState.checked = false;
          }
          offerCheckboxState.items.push(itemCheckboxState);
        });

        if (!offerCheckboxState.checked) {
          categoryCheckboxState.checked = false;
        }
        categoryCheckboxState.offers.push(offerCheckboxState);
      });

      if (!categoryCheckboxState.checked) {
        departmentCheckboxState.checked = false;
      }
      departmentCheckboxState.categories.push(categoryCheckboxState);
    });

    if (!departmentCheckboxState.checked) {
      allSelected = false;
    }
    checkboxStates.push(departmentCheckboxState);
  });

  return {
    allSelected,
    checkboxStates,
    disableRemoveBtn,
  };
};

export const getBasePriceSandboxDeletionItemsRequestBody = (
  departments: RetailerBasePriceSandboxDepartment[],
  checkboxStates: RetailerBasePriceSandboxDepartmentCheckboxState[],
): RetailerBasePriceSandboxDeleteItemsRequestBody => {
  const sandboxItemsCountData: Record<'departments' | 'categories' | 'offers', Record<number, number>> = {
    departments: {},
    categories: {},
    offers: {},
  };

  departments.forEach((department) => {
    const { id: departmentId } = department;
    sandboxItemsCountData.departments[departmentId] = 0;

    department.baseTprSandboxCategories.forEach((category) => {
      const { id: categoryId } = category;
      sandboxItemsCountData.categories[categoryId] = 0;

      category.baseTprSandboxOffers.forEach((offer) => {
        const { id, baseTprSandboxItems } = offer;
        const itemsCount = baseTprSandboxItems.length;
        sandboxItemsCountData.departments[departmentId] += itemsCount;
        sandboxItemsCountData.categories[categoryId] += itemsCount;
        sandboxItemsCountData.offers[id] = itemsCount;
      });
    });
  });

  const requestBody: RetailerBasePriceSandboxDeleteItemsRequestBody = {
    departments: [],
    categories: [],
    offers: [],
    items: [],
  };

  checkboxStates.forEach((departmentState) => {
    const itemsCountInDepartment = sandboxItemsCountData.departments[departmentState.id];
    const filteredItemsCountInDepartment = departmentState.categories.reduce(
      (itemsCountInCategory, { offers }) => (
        itemsCountInCategory + offers.reduce((itemsCountInOffer, { items }) => (
          itemsCountInOffer + items.length
        ), 0)
      ),
      0,
    );

    if (departmentState.checked && filteredItemsCountInDepartment === itemsCountInDepartment) {
      requestBody.departments!.push(departmentState.id);
      return;
    }

    departmentState.categories.forEach((categoryState) => {
      const itemsCountInCategory = sandboxItemsCountData.categories[categoryState.id];
      const filteredItemsCountInCategory = categoryState.offers.reduce(
        (itemsCountInOffer, { items }) => (
          itemsCountInOffer + items.length
        ),
        0,
      );

      if (categoryState.checked && filteredItemsCountInCategory === itemsCountInCategory) {
        requestBody.categories!.push(categoryState.id);
        return;
      }

      categoryState.offers.forEach((offerState) => {
        const itemsCountInOffer = sandboxItemsCountData.offers[offerState.id];
        const filteredItemsCountInOffer = offerState.items.length;

        if (offerState.checked && filteredItemsCountInOffer === itemsCountInOffer) {
          requestBody.offers!.push(offerState.id);
          return;
        }

        offerState.items.forEach((itemState) => {
          if (itemState.checked) {
            requestBody.items!.push(itemState.id);
          }
        });
      });
    });
  });

  return requestBody;
};

export const getBasePriceSandboxMarginsTableData = (
  basePriceSandbox: RetailerBasePriceSandbox | null,
  sandboxNeedsRecalculation: boolean,
): Array<{
  key: string,
  title: string,
  amount: string,
  percentage: string,
  warning?: boolean
}> => {
  const projectedAdMarginAmount = toTableCellDollarString(
    basePriceSandbox?.projectedAdMarginAmount,
  );
  const projectedAdMarginPercent = toTableCellPercentString(
    basePriceSandbox?.projectedAdMargin,
  );

  return [
    {
      key: 'projectedBaseMargin',
      title: 'Projected Base Margin',
      percentage: toTableCellPercentString(basePriceSandbox?.projectedBaseMargin),
      amount: toTableCellDollarString(basePriceSandbox?.projectedBaseMarginAmount),
    },
    {
      key: 'projectedTprMargin',
      title: 'Projected TPR Margin',
      percentage: toTableCellPercentString(basePriceSandbox?.projectedTprMargin),
      amount: toTableCellDollarString(basePriceSandbox?.projectedTprMarginAmount),
    },
    {
      key: 'projectedAdMarginPercent',
      title: 'Projected Ad Margin',
      amount: projectedAdMarginPercent,
      percentage: projectedAdMarginPercent,
      warning: sandboxNeedsRecalculation,
    },
    {
      key: 'totalProjectedMargin',
      title: 'Total Proj. Margin',
      warning: sandboxNeedsRecalculation,
      percentage: toTableCellPercentString(basePriceSandbox?.totalProjectedMargin),
      amount: toTableCellDollarString(basePriceSandbox?.totalProjectedMarginAmount),
    },
    {
      key: 'adjustedBaseMargin',
      title: 'Adjusted Base Margin',
      percentage: toTableCellPercentString(basePriceSandbox?.adjustedBaseMargin),
      amount: toTableCellDollarString(basePriceSandbox?.adjustedBaseMarginAmount),
    },
    {
      key: 'adjustedTprMargin',
      title: 'Adjusted TPR Margin',
      percentage: toTableCellPercentString(basePriceSandbox?.adjustedTprMargin),
      amount: toTableCellDollarString(basePriceSandbox?.adjustedTprMarginAmount),
    },
    {
      key: 'projectedAdMarginAmount',
      title: 'Projected Ad Margin',
      amount: projectedAdMarginAmount,
      percentage: projectedAdMarginAmount,
      warning: sandboxNeedsRecalculation,
    },
    {
      key: 'totalAdjustedProjectedMargin',
      title: 'Total Adjusted Proj. Margin',
      percentage: toTableCellPercentString(basePriceSandbox?.totalAdjustedProjectedMargin),
      amount: toTableCellDollarString(basePriceSandbox?.totalAdjustedProjectedMarginAmount),
      warning: sandboxNeedsRecalculation,
    },
  ];
};

export const getBasePriceSandboxDepartmentOffersIds = (
  departmentId: number,
  basePriceSandbox: RetailerBasePriceSandbox | null,
): Array<number> => {
  const updatingDepartment = basePriceSandbox?.baseTprSandboxDepartments.find(
    (department) => department.id === departmentId,
  );
  const offers = updatingDepartment?.baseTprSandboxCategories.reduce(
    (totalOffers: Array<number>, category: RetailerBasePriceSandboxCategory) => {
      const categoryOffers = category.baseTprSandboxOffers.map((offer) => offer.id);
      return [...totalOffers, ...categoryOffers];
    },
    [],
  ) || [];
  return offers;
};

export const getBasePriceSandboxCategoryOffersIds = (
  categoryId: number,
  basePriceSandbox: RetailerBasePriceSandbox | null,
): Array<number> => {
  const updatingCategory = basePriceSandbox?.baseTprSandboxDepartments.reduce(
    (
      category: RetailerBasePriceSandboxCategory | null,
      department: RetailerBasePriceSandboxDepartment,
    ) => (
      category ?? (department.baseTprSandboxCategories.find((c) => c.id === categoryId) || null)
    ),
    null,
  ) || null;
  const offers = updatingCategory?.baseTprSandboxOffers.map((offer) => offer.id) || [];
  return offers;
};

export const getBasePriceSandboxAllOffersIds = (
  basePriceSandbox: RetailerBasePriceSandbox | null,
): Array<number> => {
  const offers = basePriceSandbox?.baseTprSandboxDepartments.reduce(
    (departmentTotalOffers: Array<number>, department: RetailerBasePriceSandboxDepartment) => {
      const departmentOffers = department.baseTprSandboxCategories.reduce(
        (categoryTotalOffers: Array<number>, category: RetailerBasePriceSandboxCategory) => {
          const categoryOffers = category.baseTprSandboxOffers.map((offer) => offer.id);
          return [...categoryTotalOffers, ...categoryOffers];
        },
        [],
      );
      return [...departmentTotalOffers, ...departmentOffers];
    },
    [],
  ) || [];
  return offers;
};

const updateBasePriceDepartmentsDeletionOptions = (
  checkboxStates: RetailerBasePriceSandboxDepartmentCheckboxState[],
  checked: boolean,
  departmentId: number,
): RetailerBasePriceSandboxDepartmentCheckboxState[] => (
  checkboxStates.map((departmentState) => {
    if (departmentState.id === departmentId) {
      return {
        ...departmentState,
        checked,
        categories: departmentState.categories.map((categoryState) => ({
          ...categoryState,
          checked,
          offers: categoryState.offers.map((offerState) => ({
            ...offerState,
            checked,
            items: offerState.items.map((itemState) => ({
              ...itemState,
              checked,
            })),
          })),
        })),
      };
    }
    return departmentState;
  })
);

const updateBasePriceCategoriesDeletionOptions = (
  checkboxStates: RetailerBasePriceSandboxDepartmentCheckboxState[],
  checked: boolean,
  departmentId: number,
  categoryId: number,
): RetailerBasePriceSandboxDepartmentCheckboxState[] => checkboxStates.map((departmentState) => {
  const updatedCategories = departmentState.categories.map(
    (categoryState) => {
      if (categoryState.id === categoryId) {
        return {
          ...categoryState,
          checked,
          offers: categoryState.offers.map(
            (offerState) => ({
              ...offerState,
              checked,
              items: offerState.items.map(
                (itemState) => ({
                  ...itemState,
                  checked,
                }),
              ),
            }),
          ),
        };
      }
      return categoryState;
    },
  );

  return {
    ...departmentState,
    categories: updatedCategories,
    checked: departmentState.id !== departmentId
      ? departmentState.checked
      : !updatedCategories.some(
        (categoryState) => !categoryState.checked,
      ),
  };
});

const updateBasePriceOffersDeletionOptions = (
  checkboxStates: RetailerBasePriceSandboxDepartmentCheckboxState[],
  checked: boolean,
  departmentId: number,
  categoryId: number,
  offerId: number,
) => checkboxStates.map(
  (departmentState) => {
    const updatedCategories = departmentState.categories.map(
      (categoryState) => {
        const updatedOffers = categoryState.offers.map(
          (offerState) => {
            if (offerState.id === offerId) {
              return {
                ...offerState,
                checked,
                items: offerState.items.map(
                  (itemState) => ({ ...itemState, checked }),
                ),
              };
            }
            return offerState;
          },
        );

        return {
          ...categoryState,
          offers: updatedOffers,
          checked: categoryState.id !== categoryId
            ? categoryState.checked
            : !updatedOffers.some((offerState) => !offerState.checked),
        };
      },
    );

    return {
      ...departmentState,
      categories: updatedCategories,
      checked: departmentState.id !== departmentId
        ? departmentState.checked
        : !updatedCategories.some((categoryState) => !categoryState.checked),
    };
  },
);

const updateBasePriceItemsDeletionOptions = (
  checkboxStates: RetailerBasePriceSandboxDepartmentCheckboxState[],
  checked: boolean,
  departmentId: number,
  categoryId: number,
  offerId: number,
  itemId: number,
) => checkboxStates.map(
  (departmentState) => {
    const updatedCategories = departmentState.categories.map(
      (categoryState) => {
        const updatedOffers = categoryState.offers.map(
          (offerState) => {
            const updatedItems = offerState.items.map(
              (itemState) => {
                if (itemState.id === itemId) {
                  return { ...itemState, checked };
                }
                return itemState;
              },
            );

            return {
              ...offerState,
              items: updatedItems,
              checked: offerState.id !== offerId
                ? offerState.checked
                : !updatedItems.some((itemState) => !itemState.checked),
            };
          },
        );

        return {
          ...categoryState,
          offers: updatedOffers,
          checked: categoryState.id !== categoryId
            ? categoryState.checked
            : !updatedOffers.some((offerState) => !offerState.checked),
        };
      },
    );

    return {
      ...departmentState,
      categories: updatedCategories,
      checked: departmentState.id !== departmentId
        ? departmentState.checked
        : !updatedCategories.some((categoryState) => !categoryState.checked),
    };
  },
);

export const updateBasePriceSandboxDeletionOptions = ({
  checkboxStates,
  checked,
  departmentId,
  categoryId,
  offerId,
  itemId,
}: {
  checkboxStates: RetailerBasePriceSandboxDepartmentCheckboxState[];
  checked: boolean;
  departmentId?: number;
  categoryId?: number;
  offerId?: number;
  itemId?: number;
}): RetailerBasePriceSandboxItemsDeletionOptions => {
  let newCheckboxStates = checkboxStates;

  if (departmentId && categoryId && offerId && itemId) {
    newCheckboxStates = updateBasePriceItemsDeletionOptions(
      newCheckboxStates,
      checked,
      departmentId,
      categoryId,
      offerId,
      itemId,
    );
  } else if (departmentId && categoryId && offerId) {
    newCheckboxStates = updateBasePriceOffersDeletionOptions(
      newCheckboxStates,
      checked,
      departmentId,
      categoryId,
      offerId,
    );
  } else if (departmentId && categoryId) {
    newCheckboxStates = updateBasePriceCategoriesDeletionOptions(
      newCheckboxStates,
      checked,
      departmentId,
      categoryId,
    );
  } else if (departmentId) {
    newCheckboxStates = updateBasePriceDepartmentsDeletionOptions(
      checkboxStates,
      checked,
      departmentId,
    );
  }

  const allSelected = !newCheckboxStates.some((departmentState) => !departmentState.checked);
  const disableRemoveBtn = !newCheckboxStates.some((departmentState) => (
    departmentState.categories.some((categoryState) => (
      categoryState.offers.some((offerState) => (
        offerState.items.some((itemState) => itemState.checked)
      ))
    ))
  ));

  return {
    allSelected,
    disableRemoveBtn,
    checkboxStates: newCheckboxStates,
  };
};

export const generateBasePriceSandboxOfferUploadingBody = (
  impItemsGroups: { [key: string]: Array<RetailerImpItem> },
): RetailerBasePriceSandboxOffersUploadingBody => {
  const uploadingItems = Object.keys(impItemsGroups)
    .reduce(
      (total: RetailerBasePriceSandboxOffersUploadingBody['items'], groupCode: string) => {
        const currentGroupsSelectedItems = impItemsGroups[groupCode]
          ?.filter((item) => item.checked) || [];
        if (currentGroupsSelectedItems.length) {
          const [firstItem] = currentGroupsSelectedItems;
          const baseTprSandboxItems = currentGroupsSelectedItems.map((item) => ({
            upc: item.upc,
            description: item.description,
          }));
          const uploadingOffer = {
            sandboxItems: baseTprSandboxItems,
            linkCode: firstItem.linkCode || '',
            priceChangeName: firstItem.description || '',
            startDate: firstItem.startDate || null,
          };
          return [...total, uploadingOffer];
        }
        return total;
      },
      [],
    );
  return { items: uploadingItems };
};

export const mergeBasePriceSandboxImpItemsWithExcelResult = (
  upcList: string[],
  impItems: RetailerImpItem[],
  excelResult: RetailerImpItemExcelResultRow[],
  selectedPriceChangeType: BasePriceChangeType,
): Record<string, RetailerImpItem[]> => {
  const itemsGroups = impItems.reduce<Record<string, RetailerImpItem[]>>(
    (total, importedItem) => {
      const importedItemTrimmedUpc = trimUpc(importedItem.upc);

      const excelRow = excelResult.find(
        (row) => {
          if (!row.upc) {
            return false;
          }
          const trimmedRowUpc = trimUpc(row.upc);
          if (trimmedRowUpc === importedItemTrimmedUpc) {
            return true;
          }
          if (importedItem.commodityUpcs) {
            return importedItem.commodityUpcs.some(
              (commodityUpc) => trimmedRowUpc === trimUpc(commodityUpc),
            );
          }
          return false;
        },
      );

      const memo = excelRow?.memo;
      const newUnitCost = Number(excelRow?.newUnitCost);
      const newPrice = Number(excelRow?.newPrice);
      const startDate = excelRow?.startDate;
      let checked = upcList.includes(importedItemTrimmedUpc);
      if (!checked && importedItem.commodityUpcs !== undefined) {
        checked = importedItem.commodityUpcs.some(
          (commodityUpc) => upcList.includes(trimUpc(commodityUpc)),
        );
      }

      const item: RetailerImpItem = {
        ...importedItem,
        upc: importedItemTrimmedUpc,
        checked,
        ...(memo ? { memo } : {}),
        ...(Number.isNaN(newUnitCost) ? {} : { newUnitCost }),
        ...(Number.isNaN(newPrice) ? {} : { newPrice }),
        ...(startDate ? { startDate } : {}),
        priceChangeType: selectedPriceChangeType,
      };

      if (validateItemLinkCode(item.linkCode)) {
        const groupCode = item.linkCode || '';
        item.groupCode = groupCode;
        return {
          ...total,
          [groupCode]: total[groupCode] ? [...total[groupCode], item] : [item],
        };
      }

      item.groupCode = item.upc;
      return {
        ...total,
        [item.upc]: [item],
      };
    },
    {},
  );

  Object.keys(itemsGroups).forEach(
    (groupCode: string) => {
      const group = itemsGroups[groupCode];
      const firstNewUnitCost = group.find((item) => typeof item.newUnitCost === 'number');
      const firstNewPrice = group.find((item) => typeof item.newPrice === 'number');
      const firstStartDate = group.find((item) => item.startDate);
      group.forEach((item: RetailerImpItem) => {
        item.newUnitCost = firstNewUnitCost?.newUnitCost;
        item.newPrice = firstNewPrice?.newPrice;
        item.startDate = firstStartDate?.startDate;
      });
    },
  );

  return itemsGroups;
};

export const getPriceChangeNameError = (priceChangeName: string): string => {
  if (!priceChangeName) return 'Price change name must be set';
  if (!OFFER_NAME_REGEX.test(priceChangeName)) {
    return (
      'Price change name can\'t only be or start with a space or a list of special characters.'
    );
  }
  return '';
};
