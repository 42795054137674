import { createSlice } from '@reduxjs/toolkit';

import { Role } from 'resources/types';
import { getRoles } from './thunks';

export interface RolesState {
  items: Array<Role>;
  loading: boolean;
}

const initialState: RolesState = {
  items: [],
  loading: false,
};

/* eslint-disable no-param-reassign */

const rolesSlice = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    reset: () => initialState,
  },

  extraReducers: (builder) => {
    builder.addCase(getRoles.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getRoles.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getRoles.fulfilled, (state, action) => {
      state.loading = false;
      state.items = action.payload;
    });
  },
});

const { reset: resetRolesSlice } = rolesSlice.actions;

export { resetRolesSlice, getRoles };

export default rolesSlice;
