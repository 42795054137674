import { PayloadAction, createSlice, isAnyOf } from '@reduxjs/toolkit';

import {
  RetailerRoundingRule,
  RoundingRuleDeletionOptions,
  RetailerDepartmentRoundingRule,
} from 'resources/types';
import {
  getRetailerDefaultRoundingRules,
  updateRetailerCategoryLockStatus,
  createRetailerDefaultRoundingRule,
  deleteRetailerDefaultRoundingRule,
  updateRetailerDepartmentLockStatus,
  updateRetailerDefaultRoundingRules,
  createRetailerCategoryRoundingRule,
  deleteRetailerCategoryRoundingRule,
  updateRetailerCategoryRoundingRules,
  getRetailerDepartmentsRoundingRules,
  createRetailerDepartmentRoundingRule,
  deleteRetailerDepartmentRoundingRule,
  fillDownRetailerDefaultRoundingRules,
  updateRetailerDepartmentRoundingRules,
  fillDownRetailerDepartmentRoundingRules,
} from './thunks';

export interface RetailerRoundingRulesState {
  defaultRules: {
    items: Array<RetailerRoundingRule>;
    loading: boolean;
    open: boolean;
  }
  departmentsRules: {
    items: Array<RetailerDepartmentRoundingRule>;
    loading: boolean;
    openedCategories: Array<number>;
    openedDepartments: Array<number>;
  }

  actionLoading: boolean;
  deletionOptions: RoundingRuleDeletionOptions | null
}

const initialState: RetailerRoundingRulesState = {
  defaultRules: {
    items: [],
    loading: false,
    open: false,
  },
  departmentsRules: {
    items: [],
    loading: false,
    openedCategories: [],
    openedDepartments: [],
  },

  actionLoading: false,
  deletionOptions: null,
};

/* eslint-disable no-param-reassign */

const retailerRoundingRulesSlice = createSlice({
  name: 'retailer/rulesSetup/roundingRules',
  initialState,
  reducers: {
    reset: () => initialState,
    setOpenedDepartments: (state, action: PayloadAction<number>) => {
      state.departmentsRules.openedDepartments = (
        state.departmentsRules.openedDepartments.includes(action.payload) ? (
          state.departmentsRules.openedDepartments.filter((id) => id !== action.payload)
        ) : (
          [...state.departmentsRules.openedDepartments, action.payload]
        )
      );
    },
    setOpenedCategories: (state, action: PayloadAction<number>) => {
      state.departmentsRules.openedCategories = (
        state.departmentsRules.openedCategories.includes(action.payload) ? (
          state.departmentsRules.openedCategories.filter((id) => id !== action.payload)
        ) : (
          [...state.departmentsRules.openedCategories, action.payload]
        )
      );
    },
    toggleDefaultRules: (state) => {
      state.defaultRules.open = !state.defaultRules.open;
    },
    setDeletionOptions: (state, action: PayloadAction<RoundingRuleDeletionOptions | null>) => {
      state.deletionOptions = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getRetailerDefaultRoundingRules.pending, (state) => {
      state.defaultRules.loading = true;
    });
    builder.addCase(getRetailerDefaultRoundingRules.rejected, (state) => {
      state.defaultRules.loading = false;
    });

    builder.addCase(getRetailerDepartmentsRoundingRules.pending, (state) => {
      state.departmentsRules.loading = true;
    });
    builder.addCase(getRetailerDepartmentsRoundingRules.rejected, (state) => {
      state.departmentsRules.loading = false;
    });

    builder.addCase(fillDownRetailerDepartmentRoundingRules.fulfilled, (state, action) => {
      state.departmentsRules.items = state.departmentsRules.items.map(
        (department) => (department.id === action.payload.id ? ({
          ...action.payload,
        }) : (
          department
        )),
      );
    });

    builder.addMatcher(isAnyOf(
      updateRetailerCategoryLockStatus.fulfilled,
      updateRetailerDepartmentLockStatus.fulfilled,
    ), (state, action) => {
      const { departmentId } = action.meta.arg;
      state.departmentsRules.items = state.departmentsRules.items
        .map((department) => (department.id === departmentId ? ({
          ...action.payload,
          roundingRules: department.roundingRules,
          categories: action.payload.categories.map((category) => ({
            ...category,
            roundingRules: category.roundingRules,
          })),
        }) : (
          department
        )));
    });

    builder.addMatcher(isAnyOf(
      getRetailerDefaultRoundingRules.fulfilled,
      createRetailerDefaultRoundingRule.fulfilled,
      deleteRetailerDefaultRoundingRule.fulfilled,
      updateRetailerDefaultRoundingRules.fulfilled,
    ), (state, action) => {
      state.actionLoading = false;
      state.deletionOptions = null;
      state.defaultRules.loading = false;
      state.defaultRules.items = action.payload;
    });

    builder.addMatcher(isAnyOf(
      getRetailerDepartmentsRoundingRules.fulfilled,
      fillDownRetailerDefaultRoundingRules.fulfilled,
    ), (state, action) => {
      state.actionLoading = false;
      state.departmentsRules.loading = false;
      state.departmentsRules.items = action.payload;
    });

    builder.addMatcher(isAnyOf(
      createRetailerDepartmentRoundingRule.fulfilled,
      deleteRetailerDepartmentRoundingRule.fulfilled,
      updateRetailerDepartmentRoundingRules.fulfilled,
    ), (state, action) => {
      const { departmentId } = action.meta.arg;
      state.actionLoading = false;
      state.deletionOptions = null;
      state.departmentsRules.loading = false;
      state.departmentsRules.items = state.departmentsRules.items.map(
        (department) => (department.id === departmentId ? ({
          ...department,
          roundingRules: action.payload,
        }) : (
          department
        )),
      );
    });

    builder.addMatcher(isAnyOf(
      createRetailerCategoryRoundingRule.fulfilled,
      deleteRetailerCategoryRoundingRule.fulfilled,
      updateRetailerCategoryRoundingRules.fulfilled,
    ), (state, action) => {
      const { departmentId, categoryId } = action.meta.arg;
      state.actionLoading = false;
      state.deletionOptions = null;
      state.departmentsRules.loading = false;
      state.departmentsRules.items = state.departmentsRules.items.map(
        (department) => (department.id === departmentId ? ({
          ...department,
          categories: department.categories.map(
            (category) => (category.id === categoryId ? ({
              ...category,
              roundingRules: action.payload,
            }) : (
              category
            )),
          ),
        }) : (
          department
        )),
      );
    });

    // Action Loadings
    builder.addMatcher(isAnyOf(
      updateRetailerCategoryLockStatus.pending,
      createRetailerDefaultRoundingRule.pending,
      deleteRetailerDefaultRoundingRule.pending,
      updateRetailerDefaultRoundingRules.pending,
      updateRetailerDepartmentLockStatus.pending,
      createRetailerCategoryRoundingRule.pending,
      deleteRetailerCategoryRoundingRule.pending,
      updateRetailerCategoryRoundingRules.pending,
      createRetailerDepartmentRoundingRule.pending,
      fillDownRetailerDefaultRoundingRules.pending,
      deleteRetailerDepartmentRoundingRule.pending,
      updateRetailerDepartmentRoundingRules.pending,
      fillDownRetailerDepartmentRoundingRules.pending,
    ), (state) => {
      state.actionLoading = true;
    });

    builder.addMatcher(isAnyOf(
      updateRetailerCategoryLockStatus.rejected,
      createRetailerDefaultRoundingRule.rejected,
      updateRetailerCategoryLockStatus.fulfilled,
      deleteRetailerDefaultRoundingRule.rejected,
      createRetailerCategoryRoundingRule.rejected,
      deleteRetailerCategoryRoundingRule.rejected,
      createRetailerDefaultRoundingRule.fulfilled,
      updateRetailerDefaultRoundingRules.rejected,
      deleteRetailerDefaultRoundingRule.fulfilled,
      updateRetailerDepartmentLockStatus.rejected,
      updateRetailerDefaultRoundingRules.fulfilled,
      createRetailerCategoryRoundingRule.fulfilled,
      updateRetailerCategoryRoundingRules.rejected,
      deleteRetailerCategoryRoundingRule.fulfilled,
      updateRetailerDepartmentLockStatus.fulfilled,
      fillDownRetailerDefaultRoundingRules.rejected,
      updateRetailerCategoryRoundingRules.fulfilled,
      createRetailerDepartmentRoundingRule.rejected,
      deleteRetailerDepartmentRoundingRule.rejected,
      updateRetailerDepartmentRoundingRules.rejected,
      deleteRetailerDepartmentRoundingRule.fulfilled,
      createRetailerDepartmentRoundingRule.fulfilled,
      fillDownRetailerDefaultRoundingRules.fulfilled,
      updateRetailerDepartmentRoundingRules.fulfilled,
      fillDownRetailerDepartmentRoundingRules.rejected,
      fillDownRetailerDepartmentRoundingRules.fulfilled,
    ), (state) => {
      state.actionLoading = false;
    });
  },
});

const {
  setDeletionOptions,
  toggleDefaultRules,
  setOpenedCategories,
  setOpenedDepartments,
  reset: resetRetailerRoundingRulesState,
} = retailerRoundingRulesSlice.actions;

export {
  setDeletionOptions,
  toggleDefaultRules,
  setOpenedCategories,
  setOpenedDepartments,
  resetRetailerRoundingRulesState,
  getRetailerDefaultRoundingRules,
  updateRetailerCategoryLockStatus,
  createRetailerDefaultRoundingRule,
  deleteRetailerDefaultRoundingRule,
  updateRetailerDepartmentLockStatus,
  updateRetailerDefaultRoundingRules,
  createRetailerCategoryRoundingRule,
  deleteRetailerCategoryRoundingRule,
  updateRetailerCategoryRoundingRules,
  getRetailerDepartmentsRoundingRules,
  createRetailerDepartmentRoundingRule,
  deleteRetailerDepartmentRoundingRule,
  fillDownRetailerDefaultRoundingRules,
  updateRetailerDepartmentRoundingRules,
  fillDownRetailerDepartmentRoundingRules,
};

export default retailerRoundingRulesSlice;
