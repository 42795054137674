import {
  UserScope,
  SelectOption,
  RetailerImpItem,
  BasePriceChangeType,
  RetailerCompanyStatus,
  RetailerSandboxOfferType,
  RetailerSandboxOfferPlacement,
  RetailerSandboxSortBySelectOption,
  RetailerSandboxExcludedItemAction,
  RetailerBasePriceSandboxExcludedItemAction,
  RetailerBasePriceSandboxSortBySelectOption,
} from 'resources/types';

export * from './roles';
export * from './messages';
export * from './endpoints';
export {
  adminPrivileges,
  supplierPrivileges,
  retailerPrivileges,
  default as privileges,
} from './privileges';

export const routsPatterns = {
  // Common Routes
  SIGN_IN: '/sign-in',
  SIGN_UP: '/sign-up/:email/:key',
  FORGOT_PASSWORD: '/password/reset',
  RESET_PASSWORD: '/password/reset/:email/:key',

  // Admin Routes
  ADMIN: '/admin',
  ADMIN_DASHBOARD: '/admin/dashboard',
  ADMIN_PROFILE: '/admin/profile',
  ADMIN_VENDORS: '/admin/vendors',
  ADMIN_VENDOR_MANAGEMENT: '/admin/vendor-management',
  ADMIN_RETAILER_MANAGEMENT: '/admin/retailer-management',

  // Supplier Routes
  SUPPLIER: '/supplier',
  SUPPLIER_DASHBOARD: '/supplier/dashboard',
  SUPPLIER_PROFILE: '/supplier/profile',

  // Retailer Routes
  RETAILER: '/retailer',
  RETAILER_PROFILE: '/retailer/profile',
  RETAILER_COMPANY: '/retailer/company',
  RETAILER_DASHBOARD: '/retailer/dashboard',
  RETAILER_EMPLOYEES: '/retailer/employees',
  RETAILER_SANDBOX: '/retailer/sandbox/:adId',
  RETAILER_IMP_ITEMS: '/retailer/imp-items/:adId',
  RETAILER_AD_MANAGER: '/retailer/company-ad-manager',
  RETAILER_STORE_MANAGEMENT: '/retailer/store-management',
  RETAILER_DEPARTMENT_MANAGER: '/retailer/department-manager',
  RETAILER_WAREHOUSE_MANAGEMENT: '/retailer/warehouse-management',
  RETAILER_GROSS_MARGIN_MANAGER: '/retailer/gross-margin-manager',

  // Retailer Margin Enhancement Tool Routes
  RETAILER_MARGIN_ENHANCEMENT: '/retailer/margin-enhancement',
  RETAILER_ME_IMP_ITEMS: '/retailer/margin-enhancement/imp-items/:adId',
  RETAILER_BASE_PRICE_MANAGER: '/retailer/margin-enhancement/base-price-manager',
  RETAILER_TPR_PASS: '/retailer/margin-enhancement/tpr-pass',
  RETAILER_ELASTIC_PRICE: '/retailer/margin-enhancement/elastic-price',

  RETAILER_RULES_SETUP: '/retailer/rules-setup',
  RETAILER_ROUNDING_RULES: '/retailer/rules-setup/rounding-rules',
  RETAILER_TPR_PASSTHROUGH: '/retailer/rules-setup/tpr-passthrough',
};

export const userScopeRoutes: { [key in UserScope]: string } = {
  USER_SCOPE_ADMIN: routsPatterns.ADMIN,
  USER_SCOPE_RETAILER: routsPatterns.RETAILER,
  USER_SCOPE_SUPPLIER: routsPatterns.SUPPLIER,
};

export const userScopeProfileRoutes: { [key in UserScope]: string } = {
  USER_SCOPE_ADMIN: routsPatterns.ADMIN_PROFILE,
  USER_SCOPE_RETAILER: routsPatterns.RETAILER_PROFILE,
  USER_SCOPE_SUPPLIER: routsPatterns.SUPPLIER_PROFILE,
};

export const dynamicRouts = {
  RETAILER_SANDBOX: (adId: number) => `/retailer/sandbox/${adId}`,
  RETAILER_IMP_ITEMS: (adId: number) => `/retailer/imp-items/${adId}`,
  RETAILER_ME_IMP_ITEMS: (adId: number) => `/retailer/margin-enhancement/imp-items/${adId}`,
  RETAILER_BASE_PRICE_MANAGER_SANDBOX: (adId: number) => `/retailer/margin-enhancement/base-price-manager?week=${adId}`,
};

export const rulesSetupRoutes: Array<{ label: string, path: string, disabled?: boolean }> = [
  {
    label: 'Rounding Rules',
    path: routsPatterns.RETAILER_ROUNDING_RULES,
  },
  {
    label: 'TPR Passthrough',
    path: routsPatterns.RETAILER_TPR_PASSTHROUGH,
    disabled: true,
  },
];

export const localStorageKeys: { [key: string]: string } = {
  ACCESS_TOKEN: 'access_token',
  REFRESH_TOKEN: 'refresh_token',
  AD_SANDBOX: 'ad_sandbox',
  BASE_PRICE_SANDBOX: 'base_price_sandbox',
};

export const ROWS_PER_PAGES: number[] = [20, 50, 100];

export const WEEK_DAYS: string[] = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

export const SHORT_WEEK_DAYS: string[] = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

export const queries = {
  PAGE: 'page',
  SIZE: 'size',
  SEARCH: 'search',
  GROUP: 'group',
  WEEK: 'week',
  YEAR: 'year',
  STATUS: 'status',
  CATEGORY_CODE: 'categoryCode',
  DEPARTMENT_CODE: 'departmentCode',
  SORT_BY: 'sort',
  FILTER_BY: 'filter',
};

export const unGroup = {
  value: 'ungroup',
  label: 'Ungrouped',
};

export const retailerCompanyStatuses: SelectOption<RetailerCompanyStatus>[] = [
  { value: 'READY', label: 'Ready' },
  { value: 'DRAFT', label: 'Draft' },
];

export const placements: SelectOption<RetailerSandboxOfferPlacement>[] = [
  { value: 'front', label: 'Front Page' },
  { value: 'back', label: 'Back Page' },
  { value: 'inner', label: 'Inner Page' },
  { value: 'inStore', label: 'In Store' },
  { value: 'specialAd', label: 'Special Ad' },
];

export const priceChangeTypes: SelectOption<BasePriceChangeType>[] = [
  { value: 'bulkSales', label: 'Bulk sales' },
  { value: 'costChange', label: 'Cost change' },
  { value: 'compPriceMatch', label: 'Comp. price match' },
  { value: 'marginStructure', label: 'Margin structure' },
  { value: 'perishableUpdate', label: 'Perishable update' },
];

export const offerTypes: SelectOption<RetailerSandboxOfferType>[] = [
  { value: '', label: '' },
  { value: 'bogo', label: 'BOGO' },
  { value: 'pOff', label: '% Off' },
  { value: 'mustBuys', label: 'Must Buys' },
  { value: 'requiredRetail', label: 'Required Retail' },
  { value: 'multipleQuantity', label: 'Multiple Quantity' },
];

export const adSandboxSortingOptions: RetailerSandboxSortBySelectOption[] = [
  { value: '', label: 'Discard Sorting' },
  { value: 'salesItems,desc', label: 'Highest Sales Items', icon: 'up' },
  { value: 'salesItems,asc', label: 'Lowest Sales Items', icon: 'down' },
  { value: 'grossMarginPercent,desc', label: 'Highest % Gross Margin Items', icon: 'up' },
  { value: 'grossMarginPercent,asc', label: 'Lowest % Gross Margin Items', icon: 'down' },
  { value: 'grossMargin,desc', label: 'Highest $ Gross Margin Items', icon: 'up' },
  { value: 'grossMargin,asc', label: 'Lowest $ Gross Margin Items', icon: 'down' },
  { value: 'projectedVolume,desc', label: 'Highest Projected Volume', icon: 'up' },
  { value: 'projectedVolume,asc', label: 'Lowest Projected Volume', icon: 'down' },
];

export const adSandboxFiltersOptions: SelectOption[] = [
  { value: '', label: 'All UPC\'s' },
  { value: 'ups-with-issues', label: 'UPC\'s with issues' },
];

export const basePriceSandboxSortingOptions: RetailerBasePriceSandboxSortBySelectOption[] = [
  { value: '', label: 'Discard Sorting' },
  { value: 'salesItems,desc', label: 'Highest Sales Items', icon: 'up' },
  { value: 'salesItems,asc', label: 'Lowest Sales Items', icon: 'down' },
  { value: 'grossMarginPercent,desc', label: 'Highest % Gross Margin Items', icon: 'up' },
  { value: 'grossMarginPercent,asc', label: 'Lowest % Gross Margin Items', icon: 'down' },
  { value: 'grossMargin,desc', label: 'Highest $ Gross Margin Items', icon: 'up' },
  { value: 'grossMargin,asc', label: 'Lowest $ Gross Margin Items', icon: 'down' },
  { value: 'projectedVolume,desc', label: 'Highest Projected Volume', icon: 'up' },
  { value: 'projectedVolume,asc', label: 'Lowest Projected Volume', icon: 'down' },
];

export const basePriceSandboxFiltersOptions: SelectOption[] = [
  { value: '', label: 'All UPC\'s' },
  { value: 'ups-with-issues', label: 'UPC\'s with issues' },
];

export const headers: Record<string, string> = {
  AI_ACCESS_TOKEN: 'ai-access-token',
};

export const cookies: Record<string, string> = {
  AI_ACCESS_TOKEN: 'ai_access_token',
};

export const AI_ACCESS_TOKEN_MAX_AGE: number = 300;
export const DEBOUNCE_INPUT_TIMEOUT: number = 1800;

export const OFFER_NAME_REGEX: RegExp = /(^[A-Za-z0-9]+).*/;

export const sandboxExcludedItemActions: { [key in RetailerSandboxExcludedItemAction]: string } = {
  SANDBOX_ITEM_MISSING: 'Became Inactive',
  SANDBOX_ITEM_FAMILY_CHANGED: 'Family Changed',
  SANDBOX_ITEM_DEPARTMENT_CHANGED: 'Department Changed',
};

export const basePriceSandboxExcludedItemActions: {
  [key in RetailerBasePriceSandboxExcludedItemAction]: string
} = {
  SANDBOX_ITEM_MISSING: 'Became Inactive',
  SANDBOX_ITEM_FAMILY_CHANGED: 'Family Changed',
  SANDBOX_ITEM_DEPARTMENT_CHANGED: 'Department Changed',
};

export const batchUploadTitles: { [key: string]: string } = {
  upc: 'UPC',
  memo: 'Memo',
  adUnitCost: 'Deal Cost',
  offerPrice: 'Offer Price',
  promoNumber: 'Promo Number',
  blockNumber: 'Block Number',
};

export const basePriceMGRBatchUploadTitles: { [key: string]: string } = {
  upc: 'UPC',
  newUnitCost: 'New Cost',
  newPrice: 'New Price',
  startDate: 'Start Date',
};

export const batchUploadPossibleColumns: Array<keyof RetailerImpItem> = [
  'upc',
  'adUnitCost',
  'offerPrice',
  'memo',
  'promoNumber',
  'blockNumber',
];

export const basePriceMGRBatchUploadPossibleColumns: Array<keyof RetailerImpItem> = [
  'upc',
  'newUnitCost',
  'newPrice',
  'startDate',
];

export const UI_DATE_FORMAT: string = 'DD/MM/YYYY';
export const API_DATE_FORMAT: string = 'YYYY-MM-DD';

export const ROUNDING_RULE_FIELD_REGEX: RegExp = /^[0-9]{0,2}$/;
