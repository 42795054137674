import { useEffect, ReactElement } from 'react';
import { useSnackbar, SnackbarProvider, MaterialDesignContent } from 'notistack';
import { styled } from '@mui/material/styles';

import { RootState } from 'store';
import theme from 'resources/theme';
import { useAppSelector } from 'hooks';

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  '&.notistack-MuiContent-success': {
    backgroundColor: theme.palette.success.main,
  },
  '&.notistack-MuiContent-error': {
    backgroundColor: theme.palette.error.main,
  },
  '&.notistack-MuiContent-warning': {
    backgroundColor: theme.palette.warning.main,
  },
  '&.notistack-MuiContent-info': {
    backgroundColor: theme.palette.info.main,
  },
}));

function Notifier(props: { children: ReactElement }) {
  const { enqueueSnackbar } = useSnackbar();
  const { children } = props;
  const notifier = useAppSelector((state: RootState) => state.app.notifier);

  const { options } = notifier;

  useEffect(
    () => {
      if (notifier.message) enqueueSnackbar(notifier.message, { ...options });
    },
    [enqueueSnackbar, notifier, options],
  );

  return children;
}

function NotifierProvider(props: { children: ReactElement }) {
  const { children } = props;

  return (
    <SnackbarProvider
      Components={{
        success: StyledMaterialDesignContent,
        error: StyledMaterialDesignContent,
        warning: StyledMaterialDesignContent,
        info: StyledMaterialDesignContent,
      }}
    >
      <Notifier>
        {children}
      </Notifier>
    </SnackbarProvider>
  );
}

export default NotifierProvider;
