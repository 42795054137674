import { useCallback, MouseEvent, ChangeEvent } from 'react';
import { TextField, TextFieldProps, TextFieldPropsColorOverrides } from '@mui/material';

import { useDebounce } from 'hooks';

export interface DebounceInputProps {
  rows?: number;
  value?: string;
  maxRows?: number;
  minRows?: number;
  disabled?: boolean;
  fullWidth?: boolean;
  multiline?: boolean;
  debounceTimeout?: number,
  name?: string | undefined;
  label?: string | undefined;
  InputProps?: TextFieldProps['InputProps'];
  color?: keyof TextFieldPropsColorOverrides;
  defaultValue?: TextFieldProps['defaultValue'];
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

function DebounceInput(props: DebounceInputProps) {
  const {
    rows,
    value,
    label,
    color,
    onChange,
    disabled,
    maxRows,
    minRows,
    name = '',
    multiline,
    fullWidth,
    InputProps,
    defaultValue,
    debounceTimeout,
  } = props;

  const debounceFunction = useDebounce(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange(event);
    },
    debounceTimeout,
  );

  const onInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      debounceFunction(event);
    },
    [debounceFunction],
  );

  const onClick = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
    },
    [],
  );

  const focused = color === 'warning' || color === 'error';

  return (
    <TextField
      name={name}
      rows={rows}
      label={label}
      color={color}
      value={value}
      maxRows={maxRows}
      minRows={minRows}
      focused={focused}
      onClick={onClick}
      autoComplete="off"
      disabled={disabled}
      multiline={multiline}
      fullWidth={fullWidth}
      InputProps={InputProps}
      onChange={onInputChange}
      defaultValue={defaultValue}
    />
  );
}

DebounceInput.defaultProps = {
  name: '',
  rows: undefined,
  disabled: false,
  value: undefined,
  color: undefined,
  label: undefined,
  fullWidth: false,
  debounceTimeout: 0,
  maxRows: undefined,
  minRows: undefined,
  multiline: undefined,
  InputProps: undefined,
  defaultValue: undefined,
};

export default DebounceInput;
