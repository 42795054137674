import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { ROWS_PER_PAGES } from 'resources/constants';
import { Retailer, Pagination } from 'resources/types';
import {
  getRetailers,
  createRetailer,
  deleteRetailer,
  changeRetailerCompanyStatus,
} from './thunks';

export interface AdminRetailersState {
  items: Array<Retailer>;
  pagination: Pagination;
  loading: boolean;
  actionLoading: boolean;
}

const initialState: AdminRetailersState = {
  items: [],
  pagination: {
    page: 0,
    totalPages: 1,
    totalItems: 0,
    size: ROWS_PER_PAGES[0],
  },
  loading: false,
  actionLoading: false,
};

/* eslint-disable no-param-reassign */

const adminRetailersSlice = createSlice({
  name: 'admin/retailers',
  initialState,
  reducers: {
    reset: () => initialState,
  },

  extraReducers: (builder) => {
    builder.addCase(getRetailers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getRetailers.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getRetailers.fulfilled, (state, action) => {
      const {
        items, page, size, totalPages, totalItems,
      } = action.payload;
      state.loading = false;
      state.items = items;
      state.pagination = {
        page, size, totalPages, totalItems,
      };
    });

    builder.addMatcher(isAnyOf(
      deleteRetailer.pending,
      changeRetailerCompanyStatus.pending,
    ), (state) => {
      state.actionLoading = true;
    });

    builder.addMatcher(isAnyOf(
      deleteRetailer.rejected,
      deleteRetailer.fulfilled,
      changeRetailerCompanyStatus.rejected,
      changeRetailerCompanyStatus.fulfilled,
    ), (state) => {
      state.actionLoading = false;
    });
  },
});

const { reset: resetAdminRetailersState } = adminRetailersSlice.actions;

export {
  getRetailers,
  createRetailer,
  deleteRetailer,
  resetAdminRetailersState,
  changeRetailerCompanyStatus,
};

export default adminRetailersSlice;
