import { Link } from 'react-router-dom';
import { Grid, Theme, Typography } from '@mui/material';

import { routsPatterns } from 'resources/constants';

function BackToSignIn() {
  return (
    <Grid item container xs={12} mt={3}>
      <Typography variant="subtitle2" sx={{ display: 'flex' }}>
        Return to
        <Link to={routsPatterns.SIGN_IN} style={{ textDecoration: 'none' }}>
          <Typography
            variant="subtitle2"
            sx={(theme: Theme) => ({ ml: 0.4, color: theme.palette.info.main })}
          >
            Sign in
          </Typography>
        </Link>
      </Typography>
    </Grid>
  );
}

export default BackToSignIn;
