import { createAsyncThunk } from '@reduxjs/toolkit';

import services from 'services';
import {
  ListQueries,
  ListResponse,
  RetailerEmployee,
  RetailerEmployeeBody,
} from 'resources/types';

const GET_RETAILER_EMPLOYEES = 'retailer/employees/getRetailerEmployees';
const UPDATE_RETAILER_EMPLOYEE = 'retailer/employees/updateRetailerEmployee';
const CREATE_RETAILER_EMPLOYEE = 'retailer/employees/createRetailerEmployee';
const DELETE_RETAILER_EMPLOYEE = 'retailer/employees/deleteRetailerEmployee';
const MAKE_OWNER_RETAILER_EMPLOYEE = 'retailer/employees/makeOwnerRetailerEmployee';
const GET_RETAILER_CURRENT_EMPLOYEE = 'retailer/employees/getRetailerCurrentEmployee';
const UPDATE_RETAILER_EMPLOYEE_STATUS = 'retailer/employees/updateRetailerEmployeeStatus';
const RESET_RETAILER_EMPLOYEE_PASSWORD = 'retailer/employees/resetRetailerEmployeePassword';
const RESEND_RETAILER_EMPLOYEE_ACTIVATION = 'retailer/employees/resendRetailerEmployeeActivation';

const getRetailerEmployees = createAsyncThunk<
  ListResponse<RetailerEmployee>,
  ListQueries | undefined
>(
  GET_RETAILER_EMPLOYEES,
  async (query) => {
    const response = await services.getRetailerEmployees(query);
    return response.data;
  },
);

const updateRetailerEmployee = createAsyncThunk<
  RetailerEmployee,
  { employeeId: number, data: RetailerEmployeeBody }
>(
  UPDATE_RETAILER_EMPLOYEE,
  async (params) => {
    const { employeeId, data } = params;
    const response = await services.updateRetailerEmployee(employeeId, data);
    return response.data;
  },
);

const createRetailerEmployee = createAsyncThunk<
  RetailerEmployee,
  RetailerEmployeeBody
>(
  CREATE_RETAILER_EMPLOYEE,
  async (data) => {
    const response = await services.createRetailerEmployee(data);
    return response.data;
  },
);

const deleteRetailerEmployee = createAsyncThunk<
  { value: boolean },
  number
>(
  DELETE_RETAILER_EMPLOYEE,
  async (employeeId) => {
    const response = await services.deleteRetailerEmployee(employeeId);
    return response.data;
  },
);

const makeOwnerRetailerEmployee = createAsyncThunk<
  { value: boolean },
  number
>(
  MAKE_OWNER_RETAILER_EMPLOYEE,
  async (employeeId) => {
    const response = await services.makeOwnerRetailerEmployee(employeeId);
    return response.data;
  },
);

const getRetailerEmployeeById = createAsyncThunk<
  RetailerEmployee,
  number
>(
  GET_RETAILER_CURRENT_EMPLOYEE,
  async (employeeId) => {
    const response = await services.getRetailerEmployeeById(employeeId);
    return response.data;
  },
);

const updateRetailerEmployeeStatus = createAsyncThunk<
  { employeeId: number, locked: boolean },
  { employeeId: number, data: { locked: boolean } }
>(
  UPDATE_RETAILER_EMPLOYEE_STATUS,
  async (params) => {
    const { employeeId, data } = params;
    const response = await services.updateRetailerEmployeeStatus(employeeId, data);
    return response.data;
  },
);

const resetRetailerEmployeePassword = createAsyncThunk<
  void,
  { email: string }
>(
  RESET_RETAILER_EMPLOYEE_PASSWORD,
  async (params) => {
    const response = await services.resetRetailerEmployeePassword(params);
    return response.data;
  },
);

const resendRetailerEmployeeActivation = createAsyncThunk<
  { value: boolean },
  number
>(
  RESEND_RETAILER_EMPLOYEE_ACTIVATION,
  async (employeeId) => {
    const response = await services.resendRetailerEmployeeActivation(employeeId);
    return response.data;
  },
);

export {
  getRetailerEmployees,
  deleteRetailerEmployee,
  createRetailerEmployee,
  updateRetailerEmployee,
  getRetailerEmployeeById,
  makeOwnerRetailerEmployee,
  updateRetailerEmployeeStatus,
  resetRetailerEmployeePassword,
  resendRetailerEmployeeActivation,
};
