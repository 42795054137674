import { AxiosResponse } from 'axios';

import {
  ListQueries,
  RetailerSandbox,
  RetailerSandboxItem,
  RetailerSandboxOffer,
  RetailerSandboxStatus,
  RetailerSandboxItemType,
  RetailerSandboxExcludedItem,
  RetailerItemSalesHistoryResponse,
  RetailerItemPricesHistoryResponse,
  RetailerSandboxOffersUploadingBody,
  RetailerSandboxOrdersConfigsResponse,
  RetailerSandboxDeleteItemsRequestBody,
  RetailerSandboxOfferPricesRequestBody,
  RetailerSandboxOffersUploadingResponse,
  RetailerSandboxChangeDepartmentOrderBody,
} from 'resources/types';
import instance from 'services/instance';
import { endpoints } from 'resources/constants';
import { sandboxEvent } from 'resources/constants/mixpanel';

const getRetailerSandbox = (
  companyId: number,
  adId: number,
  params?: ListQueries,
): Promise<AxiosResponse<RetailerSandbox>> => (
  instance.get(
    endpoints.RETAILER_SANDBOX(companyId, adId),
    {
      params,
      failureMessage: 'Could not get retailer sandbox',
    },
  )
);

const uploadRetailerSandboxOffers = (
  companyId: number,
  adId: number,
  data: RetailerSandboxOffersUploadingBody,
): Promise<AxiosResponse<RetailerSandboxOffersUploadingResponse>> => (
  instance.post(
    endpoints.RETAILER_SANDBOX(companyId, adId),
    data,
    {
      failureMessage: 'Could not upload retailer sandbox offers',
      mixpanelEvent: {
        EventName: sandboxEvent.addSandboxOffer,
        Value: adId,
      },
    },
  )
);

const getRetailerSandboxStatus = (
  companyId: number,
  adId: number,
): Promise<AxiosResponse<{ value: RetailerSandboxStatus }>> => (
  instance.get(
    endpoints.RETAILER_SANDBOX_STATUS(companyId, adId),
    {
      preventFeedbackMessage: true,
    },
  )
);

const getRetailerSandboxExcludedItems = (
  companyId: number,
  adId: number,
): Promise<AxiosResponse<Array<RetailerSandboxExcludedItem>>> => (
  instance.get(
    endpoints.RETAILER_SANDBOX_EXCLUDED_ITEMS(companyId, adId),
  )
);

const removeRetailerSandboxExcludedItems = (
  companyId: number,
  adId: number,
): Promise<AxiosResponse<{ value: boolean }>> => (
  instance.delete(
    endpoints.RETAILER_SANDBOX_EXCLUDED_ITEMS(companyId, adId),
  )
);

const changeRetailerSandboxItemStatus = (
  companyId: number,
  adId: number,
  itemType: RetailerSandboxItemType,
  itemId: number,
  data: { value: boolean },
): Promise<AxiosResponse<RetailerSandboxOffer>> => (
  instance.put(
    endpoints.RETAILER_SANDBOX_ITEM_STATUS(companyId, adId, itemType, itemId),
    data,
    {
      successMessage: 'The sandbox item status has been updated',
      failureMessage: 'Could not update sandbox item status',
      mixpanelEvent: {
        EventName: sandboxEvent.changeRowStatus,
        Field: itemType,
        Value: itemId,
      },
    },
  )
);

const updateRetailerSandboxItem = (
  companyId: number,
  adId: number,
  itemId: number,
  data: RetailerSandboxItem,
  name: string,
): Promise<AxiosResponse<RetailerSandboxOffer>> => (
  instance.put(
    endpoints.RETAILER_SANDBOX_ITEM(companyId, adId, itemId),
    data,
    {
      successMessage: 'The item has been updated',
      failureMessage: 'Something went wrong, could not add update Sandbox item',
      mixpanelEvent: {
        EventName: sandboxEvent.updateItemField,
        Field: name,
        Value: data[name as keyof RetailerSandboxItem],
      },
    },
  )
);

const updateRetailerSandboxItemNames = (
  companyId: number,
  adId: number,
  itemId: number,
  data: RetailerSandboxItem,
  name: string,
): Promise<AxiosResponse<RetailerSandboxOffer>> => (
  instance.put(
    endpoints.RETAILER_SANDBOX_ITEM_NAMES(companyId, adId, itemId),
    data,
    {
      successMessage: 'Sandbox item successfully updated',
      failureMessage: 'Could not update sandbox item',
      mixpanelEvent: {
        EventName: sandboxEvent.updateItemField,
        Field: name,
        Value: data[name as keyof RetailerSandboxItem],
      },
    },
  )
);

const updateRetailerSandboxOfferNames = (
  companyId: number,
  adId: number,
  offerId: number,
  data: RetailerSandboxOffer,
  name: string,
): Promise<AxiosResponse<RetailerSandboxOffer>> => (
  instance.put(
    endpoints.RETAILER_SANDBOX_OFFER_NAMES(companyId, adId, offerId),
    data,
    {
      successMessage: 'Sandbox offer successfully updated',
      failureMessage: 'Could not update sandbox offer',
      mixpanelEvent: {
        EventName: sandboxEvent.updateOfferField,
        Field: name,
        Value: data[name as keyof RetailerSandboxOffer],
      },
    },
  )
);

const updateRetailerSandboxOffer = (
  companyId: number,
  adId: number,
  offerId: number,
  data: RetailerSandboxOffer,
  name: string,
): Promise<AxiosResponse<RetailerSandboxOffer>> => (
  instance.put(
    endpoints.RETAILER_SANDBOX_OFFER(companyId, adId, offerId),
    data,
    {
      successMessage: 'Sandbox items successfully updated',
      failureMessage: 'Could not update sandbox offer',
      mixpanelEvent: {
        EventName: sandboxEvent.updateOfferField,
        Field: name,
        Value: data[name as keyof RetailerSandboxOffer],
      },
    },
  )
);

const updateRetailerSandboxOffers = (
  companyId: number,
  adId: number,
  data: RetailerSandboxOfferPricesRequestBody,
): Promise<AxiosResponse<RetailerSandbox>> => (
  instance.put(
    endpoints.RETAILER_SANDBOX_OFFERS(companyId, adId),
    data,
    {
      successMessage: 'Sandbox items successfully updated',
      failureMessage: 'Could not duplicate sandbox offers',
    },
  )
);

const duplicateRetailerSandboxOffer = (
  companyId: number,
  adId: number,
  offerId: number,
): Promise<AxiosResponse<RetailerSandboxOffer>> => (
  instance.put(
    endpoints.RETAILER_SANDBOX_OFFER_DUPLICATE(companyId, adId, offerId),
    undefined,
    {
      successMessage: 'The offer has been duplicated.',
      failureMessage: 'Something went wrong, could not duplicate offer',
      mixpanelEvent: {
        EventName: sandboxEvent.duplicateSandboxOffer,
        Value: offerId,
      },
    },
  )
);

const duplicateRetailerSandboxItem = (
  companyId: number,
  adId: number,
  itemId: number,
): Promise<AxiosResponse<RetailerSandboxOffer>> => (
  instance.put(
    endpoints.RETAILER_SANDBOX_ITEM_DUPLICATE(companyId, adId, itemId),
    undefined,
    {
      successMessage: 'The item has been duplicated.',
      failureMessage: 'Something went wrong, could not duplicate item',
      mixpanelEvent: {
        EventName: sandboxEvent.duplicateSandboxItem,
        Value: itemId,
      },
    },
  )
);

const removeRetailerSandboxOffer = (
  companyId: number,
  adId: number,
  offerId: number,
): Promise<AxiosResponse<RetailerSandbox>> => (
  instance.delete(
    endpoints.RETAILER_SANDBOX_OFFER(companyId, adId, offerId),
    {
      successMessage: 'The offer has been removed.',
      failureMessage: 'Something went wrong, could not remove offer',
      mixpanelEvent: {
        EventName: sandboxEvent.removeSandboxRow,
        Field: 'offer',
        Value: offerId,
      },
    },
  )
);

const removeRetailerSandboxItem = (
  companyId: number,
  adId: number,
  itemId: number,
): Promise<AxiosResponse<RetailerSandbox>> => (
  instance.delete(
    endpoints.RETAILER_SANDBOX_ITEM(companyId, adId, itemId),
    {
      successMessage: 'The item has been removed.',
      failureMessage: 'Something went wrong, could not remove item',
      mixpanelEvent: {
        EventName: sandboxEvent.removeSandboxRow,
        Field: 'item',
        Value: itemId,
      },
    },
  )
);

const removeRetailerSandboxSelectedItems = (
  companyId: number,
  adId: number,
  params?: RetailerSandboxDeleteItemsRequestBody,
): Promise<AxiosResponse<RetailerSandbox>> => (
  instance.delete(
    endpoints.RETAILER_SANDBOX_BY_CHECKED(companyId, adId),
    {
      params,
      successMessage: 'The items has been removed.',
      failureMessage: 'Something went wrong, could not remove items',
    },
  )
);

const removeRetailerSandboxAllItems = (
  companyId: number,
  adId: number,
): Promise<AxiosResponse<RetailerSandbox>> => (
  instance.delete(
    endpoints.RETAILER_SANDBOX(companyId, adId),
    {
      successMessage: 'The items has been removed.',
      failureMessage: 'Something went wrong, could not remove items',
      mixpanelEvent: {
        EventName: sandboxEvent.removeMultipleItems,
      },
    },
  )
);

const getRetailerSandboxOrdersConfigs = (
  companyId: number,
  adId: number,
): Promise<AxiosResponse<RetailerSandboxOrdersConfigsResponse>> => (
  instance.get(
    endpoints.RETAILER_SANDBOX_ORDERS_CONFIG(companyId, adId),
    {
      failureMessage: 'Could not get sandbox items configurations',
    },
  )
);

const getRetailerSandboxItemSalesHistory = (
  companyId: number,
  adId: number,
  itemType: RetailerSandboxItemType,
  itemId: number,
): Promise<AxiosResponse<RetailerItemSalesHistoryResponse>> => (
  instance.get(
    endpoints.RETAILER_SANDBOX_ITEM_SALES_HISTORY(companyId, adId, itemType, itemId),
    {
      failureMessage: 'Could not get sales historical data',
    },
  )
);

const getRetailerSandboxItemPricesHistory = (
  companyId: number,
  adId: number,
  itemType: RetailerSandboxItemType,
  itemId: number,
): Promise<AxiosResponse<RetailerItemPricesHistoryResponse>> => (
  instance.get(
    endpoints.RETAILER_SANDBOX_ITEM_PRICES_HISTORY(companyId, adId, itemType, itemId),
    {
      failureMessage: 'Could not get prices historical data',
    },
  )
);

const finalizeRetailerSandbox = (
  companyId: number,
  adId: number,
): Promise<AxiosResponse<{ value: boolean }>> => (
  instance.put(
    endpoints.RETAILER_SANDBOX_FINALIZE(companyId, adId),
    undefined,
    {
      successMessage: 'The sandbox has been finalized',
      failureMessage: 'Something went wrong, failed to finalize sandbox',
      mixpanelEvent: {
        EventName: sandboxEvent.finalizeSandbox,
        Value: adId,
      },
    },
  )
);

const calculateRetailerSandbox = (
  companyId: number,
  adId: number,
): Promise<AxiosResponse<RetailerSandbox>> => (
  instance.put(
    endpoints.RETAILER_SANDBOX_CALCULATE(companyId, adId),
    undefined,
    {
      successMessage: 'Sandbox have been calculated',
      failureMessage: 'Something went wrong, could not calculate sandbox',
      mixpanelEvent: {
        EventName: sandboxEvent.calculateSandbox,
      },
    },
  )
);

const fillDownRetailerSandboxItemsDealCost = (
  companyId: number,
  adId: number,
  itemId: number,
): Promise<AxiosResponse<RetailerSandboxOffer>> => (
  instance.put(
    endpoints.RETAILER_SANDBOX_ITEM_DEAL_COST(companyId, adId, itemId),
    undefined,
    {
      successMessage: 'The items have been updated.',
      failureMessage: 'Something went wrong, could not add update Sandbox items',
      mixpanelEvent: {
        EventName: sandboxEvent.dealCostFillDown,
        Value: itemId,
      },
    },
  )
);

const updateRetailerSandboxProjectedAdVolumes = (
  companyId: number,
  adId: number,
): Promise<AxiosResponse<RetailerSandbox>> => (
  instance.put(
    endpoints.RETAILER_SANDBOX_UPDATE_PROJECTED_AD_VOLUMES(companyId, adId),
    undefined,
    {
      successMessage: 'The AI volumes successfully updated.',
      failureMessage: 'Something went wrong, could not update AI volumes',
    },
  )
);

const changeRetailerSandboxDepartmentOrder = (
  companyId: number,
  adId: number,
  data: RetailerSandboxChangeDepartmentOrderBody,
): Promise<void> => instance.put(
  endpoints.RETAILER_SANDBOX_DEPARTMENT_CHANGE_ORDER(companyId, adId),
  data,
  {
    successMessage: 'The department order has been updated.',
    failureMessage: 'Something went wrong, could not update department order',
  },
);

export default {
  getRetailerSandbox,
  finalizeRetailerSandbox,
  calculateRetailerSandbox,
  getRetailerSandboxStatus,
  updateRetailerSandboxItem,
  removeRetailerSandboxItem,
  updateRetailerSandboxOffer,
  removeRetailerSandboxOffer,
  uploadRetailerSandboxOffers,
  updateRetailerSandboxOffers,
  duplicateRetailerSandboxItem,
  duplicateRetailerSandboxOffer,
  removeRetailerSandboxAllItems,
  updateRetailerSandboxItemNames,
  updateRetailerSandboxOfferNames,
  changeRetailerSandboxItemStatus,
  getRetailerSandboxOrdersConfigs,
  getRetailerSandboxExcludedItems,
  getRetailerSandboxItemSalesHistory,
  removeRetailerSandboxExcludedItems,
  removeRetailerSandboxSelectedItems,
  getRetailerSandboxItemPricesHistory,
  fillDownRetailerSandboxItemsDealCost,
  changeRetailerSandboxDepartmentOrder,
  updateRetailerSandboxProjectedAdVolumes,
};
