import {
  useRef,
  Fragment,
  ReactNode,
  useCallback,
  MutableRefObject,
  MouseEventHandler,
} from 'react';
import {
  Box,
  Theme,
  SxProps,
  TableRow,
  Skeleton,
  TableCell,
  TableBody,
  TableHead,
  TableCellProps,
  LinearProgress,
  TableContainer,
  Table as MuiTable,
  TableProps as MuiTableProps,
} from '@mui/material';
import { SystemStyleObject } from '@mui/system';

import { Instance } from 'resources/types';

interface TableDataSource extends Instance {
  [key: string]: any;
}

interface TableColBasic {
  key?: string;
  title: string;
  minWidth?: number;
  maxWidth?: number;
  width: number | string;
  align?: TableCellProps['align'];
  renderTableHead?: ((title: string) => ReactNode);
}

interface WithDataKey<T> extends TableColBasic {
  dataKey: keyof T | '';
  renderTableData?: ((row: T) => ReactNode | any);
}

interface WithRenderTableData<T> extends TableColBasic {
  dataKey?: keyof T | '';
  renderTableData: ((row: T) => ReactNode | any);
}

export type TableCol<T extends TableDataSource> = WithDataKey<T> | WithRenderTableData<T>;

export interface TableProps<T extends TableDataSource> extends MuiTableProps {
  loading?: boolean;
  empty?: ReactNode;
  dataSource: Array<T>;
  actionLoading?: boolean;
  emptySymbol?: ReactNode;
  columns: Array<TableCol<T>>;
  paginationComponent?: ReactNode;
  headRef?: MutableRefObject<HTMLTableRowElement | null>;
  groupBy?: ((row: T) => string) | undefined;
  onRowClick?: ((row: T) => void) | undefined;
  setRowStyles?: ((row: T) => SxProps<Theme>) | undefined;
  headStyles?: (theme: Theme) => SystemStyleObject<Theme>;
  bodyStyles?: (theme: Theme, row: T, rowIndex: number) => SystemStyleObject<Theme>;
}

/* eslint-disable no-nested-ternary */

function Table<T extends TableDataSource>(props: TableProps<T>) {
  const {
    empty,
    loading,
    columns,
    headRef,
    dataSource,
    emptySymbol,
    actionLoading,
    groupBy,
    onRowClick,
    setRowStyles,
    bodyStyles,
    headStyles,
    paginationComponent,
  } = props;

  const headingRef = useRef<HTMLTableRowElement | null>(null);

  const rowClickHandler = useCallback(
    (row: T) => (
      () => {
        onRowClick?.(row);
      }
    ) as MouseEventHandler<HTMLTableRowElement>,
    [onRowClick],
  );

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
      }}
    >
      <TableContainer
        sx={{
          flex: 1,
          overflowX: 'auto',
          ...(loading ? { overflowY: 'hidden' } : {}),
        }}
      >
        <MuiTable>
          <TableHead>
            <TableRow
              ref={headRef || headingRef}
              sx={(theme: Theme) => ({
                top: 0,
                boxShadow: 2,
                position: 'sticky',
                zIndex: theme.zIndex.speedDial,
              })}
            >
              {columns.map((column) => (
                <TableCell
                  key={column.key}
                  variant="head"
                  align={column.align}
                  sx={(theme: Theme) => ({
                    width: column.width,
                    minWidth: column.minWidth,
                    maxWidth: column.maxWidth,
                    zIndex: theme.zIndex.speedDial + 2,
                    backgroundColor: theme.palette.grey[50],
                    fontWeight: theme.typography.fontWeightBold,
                    ...headStyles?.(theme),
                  })}
                >
                  {column.renderTableHead ? (
                    column.renderTableHead(column.title)
                  ) : (
                    column.title
                  )}
                </TableCell>
              ))}
            </TableRow>
            {actionLoading && (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  sx={(theme: Theme) => ({
                    p: 0,
                    position: 'sticky',
                    zIndex: theme.zIndex.speedDial,
                    top: ((headRef || headingRef)?.current?.offsetHeight || 0) - 2,
                  })}
                >
                  <LinearProgress />
                </TableCell>
              </TableRow>
            )}
          </TableHead>

          <TableBody>
            {loading ? (
              /* eslint-disable react/no-array-index-key */
              [...Array(25)].map((_, index: number) => (
                <TableRow key={index}>
                  {columns.map((column) => (
                    <TableCell
                      scope="row"
                      component="th"
                      sx={{
                        height: 30,
                        width: column.width,
                        minWidth: column.minWidth,
                        maxWidth: column.maxWidth,
                      }}
                    >
                      {column.title && (
                        <Skeleton variant="text" sx={{ height: 20 }} />
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              empty && !dataSource.length ? (
                <TableRow>
                  <TableCell variant="body" colSpan={columns.length}>
                    {empty}
                  </TableCell>
                </TableRow>
              ) : (
                dataSource.map((row: T, rowIndex: number) => {
                  const prevRow = dataSource[rowIndex - 1];
                  return (
                    <Fragment key={row?.id || rowIndex}>
                      {/* Separator */}
                      {(rowIndex === 0 || groupBy) && groupBy?.(row) !== groupBy?.(prevRow) && (
                        <TableRow
                          sx={(theme: Theme) => ({
                            position: 'sticky',
                            zIndex: theme.zIndex.speedDial,
                            top: ((headRef || headingRef)?.current?.offsetHeight || 0) - 2,

                            boxShadow: 2,
                            bgcolor: theme.palette.info.main,
                          })}
                        >
                          <TableCell
                            colSpan={columns.length}
                            sx={(theme: Theme) => ({
                              py: 0,
                              px: 2,
                              fontSize: 24,
                              fontWeight: 'bold',
                              fontStyle: 'italic',
                              color: theme.palette.common.white,
                            })}
                          >
                            {groupBy?.(row)}
                          </TableCell>
                        </TableRow>
                      )}

                      <TableRow
                        hover
                        sx={setRowStyles?.(row)}
                        onClick={rowClickHandler(row)}
                      >
                        {columns.map((column) => (
                          <TableCell
                            variant="body"
                            key={column.key}
                            align={column.align}
                            sx={(theme: Theme) => ({
                              width: column.width,
                              minWidth: column.minWidth,
                              maxWidth: column.maxWidth,
                              zIndex: theme.zIndex.speedDial + 1,
                              ...bodyStyles?.(theme, row, rowIndex),
                            })}
                          >
                            {column.renderTableData ? (
                              column.renderTableData(row)
                            ) : (
                              row[column.dataKey || ''] ?? emptySymbol
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    </Fragment>
                  );
                })
              )
            )}
          </TableBody>
        </MuiTable>
      </TableContainer>
      {paginationComponent}
    </Box>
  );
}

Table.defaultProps = {
  loading: false,
  emptySymbol: '',
  empty: undefined,
  groupBy: undefined,
  headRef: undefined,
  actionLoading: false,
  onRowClick: undefined,
  headStyles: undefined,
  bodyStyles: undefined,
  setRowStyles: undefined,
  paginationComponent: undefined,
};

export default Table;
