import moment from 'moment';
import { RefObject } from 'react';
import { matchRoutes } from 'react-router-dom';
import { OFFER_NAME_REGEX } from 'resources/constants';

import {
  AppRouteObject,
  AppRoutePlacement,
  HistoricalPriceType,
  Instance,
  RetailerAd,
  RetailerFreezeWeek,
  RetailerItemSalesHistory,
  RetailerSalesChartColors,
  RetailerSalesChartData,
  RetailerSalesChartHistoryComponent,
  RetailerSalesChartHistoryComponentItem,
  RetailerWeeklyMargins,
  UserScope,
} from 'resources/types';
import { roundNumber } from './numbers';

export * from './admin';
export * from './retailer';
export * from './numbers';

const MAX_ATTEMPT: number = 20;
const TIMEOUT_DELTA: number = 500;

export const trimUpc = (upc: string): string => (
  Number.parseInt(upc, 10) ? Number.parseInt(upc, 10).toString() : upc.toString()
);

export const getRoutesForPlacement = (
  routeObjects: AppRouteObject[],
  userScope: UserScope | null,
  placement: AppRoutePlacement,
): AppRouteObject[] => (
  routeObjects.reduce<AppRouteObject[]>((reducedRouteObjects, routeObject) => {
    if (routeObject.placements?.includes(placement) && routeObject.userScope === userScope) {
      return [...reducedRouteObjects, routeObject];
    }

    if (routeObject.children) {
      const childrenRouteObjects = getRoutesForPlacement(
        routeObject.children,
        userScope,
        placement,
      );
      return [...reducedRouteObjects, ...childrenRouteObjects];
    }

    return reducedRouteObjects;
  }, [])
);

export const getMatchedRoutes = (routeObjects: AppRouteObject[], pathname: string) => {
  const routMatches = matchRoutes(routeObjects, pathname);
  const activeRout = routMatches?.find((routMatch) => Boolean(routMatch?.route?.label));
  const label = activeRout?.route.label;
  document.title = label ? `${label} - Puzl` : 'Puzl Web Platform';
  return routMatches;
};

export const generateFullName = (firstName?: string, lastName?: string): string => (
  `${firstName || ''} ${lastName || ''}`
);

export const highlightSearchText = (
  inputString: string,
  searchedText: string,
  styles: string = 'font-weight: bold;',
) => {
  const words: Array<string> = searchedText.split(' ');
  const regex: RegExp = new RegExp(words.join('|'), 'gi');
  return inputString?.replace(regex, (str) => `<span style='${styles}'>${str}</span>`);
};

export const generateAvatarName = (): string => `avatar_${Date.now()}.png`;

export const downloadAdWeek = (
  file: File,
  adWeek: RetailerAd,
  contentDisposition?: string,
): Promise<unknown> => (
  new Promise((resolve, reject) => {
    try {
      const date: string = moment(adWeek.startDate).format('MM-DD-YY');
      const disposition = contentDisposition?.split(';')[1];
      const quotedFileName = disposition?.split('=')[1];
      const fileName = quotedFileName?.substring(1, quotedFileName.length - 1);

      const url: string = window.URL.createObjectURL(new Blob([file]));
      const link: HTMLAnchorElement = document.createElement('a');

      link.href = url;
      link.setAttribute('download', fileName || `${date} ${adWeek.adName}.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
      URL.revokeObjectURL(url);
      resolve({ success: true });
    } catch (error) {
      reject(error);
    }
  })
);

export const generateAdNameRange = (retailerAd: RetailerAd | RetailerFreezeWeek) => (
  `(${moment(retailerAd.startDate).format('MM/DD/YYYY')}-${moment(retailerAd.endDate).format('MM/DD/YYYY')})`
);

// TODO: Make timeout from 3 seconds to 0
export const retry = <T>(
  callback: () => Promise<T>,
  timeout: number = 3000,
  attemptNumber: number = 0,
): Promise<T | void> => (
    new Promise((resolve, reject) => {
      setTimeout(() => {
        callback()
          .then((result: T) => resolve(result))
          .catch(() => {
            if (attemptNumber >= MAX_ATTEMPT) {
              return reject();
            }
            return retry(callback, timeout + TIMEOUT_DELTA, attemptNumber + 1);
          });
      }, timeout);
    })
  );

export const changeCursorPosition = (
  inputRef: RefObject<HTMLInputElement>,
  cursorPosition: number,
) => {
  const inputElement: HTMLInputElement | null = inputRef?.current;
  if (inputElement === document.activeElement) {
    if (inputElement?.selectionStart) {
      inputElement?.setSelectionRange(cursorPosition, cursorPosition);
    }
    inputElement?.focus();
  }
};

export const convertFromArrayToObject = <Type extends Instance>(
  data: Type[],
): Record<number, Type> => (
    data.reduce((total, item) => (item.id ? { ...total, [item.id]: item } : total), {})
  );

/* eslint-disable no-param-reassign */
export const groupListItemsByKey = <Type extends { [key: string]: any }>(
  data: Array<Type>, key: string,
): { [key: string]: Array<Type> } => (
    data.reduce((total: { [key: string]: Array<Type> }, item: Type) => {
      (total[item[key]] = total[item[key]] || []).push(item);
      return total;
    }, {})
  );

export const validateItemLinkCode = (linkCode?: string): boolean => {
  if (linkCode) {
    return !linkCode.match(/^0+$/);
  }
  return false;
};

export const generateChartsData = (
  salesHistory: RetailerItemSalesHistory,
  historicalPriceType: HistoricalPriceType,
  weeklyMargins: RetailerWeeklyMargins | null,
): RetailerSalesChartData => {
  const years: Array<number> = [];
  const points: Array<{ key: string; year: number }> = [];
  let isEmpty = true;

  const aiItem: RetailerSalesChartHistoryComponentItem = {
    key: 'AI',
    year: 0,
    title: 'AI',
    name: 'AI',
    type: historicalPriceType,
    price: Number(salesHistory.aiOfferPrice),
    volume: roundNumber(Number(salesHistory.aiVolume), 1),
  };

  const weekNumbers = Object.keys(salesHistory.historyComponents)
    .map((weekNumber) => Number(weekNumber));

  // If there are first week and week older than 10
  // then we consider there are replacement of years in the chart
  const firstWeek = weekNumbers.includes(1);
  const oldWeek = Boolean(weekNumbers.find((weekNumber) => weekNumber > 10));

  const historyComponents: Array<RetailerSalesChartHistoryComponent> = weekNumbers
    .map((weekNumber: number) => {
      const weekData = salesHistory.historyComponents[Number(weekNumber)] || [];
      const postfix = (firstWeek && oldWeek) && (+weekNumber < 10) ? 'pro' : 'pre';

      if (weekData.length) isEmpty = false;

      const [firstItem] = weekData;
      const initialData = firstItem?.w === weeklyMargins?.weekNumber ? [aiItem] : [];

      const data: Array<RetailerSalesChartHistoryComponentItem> = weekData.reduce(
        (total: Array<RetailerSalesChartHistoryComponentItem>, item) => {
          const chartItem = {
            year: item.y,
            name: item.n,
            price: item.p,
            type: item.pt,
            volume: item.v,
            weekNumber: item.w,
          };

          if (chartItem.year != null && !years.includes(chartItem.year)) years.push(chartItem.year);
          if (!points.find((point) => point.key === `${chartItem.year}_${postfix}`)) {
            points.push({
              key: `${chartItem.year}_${postfix}`,
              year: chartItem.year,
            });
          }

          const result: Array<RetailerSalesChartHistoryComponentItem> = [
            ...total,
            {
              ...chartItem,
              title: String(chartItem.year),
              key: `${chartItem.year}_${postfix}`,
              volume: roundNumber(chartItem.volume, 1),
              price: roundNumber(Number(chartItem.price)),
            },
          ];

          if (weekNumber === 1) {
            const previousYear = chartItem.year - 1;
            result.push({
              year: previousYear,
              volume: roundNumber(chartItem.volume, 1),
              key: `${previousYear}_pre`,
              hidden: true,
            });
          }

          return result;
        },
        initialData,
      );

      return {
        weekNumber,
        name: `WEEK  ${weekNumber}`,
        data: data.sort((a, b) => (!b.year ? 1 : b.year - a.year)),
      };
    });

  return {
    isEmpty,
    ai: aiItem,
    historyComponents,
    years: years.sort().reverse(),
    points: points.sort((a, b) => a.year - b.year),
  };
};

export const generateAdWeekSetupTitle = (
  weeklyMargins: RetailerWeeklyMargins | null,
  priceChange: boolean = false,
): string => {
  // For Price Change upload always use this title
  if (priceChange) return 'Add New Items';
  if (!weeklyMargins) return '';
  const { status } = weeklyMargins;
  const isEndedWeek = moment().isAfter(weeklyMargins.endDate, 'day');
  const sandboxNotExists: boolean = (
    status !== 'NEW'
    && status !== 'NOT_READY'
    && status !== 'IN_MARGINS_SETUP'
  ) || isEndedWeek;
  if (sandboxNotExists) return 'Add New Items';
  const endDate = moment(weeklyMargins.endDate).format('DD/MM/YY');
  const startDate = moment(weeklyMargins.startDate).format('DD/MM/YY');
  return `${weeklyMargins.adName} Ad Sandbox (${startDate}-${endDate})`;
};

export const getSalesTableRowColor = (
  historyComponentItem: RetailerSalesChartHistoryComponentItem,
  salesChartsData: RetailerSalesChartData | null,
  colors: RetailerSalesChartColors,
) => {
  const { AI_COLOR, LINES_COLORS } = colors;
  if (!salesChartsData) return '';
  if (historyComponentItem.key === 'AI') return AI_COLOR;
  const colorIndex = salesChartsData.years.findIndex((year) => year === historyComponentItem.year);
  return LINES_COLORS[colorIndex];
};

export const getOfferNameError = (offerName: string): string => {
  if (!offerName) return 'Offer name must be set';
  if (!OFFER_NAME_REGEX.test(offerName)) {
    return (
      'Offer name can\'t only be or start with a space or a list of special characters.'
    );
  }
  return '';
};

export const getStoredValueForKey = (storageKey: string, key: string): string | null => {
  const storedData = localStorage.getItem(storageKey);
  if (storedData === null) {
    return null;
  }
  try {
    const jsonData = JSON.parse(storedData);
    return jsonData[key] ?? null;
  } catch (e) {
    return null;
  }
};

export const setStoredValueForKey = (
  storageKey: string,
  key: string,
  value: string,
) => {
  const storedData = localStorage.getItem(storageKey);
  if (storedData !== null) {
    let jsonData: Record<string, string>;
    try {
      jsonData = JSON.parse(storedData);
    } catch {
      jsonData = {};
    }
    jsonData[key] = value;
    localStorage.setItem(storageKey, JSON.stringify(jsonData));
  } else {
    const jsonData: Record<string, string> = { [key]: value };
    localStorage.setItem(storageKey, JSON.stringify(jsonData));
  }
};

export const removeStoredValueForKey = (
  storageKey: string,
  key: string,
) => {
  const storedData = localStorage.getItem(storageKey);
  if (storedData !== null) {
    try {
      const jsonData = JSON.parse(storedData);
      delete jsonData[key];
      localStorage.setItem(storageKey, JSON.stringify(jsonData));
    } catch (e) {
      // Do nothing
    }
  }
};

export function swapListElements<T>(
  list: T[],
  sourceIndex: number,
  targetIndex: number,
) {
  const slicedRows = [
    ...list.slice(0, sourceIndex),
    ...list.slice(sourceIndex + 1),
  ];
  return [
    ...slicedRows.slice(0, targetIndex),
    list[sourceIndex],
    ...slicedRows.slice(targetIndex),
  ];
}
