import * as yup from 'yup';

export const signInSchema = yup.object({
  email: yup
    .string()
    .email('Enter a valid email')
    .required('Email is required'),
  password: yup
    .string()
    .required('Password is required'),
});

export const signUpSchema = yup.object({
  password: yup
    .string()
    .required('Password is required'),
  confirmPassword: yup
    .string()
    .required('Password confirmation is required')
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
});

export const resetPasswordDataSchema = yup.object({
  password: yup
    .string()
    .required('Password is required'),
  confirmPassword: yup
    .string()
    .required('Password confirmation is required')
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
});

export const forgotPasswordSchema = yup.object({
  email: yup
    .string()
    .email('Enter a valid email')
    .required('Email is required'),
});

export const employeeSchema = yup.object({
  firstName: yup
    .string()
    .required('First Name is required'),
  lastName: yup
    .string()
    .required('Last Name is required'),
  email: yup
    .string()
    .email('Enter a valid email')
    .required('Email is required'),
  roles: yup
    .array().min(1, 'Please select at least one Role').of(yup.number()),
});

export const retailerSchema = yup.object({
  firstName: yup
    .string()
    .required('First Name is required'),
  lastName: yup
    .string()
    .required('Last Name is required'),
  email: yup
    .string()
    .email('Enter a valid email')
    .required('Email is required'),
  company: yup
    .string()
    .required('Company Name is required'),
});

export const storeSchema = yup.object({
  storeName: yup
    .string()
    .required('Store name is required'),
  storeNumber: yup
    .string()
    .required('Store number is required'),
  contactName: yup
    .string()
    .required('Manager name is required'),
  email: yup
    .string()
    .email('Enter a valid email')
    .required('Email is required'),
  address: yup
    .string()
    .required('Address is required'),
  city: yup
    .string()
    .required('City is required'),
  state: yup
    .string()
    .required('State is required'),
  zip: yup
    .string()
    .required('Zip is required'),
  retailerStoreGroup: yup
    .number()
    .required('Store group is required'),
  timeZone: yup
    .string()
    .required('Time zone is required'),
  phone: yup
    .string()
    .required('Phone is required'),
});

export const warehouseSchema = yup.object({
  warehouseName: yup
    .string()
    .required('Warehouse name is required'),
  contactName: yup
    .string()
    .required('Contact name is required'),
  address: yup
    .string()
    .required('Address name is required'),
  city: yup
    .string()
    .required('City name is required'),
  state: yup
    .string()
    .required('State name is required'),
  zip: yup
    .string()
    .required('Zip name is required'),
  email: yup
    .string()
    .email('Enter a valid email')
    .required('Email is required'),
});

export const companySchema = yup.object({
  companyName: yup
    .string()
    .required('Company name is required'),
  email: yup
    .string()
    .email('Enter a valid email')
    .required('Email is required'),
  city: yup
    .string()
    .required('City is required'),
  address: yup
    .string()
    .required('Address is required'),
  state: yup
    .string()
    .required('State name is required'),
  zip: yup
    .string()
    .required('Zip name is required'),
  phone: yup
    .string()
    .required('Phone name is required'),
});

export const profileSchema = yup.object({
  firstName: yup
    .string()
    .required('First name is required'),
  lastName: yup
    .string()
    .required('Last name is required'),
  position: yup
    .string()
    .required('Position is required'),
});

export const changePasswordSchema = yup.object({
  oldPassword: yup
    .string()
    .required('The old password is required'),
  newPassword: yup
    .string()
    .required('The new password is required'),
  repeatNewPassword: yup
    .string()
    .required('Password confirmation is required')
    .oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
});
