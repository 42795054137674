import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';

import { ROWS_PER_PAGES } from 'resources/constants';
import {
  RetailerAd,
  Pagination,
  RetailerFreezeWeek,
  RetailerWeeklyMargins,
  RetailerQuartersLifts,
  RetailerCategoryWeeklyMargin,
  RetailerDepartmentAnnualMargin,
  RetailerDepartmentWeeklyMargin,
  RetailerDepartmentCategorySuggestion,
} from 'resources/types';
import {
  decimalToPercent,
  convertFromArrayToObject,
  formatQuartersLiftsResponse,
  generateDepartmentCategorySuggestions,
} from 'utils';
import {
  getRetailerCurrentAds,
  getAvailableFreezeWeeks,
  getRetailerCurrentAdYear,
  getRetailerAvailableYears,
  getRetailerAdWeeklyMargins,
  getRetailerAdsQuartersLifts,
  changeRetailerCategoryOrder,
  confirmRetailerAdWeekMargins,
  updateRetailerAdsQuarterLift,
  updateRetailerAdReferenceWeek,
  changeRetailerDepartmentOrder,
  getRetailerCompanyAnnualMargin,
  updateRetailerCompanyAnnualMargin,
  updateRetailerCategoryAnnualMargin,
  updateRetailerCategoryWeeklyMargin,
  getRetailerDepartmentsAnnualMargins,
  updateRetailerDepartmentAnnualMargin,
  updateRetailerDepartmentWeeklyMargin,
  calculateRetailerAdWeekProjectedSales,
  fillDownRetailerDepartmentAnnualMargins,
  fillDownRetailerDepartmentWeeklyMargins,
  updateRetailerCategoryAnnualBudgetedShrink,
  updateRetailerCategoryWeeklyBudgetedShrink,
  updateRetailerDepartmentAnnualBudgetedShrink,
  getRetailerDepartmentsCategoriesWeeklyMargins,
  fillDownRetailerDepartmentWeeklyBudgetedShrinks,
  fillDownRetailerDepartmentAnnualBudgetedShrinks,
} from './thunks';

export interface MarginsState {
  items: Array<RetailerDepartmentAnnualMargin>;
  pagination: Pagination;
  loading: boolean;
  suggestions: Array<RetailerDepartmentCategorySuggestion>;
  actionLoading: boolean;
  calculationLoading: boolean;
  confirmationLoading: boolean;
  referenceWeekLoading: boolean;
  quartersLiftsLoading: boolean;
  openedDepartments: Array<number>;

  currentAds: Array<RetailerAd>;
  currentAdYear: number | null;
  quartersLifts: RetailerQuartersLifts;
  availableYears: Array<number>;
  selectedAdYear: number | null;
  companyAnnualMargin: number | null;

  weeklyMargins: RetailerWeeklyMargins | null;
  availableFreezeWeeks: Array<RetailerFreezeWeek>;
  categoriesWeeklyMargins: { [key: number]: RetailerCategoryWeeklyMargin };
  departmentsWeeklyMargins: { [key: number]: RetailerDepartmentWeeklyMargin };
  departmentsWeeklyBudgetShrinksInputValues: { [key: number]: number | null };
}

const initialState: MarginsState = {
  items: [],
  pagination: {
    page: 0,
    totalPages: 1,
    totalItems: 0,
    size: ROWS_PER_PAGES[0],
  },
  loading: false,
  suggestions: [],
  actionLoading: false,
  calculationLoading: false,
  confirmationLoading: false,
  quartersLiftsLoading: false,
  referenceWeekLoading: false,
  openedDepartments: [],

  currentAds: [],
  availableYears: [],
  quartersLifts: {
    Q1: null,
    Q2: null,
    Q3: null,
    Q4: null,
  },
  currentAdYear: null,
  selectedAdYear: null,
  companyAnnualMargin: null,

  weeklyMargins: null,
  availableFreezeWeeks: [],
  categoriesWeeklyMargins: {},
  departmentsWeeklyMargins: {},
  departmentsWeeklyBudgetShrinksInputValues: {},
};

/* eslint-disable no-param-reassign */

const marginsSlice = createSlice({
  name: 'retailer/margins',
  initialState,
  reducers: {
    reset: () => initialState,
    setOpenedDepartments: (state, action: PayloadAction<number>) => {
      state.openedDepartments = state.openedDepartments.includes(action.payload) ? (
        state.openedDepartments.filter((id) => id !== action.payload)
      ) : (
        [...state.openedDepartments, action.payload]
      );
    },
    setSelectedAdYear: (state, action: PayloadAction<number>) => {
      state.selectedAdYear = action.payload;
    },
    resetWeeklyMarginsData: (state) => {
      state.actionLoading = false;

      state.weeklyMargins = null;
      state.availableFreezeWeeks = [];
      state.categoriesWeeklyMargins = {};
      state.departmentsWeeklyMargins = {};
    },
    changeDepartmentWeeklyBudgetShrinkInputValue: (
      state,
      action: PayloadAction<{ departmentId: number, budgetShrink: number | null }>,
    ) => {
      const { departmentId, budgetShrink } = action.payload;
      state.departmentsWeeklyBudgetShrinksInputValues = {
        ...state.departmentsWeeklyBudgetShrinksInputValues,
        [departmentId]: budgetShrink,
      };
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getRetailerDepartmentsAnnualMargins.pending, (state, action) => {
      const { refetch } = action.meta.arg;
      if (!refetch) {
        state.loading = true;
      }
    });
    builder.addCase(getRetailerDepartmentsAnnualMargins.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getRetailerDepartmentsAnnualMargins.fulfilled, (state, action) => {
      const { refetch } = action.meta.arg;
      const {
        items, page, size, totalPages, totalItems,
      } = action.payload;
      const suggestions = generateDepartmentCategorySuggestions(items);
      state.items = items;
      state.pagination = {
        page, size, totalPages, totalItems,
      };
      state.loading = false;
      state.suggestions = suggestions;
      if (refetch) {
        state.actionLoading = false;
      }
    });

    builder.addCase(getRetailerCurrentAdYear.rejected, (state) => {
      state.currentAdYear = null;
      state.selectedAdYear = null;
    });
    builder.addCase(getRetailerCurrentAdYear.fulfilled, (state, action) => {
      state.currentAdYear = action.payload;
      state.selectedAdYear = action.payload;
    });

    builder.addCase(getRetailerCurrentAds.rejected, (state) => {
      state.currentAds = [];
    });
    builder.addCase(getRetailerCurrentAds.fulfilled, (state, action) => {
      state.currentAds = action.payload;
    });

    builder.addCase(getRetailerCompanyAnnualMargin.rejected, (state) => {
      state.companyAnnualMargin = null;
    });
    builder.addCase(getRetailerCompanyAnnualMargin.fulfilled, (state, action) => {
      state.companyAnnualMargin = decimalToPercent(action.payload);
    });

    builder.addCase(getRetailerAvailableYears.rejected, (state) => {
      state.availableYears = [];
    });
    builder.addCase(getRetailerAvailableYears.fulfilled, (state, action) => {
      state.availableYears = action.payload;
    });

    builder.addCase(updateRetailerCompanyAnnualMargin.fulfilled, (state, action) => {
      state.companyAnnualMargin = decimalToPercent(action.payload);
    });

    builder.addCase(getRetailerAdWeeklyMargins.rejected, (state) => {
      state.weeklyMargins = null;
    });
    builder.addCase(getRetailerAdWeeklyMargins.fulfilled, (state, action) => {
      state.weeklyMargins = action.payload;
    });

    builder.addCase(getAvailableFreezeWeeks.rejected, (state) => {
      state.availableFreezeWeeks = [];
    });
    builder.addCase(getAvailableFreezeWeeks.fulfilled, (state, action) => {
      state.availableFreezeWeeks = action.payload;
    });

    builder.addCase(getRetailerDepartmentsCategoriesWeeklyMargins.pending, (state) => {
      state.categoriesWeeklyMargins = {};
      state.departmentsWeeklyMargins = {};
    });
    builder.addCase(getRetailerDepartmentsCategoriesWeeklyMargins.rejected, (state) => {
      state.categoriesWeeklyMargins = {};
      state.departmentsWeeklyMargins = {};
    });
    builder.addCase(getRetailerDepartmentsCategoriesWeeklyMargins.fulfilled, (state, action) => {
      const [departmentsWeeklyMarginsArray, categoriesWeeklyMarginsArray] = action.payload;
      const categoriesWeeklyMargins = convertFromArrayToObject(categoriesWeeklyMarginsArray);
      const departmentsWeeklyMargins = convertFromArrayToObject(departmentsWeeklyMarginsArray);
      state.categoriesWeeklyMargins = categoriesWeeklyMargins;
      state.departmentsWeeklyMargins = departmentsWeeklyMargins;
      state.departmentsWeeklyBudgetShrinksInputValues = {};
      state.actionLoading = false;
    });

    builder.addCase(updateRetailerDepartmentWeeklyMargin.fulfilled, (state, action) => {
      const departmentWeeklyMargins = action.payload;
      state.departmentsWeeklyMargins = {
        ...state.departmentsWeeklyMargins,
        [departmentWeeklyMargins.id]: departmentWeeklyMargins,
      };
      state.actionLoading = false;
    });

    builder.addCase(fillDownRetailerDepartmentWeeklyBudgetedShrinks.fulfilled, (state, action) => {
      const { departmentId } = action.meta.arg;
      const [departmentWeeklyMargins, categoriesWeeklyMarginsArray] = action.payload;
      const categoriesWeeklyMargins = { ...state.categoriesWeeklyMargins };
      categoriesWeeklyMarginsArray.forEach((categoriesWeeklyMargin) => {
        categoriesWeeklyMargins[categoriesWeeklyMargin.id] = categoriesWeeklyMargin;
      });
      state.categoriesWeeklyMargins = categoriesWeeklyMargins;
      state.departmentsWeeklyMargins = {
        ...state.departmentsWeeklyMargins,
        [departmentWeeklyMargins.id]: departmentWeeklyMargins,
      };
      state.departmentsWeeklyBudgetShrinksInputValues = {
        ...state.departmentsWeeklyBudgetShrinksInputValues,
        [departmentId]: null,
      };
      state.actionLoading = false;
    });

    builder.addCase(calculateRetailerAdWeekProjectedSales.pending, (state) => {
      state.calculationLoading = true;
    });
    builder.addCase(calculateRetailerAdWeekProjectedSales.rejected, (state) => {
      state.calculationLoading = false;
    });
    builder.addCase(calculateRetailerAdWeekProjectedSales.fulfilled, (state, action) => {
      state.calculationLoading = false;
      state.weeklyMargins = action.payload;
    });

    builder.addCase(confirmRetailerAdWeekMargins.pending, (state) => {
      state.confirmationLoading = true;
    });
    builder.addCase(confirmRetailerAdWeekMargins.rejected, (state) => {
      state.confirmationLoading = false;
    });
    builder.addCase(confirmRetailerAdWeekMargins.fulfilled, (state, action) => {
      state.confirmationLoading = false;
      state.weeklyMargins = action.payload;
    });

    builder.addCase(updateRetailerAdReferenceWeek.pending, (state) => {
      state.referenceWeekLoading = true;
    });
    builder.addCase(updateRetailerAdReferenceWeek.rejected, (state) => {
      state.referenceWeekLoading = false;
    });
    builder.addCase(updateRetailerAdReferenceWeek.fulfilled, (state, action) => {
      state.referenceWeekLoading = false;
      state.weeklyMargins = action.payload;
    });

    builder.addCase(getRetailerAdsQuartersLifts.pending, (state) => {
      state.quartersLiftsLoading = true;
    });
    builder.addCase(getRetailerAdsQuartersLifts.rejected, (state) => {
      state.quartersLiftsLoading = false;
      state.quartersLifts = initialState.quartersLifts;
    });
    builder.addCase(getRetailerAdsQuartersLifts.fulfilled, (state, action) => {
      const quartersLifts = formatQuartersLiftsResponse(action.payload);
      state.quartersLiftsLoading = false;
      state.quartersLifts = quartersLifts;
    });

    builder.addCase(updateRetailerAdsQuarterLift.pending, (state) => {
      state.actionLoading = true;
      state.quartersLiftsLoading = true;
    });
    builder.addCase(updateRetailerAdsQuarterLift.rejected, (state) => {
      state.actionLoading = false;
      state.quartersLiftsLoading = false;
    });
    builder.addCase(updateRetailerAdsQuarterLift.fulfilled, (state, action) => {
      const quartersLifts = formatQuartersLiftsResponse(action.payload);
      state.actionLoading = false;
      state.quartersLiftsLoading = false;
      state.quartersLifts = quartersLifts;
    });

    builder.addMatcher(isAnyOf(
      updateRetailerCategoryWeeklyMargin.fulfilled,
      updateRetailerCategoryWeeklyBudgetedShrink.fulfilled,
    ), (state, action) => {
      const { categoryId } = action.meta.arg;
      const categoryWeeklyMargins = action.payload;
      state.categoriesWeeklyMargins = {
        ...state.categoriesWeeklyMargins,
        [categoryId]: categoryWeeklyMargins,
      };
      state.actionLoading = false;
    });

    builder.addMatcher(isAnyOf(
      fillDownRetailerDepartmentWeeklyMargins.fulfilled,
    ), (state, action) => {
      const categoriesWeeklyMarginsArray = action.payload;
      const categoriesWeeklyMargins = { ...state.categoriesWeeklyMargins };
      categoriesWeeklyMarginsArray.forEach((categoriesWeeklyMargin) => {
        categoriesWeeklyMargins[categoriesWeeklyMargin.id] = categoriesWeeklyMargin;
      });
      state.categoriesWeeklyMargins = categoriesWeeklyMargins;
      state.actionLoading = false;
    });

    builder.addMatcher(isAnyOf(
      changeRetailerDepartmentOrder.pending,
      changeRetailerCategoryOrder.pending,
      updateRetailerCategoryAnnualMargin.pending,
      updateRetailerCategoryWeeklyMargin.pending,
      updateRetailerDepartmentAnnualMargin.pending,
      updateRetailerDepartmentWeeklyMargin.pending,
      fillDownRetailerDepartmentAnnualMargins.pending,
      fillDownRetailerDepartmentWeeklyMargins.pending,
      updateRetailerCategoryAnnualBudgetedShrink.pending,
      updateRetailerCategoryWeeklyBudgetedShrink.pending,
      updateRetailerDepartmentAnnualBudgetedShrink.pending,
      getRetailerDepartmentsCategoriesWeeklyMargins.pending,
      fillDownRetailerDepartmentWeeklyBudgetedShrinks.pending,
      fillDownRetailerDepartmentAnnualBudgetedShrinks.pending,
    ), (state) => {
      state.actionLoading = true;
    });

    builder.addMatcher(isAnyOf(
      changeRetailerDepartmentOrder.rejected,
      changeRetailerCategoryOrder.rejected,
      updateRetailerCategoryAnnualMargin.rejected,
      updateRetailerCategoryWeeklyMargin.rejected,
      updateRetailerDepartmentWeeklyMargin.rejected,
      updateRetailerDepartmentAnnualMargin.rejected,
      fillDownRetailerDepartmentAnnualMargins.rejected,
      fillDownRetailerDepartmentWeeklyMargins.rejected,
      updateRetailerCategoryAnnualBudgetedShrink.rejected,
      updateRetailerCategoryWeeklyBudgetedShrink.rejected,
      updateRetailerDepartmentAnnualBudgetedShrink.rejected,
      getRetailerDepartmentsCategoriesWeeklyMargins.rejected,
      fillDownRetailerDepartmentWeeklyBudgetedShrinks.rejected,
      fillDownRetailerDepartmentAnnualBudgetedShrinks.rejected,
    ), (state) => {
      state.actionLoading = false;
    });

    builder.addMatcher(isAnyOf(
      updateRetailerDepartmentAnnualMargin.fulfilled,
      updateRetailerDepartmentAnnualBudgetedShrink.fulfilled,
    ), (state, action) => {
      const { departmentId } = action.meta.arg;
      const { marginTarget, averageWeeklyBudgetedShrinkVolume } = action.payload;
      state.actionLoading = false;
      state.items = state.items.map((department) => (
        department.id === departmentId ? (
          { ...department, marginTarget, averageWeeklyBudgetedShrinkVolume }
        ) : (
          department
        )
      ));
    });

    builder.addMatcher(isAnyOf(
      fillDownRetailerDepartmentAnnualMargins.fulfilled,
      fillDownRetailerDepartmentAnnualBudgetedShrinks.fulfilled,
    ), (state, action) => {
      const { departmentId } = action.meta.arg;
      const categories = action.payload;
      state.actionLoading = false;
      state.items = state.items.map((department) => (
        department.id === departmentId ? (
          { ...department, categories }
        ) : (
          department
        )
      ));
    });

    builder.addMatcher(isAnyOf(
      updateRetailerCategoryAnnualMargin.fulfilled,
      updateRetailerCategoryAnnualBudgetedShrink.fulfilled,
    ), (state, action) => {
      const { departmentId } = action.meta.arg;
      const categoryResponse = action.payload;
      state.actionLoading = false;
      state.items = state.items.map((department) => (
        department.id === departmentId ? ({
          ...department,
          categories: department.categories.map((category) => (
            category.id === categoryResponse.id ? ({
              ...category,
              marginTarget: categoryResponse.marginTarget,
              shrinkVolume: categoryResponse.shrinkVolume,
            }) : (
              category
            )
          )),
        }) : (
          department
        )
      ));
    });
  },
});

const {
  setSelectedAdYear,
  setOpenedDepartments,
  resetWeeklyMarginsData,
  reset: resetRetailerMarginsState,
  changeDepartmentWeeklyBudgetShrinkInputValue,
} = marginsSlice.actions;

export {
  setSelectedAdYear,
  setOpenedDepartments,
  getRetailerCurrentAds,
  resetWeeklyMarginsData,
  getAvailableFreezeWeeks,
  getRetailerCurrentAdYear,
  getRetailerAvailableYears,
  resetRetailerMarginsState,
  getRetailerAdWeeklyMargins,
  getRetailerAdsQuartersLifts,
  changeRetailerCategoryOrder,
  updateRetailerAdsQuarterLift,
  confirmRetailerAdWeekMargins,
  updateRetailerAdReferenceWeek,
  changeRetailerDepartmentOrder,
  getRetailerCompanyAnnualMargin,
  updateRetailerCompanyAnnualMargin,
  updateRetailerCategoryAnnualMargin,
  updateRetailerCategoryWeeklyMargin,
  getRetailerDepartmentsAnnualMargins,
  updateRetailerDepartmentAnnualMargin,
  updateRetailerDepartmentWeeklyMargin,
  calculateRetailerAdWeekProjectedSales,
  fillDownRetailerDepartmentAnnualMargins,
  fillDownRetailerDepartmentWeeklyMargins,
  updateRetailerCategoryAnnualBudgetedShrink,
  updateRetailerCategoryWeeklyBudgetedShrink,
  updateRetailerDepartmentAnnualBudgetedShrink,
  changeDepartmentWeeklyBudgetShrinkInputValue,
  getRetailerDepartmentsCategoriesWeeklyMargins,
  fillDownRetailerDepartmentWeeklyBudgetedShrinks,
  fillDownRetailerDepartmentAnnualBudgetedShrinks,
};

export default marginsSlice;
