import { AxiosResponse } from 'axios';

import { endpoints } from 'resources/constants';
import userServices from './user';
import adminServices from './admin';
import retailerServices from './retailer';
import instance, { signIn, signOut, checkAuthStatus } from './instance';

const getUsStates = (): Promise<AxiosResponse> => (
  instance.get(endpoints.US_STATES, { failureMessage: 'Could not get US states' })
);

const getTimeZones = (): Promise<AxiosResponse> => (
  instance.get(endpoints.TIME_ZONES, { failureMessage: 'Could not get time zones' })
);

const getRoles = (): Promise<AxiosResponse> => instance.get(endpoints.ROLES);

export default {
  signIn,
  signOut,
  getRoles,
  getUsStates,
  getTimeZones,
  checkAuthStatus,
  ...userServices,
  ...adminServices,
  ...retailerServices,
};
