import { useMemo } from 'react';
import {
  Grid,
  List,
  Zoom,
  Theme,
  ListItem,
  Typography,
  ListItemIcon,
} from '@mui/material';
import { Link, useLocation } from 'react-router-dom';

import { RootState } from 'store';
import { useAppSelector } from 'hooks';
import { routeObjects } from 'components/main';
import { getMatchedRoutes, getRoutesForPlacement } from 'utils';

const SPACING: number = 16;

function Menu() {
  const location = useLocation();
  const userState = useAppSelector((state: RootState) => state.user);
  const drawer = useAppSelector((state: RootState) => state.app.drawer);

  const {
    opened,
    closed,
    menuOpened,
    menuWidth,
  } = drawer;
  const { userScope } = userState;

  const routes = useMemo(
    () => getRoutesForPlacement(routeObjects, userScope, 'sidebar'),
    [userScope],
  );

  const matches = useMemo(
    () => getMatchedRoutes(routeObjects, location.pathname),
    [location.pathname],
  );

  return (
    <Grid container height="inherit" mt={8} sx={{ flex: 1 }}>
      <List
        sx={{
          padding: 1,
          display: 'flex',
          alignItems: 'start',
          flexDirection: 'column',
        }}
      >
        {routes.map((route) => {
          const isActive = matches?.find((routMatch) => routMatch.route.path === route.path);
          return (
            <ListItem
              key={route.path}
              disablePadding
              sx={(theme: Theme) => ({
                mt: 1,
                display: 'flex',
                cursor: 'pointer',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: menuWidth - SPACING,
                borderRadius: menuOpened ? 2 : SPACING,
                backgroundColor: isActive ? theme.palette.primary.light : 'unset',
                color: isActive ? theme.palette.common.white : theme.palette.template.contrastText,
                transition: theme.transitions.create(
                  ['background-color', 'color', 'width', 'border-radius'],
                  {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.standard,
                  },
                ),
                '&:hover': {
                  color: theme.palette.common.white,
                  backgroundColor: theme.palette.primary.main,
                },
              })}
            >
              <Zoom in timeout={600}>
                <Link
                  to={route.path || ''}
                  style={{ color: 'inherit', textDecoration: 'inherit' }}
                >
                  <Grid container flexWrap="nowrap" sx={{ width: opened }}>
                    <ListItemIcon
                      sx={{
                        display: 'flex',
                        color: 'inherit',
                        minWidth: 'unset',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: closed - SPACING,
                        height: closed - SPACING,
                      }}
                    >
                      {route.sidebarIcon}
                    </ListItemIcon>
                    <Zoom in={menuOpened} timeout={350}>
                      <Typography sx={{ display: 'flex', alignItems: 'center', color: 'inherit' }}>
                        {route.label}
                      </Typography>
                    </Zoom>
                  </Grid>
                </Link>
              </Zoom>
            </ListItem>
          );
        })}
      </List>
    </Grid>
  );
}

export default Menu;
