import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  RetailerRoundingRule,
  RetailerCategoryRoundingRule,
  RetailerDepartmentRoundingRule,
} from 'resources/types';
import services from 'services';
import { updateRoundingRuleForAPI } from 'utils';

const GET_RETAILER_DEFAULT_ROUNDING_RULES = 'retailer/rulesSetup/roundingRules/getRetailerDefaultRoundingRules';
const UPDATE_RETAILER_CATEGORY_LOCK_STATUS = 'retailer/rulesSetup/roundingRules/updateRetailerCategoryLockStatus';
const CREATE_RETAILER_DEFAULT_ROUNDING_RULE = 'retailer/rulesSetup/roundingRules/createRetailerDefaultRoundingRule';
const DELETE_RETAILER_DEFAULT_ROUNDING_RULE = 'retailer/rulesSetup/roundingRules/deleteRetailerDefaultRoundingRule';
const UPDATE_RETAILER_DEPARTMENT_LOCK_STATUS = 'retailer/rulesSetup/roundingRules/updateRetailerDepartmentLockStatus';
const UPDATE_RETAILER_DEFAULT_ROUNDING_RULES = 'retailer/rulesSetup/roundingRules/updateRetailerDefaultRoundingRules';
const CREATE_RETAILER_CATEGORY_ROUNDING_RULE = 'retailer/rulesSetup/roundingRules/createRetailerCategoryRoundingRule';
const DELETE_RETAILER_CATEGORY_ROUNDING_RULE = 'retailer/rulesSetup/roundingRules/deleteRetailerCategoryRoundingRule';
const UPDATE_RETAILER_CATEGORY_ROUNDING_RULES = 'retailer/rulesSetup/roundingRules/updateRetailerCategoryRoundingRules';
const GET_RETAILER_DEPARTMENTS_ROUNDING_RULES = 'retailer/rulesSetup/roundingRules/getRetailerDepartmentsRoundingRules';
const CREATE_RETAILER_DEPARTMENT_ROUNDING_RULE = 'retailer/rulesSetup/roundingRules/createRetailerDepartmentRoundingRule';
const DELETE_RETAILER_DEPARTMENT_ROUNDING_RULE = 'retailer/rulesSetup/roundingRules/deleteRetailerDepartmentRoundingRule';
const UPDATE_RETAILER_DEPARTMENT_ROUNDING_RULES = 'retailer/rulesSetup/roundingRules/updateRetailerDepartmentRoundingRules';
const FILL_DOWN_RETAILER_DEFAULT_ROUNDING_RULES = 'retailer/rulesSetup/roundingRules/fillDownRetailerDefaultRoundingRules';
const FILL_DOWN_RETAILER_DEPARTMENT_ROUNDING_RULES = 'retailer/rulesSetup/roundingRules/fillDownRetailerDepartmentRoundingRules';

const getRetailerDefaultRoundingRules = createAsyncThunk<
  Array<RetailerRoundingRule>,
  number
>(
  GET_RETAILER_DEFAULT_ROUNDING_RULES,
  async (companyId) => {
    const response = await services.getRetailerDefaultRoundingRules(companyId);
    return response.data;
  },
);

const createRetailerDefaultRoundingRule = createAsyncThunk<
  Array<RetailerRoundingRule>,
  {
    companyId: number;
    roundingRule: RetailerRoundingRule;
  }
>(
  CREATE_RETAILER_DEFAULT_ROUNDING_RULE,
  async (params) => {
    const { companyId, roundingRule } = params;
    await services.createRetailerDefaultRoundingRule(
      companyId,
      updateRoundingRuleForAPI(roundingRule),
    );
    const response = await services.getRetailerDefaultRoundingRules(companyId);
    return response.data;
  },
);

const deleteRetailerDefaultRoundingRule = createAsyncThunk<
  Array<RetailerRoundingRule>,
  {
    companyId: number;
    ruleId: number;
  }
>(
  DELETE_RETAILER_DEFAULT_ROUNDING_RULE,
  async (params) => {
    const { companyId, ruleId } = params;
    await services.deleteRetailerRoundingRule(companyId, ruleId);
    const response = await services.getRetailerDefaultRoundingRules(companyId);
    return response.data;
  },
);

const updateRetailerDefaultRoundingRules = createAsyncThunk<
  Array<RetailerRoundingRule>,
  {
    companyId: number;
    roundingRules: Array<RetailerRoundingRule>;
  }
>(
  UPDATE_RETAILER_DEFAULT_ROUNDING_RULES,
  async (params) => {
    const { companyId, roundingRules } = params;
    await Promise.all(roundingRules.map(
      (roundingRule) => services.updateRetailerDefaultRoundingRule(
        companyId,
        roundingRule.id,
        updateRoundingRuleForAPI(roundingRule),
      ),
    ));
    const response = await services.getRetailerDefaultRoundingRules(companyId);
    return response.data;
  },
);

const getRetailerDepartmentsRoundingRules = createAsyncThunk<
  Array<RetailerDepartmentRoundingRule>,
  number
>(
  GET_RETAILER_DEPARTMENTS_ROUNDING_RULES,
  async (companyId) => {
    const response = await services.getRetailerDepartmentsRoundingRules(companyId);
    return response.data.items;
  },
);

const updateRetailerDepartmentRoundingRules = createAsyncThunk<
  Array<RetailerRoundingRule>,
  {
    companyId: number;
    departmentId: number;
    roundingRules: Array<RetailerRoundingRule>;
  }
>(
  UPDATE_RETAILER_DEPARTMENT_ROUNDING_RULES,
  async (params) => {
    const { companyId, roundingRules, departmentId } = params;

    await Promise.all(roundingRules.map(
      (roundingRule) => services.updateRetailerDepartmentRoundingRule(
        companyId,
        roundingRule.id,
        updateRoundingRuleForAPI(roundingRule),
      ),
    ));
    const response = await services.getRetailerDepartmentRoundingRules(companyId, departmentId);
    return response.data;
  },
);

const createRetailerDepartmentRoundingRule = createAsyncThunk<
  Array<RetailerRoundingRule>,
  {
    companyId: number;
    departmentId: number;
    roundingRule: RetailerRoundingRule;
  }
>(
  CREATE_RETAILER_DEPARTMENT_ROUNDING_RULE,
  async (params) => {
    const { companyId, departmentId, roundingRule } = params;
    await services.createRetailerDepartmentRoundingRule(
      companyId,
      departmentId,
      updateRoundingRuleForAPI(roundingRule),
    );
    const response = await services.getRetailerDepartmentRoundingRules(companyId, departmentId);
    return response.data;
  },
);

const deleteRetailerDepartmentRoundingRule = createAsyncThunk<
  Array<RetailerRoundingRule>,
  {
    companyId: number;
    departmentId: number;
    ruleId: number;
  }
>(
  DELETE_RETAILER_DEPARTMENT_ROUNDING_RULE,
  async (params) => {
    const { companyId, departmentId, ruleId } = params;
    await services.deleteRetailerRoundingRule(companyId, ruleId);
    const response = await services.getRetailerDepartmentRoundingRules(companyId, departmentId);
    return response.data;
  },
);

const updateRetailerCategoryRoundingRules = createAsyncThunk<
  Array<RetailerRoundingRule>,
  {
    companyId: number;
    categoryId: number;
    departmentId: number;
    roundingRules: Array<RetailerRoundingRule>;
  }
>(
  UPDATE_RETAILER_CATEGORY_ROUNDING_RULES,
  async (params) => {
    const { companyId, roundingRules, categoryId } = params;
    await Promise.all(roundingRules.map(
      (roundingRule) => services.updateRetailerCategoryRoundingRule(
        companyId,
        roundingRule.id,
        updateRoundingRuleForAPI(roundingRule),
      ),
    ));
    const response = await services.getRetailerCategoryRoundingRules(companyId, categoryId);
    return response.data;
  },
);

const createRetailerCategoryRoundingRule = createAsyncThunk<
  Array<RetailerRoundingRule>,
  {
    companyId: number;
    categoryId: number;
    departmentId: number;
    roundingRule: RetailerRoundingRule;
  }
>(
  CREATE_RETAILER_CATEGORY_ROUNDING_RULE,
  async (params) => {
    const { companyId, categoryId, roundingRule } = params;
    await services.createRetailerCategoryRoundingRule(
      companyId,
      categoryId,
      updateRoundingRuleForAPI(roundingRule),
    );
    const response = await services.getRetailerCategoryRoundingRules(companyId, categoryId);
    return response.data;
  },
);

const deleteRetailerCategoryRoundingRule = createAsyncThunk<
  Array<RetailerRoundingRule>,
  {
    companyId: number;
    categoryId: number;
    departmentId: number;
    ruleId: number;
  }
>(
  DELETE_RETAILER_CATEGORY_ROUNDING_RULE,
  async (params) => {
    const { companyId, categoryId, ruleId } = params;
    await services.deleteRetailerRoundingRule(companyId, ruleId);
    const response = await services.getRetailerCategoryRoundingRules(companyId, categoryId);
    return response.data;
  },
);

const fillDownRetailerDefaultRoundingRules = createAsyncThunk<
  Array<RetailerDepartmentRoundingRule>,
  number
>(
  FILL_DOWN_RETAILER_DEFAULT_ROUNDING_RULES,
  async (companyId) => {
    const response = await services.fillDownRetailerDefaultRoundingRules(companyId);
    return response.data;
  },
);

const fillDownRetailerDepartmentRoundingRules = createAsyncThunk<
  RetailerDepartmentRoundingRule,
  {
    companyId: number;
    departmentId: number;
  }
>(
  FILL_DOWN_RETAILER_DEPARTMENT_ROUNDING_RULES,
  async (params) => {
    const { companyId, departmentId } = params;
    const response = await services.fillDownRetailerDepartmentRoundingRules(
      companyId,
      departmentId,
    );
    return response.data;
  },
);

const updateRetailerDepartmentLockStatus = createAsyncThunk<
  RetailerDepartmentRoundingRule,
  {
    companyId: number;
    departmentId: number;
    department: RetailerDepartmentRoundingRule;
  }
>(
  UPDATE_RETAILER_DEPARTMENT_LOCK_STATUS,
  async (params) => {
    const { companyId, department } = params;
    const response = await services.updateRetailerDepartmentLockStatus(
      companyId,
      department.id,
      { value: !department.locked },
    );
    return response.data;
  },
);

const updateRetailerCategoryLockStatus = createAsyncThunk<
  RetailerDepartmentRoundingRule,
  {
    companyId: number;
    departmentId: number;
    category: RetailerCategoryRoundingRule;
  }
>(
  UPDATE_RETAILER_CATEGORY_LOCK_STATUS,
  async (params) => {
    const { companyId, category } = params;
    const response = await services.updateRetailerCategoryLockStatus(
      companyId,
      category.id,
      { value: !category.locked },
    );
    return response.data;
  },
);

export {
  getRetailerDefaultRoundingRules,
  updateRetailerCategoryLockStatus,
  createRetailerDefaultRoundingRule,
  deleteRetailerDefaultRoundingRule,
  updateRetailerDepartmentLockStatus,
  updateRetailerDefaultRoundingRules,
  createRetailerCategoryRoundingRule,
  deleteRetailerCategoryRoundingRule,
  updateRetailerCategoryRoundingRules,
  getRetailerDepartmentsRoundingRules,
  createRetailerDepartmentRoundingRule,
  deleteRetailerDepartmentRoundingRule,
  fillDownRetailerDefaultRoundingRules,
  updateRetailerDepartmentRoundingRules,
  fillDownRetailerDepartmentRoundingRules,
};
