import { createSlice } from '@reduxjs/toolkit';

import { ROWS_PER_PAGES } from 'resources/constants';
import { RetailerEmployee, Pagination } from 'resources/types';
import {
  getRetailerEmployees,
  deleteRetailerEmployee,
  updateRetailerEmployee,
  createRetailerEmployee,
  getRetailerEmployeeById,
  makeOwnerRetailerEmployee,
  updateRetailerEmployeeStatus,
  resetRetailerEmployeePassword,
  resendRetailerEmployeeActivation,
} from './thunks';

export interface RetailerEmployeesState {
  items: Array<RetailerEmployee>;
  pagination: Pagination;
  currentAuthenticatedEmployee: RetailerEmployee | null;
  loading: boolean;
  actionLoading: boolean;
}

const initialState: RetailerEmployeesState = {
  items: [],
  pagination: {
    page: 0,
    totalPages: 1,
    totalItems: 0,
    size: ROWS_PER_PAGES[0],
  },
  currentAuthenticatedEmployee: null,
  loading: false,
  actionLoading: false,
};

/* eslint-disable no-param-reassign */

const retailerEmployeesSlice = createSlice({
  name: 'retailer/employees',
  initialState,
  reducers: {
    reset: () => initialState,
  },

  extraReducers: (builder) => {
    builder.addCase(getRetailerEmployees.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getRetailerEmployees.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getRetailerEmployees.fulfilled, (state, action) => {
      const {
        items, page, size, totalPages, totalItems,
      } = action.payload;
      state.loading = false;
      state.items = items;
      state.pagination = {
        page, size, totalPages, totalItems,
      };
    });

    builder.addCase(deleteRetailerEmployee.pending, (state) => {
      state.actionLoading = true;
    });
    builder.addCase(deleteRetailerEmployee.rejected, (state) => {
      state.actionLoading = false;
    });
    builder.addCase(deleteRetailerEmployee.fulfilled, (state) => {
      state.actionLoading = false;
    });

    builder.addCase(makeOwnerRetailerEmployee.pending, (state) => {
      state.actionLoading = true;
    });
    builder.addCase(makeOwnerRetailerEmployee.rejected, (state) => {
      state.actionLoading = false;
    });
    builder.addCase(makeOwnerRetailerEmployee.fulfilled, (state) => {
      state.actionLoading = false;
    });

    builder.addCase(updateRetailerEmployeeStatus.pending, (state) => {
      state.actionLoading = true;
    });
    builder.addCase(updateRetailerEmployeeStatus.rejected, (state) => {
      state.actionLoading = false;
    });
    builder.addCase(updateRetailerEmployeeStatus.fulfilled, (state, action) => {
      const { employeeId, locked } = action.payload;
      const items = state.items.map((employee) => (
        employee.id === employeeId ? { ...employee, locked } : employee
      ));
      state.items = items;
      state.actionLoading = false;
    });

    builder.addCase(getRetailerEmployeeById.rejected, (state) => {
      state.currentAuthenticatedEmployee = null;
    });
    builder.addCase(getRetailerEmployeeById.fulfilled, (state, action) => {
      state.currentAuthenticatedEmployee = action.payload;
    });

    builder.addCase(resetRetailerEmployeePassword.pending, (state) => {
      state.actionLoading = true;
    });
    builder.addCase(resetRetailerEmployeePassword.rejected, (state) => {
      state.actionLoading = false;
    });
    builder.addCase(resetRetailerEmployeePassword.fulfilled, (state) => {
      state.actionLoading = false;
    });

    builder.addCase(resendRetailerEmployeeActivation.pending, (state) => {
      state.actionLoading = true;
    });
    builder.addCase(resendRetailerEmployeeActivation.rejected, (state) => {
      state.actionLoading = false;
    });
    builder.addCase(resendRetailerEmployeeActivation.fulfilled, (state) => {
      state.actionLoading = false;
    });
  },
});

const { reset: resetRetailerEmployeesState } = retailerEmployeesSlice.actions;

export {
  getRetailerEmployees,
  updateRetailerEmployee,
  createRetailerEmployee,
  deleteRetailerEmployee,
  getRetailerEmployeeById,
  makeOwnerRetailerEmployee,
  resetRetailerEmployeesState,
  updateRetailerEmployeeStatus,
  resetRetailerEmployeePassword,
  resendRetailerEmployeeActivation,
};

export default retailerEmployeesSlice;
