import './styles.css';

function SplashScreen() {
  return (
    <div id="splash-screen">
      <div className="center">
        <div className="img-container">
          <img
            alt="logo"
            width="200"
            src="/puzl-logo.png"
            style={{ filter: 'drop-shadow(0px 10px 6px rgba(0, 0, 0, 0.2))' }}
          />
        </div>
        <div className="loader-container">
          <div className="loader" />
        </div>
      </div>
    </div>
  );
}

export default SplashScreen;
