import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ROWS_PER_PAGES } from 'resources/constants';
import { RetailerAd, Pagination } from 'resources/types';
import { getRetailerAds, exportRetailerAd, exportMultipleRetailerAds } from './thunks';

export interface RetailerAdWeeksState {
  items: Array<RetailerAd>;
  pagination: Pagination;
  selectedAds: Array<RetailerAd>;
  loading: boolean;
  exportLoadings: Array<number>;
  multipleExportLoading: boolean;
}

const initialState: RetailerAdWeeksState = {
  items: [],
  pagination: {
    page: 0,
    totalPages: 1,
    totalItems: 0,
    size: ROWS_PER_PAGES[0],
  },
  selectedAds: [],
  loading: false,
  exportLoadings: [],
  multipleExportLoading: false,
};

/* eslint-disable no-param-reassign */

const retailerAdWeeksSlice = createSlice({
  name: 'retailer/adWeeks',
  initialState,
  reducers: {
    reset: () => initialState,
    setSelectedAds: (
      state,
      action: PayloadAction<{ adWeek: RetailerAd, checked: boolean }>,
    ) => {
      const { adWeek, checked } = action.payload;
      state.selectedAds = checked ? (
        [...state.selectedAds, adWeek]
      ) : (
        state.selectedAds.filter((ad) => ad.id !== adWeek.id)
      );
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getRetailerAds.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getRetailerAds.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getRetailerAds.fulfilled, (state, action) => {
      const {
        items, page, size, totalPages, totalItems,
      } = action.payload;
      state.loading = false;
      state.items = items.map((item: RetailerAd) => ({ ...item, label: item.adName }));
      state.pagination = {
        page, size, totalPages, totalItems,
      };
    });

    builder.addCase(exportRetailerAd.pending, (state, action) => {
      const { adWeek } = action.meta.arg;
      state.exportLoadings = [...state.exportLoadings, adWeek.id];
    });
    builder.addCase(exportRetailerAd.rejected, (state, action) => {
      const { adWeek } = action.meta.arg;
      state.exportLoadings = state.exportLoadings.filter((adWeekId: number) => (
        adWeekId !== adWeek.id
      ));
    });
    builder.addCase(exportRetailerAd.fulfilled, (state, action) => {
      const { id } = action.payload;
      state.exportLoadings = state.exportLoadings.filter((adWeekId: number) => (
        adWeekId !== id
      ));
    });

    builder.addCase(exportMultipleRetailerAds.pending, (state, action) => {
      const { adWeeks } = action.meta.arg;
      state.multipleExportLoading = true;
      state.exportLoadings = adWeeks.map((adWeek) => adWeek.id);
    });
    builder.addCase(exportMultipleRetailerAds.rejected, (state) => {
      state.exportLoadings = [];
      state.multipleExportLoading = false;
    });
    builder.addCase(exportMultipleRetailerAds.fulfilled, (state) => {
      state.selectedAds = [];
      state.exportLoadings = [];
      state.multipleExportLoading = false;
    });
  },
});

const { reset: resetRetailerAdWeeksState, setSelectedAds } = retailerAdWeeksSlice.actions;

export {
  setSelectedAds,
  getRetailerAds,
  exportRetailerAd,
  exportMultipleRetailerAds,
  resetRetailerAdWeeksState,
};

export default retailerAdWeeksSlice;
